import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, {FC} from 'react';
import {Link} from 'react-router-dom';
import './BreadCrumbs.scss';
import {domId} from '../../dom';
import {PropsWithClassName} from '../../model';

interface BreadCrumbProps extends PropsWithClassName {
  link: string;
  text: string;
}

const BreadCrumbFc: FC<BreadCrumbProps> = (props) => {
  return (
    <li className={classnames('breadcrumbs__breadcrumb', props.className)}>
      <Link id={domId(props.text, 'breadCrumb')} to={props.link}>
        {props.text}
      </Link>
    </li>
  );
};

export default BreadCrumbFc;
