import React, {FC, PropsWithChildren, ReactElement} from 'react';

interface AccordionItemDescriptionFcProps extends PropsWithChildren {
  open: boolean;
  description?: ReactElement;
}

export const AccordionItemDescriptionFc: FC<AccordionItemDescriptionFcProps> = ({open, description}) => {
  return description && !open ? <div className="accordion-item__description">{description}</div> : <></>;
};
