"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const productTypes = [
    {
        id: 'POSITIONING',
        name: 'Positioning',
        countryId: 'NO',
        brandId: 'TELIA_NO',
        canSend: false,
        description: 'description',
        feeModels: [
            {
                description: 'Fee model for SMS_BULK',
                startDate: '2022-09-01',
                fees: [
                    {
                        feeTypeId: 'MONTHLY',
                        description: 'Monthly fee',
                        fixedAmount: 100,
                        steps: [],
                    },
                    {
                        feeTypeId: 'ESTABLISHMENT',
                        description: 'Establishment Fee',
                        fixedAmount: 50,
                        steps: [],
                    },
                ],
            },
        ],
    },
    {
        id: 'SMS_BULK',
        countryId: 'NO',
        brandId: 'TELIA_NO',
        canSend: true,
        feeModels: [
            {
                description: 'Fee model for SMS_BULK',
                startDate: '2022-09-01',
                fees: [
                    {
                        feeTypeId: 'MONTHLY',
                        description: 'Monthly fee',
                        fixedAmount: 1000,
                        steps: [],
                    },
                    {
                        feeTypeId: 'ESTABLISHMENT',
                        description: 'Establishment Fee',
                        fixedAmount: 500,
                        steps: [],
                    },
                ],
            },
        ],
    },
    {
        id: 'SMS_BULK_TELIA_SE',
        countryId: 'SE',
        brandId: 'TELIA_SE',
        canSend: true,
        feeModels: [
            {
                description: 'Fee model for SMS_BULK',
                startDate: '2022-09-01',
                fees: [
                    {
                        feeTypeId: 'MONTHLY',
                        description: 'Monthly fee',
                        fixedAmount: 1000,
                        steps: [],
                    },
                    {
                        feeTypeId: 'ESTABLISHMENT',
                        description: 'Establishment Fee',
                        fixedAmount: 500,
                        steps: [],
                    },
                ],
            },
        ],
    },
    {
        id: 'MO_LONG_NUMBER',
        countryId: 'NO',
        brandId: 'TELIA_NO',
        name: 'SMS Long Number',
        description: 'Receive MO SMS via Long Numbers',
        feeModels: [
            {
                description: 'Fee model for MO_LONG_NUMBER',
                startDate: '2022-09-01',
                fees: [
                    {
                        feeTypeId: 'MONTHLY',
                        description: 'Monthly fee',
                        fixedAmount: 0,
                        steps: [],
                    },
                    {
                        feeTypeId: 'ESTABLISHMENT',
                        description: 'Establishment Fee',
                        fixedAmount: 0,
                        steps: [],
                    },
                ],
            },
        ],
    },
];
exports.default = productTypes;
