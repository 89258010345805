"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getIsProtocolCredentialsRequired = exports.cloneObjectDeepness = exports.cloneObjectWithoutNullProperties = exports.objectProperty = void 0;
const model_1 = require("./model");
String.prototype.isEmpty = function () {
    return !this.length;
};
String.prototype.capitalize = function () {
    return this[0] !== undefined ? this[0].toUpperCase() + this.slice(1) : this;
};
String.prototype.equalsIgnoreCase = function (other) {
    return !!other && other.toUpperCase && this.toUpperCase() === other.toUpperCase();
};
String.prototype.before = function (search) {
    const index = this.indexOf(search);
    return index < 0 ? this : this.substring(0, index);
};
Array.prototype.isEmpty = function () {
    return !this.length;
};
Array.prototype.includes = function (arg) {
    return this.indexOf(arg) > -1;
};
Array.prototype.clone = function () {
    return this.slice();
};
Array.prototype.removeIndex = function (index) {
    this.splice(index, 1);
    return this;
};
Array.prototype.remove = function (item) {
    const i = this.indexOf(item);
    if (i !== -1) {
        this.removeIndex(i);
    }
    return this;
};
Array.prototype.cloneExcluding = function (...items) {
    const clone = this.clone();
    items.forEach((item) => clone.remove(item));
    return clone;
};
Array.prototype.cloneIncluding = function (...items) {
    const clone = this.clone();
    items.forEach((item) => clone.includes(item) || clone.push(item));
    return clone;
};
Array.prototype.cloneAfter = function (item) {
    const i = this.indexOf(item);
    return i === -1 ? [] : this.clone().splice(i + 1, this.length);
};
Array.prototype.cloneToggle = function (item) {
    let clone = this.slice();
    clone.includes(item) ? clone.remove(item) : clone.push(item);
    return clone;
};
Array.prototype.flatMap = function (lambda) {
    return [].concat.apply([], this.map(lambda));
};
Array.prototype.head = function () {
    return this[0];
};
Array.range = (start, end) => Array.from({ length: end - start + 1 }, (v, k) => k + start);
function objectProperty(obj, key) {
    return obj[key];
}
exports.objectProperty = objectProperty;
function cloneObjectWithoutNullProperties(obj) {
    if (obj === null || obj === undefined) {
        return {};
    }
    const keysOfObject = Object.keys(obj);
    let clone = keysOfObject.reduce((clone, key) => {
        const prop = objectProperty(obj, key);
        if (prop !== null && prop !== undefined) {
            clone[key] = obj[key];
        }
        return clone;
    }, {});
    return clone;
}
exports.cloneObjectWithoutNullProperties = cloneObjectWithoutNullProperties;
function cloneObjectDeepness(obj, deepness) {
    if (obj === null || obj === undefined || typeof obj === 'string' || typeof obj === 'number') {
        return obj;
    }
    if (obj instanceof Function) {
        return 'Function';
    }
    if (Array.isArray(obj)) {
        return 'Array';
    }
    //  last recursion deepness
    if (deepness === 0) {
        if (obj instanceof Error) {
            return 'Error: ' + obj.message;
        }
        return 'Object';
    }
    let clone = Object.keys(obj).reduce((clone, key) => {
        clone[key] = cloneObjectDeepness(obj[key], deepness && deepness - 1);
        return clone;
    }, {});
    return clone;
}
exports.cloneObjectDeepness = cloneObjectDeepness;
function getIsProtocolCredentialsRequired(protocol) {
    return !(protocol === model_1.EndpointProtocol.SMPP || !protocol);
}
exports.getIsProtocolCredentialsRequired = getIsProtocolCredentialsRequired;
