import {gql, useQuery} from '@apollo/client';
import {period} from '@telia/cpa-web-common';
import {
  PRODUCT_TYPE_EVENT,
  PRODUCT_TYPE_MO_CUSTOMER_NUMBER,
  PRODUCT_TYPE_MO_DIRECT_CHARGE_RANGE,
  PRODUCT_TYPE_MO_LONG_NUMBER,
  PRODUCT_TYPE_SMS_BULK,
} from '@telia/cpa-web-common/dist/constants';
import {SmppApiConfig} from '@telia/cpa-web-common/dist/model';

import productTypesQuery from '../graphql/query/productTypes.graphql';

import {getLog} from '../log';
import {Fee, ID, ProductType, WithId, hasFeeModels} from '../model';
import {useFees} from './useFees';

const log = getLog('useProductTypes', 'INFO');

const DEFAULT_QOS_MESSAGE_PRIORITY = 2;
const DEFAULT_QOS_MAX_SMS_PER_SECOND = 80;

export interface UseProductTypes {
  loading: boolean;
  productTypes: ProductType[];
  getBrandProductTypes: (brandId: ID) => ProductType[];
  getEstablishFee: (id: ID) => number | undefined;
  getMonthlyFee: (id: ID) => number | undefined;
  getFees: (id: ID) => Fee[];
  getName: (id: ID) => string;
  getDescription: (id: ID) => string;
  getAccessNumberDisplayName: (id: ID) => string;
  getDefaultQoSMessagePriority: (id: ID) => number;
  getDefaultQoSMaxSmsPerSecond: (id: ID) => number;
  isDirectChargeRange: (id: ID) => boolean;
  canSend: (id: ID) => boolean;
  usesAccessNumberRange: (id: ID) => boolean;
  usesAccessNumber: (id: ID) => boolean;
  usesPrefixNumber: (id: ID) => boolean;
  usesRbmAgentOwner: (id: ID) => boolean;
  usesProductName: (id: ID) => boolean;
  hasProductTechnical: (id: ID) => boolean;
  hasProductTechnicalApiCredentials: (id: ID) => boolean;
  hasProductTechnicalDr: (id: ID) => boolean;
  hasProductTechnicalMo: (id: ID) => boolean;
  canReserveOa: (id: ID) => boolean;
  brandHasCanSendProductType: (brandId: ID) => boolean;
  getProductTypeSmppApiDefaultConfig: (id: ID) => SmppApiConfig | undefined;
}

interface ProductTypesQuery {
  productTypes: ProductType[];
}

export const useProductTypes = () => {
  const {getEstablishFee: _getEstablishFee, getMonthlyFee: _getMonthlyFee} = useFees();
  const {loading, data: {productTypes = []} = {}} = useQuery<ProductTypesQuery>(gql(productTypesQuery));
  const _getProductType: (id: ID) => ProductType | undefined = (id) => {
    const productType = productTypes.find((pt: WithId) => pt.id === id);
    log.debug('productType', id, productType);
    return productType;
  };

  const getBrandProductTypes: (brandId: ID) => ProductType[] = (brandId) =>
    productTypes?.filter((productType) => productType.brandId === brandId);

  const getEstablishFee: (id: ID) => number | undefined = (id) => {
    const productType = _getProductType(id);
    const establishFee = hasFeeModels(productType) ? _getEstablishFee(productType) : undefined;
    log.debug('getEstablishFee', id, productType, establishFee);
    return establishFee;
  };

  const getMonthlyFee: (id: ID) => number | undefined = (id) => {
    const productType = _getProductType(id);
    const monthlyFee = hasFeeModels(productType) ? _getMonthlyFee(productType) : undefined;
    log.debug('getMonthlyFee', id, productType, monthlyFee);
    return monthlyFee;
  };

  const getFees: (id: ID) => Fee[] = (id) => {
    const productType = _getProductType(id);
    const fees = productType?.feeModels?.filter((feeModel) => period.isActive(feeModel)).flatMap<Fee>(({fees}) => fees);
    return fees || [];
  };

  const getName: (id: ID) => string = (id) => {
    const productType = _getProductType(id);
    const name = productType?.name || id;
    log.debug('getName', id, productType, name);
    return name;
  };

  const getDescription: (id: ID) => string = (id) => {
    const productType = _getProductType(id);
    const description = productType?.description || id;
    log.debug('getDescription', id, productType, description);
    return description;
  };

  const getAccessNumberDisplayName: (id: ID) => string = (id) => {
    const displayName =
      id.startsWith(PRODUCT_TYPE_MO_LONG_NUMBER) || id.startsWith(PRODUCT_TYPE_MO_CUSTOMER_NUMBER)
        ? 'Long Number'
        : 'Short Number';
    log.debug('getAccessNumberDisplayName', id, displayName);
    return displayName;
  };

  const getDefaultQoSMessagePriority: (id: ID) => number = (id) => {
    log.debug('getQoSMessagePriority', id);
    return DEFAULT_QOS_MESSAGE_PRIORITY; // TODO: Move to productType?
  };

  const getDefaultQoSMaxSmsPerSecond: (id: ID) => number = (id) => {
    log.debug('getQoSMaxSmsPerSecond', id);
    return DEFAULT_QOS_MAX_SMS_PER_SECOND; // TODO: Move to productType?
  };

  const isDirectChargeRange: (id: ID) => boolean = (id) => {
    return id.includes('DIRECT_CHARGE_RANGE');
  };

  //  Product Capabilities

  const canSend: (id: ID) => boolean = (id) => {
    const productType = _getProductType(id);
    const canSend = productType?.canSend || false;
    log.debug('getDescription', id, productType, canSend);
    return canSend;
  };

  const canReserveOa: (id: ID) => boolean = (id) => !!id.match('RESERVED_OA.*');

  const usesAccessNumberRange: (id: ID) => boolean = (id) => {
    const usesAccessNumberRange = id.includes('_RANGE');
    log.debug('usesAccessNumberRange', id, usesAccessNumberRange);
    return usesAccessNumberRange;
  };

  const usesAccessNumber: (id: ID) => boolean = (id) => {
    const usesAccessNumber =
      id.startsWith(PRODUCT_TYPE_SMS_BULK) ||
      (id.startsWith(PRODUCT_TYPE_MO_LONG_NUMBER) && !id.includes('_RANGE')) ||
      id.startsWith(PRODUCT_TYPE_EVENT);
    log.debug('usesAccessNumber', id, usesAccessNumber);

    return usesAccessNumber;
  };

  const usesPrefixNumber: (id: ID) => boolean = (id) => {
    const usesPrefixNumber = id.startsWith(PRODUCT_TYPE_MO_CUSTOMER_NUMBER);
    log.debug('usesPrefixNumber', id, usesPrefixNumber);

    return usesPrefixNumber;
  };

  const usesRbmAgentOwner: (id: ID) => boolean = (id) => {
    return id.startsWith('RBM');
  };

  const usesProductName: (id: ID) => boolean = (id) => {
    return canReserveOa(id);
  };

  const hasProductTechnicalApiCredentials: (id: ID) => boolean = (id) => {
    return canSend(id);
  };

  const hasProductTechnicalDr: (id: ID) => boolean = (id) => {
    return canSend(id);
  };

  const hasProductTechnicalMo: (id: ID) => boolean = (id) => {
    const hasProductTechnicalMo =
      id.startsWith(PRODUCT_TYPE_SMS_BULK) ||
      id.startsWith(PRODUCT_TYPE_MO_LONG_NUMBER) ||
      id.startsWith(PRODUCT_TYPE_MO_DIRECT_CHARGE_RANGE) ||
      id.startsWith(PRODUCT_TYPE_MO_CUSTOMER_NUMBER);
    log.debug('hasProductTechnicalMo', id, hasProductTechnicalMo);

    return hasProductTechnicalMo;
  };

  const hasProductTechnical: (id: ID) => boolean = (id) => {
    return hasProductTechnicalApiCredentials(id) || hasProductTechnicalDr(id) || hasProductTechnicalMo(id);
  };

  const brandHasCanSendProductType = (brandId: ID): boolean => {
    return !!getBrandProductTypes(brandId).filter(({id}) => canSend(id)).length;
  };

  const getProductTypeSmppApiDefaultConfig = (id: ID) => {
    return _getProductType(id)?.smppApiConfigDefaults;
  };

  const useProductTypes: UseProductTypes = {
    loading,
    productTypes,
    getBrandProductTypes,
    getEstablishFee,
    getMonthlyFee,
    getFees,
    getName,
    getDescription,
    getAccessNumberDisplayName,
    getDefaultQoSMessagePriority,
    getDefaultQoSMaxSmsPerSecond,
    isDirectChargeRange,
    canSend,
    usesAccessNumberRange,
    usesAccessNumber,
    usesPrefixNumber,
    usesProductName,
    hasProductTechnical,
    hasProductTechnicalApiCredentials,
    hasProductTechnicalDr,
    hasProductTechnicalMo,
    usesRbmAgentOwner,
    canReserveOa,
    brandHasCanSendProductType,
    getProductTypeSmppApiDefaultConfig,
  };
  log.debug('render', useProductTypes);
  return useProductTypes;
};
