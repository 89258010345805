import {format} from '@telia/cpa-web-common';
import classnames from 'classnames';
import React, {Dispatch, LegacyRef, MutableRefObject, PropsWithChildren, ReactNode, RefObject} from 'react';
import {Checkbox, FieldCheckboxProps, isFieldCheckboxProps} from './_Checkbox';
import {DurationTipFc} from './_DurationTip';
import {FieldMultiCheckboxProps, isFieldMultiCheckboxProps, MultiCheckbox} from './_MultiCheckbox';
import {FieldSelectProps, isFieldSelectProps, resolveSelectTextValue, Select} from './_Select';
import {FieldTextareaProps, isFieldTextareaProps, Textarea} from './_Textarea';
import './Field.scss';
import {Icon} from '../Icon';
import Tooltip from '../Tooltip';
import {getLog} from '../../../log';
import {Duration, CurrencyCode} from '../../../model';
import {currency} from '@telia/cpa-web-common/dist/format';
import {hasDecimals} from '../../../../../common/src/format';
import {toast} from 'react-toastify';
import * as AppRoutes from '../../../appRoutes';
import {IconDefinition} from '@telia/styleguide';
import {IconLink} from '../IconLink';

const log = getLog('Field', 'INFO');

let _uniqueId = 0;
const getUniqueId = (typeShortName: string) => `field-${typeShortName}-${_uniqueId++}`;

const newLineToBr = (value: string) =>
  value && value.match && value.match(/(\r\n|\r|\n)/)
    ? value
        .replace(/(?:\r\n|\r)/g, '\n')
        .split('\n')
        .map((line, key) => {
          return (
            <React.Fragment key={key}>
              {line}
              <br />
            </React.Fragment>
          );
        })
    : value;

export type FieldValue = string | string[] | boolean | number | null;

export const FieldTypes = {
  checkbox: '_field_type_checkbox',
  date: '_field_type_date',
  time: '_field_type_time',
  element: '_field_type_element',
  input: '_field_type_input',
  multi: '_field_type_multi',
  number: '_field_type_number',
  password: '_field_type_password',
  select: '_field_type_select',
  textarea: '_field_type_textarea',
  duration: '_field_type_duration',
  currency: '_field_type_currency',
} as const;
type FieldTypesType = typeof FieldTypes;
export type FieldType = keyof FieldTypesType;
type FieldTypeValue = FieldTypesType[keyof FieldTypesType];
const typeShortName = (type: FieldTypeValue) => type.replace('_field_type_', '');

type FieldInputType =
  | typeof FieldTypes.date
  | typeof FieldTypes.time
  | typeof FieldTypes.input
  | typeof FieldTypes.number
  | typeof FieldTypes.password
  | typeof FieldTypes.checkbox
  | typeof FieldTypes.duration
  | typeof FieldTypes.currency;

function isFieldElementProps(props: FieldProps): props is FieldElementProps {
  return props.type === FieldTypes.element;
}

function isFieldInputProps(props: FieldProps): props is FieldInputProps {
  return (
    !props.type ||
    [
      FieldTypes.checkbox,
      FieldTypes.date,
      FieldTypes.time,
      FieldTypes.input,
      FieldTypes.multi,
      FieldTypes.number,
      FieldTypes.password,
      FieldTypes.duration,
      FieldTypes.currency,
    ].includes(props.type as unknown as FieldInputType)
  );
}

export interface FieldDurationProps extends FieldCommonProps {
  type: typeof FieldTypes.duration;
  value?: Duration;
}

function isFieldDurationProps(props: FieldProps): props is FieldDurationProps {
  return props.type === FieldTypes.duration;
}

export interface FieldCurrencyProps extends FieldCommonProps {
  type: typeof FieldTypes.currency;
  value?: number;
  currencyCode: CurrencyCode;
}

function isFieldCurrencyProps(props: FieldProps): props is FieldCurrencyProps {
  return props.type === FieldTypes.currency;
}

export interface FieldCommonProps extends PropsWithChildren {
  autocomplete?: string;
  button?: ReactNode; //  top right optional extra button
  className?: string; //  CSS class names for the Field element
  error?: string;
  id?: string; //  html id
  inputRef?: any; //LegacyRef<HTMLInputElement | undefined>;
  isDisabled?: boolean;
  isEditing?: boolean;
  label?: string;
  name?: string; //  html name
  onChange?: Dispatch<FieldValue>;
  tip?: any; //string | number | JSX.Element | null | false;
  // type: FieldType;
  // value?: FieldValue;
  valueClassName?: string; //  CSS class names for the value element
}

export interface FieldInputProps extends FieldCommonProps {
  icon?: IconDefinition;
  placeholder?: string;
  type?: FieldInputType;
  value?: string | number;
  defaultValue?: string | number; //  What to shown when field has no (or null) value
  currencyCode?: CurrencyCode;
  linkTo?: string | ((value: string) => string);
}

export interface FieldElementProps extends FieldCommonProps {
  icon?: string;
  type: typeof FieldTypes.element;
  value?: React.ReactElement;
}

export type FieldProps =
  | FieldInputProps
  | FieldSelectProps
  | FieldTextareaProps
  | FieldCheckboxProps
  | FieldMultiCheckboxProps
  | FieldElementProps;

export const Field: React.FunctionComponent<FieldProps> & FieldUtils = (props) => {
  const {
    button,
    className,
    isDisabled = false,
    error,
    isEditing = false,
    inputRef,
    label,
    name,
    onChange,
    tip,
    type = FieldTypes.input,
    value,
    autocomplete,
  } = props;

  log.debug('render', props.value);

  const uniqueId = getUniqueId(typeShortName(type)); // TODO: useMemo(getUniqueId());

  const hasLink = isFieldInputProps(props) && props.linkTo;

  const FieldValueFc = () => (
    <div
      className={classnames('field__value', props.valueClassName, {
        'field__value--default': !Field.hasValue(value),
        'field__value--link': hasLink,
      })}
      onDoubleClick={() => {
        navigator.clipboard.writeText(Field.resolveValue(props).toString());
        toast.success('Copied ' + props.label + ' to clipboard', {autoClose: 2500});
      }}
    >
      {isFieldElementProps(props)
        ? props.value || props.children
        : newLineToBr(hasValue(Field.resolveValue(props)) ? '' + Field.resolveValue(props) : '\u00A0')}
    </div>
  );

  return (
    <div
      className={classnames(
        'field',
        {'field--disabled': isDisabled},
        isEditing ? 'field--editing' : 'field--displaying',
        `field--${typeShortName(type)}`,
        className
      )}
    >
      {isFieldMultiCheckboxProps(props) ? (
        <fieldset>
          {label && <legend className="label">{label}</legend>}
          {tip && <Tooltip>{tip}</Tooltip>}
          {button}
          <MultiCheckbox {...props} />
        </fieldset>
      ) : isFieldCheckboxProps(props) ? (
        <>
          <Checkbox id={uniqueId} {...props} />
          {tip && <Tooltip text={tip} />}
        </>
      ) : (
        <>
          {label && (
            <label className={`label`} htmlFor={isEditing ? uniqueId : undefined}>
              {label}
            </label>
          )}
          {hasLink && (
            <IconLink
              linkTo={
                isString(props.linkTo)
                  ? AppRoutes.format(props.linkTo, Field.resolveValue(props).toString())
                  : (props.linkTo as Function)(Field.resolveValue(props).toString())
              }
              icon="open"
              info={'Open link'}
              className="field--linkTo__icon"
            />
          )}
          {/*{hasLink && <Icon className="field__value__icon" icon="open" />}*/}
          {(tip && <Tooltip>{tip}</Tooltip>) ||
            (isFieldDurationProps(props) && isEditing && <Tooltip>{<DurationTipFc />}</Tooltip>)}
          {button}
          {isEditing ? (
            <div className="field__value__wrapper">
              {
                isFieldInputProps(props) && props.icon && isEditing && (
                  <Icon className="field__value__icon" icon={props.icon} />
                )
                //  TODO: styleguide Icons && position
              }
              {isFieldTextareaProps(props) ? (
                <Textarea id={uniqueId} {...props} />
              ) : isFieldSelectProps(props) ? (
                <Select id={uniqueId} {...props} />
              ) : (
                <input
                  id={uniqueId}
                  name={name}
                  type={
                    type === FieldTypes.password
                      ? 'password'
                      : type === FieldTypes.date
                      ? 'date'
                      : type === FieldTypes.time
                      ? 'time'
                      : type === FieldTypes.number || type === FieldTypes.currency
                      ? 'number'
                      : 'text'
                  }
                  className={classnames(
                    'field__value',
                    {'field__input--date': type === FieldTypes.date},
                    {'field__input--time': type === FieldTypes.time},
                    props.valueClassName
                  )}
                  disabled={isDisabled}
                  placeholder={isFieldInputProps(props) ? Field.resolvePlaceholder(props) : undefined}
                  value={Field.resolveValue(props)}
                  onChange={(e) =>
                    onChange &&
                    onChange(
                      type === FieldTypes.number && e.target.value && !isNaN(Number(e.target.value))
                        ? Number(e.target.value)
                        : e.target.value
                    )
                  }
                  ref={inputRef}
                  autoComplete={autocomplete}
                />
              )}
            </div>
          ) : (
            <FieldValueFc />
          )}
        </>
      )}
      {error && <span className="input-error">{error}</span>}
    </div>
  );
};

function hasValue(str: string | number | undefined): str is string | number {
  const has = str !== null && str !== undefined;
  log.debug('hasValue', str, has);
  return has;
}

function isString(str: any | null | undefined): str is string {
  const isString = hasValue(str) && typeof str === 'string';
  log.debug('isString', str, isString);
  return isString;
}

Field.resolvePlaceholder = ({defaultValue, placeholder, label}) =>
  Field.hasValue(defaultValue) ? '' + defaultValue : placeholder || label || '';

Field.hasValue = (value) => value !== null && value !== undefined;

Field.resolveValue = (props: FieldInputProps) => {
  const {defaultValue, isEditing, label, type, value, currencyCode} = {currencyCode: undefined, ...props};
  return isFieldSelectProps(props)
    ? resolveSelectTextValue(props)
    : hasValue(value)
    ? type === FieldTypes.date
      ? value && format.date(value)
      : type === FieldTypes.password && !isEditing
      ? '*'.repeat((isString(value) && value?.length) || 0)
      : type === FieldTypes.duration && !isEditing
      ? format.duration(value as Duration)
      : type === FieldTypes.number && !isEditing
      ? hasDecimals(value as number)
        ? format.decimal(value as number)
        : format.integer(value as number)
      : type === FieldTypes.currency && !isEditing
      ? format.currency(value as number, currencyCode!)
      : value
    : isEditing
    ? ''
    : hasValue(defaultValue)
    ? type === FieldTypes.duration
      ? format.duration(defaultValue as Duration)
      : type === FieldTypes.number
      ? hasDecimals(defaultValue as number)
        ? format.decimal(defaultValue as number)
        : format.integer(defaultValue as number)
      : type === FieldTypes.currency && !!currencyCode
      ? format.currency(defaultValue as number, currencyCode!)
      : defaultValue
    : hasValue(label)
    ? label
    : '';
};

interface FieldUtils {
  resolvePlaceholder: (props: FieldInputProps | FieldTextareaProps) => string;
  hasValue: (value?: any) => boolean;
  resolveValue: (props: FieldProps) => string | number;
}

export default Field;
