import {ApolloCache, gql} from '@apollo/client';
import {userComparator} from '@telia/cpa-web-common/dist/sort';
import {Button} from '@telia/styleguide';
import React, {FC} from 'react';
import {useNavigate, useParams} from 'react-router-dom';

import userFragment from '../../graphql/fragment/user.graphql';
import approveOrRejectUserMutation from '../../graphql/mutation/approveOrRejectUser.graphql';
import pendingUsersQuery from '../../graphql/query/pendingUsers.graphql';
import teliaUsersQuery from '../../graphql/query/teliaUsers.graphql';

import * as AppRoutes from '../../appRoutes';
import {UseApolloCacheEntityProps, useApolloCacheEntity} from '../../hooks/useApolloCacheEntity';
import {useFormState} from '../../hooks/useFormState';
import {useMutationWrap} from '../../hooks/useMutationWrap';
import {getLog} from '../../log';
import {ID, User, UserAccessStatusType} from '../../model';
import Form from '../common/Form';
import FormColumn from '../common/FormColumn';
import FormRow from '../common/FormRow';
import Page from '../common/Page';
import PageSubtitle from '../common/PageSubtitle';
import {PageViewCounter} from '../metrics/PageViewCounter';
import {SelfServiceUser} from '../shared/SelfServiceUser';

const log = getLog('PendingUser', 'INFO');

export const PendingUser: FC = (props) => {
  const {userId} = useParams<{userId: ID}>() as {userId: ID};

  const navigate = useNavigate();
  const {formatWithBrand} = AppRoutes.useBrandFormat();

  const useApolloCacheEntityProps: UseApolloCacheEntityProps = {
    fragment: userFragment,
    entityId: userId,
    newEntity: {
      firstName: null,
      lastName: null,
      email: null,
      phone: null,
      rolesMap: [],
    },
  };

  const formStateOptions = useApolloCacheEntity(useApolloCacheEntityProps);
  const formState = useFormState({...formStateOptions, isEditing: false});

  const approveOrRejectUser = useMutationWrap<{id: ID}, {id: ID; userAccessStatusType: ID}>(
    gql(approveOrRejectUserMutation)
  );

  const addUserToCachedApprovedList = (proxy: ApolloCache<any>, user: User) => {
    const {teliaUsers = []} = proxy.readQuery<{teliaUsers: User[]}>({query: gql(teliaUsersQuery), variables: {}}) || {};
    const updatedTeliaUsers = [...teliaUsers, user].sort(userComparator);
    proxy.writeQuery({query: gql(teliaUsersQuery), data: {teliaUsers: updatedTeliaUsers}});
  };

  const removeUserFromCachedPendingList = (proxy: ApolloCache<any>, user: User) => {
    const {pendingUsers = []} =
      proxy.readQuery<{pendingUsers: User[]}>({
        query: gql(pendingUsersQuery),
        variables: {},
      }) || {};
    const updatedPendingUsers = pendingUsers.filter((pendingUser) => pendingUser.id !== user.id);
    proxy.writeQuery({query: gql(pendingUsersQuery), data: {pendingUsers: updatedPendingUsers}});
  };

  const onApprove = () => {
    approveOrRejectUser({
      loadingText: 'Approving user...',
      successText: 'User approved',
      variables: {
        id: userId,
        userAccessStatusType: UserAccessStatusType.GRANTED,
      },
      update: (proxy, {data}) => {
        const approvedUser = formState.entityAs<User>();
        approvedUser.userAccessStatusType = UserAccessStatusType.GRANTED;
        log.debug('approveOrRejectUser update', userId);

        addUserToCachedApprovedList(proxy, approvedUser);
        removeUserFromCachedPendingList(proxy, approvedUser);

        // Go to management page of approved user
        navigate(formatWithBrand(AppRoutes.MANAGEMENT_USER__userId, approvedUser.id), {replace: true});
      },
    });
  };

  const onReject = () => {
    approveOrRejectUser({
      loadingText: 'Rejecting user...',
      successText: 'User rejected',
      variables: {
        id: userId,
        userAccessStatusType: UserAccessStatusType.REJECTED,
      },
      update: (proxy, {data}) => {
        // const {id: userId} = data || {};
        const rejectedUser = formState.entityAs<User>();
        log.debug('approveOrRejectUser update', userId);

        removeUserFromCachedPendingList(proxy, rejectedUser);

        navigate(formatWithBrand(AppRoutes.MANAGEMENT_PENDING_USERS), {replace: true});
      },
    });
  };

  return (
    <Page>
      <PageSubtitle subtitle="Approve or Reject User" />
      <PageViewCounter page="pending-user" />

      <p>
        The following user has applied for access to Telia Bulk Messaging Web. By approving the users application they
        are given access to Telia Bulk Messaging Web.
      </p>
      <Form>
        <SelfServiceUser formState={formState} />
        <FormRow>
          <FormColumn>
            <div>
              <Button text="Approve" onClick={onApprove} kind={Button.kinds.primary} />
              <Button text="Reject" onClick={onReject} kind={Button.kinds.negative} />
            </div>
          </FormColumn>
        </FormRow>
      </Form>
    </Page>
  );
};
