import {getTimezonesWithCountryCode} from 'country-timezone';
import {useMemo} from 'react';

import {getLog} from '../log';
import {Brand} from '../model';
import {useBrands} from './useBrands';

const log = getLog('useTimezones', 'INFO');

interface UseTimezones {
  timezones: string[];
}

export const calculateTimezones = (brands?: Brand[]) => {
  const timezones = ['UTC'];

  if (brands) {
    const brandTimezones = brands.map((brand) => getTimezonesWithCountryCode(brand.countryId)[0]);

    timezones.push(...brandTimezones.filter((timezone, index) => brandTimezones.indexOf(timezone) === index));
  }

  return timezones;
};

export const useTimezones = (): UseTimezones => {
  const {brands} = useBrands();

  const timezones = useMemo(() => calculateTimezones(brands), [brands]);

  return {timezones};
};
