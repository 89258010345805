import React, {FunctionComponent, useCallback} from 'react';
import Field, {FieldCommonProps, FieldProps, FieldTypes} from './Field';
import {getLog} from '../../../log';
import {domId} from '../../../dom';
import {ID} from '../../../model';

const log = getLog('MultiCheckbox');

export interface MultiCheckboxOption {
  id: ID;
  name: string;
  tip?: React.ReactElement;
  description?: string;
  isDisabled?: boolean;
}

export type MultiCheckboxOptions = (MultiCheckboxOption | string)[];

export interface FieldMultiCheckboxProps extends FieldCommonProps {
  type: typeof FieldTypes.multi;
  options: MultiCheckboxOptions;
  value: string[];
}

function isMultiCheckboxOption(option: MultiCheckboxOption | string): option is MultiCheckboxOption {
  return typeof option !== 'string';
}

export function isFieldMultiCheckboxProps(props: FieldProps): props is FieldMultiCheckboxProps {
  return props.type === FieldTypes.multi;
}

export const MultiCheckbox: FunctionComponent<FieldMultiCheckboxProps> = (props) => {
  const {isDisabled = false, isEditing, onChange, options, value: tmpValue} = props;
  const value = tmpValue === null || tmpValue === undefined ? [] : tmpValue;

  const onChangeMulti = useCallback(
    (option: string) => (checked: boolean) => {
      if (!Array.isArray(value)) {
        log.error('A MultiCheckbox value must be an array. But was: ', value, typeof value);
      } else {
        log.debug('onChangeMulti', value, option, checked);
        const valueClone = value.clone();
        !checked ? valueClone.remove(option) : valueClone.push(option);
        onChange && onChange(valueClone);
      }
    },
    [value, onChange]
  );

  return !options ? null : (
    <ul>
      {options.map((option) => {
        const {
          id,
          name,
          tip,
          description,
          isDisabled: isDisabledCheckbox = false,
        } = isMultiCheckboxOption(option) ? option : {id: option, name: option, tip: undefined, description: undefined};
        const checked = value && Array.isArray(value) && value.includes(id);
        return (
          <li key={id}>
            <Field
              type={FieldTypes.checkbox}
              label={name}
              name={domId(name)}
              value={checked}
              isDisabled={isDisabled || isDisabledCheckbox}
              isEditing={isEditing}
              onChange={onChangeMulti(id)}
              tip={tip || description}
            />
          </li>
        );
      })}
    </ul>
  );
};
