import React, {FC, PropsWithChildren} from 'react';
import {ID} from '../../model';

interface AccordionItemContentFcProps extends PropsWithChildren {
  id?: ID;
  open?: boolean;
}

export const AccordionItemContentFc: FC<AccordionItemContentFcProps> = (props) => {
  return (
    <div className="accordion__panel rich-text-area" id={props.id}>
      {props.open && props.children}
    </div>
  );
};
