import React, {FC} from 'react';
import {useParams} from 'react-router-dom';
import {Icon} from '../common/Icon';
import {ReservedOasFc} from '../management/ReservedOas';
import {useUser} from '../../hooks/useUser';
import {getLog} from '../../log';
import {ID} from '../../model';
import {RESERVED_OA_MANAGE} from '../../permissions';

const log = getLog('ProvisionReservedOas', 'INFO');

interface ProvisionReservedOasFcProps {
  ownerProductId: ID;
  startDate: string;
  endDate?: string;
}

export const ProvisionReservedOasFc: FC<ProvisionReservedOasFcProps> = ({startDate, endDate, ownerProductId}) => {
  const {customerId} = useParams<{customerId: ID}>() as {customerId: ID};
  const {isTelia, hasPermission} = useUser();
  log.debug('render', {ownerProductId});
  return (
    <>
      <br />
      <h3>
        <Icon icon={'security'} className={'blueText vertical-align-bottom'} />
        Reserved SenderIDs
      </h3>

      <ReservedOasFc
        effect={'ALLOW'}
        ownerProductId={ownerProductId}
        customerId={customerId}
        allowAdmin={hasPermission(RESERVED_OA_MANAGE)}
      />
      <br />

      {hasPermission(RESERVED_OA_MANAGE) && (
        <>
          <h3>
            <Icon icon={'warning'} className={'redText vertical-align-bottom'} />
            Blocked SenderIDs (Visible only for Telia users)
          </h3>

          <ReservedOasFc
            effect={'DENY'}
            ownerProductId={ownerProductId}
            customerId={customerId}
            allowAdmin={hasPermission(RESERVED_OA_MANAGE)}
          />
        </>
      )}
    </>
  );
};
