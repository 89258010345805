import {ApolloLink, Observable} from '@apollo/client';
import {cachedLocalStorage, AUTH_TOKEN} from '../cachedLocalStorage';
import {getLog} from '../log';

const log = getLog('apolloLinkAuthorization', 'INFO');

export const apolloLinkAuthorization = new ApolloLink((operation, forward) => {
  log.debug('Apollo link');
  const addAuthorizationHeaderToContext: (context: Record<string, any>) => Record<string, any> = (context) => {
    log.debug('addAuthorizationHeaderToContext');
    const authToken = cachedLocalStorage.get(AUTH_TOKEN);
    if (authToken) {
      log.debug('Setting Bearer (..) Authorization http header', authToken.substring(0, 20) + '...');
      return {...context, headers: {...context.headers, Authorization: `Bearer ${authToken}`}};
    } else {
      return context;
    }
  };

  const parseResponseCustomAuthorizationHeaders = () => {
    log.debug('parseResponseCustomAuthorizationHeaders');
    const context = operation.getContext();
    const {response} = context;
    if (response) {
      const {headers} = response;
      if (headers) {
        const authToken = headers.get('x-token');
        if (authToken) {
          if (authToken === 'x-remove') {
            log.info(`Found 'x-remove' x-token response header`);
            cachedLocalStorage.remove(AUTH_TOKEN);
          } else {
            log.debug('Found x-token response header');
            cachedLocalStorage.set(AUTH_TOKEN, authToken);
          }
        }
      }
    }
  };

  operation.setContext(addAuthorizationHeaderToContext);
  const fetchResultObservable = forward(operation);

  return new Observable((observer) => {
    fetchResultObservable.subscribe(
      (observerOrNext) => {
        log.debug('Observable observerOrNext', observerOrNext);
        observer.next(observerOrNext);
      },
      (error) => {
        log.error('Observable error', error);
        observer.error(error);
      },
      () => {
        log.debug('Observable complete');
        parseResponseCustomAuthorizationHeaders();
        observer.complete();
      }
    );
  });
});
