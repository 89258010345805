import classNames from 'classnames';
import React, {FC, ReactNode} from 'react';

import './MfaInfo.scss';

import {Icon} from '../../common/Icon';

interface MfaOptionRow {
  isConfigured: boolean;
  title: string;
  subtitle?: string;
  rightElement?: ReactNode;
  buttonText?: string;
  onButtonClick?: () => void;
}

export const MfaOptionRow: FC<MfaOptionRow> = ({isConfigured, title, subtitle, rightElement}) => {
  return (
    <div
      className={classNames(
        'mfa--option--row__wrapper',
        isConfigured ? 'mfa--option--row__configured' : 'mfa--option--row__unconfigured'
      )}
    >
      <Icon
        icon={isConfigured ? 'check-circle-filled' : 'alert-filled'}
        className={classNames(
          'mfa--option--row__icon',
          'vertical-align-bottom',
          isConfigured ? 'greenText' : 'orangeText'
        )}
      />
      <div className="mfa--option--row__title">{title}</div>
      <div className="mfa--option--row__subtitle">{subtitle}</div>
      <div className="mfa--option--row__action">{rightElement}</div>
    </div>
  );
};
