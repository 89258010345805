import classnames from 'classnames';
import React, {FC, PropsWithChildren} from 'react';
import {PropsWithClassName, PropsWithId} from '../../model';
import './Table.scss';

interface TableProps extends PropsWithId, PropsWithClassName, PropsWithChildren {}

const TableFc: FC<TableProps> = (props) => {
  return (
    <table id={props.id} className={classnames(props.className)}>
      {props.children}
    </table>
  );
};

export default TableFc;
