"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.hasUserPermission = exports.getUserPermissions = exports.getPermissionsFromRolesMap = exports.getBrandId = exports.RESOURCE_BRAND_DEF = exports.RESOURCE_BRAND_PARAM = exports.RESOURCE_BRAND_PREFIX = exports.RESOURCE_WILDCARD = void 0;
const model_1 = require("./model");
const pathParams_1 = require("./pathParams");
exports.RESOURCE_WILDCARD = '*';
exports.RESOURCE_BRAND_PREFIX = '/brands/';
exports.RESOURCE_BRAND_PARAM = 'brandId';
exports.RESOURCE_BRAND_DEF = exports.RESOURCE_BRAND_PREFIX + ':' + exports.RESOURCE_BRAND_PARAM;
const getBrandId = (resource) => (0, pathParams_1.createParamExtractor)(exports.RESOURCE_BRAND_DEF)(exports.RESOURCE_BRAND_PARAM, resource);
exports.getBrandId = getBrandId;
const getPermissionsFromRolesMap = (rolesMap, brandId, exact = false) => {
    const groupedPermissions = rolesMap
        ? rolesMap
            .filter((map) => (0, exports.getBrandId)(map.resource) === brandId || (!exact && (0, exports.getBrandId)(map.resource) === exports.RESOURCE_WILDCARD))
            .flatMap((filteredMap) => filteredMap.permissions)
        : [];
    return groupedPermissions.filter((permission, index) => groupedPermissions.indexOf(permission) === index);
};
exports.getPermissionsFromRolesMap = getPermissionsFromRolesMap;
const getUserPermissions = (user, brandId) => {
    var _a;
    switch (user === null || user === void 0 ? void 0 : user.type) {
        case model_1.UserType.TELIA:
            return (0, exports.getPermissionsFromRolesMap)(user.rolesMap, brandId);
        case model_1.UserType.CUSTOMER:
            return (_a = user.permissions) !== null && _a !== void 0 ? _a : [];
        default:
            return [];
    }
};
exports.getUserPermissions = getUserPermissions;
const hasUserPermission = (permission, user, brandId) => {
    const permissions = (0, exports.getUserPermissions)(user, brandId);
    return permissions.includes(permission);
};
exports.hasUserPermission = hasUserPermission;
