import {ModalDialog} from '@telia/styleguide';
import {ButtonKind} from '@telia/styleguide/atoms/Button';
import React, {FC, FunctionComponent, PropsWithChildren, ReactElement, useEffect, useRef, useState} from 'react';

import {TypeToConfirmField} from '../components/common/TypeToConfirmField';
import {getLog} from '../log';
import {ID} from '../model';

const log = getLog('Modal', 'INFO');

export interface ModalProps extends PropsWithChildren {
  show?: boolean;
  title: string;
  content?: ReactElement;
  name?: string;
  onConfirm?: (...args: any[]) => any;
  confirmDisabled?: boolean;
  confirmType?: ButtonKind;
  confirmText?: string;
  typeToConfirmText?: string;
  onCancel?: (...args: any[]) => any; //  set to null with no onConfirm to prevent manual modal closing
  cancelText?: string;
}

const defaultModal: ModalProps = {
  show: false,
  title: 'Hidden modal',
  content: undefined,
  name: undefined,
  onConfirm: () => {},
  confirmType: 'normal',
  confirmText: undefined,
  onCancel: () => {},
  cancelText: undefined,
};

export type Modal = {
  showModal: (modalProps: ModalProps) => ID;
  hideModal: (id?: ID) => void; //  in case of programmatic modal closing (like when no onConfirm and onCancel is null)
};

export const ModalContext = React.createContext<Modal>({
  showModal: (modal: ModalProps) => {
    log.debug('showModal default', modal);
    return 'default ID';
  },
  hideModal: (id) => log.debug('hideModal default', id),
});

export const ModalProvider: FC<PropsWithChildren> = (props) => {
  const [modal, setModal] = useState<ModalProps>(defaultModal);
  const modalIdEl = useRef<HTMLInputElement>(null);
  const modalId = modalIdEl.current?.value;
  const modalContext: Modal = {
    showModal: (modal: ModalProps) => {
      const composedModal = {...defaultModal, show: true, ...modal};
      const modalId = 'id_' + Date.now();
      log.debug('showModal', {modalId, composedModal});
      setModal(composedModal);
      if (modalIdEl.current) {
        modalIdEl.current.value = modalId;
      }
      return modalId;
    },
    hideModal: (uuid: ID) => {
      const modalId = modalIdEl.current?.value;
      if (uuid === undefined || uuid === modalId) {
        log.debug('hideModal closing current Modal', uuid);
        setModal({...defaultModal});
      } else {
        log.debug('hideModal skip closing outdated Modal');
      }
    },
  };
  log.debug('render', {modalId, modal});
  const {
    show,
    title,
    content,
    name,
    typeToConfirmText,
    confirmDisabled,
    onConfirm,
    confirmType,
    confirmText,
    onCancel,
    cancelText,
  } = modal;
  return (
    <ModalContext.Provider value={modalContext}>
      <input hidden={true} ref={modalIdEl} />
      <ModalFC
        show={show}
        title={title}
        name={name}
        typeToConfirmText={typeToConfirmText}
        confirmDisabled={confirmDisabled}
        confirmText={confirmText}
        confirmType={confirmType}
        onConfirm={(...args) => {
          setModal(defaultModal);
          onConfirm && onConfirm(...args);
        }}
        onCancel={
          onCancel === null
            ? undefined
            : (...args) => {
                setModal(defaultModal);
                onCancel && onCancel(...args);
              }
        }
        cancelText={cancelText}
        content={content}
      />
      {props.children}
    </ModalContext.Provider>
  );
};

export const ModalFC: FunctionComponent<ModalProps> = (props) => {
  const {
    show,
    title,
    content,
    onConfirm,
    confirmType,
    confirmText,
    confirmDisabled = false,
    typeToConfirmText,
    onCancel,
    cancelText,
    name,
    children,
  } = props;
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(!!typeToConfirmText || confirmDisabled);

  useEffect(() => {
    setSubmitDisabled(!!typeToConfirmText || confirmDisabled);
  }, [confirmDisabled, typeToConfirmText]);

  return show ? (
    <ModalDialog
      size={'medium'}
      name={name || 'confirmation-dialog'}
      heading={title}
      submitDisabled={submitDisabled}
      submitText={confirmText}
      submitKind={confirmType}
      onSubmit={onConfirm}
      closeText={cancelText || 'Close'}
      onClose={onCancel}
    >
      <div>{content || children}</div>
      {typeToConfirmText && (
        <TypeToConfirmField
          confirmText={typeToConfirmText}
          onChange={(confirmed) => {
            setSubmitDisabled(!confirmed);
          }}
        />
      )}
    </ModalDialog>
  ) : null;
};
