import React, {FC} from 'react';
import './SettlementLine.scss';
import {format} from '@telia/cpa-web-common';
import {getLog} from '../../../../log';
import {Settlement} from '../../../../model';

const log = getLog('SettlementPendingLine');

interface SettlementPendingLineFcProps {
  settlement: Settlement;
}

export const SettlementPendingLineFc: FC<SettlementPendingLineFcProps> = (props) => {
  const {settlement} = props;
  // log.debug('settlement', settlement);
  return (
    <div className={'settlement__name'}>
      <span className="settlement__name__contract">
        {' '}
        {format.integer(settlement.summary.totalExclVat)},- {format.yearMonth(settlement.yearMonth)}
      </span>
      {settlement.customer.company.name} ({settlement.contract.id})
    </div>
  );
};
