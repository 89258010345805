import React, {FC} from 'react';
import classnames from 'classnames';
import './HighlightSpan.scss';

interface HighlightSpanProps {
  text: string;
  highlight: string;
  onlyWordStart: boolean;
  className?: string;
}

const HighlightSpanFc: FC<HighlightSpanProps> = (props) => {
  const {text, highlight = '', onlyWordStart = true} = props;
  let parts = text.split(new RegExp(`${onlyWordStart ? '\\b' : ''}(${highlight})`, 'gi'));
  return (
    <div className={classnames(props.className)}>
      {parts.map((part, i) => (
        <span
          key={i}
          className={classnames({highlighted: part.toLowerCase() === highlight && highlight.toLowerCase()})}
        >
          {part}
        </span>
      ))}
    </div>
  );
};

export default HighlightSpanFc;
