// import {Form} from '@telia/styleguide';
// export const FormColumn = Form.FormColumn;
// export default FormColumn;

import React, {FC, PropsWithChildren} from 'react';

export type FormColumnSize = 'half' | 'normal' | 'double';
export const FormColumnSizeHalf: FormColumnSize = 'half';
export const FormColumnSizeNormal: FormColumnSize = 'normal';
export const FormColumnSizeDouble: FormColumnSize = 'double';

export interface FormColumnFcProps extends PropsWithChildren {
  size?: FormColumnSize;
}
export const FormColumn: FC<FormColumnFcProps> = ({children, size = FormColumnSizeDouble}) => (
  <div className={`form__column form__column--${size}`}>{children}</div>
);

export default FormColumn;
