query settlementsTotals($years: [Int]) {
  settlementsTotals(years: $years) {
    period
    isComplete
    isApproved
    products {
      productId
      EXTERNAL
      INTERNAL
    }
  }
}
