import {Button} from '@telia/styleguide';
import gql from 'graphql-tag';
import React, {FC, useState} from 'react';
import {toast} from 'react-toastify';

import saveMfaPhoneNumberMutation from '../../../../graphql/mutation/saveMfaPhoneNumber.graphql';
import verifyMfaPhoneNumberMutation from '../../../../graphql/mutation/verifyMfaPhoneNumber.graphql';

import {useFormState} from '../../../../hooks/useFormState';
import {useMutationWrap} from '../../../../hooks/useMutationWrap';
import {useUser} from '../../../../hooks/useUser';
import {getLog} from '../../../../log';
import LoadingFc from '../../../Loading';
import FormColumn from '../../../common/FormColumn';
import FormRow from '../../../common/FormRow';
import {MobileField} from '../../../common/MobileField';
import {FieldWithFormState} from '../../../common/field';

const log = getLog('MfaSmsEditForm', 'DEBUG');

export interface MfaSmsEditFormProps {
  onClose: () => void;
}

enum EditState {
  'ENTER_NUMBER',
  'VERIFY',
}

interface SaveMfaPhoneNumber {
  phone: string;
  verified: boolean;
}

export const MfaSmsEditForm: FC<MfaSmsEditFormProps> = ({onClose}) => {
  const {realUser, refetch} = useUser();

  const [loading, setLoading] = useState<boolean>(false);

  const saveMfaPhoneNumber = useMutationWrap<{saveMfaPhoneNumber: SaveMfaPhoneNumber}, {phone: string}>(
    gql(saveMfaPhoneNumberMutation)
  );

  const verifyMfaPhoneNumber = useMutationWrap<{verifyMfaPhoneNumber: boolean}, {code: string}>(
    gql(verifyMfaPhoneNumberMutation)
  );

  const formState = useFormState({isEditing: true});
  const {entityAs, putError} = formState;

  const onSendCode = async () => {
    const {mfaPhone} = entityAs<{mfaPhone?: string}>();

    if (!mfaPhone) {
      putError('mfaPhone', 'Please enter a phone number');
      return;
    }

    if (realUser?.mfaOptions?.sms.enabled && mfaPhone === realUser?.mfaOptions?.sms.phone) {
      putError('mfaPhone', 'This number is the same as your current configuration. Please enter a new phone number.');
      return;
    }

    setLoading(true);

    const {data} = await saveMfaPhoneNumber({
      variables: {
        phone: mfaPhone,
      },
    });

    if (!data?.saveMfaPhoneNumber.verified && data?.saveMfaPhoneNumber.phone) {
      onSent(data?.saveMfaPhoneNumber.phone);
    } else if (data?.saveMfaPhoneNumber.verified === true) {
      toast.success('Phone number already verified.', {autoClose: 2500});
      await refetch();
      onClose();
    }

    setLoading(false);
  };

  const [sentTo, setSentTo] = useState<string>();
  const [state, setState] = useState<EditState>(EditState.ENTER_NUMBER);

  const onSent = (to: string) => {
    setState(EditState.VERIFY);
    setSentTo(to);
  };

  const onVerify = async () => {
    setLoading(true);
    const {code} = entityAs<{code?: string}>();

    if (!code) {
      putError('code', 'Please enter your authentication code.');
      return;
    }

    const {data} = await verifyMfaPhoneNumber({
      successText: 'Phone number updated',
      variables: {code},
    });

    if (data?.verifyMfaPhoneNumber) {
      await refetch();
      onClose();
    }
    setLoading(false);
  };

  return (
    <>
      {state === EditState.VERIFY && (
        <FormRow>
          <FormColumn>
            <p className="noMarginTop">
              We have sent an authentication code by SMS to {sentTo}. Please enter the code below to verify your number.
            </p>
          </FormColumn>
        </FormRow>
      )}
      <FormRow>
        <FormColumn>
          {state === EditState.ENTER_NUMBER ? (
            <MobileField label="Phone number" formState={formState} entityFieldId="mfaPhone" />
          ) : (
            <FieldWithFormState
              label="Enter authentication code"
              formState={formState}
              entityFieldId="code"
              placeholder="000000"
            />
          )}
        </FormColumn>
      </FormRow>
      <FormRow>
        <FormColumn>
          <div>
            {loading ? (
              <LoadingFc />
            ) : (
              <>
                <Button
                  text={state === EditState.ENTER_NUMBER ? 'Send Code' : 'Update number'}
                  onClick={state === EditState.ENTER_NUMBER ? onSendCode : onVerify}
                  kind={Button.kinds.primary}
                />
                <Button text="Cancel" onClick={onClose} kind={Button.kinds.cancel} />
              </>
            )}
          </div>
        </FormColumn>
      </FormRow>
    </>
  );
};
