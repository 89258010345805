import React, {FC, PropsWithChildren} from 'react';
import classnames from 'classnames';
import {PropsWithClassName} from '../../model';

interface PageProps extends PropsWithClassName, PropsWithChildren {}

export const PageFc: FC<PageProps> = (props) => {
  return <div className={classnames(props.className)}>{props.children}</div>;
};

export default PageFc;
