"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const countries = [
    {
        id: 'SE',
        countryCode: '46',
        name: 'Sweden',
    },
    {
        id: 'NO',
        countryCode: '47',
        name: 'Norway',
    },
];
exports.default = countries;
