export type Comparator<T> = (a: T, b: T) => number;

interface ComparatorDictionary<T> {
  [key: string]: Comparator<T>;
}

export const stringLengthAndAlphabeticComparator: Comparator<string> = (a: string, b: string): number => {
  return a.length !== b.length ? a.length - b.length : a.localeCompare(b);
};

export const specialFieldComparators: ComparatorDictionary<any> = {
  accessNumber: stringLengthAndAlphabeticComparator,
};
