import React, {FC, PropsWithChildren, ReactNode} from 'react';

interface PageTitleProps {
  title?: string;
}

const PageTitleFc: FC<PropsWithChildren<PageTitleProps>> = (props) => {
  return <h1 className="heading heading--level-1">{props.title || props.children}</h1>;
};

export default PageTitleFc;
