"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const customerOverviews = [
    {
        id: '10001',
        countryId: 'NO',
        brandId: 'TELIA_NO',
        name: 'Mock Customer',
        productOverviews: [
            {
                productOverviewType: 'generic',
                productTypeId: 'POSITIONING',
                customerId: '10502',
                contractId: '10503',
                productId: '10504',
                state: 'ACTIVE',
            },
            {
                productOverviewType: 'rbm',
                productTypeId: 'RBM_TELIA_NO',
                customerId: '10302',
                contractId: '10303',
                productId: '10304',
                state: 'ACTIVE',
                rbmAgentOwner: 'mock',
            },
            {
                productOverviewType: 'reservedOa',
                productTypeId: 'RESERVED_OA',
                customerId: '10402',
                contractId: '10403',
                productId: '10404',
                state: 'ACTIVE',
                reservedOas: ['mock1', 'mock2'],
            },
            {
                productOverviewType: 'accessNumberRange',
                productTypeId: 'MO_LONG_NUMBER_RANGE',
                customerId: '10202',
                contractId: '10203',
                productId: '10204',
                state: 'ACTIVE',
                accessNumberRange: {
                    from: '11112',
                    to: '11115',
                },
            },
            {
                productOverviewType: 'accessNumber',
                productTypeId: 'SMS_BULK',
                customerId: '10102',
                contractId: '10103',
                productId: '10104',
                state: 'ACTIVE',
                accessNumber: '11111',
            },
        ],
    },
];
exports.default = customerOverviews;
