#import '../fragment/statistic.graphql'

query statistics(
  $accessNumber: AccessNumberString
  $contractId: ID
  $contractType: ContractTypeEnum
  $customerId: ID
  $endDate: Date
  $messageTypes: [ID]
  $resolutions: [ID]
  $status: Int
  $isCustomer: Boolean
  $operatorCode: ID
  $productType: ID
  $startDate: Date
) {
  statistics(
    accessNumber: $accessNumber
    contractId: $contractId
    contractType: $contractType
    customerId: $customerId
    endDate: $endDate
    messageTypes: $messageTypes
    resolutions: $resolutions
    status: $status
    isCustomer: $isCustomer
    operatorCode: $operatorCode
    productType: $productType
    startDate: $startDate
  ) {
    ...statisticFragment
  }
}
