import {ApolloClient, ApolloLink, HttpLink, InMemoryCache} from '@apollo/client';
import {urlPaths} from '@telia/cpa-web-common';

import {version} from '../../../../package.json';
import {cachedLocalStorage} from '../cachedLocalStorage';
import {ApolloLinkErrorContextRef} from '../context/ApolloLinkError';
import {getLog} from '../log';
import {isLocalhost} from '../utils/host';
import {apolloLinkAuthorization} from './apolloLinkAuthorization';
import {createApolloLinkError} from './apolloLinkError';
import apolloLinkSentry from './apolloLinkSentry';

const log = getLog('apollo/client', 'INFO');

const {protocol, hostname, port} = window.location;
log.info({protocol, hostname, port, isLocalhost: isLocalhost()});

const createHttpLink = () => {
  return new HttpLink({
    uri: urlPaths.GRAPHQL,
    fetch: fetchWithBrandId,
  });
};

const fetchWithBrandId = (uri: RequestInfo | URL, options?: RequestInit) => {
  const serializedbrandId = cachedLocalStorage.get('brandId');
  const brandId = serializedbrandId ? JSON.parse(serializedbrandId) : '';
  return fetch(`${uri}?brandId=${brandId}`, options);
};

export const createApolloClient = (apolloLinkErrorContextRef: ApolloLinkErrorContextRef) =>
  new ApolloClient({
    link: ApolloLink.from([
      createApolloLinkError(apolloLinkErrorContextRef),
      apolloLinkAuthorization,
      apolloLinkSentry,
      createHttpLink(),
    ]),
    headers: {
      // TODO: ( replace AuthenticationLink) authorization: localStorage.getItem('token') || '',
      'client-name': 'CPA Web',
      'client-version': version,
    },
    cache: new InMemoryCache(),
    connectToDevTools: true,
  });
