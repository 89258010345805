import {gql, useQuery} from '@apollo/client';

import mfaConfigQuery from '../graphql/query/mfaConfig.graphql';

import {MfaConfig} from '../model';

interface MfaConfigQuery {
  mfaConfig?: MfaConfig;
}

export interface UseMfaConfig {
  loading: boolean;
  mfaConfig?: MfaConfig;
  isMfaEnabled: boolean;
}

export const useMfaConfig = (): UseMfaConfig => {
  const {loading, data: {mfaConfig} = {}} = useQuery<MfaConfigQuery>(gql(mfaConfigQuery));

  return {
    loading,
    mfaConfig,
    isMfaEnabled: mfaConfig === 'ON' || mfaConfig === 'OPTIONAL',
  };
};
