import classnames from 'classnames';
import React from 'react';

import {getLog} from '../../../log';
import {ID} from '../../../model';
import Field, {FieldCommonProps, FieldProps, FieldTypes} from './Field';

const log = getLog('Select', 'INFO');

export interface SelectOption {
  id: ID | null;
  name: string;
}

export type SelectOptions = (SelectOption | string)[];

export interface FieldSelectProps extends FieldCommonProps {
  options?: SelectOptions;
  isNullable?: boolean;
  type: typeof FieldTypes.select;
  value?: ID | string;
  defaultValue?: ID | string; //  What to shown when field has no (or null) value
}

function isSelectOption(op: SelectOption | string): op is SelectOption {
  return typeof op !== 'string';
}

export function isFieldSelectProps(props: FieldProps): props is FieldSelectProps {
  return props.type === FieldTypes.select;
}

const NULL_VALUE_STRING = '--';

const parseSelectOption: (option: SelectOption | string) => SelectOption = (option) =>
  isSelectOption(option) ? option : {id: option, name: option};

export const Select: React.FunctionComponent<FieldSelectProps> = ({type = 'select', ...props}) => {
  // log.debug('render', props);
  const {
    autocomplete,
    defaultValue,
    id,
    isDisabled = false,
    isNullable = false,
    inputRef,
    name,
    onChange,
    options,
    value,
  } = props;
  log.debug('render', name, value);
  const unparsedOptions = (options && [...options]) || [];
  if (!Field.hasValue(value) || isNullable) {
    unparsedOptions.unshift({id: null, name: defaultValue ? defaultValue : NULL_VALUE_STRING});
  } else if (!unparsedOptions.some((o) => (isSelectOption(o) ? value === o.id : value === o))) {
    value && unparsedOptions.unshift(value); //  Adds value to the options if not included. //  TODO: remove?
  }
  const selectOptions = unparsedOptions.map(parseSelectOption);
  return (
    <select
      id={id}
      name={name}
      className={classnames('field__value', 'dropdown-list-with-label__select', props.valueClassName)}
      onChange={(e) => onChange && onChange(selectOptions[e.target.selectedIndex].id)}
      disabled={isDisabled}
      value={Field.hasValue(value) ? value : defaultValue ? defaultValue : NULL_VALUE_STRING}
      ref={inputRef}
      autoComplete={autocomplete}
    >
      {selectOptions &&
        selectOptions.map(({id, name}) => {
          const value = id === null ? undefined : id;
          return (
            <option className="dropdown-list-with-label__option" value={value} key={value || 'undefined'}>
              {name}
            </option>
          );
        })}
    </select>
  );
};

export const resolveSelectTextValue: (props: FieldSelectProps) => string = ({defaultValue, options, value}) => {
  return !Field.hasValue(value)
    ? defaultValue
      ? defaultValue
      : NULL_VALUE_STRING
    : (options && options.map(parseSelectOption).find(({id}) => id === value)?.name) || 'Wrong value:' + value;
};
