import React, {FC, PropsWithChildren, useEffect, useMemo} from 'react';
import {Outlet, matchPath, useNavigate} from 'react-router-dom';

import * as AppRoutes from '../../appRoutes';
import {usePreferences} from '../../hooks/usePreferences';
import {useUser} from '../../hooks/useUser';
import Loading from '../Loading';
import NoPermission from './NoPermission';

export const PathBrandGuard: FC<PropsWithChildren> = () => {
  const {loadingUser, userBrands} = useUser();
  const {getPreference, setPreference} = usePreferences(['brandId']);
  const navigate = useNavigate();

  const brandId = getPreference('brandId');

  const pathBrandId: string | undefined = useMemo(() => {
    const match = matchPath(AppRoutes.BRAND__brandId + '/*', location.pathname);
    return match?.params?.brandId?.toUpperCase();
  }, [location.pathname]);

  const userHasPathBrand = useMemo(() => {
    return !!userBrands?.find(({id}) => id === pathBrandId);
  }, [userBrands, pathBrandId]);

  useEffect(() => {
    if (userHasPathBrand && brandId !== pathBrandId) {
      setPreference('brandId', pathBrandId);
      navigate(0);
    }
  }, [userHasPathBrand, pathBrandId]);

  return loadingUser || !brandId ? <Loading /> : pathBrandId && !userHasPathBrand ? <NoPermission /> : <Outlet />;
};
