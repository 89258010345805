import React, {FC} from 'react';
import classnames from 'classnames';
import Tooltip from './Tooltip';
import {useCpaStatusCodes} from '../../hooks/useCpaStatusCodes';

interface MessageStatusDisplayProps {
  status?: number;
  details?: string;
}

const MessageStatusDisplayFc: FC<MessageStatusDisplayProps> = ({status, details}) => {
  const {getStatusDescription} = useCpaStatusCodes();

  return (
    <span className={classnames(!status || status === 9999 ? 'greyText' : status === 200 ? 'greenText' : 'redText')}>
      {status || ''}
      {status && <Tooltip text={`${status}: ${getStatusDescription(status)}`} />}
      {details && <Tooltip text={`Details: ${details}`} buttonContent={'d'} className={`tooltip--S`} />}{' '}
    </span>
  );
};

export default MessageStatusDisplayFc;
