"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PRODUCT_OVERVIEW_TYPE_GENERIC = exports.PRODUCT_OVERVIEW_TYPE_RBM = exports.PRODUCT_OVERVIEW_TYPE_RESERVED_OA = exports.PRODUCT_OVERVIEW_TYPE_ACCESS_NUMBER_RANGE = exports.PRODUCT_OVERVIEW_TYPE_ACCESS_NUMBER = exports.FEE_TYPE_MONTHLY = exports.FEE_TYPE_ESTABLISHMENT = exports.chargeTypes = exports.CHARGE_TYPE_POSTPAID = exports.CHARGE_TYPE_PREPAID = exports.balanceTypes = exports.BALANCE_TYPE_CREDIT = exports.BALANCE_TYPE_DEBIT = exports.PRODUCT_TYPE_EVENT = exports.PRODUCT_TYPE_RESERVED_OA = exports.PRODUCT_TYPE_POSITIONING = exports.PRODUCT_TYPE_MO_DIRECT_CHARGE_RANGE = exports.PRODUCT_TYPE_MO_CUSTOMER_NUMBER = exports.PRODUCT_TYPE_MO_LONG_NUMBER_RANGE = exports.PRODUCT_TYPE_MO_LONG_NUMBER = exports.PRODUCT_TYPE_SMS_BULK = exports.contractTypeName = exports.contractTypes = exports.INTERNAL_CONTRACT_TYPE = exports.EXTERNAL_CONTRACT_TYPE = exports.ALL = void 0;
exports.ALL = 'ALL';
exports.EXTERNAL_CONTRACT_TYPE = 'EXTERNAL';
exports.INTERNAL_CONTRACT_TYPE = 'INTERNAL';
exports.contractTypes = [
    { id: exports.EXTERNAL_CONTRACT_TYPE, name: 'External' },
    { id: exports.INTERNAL_CONTRACT_TYPE, name: 'Internal' },
];
const contractTypeName = (id) => { var _a; return ((_a = exports.contractTypes.find((ct) => ct.id.equalsIgnoreCase(id))) === null || _a === void 0 ? void 0 : _a.name) || id; };
exports.contractTypeName = contractTypeName;
exports.PRODUCT_TYPE_SMS_BULK = 'SMS_BULK';
exports.PRODUCT_TYPE_MO_LONG_NUMBER = 'MO_LONG_NUMBER';
exports.PRODUCT_TYPE_MO_LONG_NUMBER_RANGE = 'MO_LONG_NUMBER_RANGE';
exports.PRODUCT_TYPE_MO_CUSTOMER_NUMBER = 'MO_CUSTOMER_NUMBER';
exports.PRODUCT_TYPE_MO_DIRECT_CHARGE_RANGE = 'MO_DIRECT_CHARGE_RANGE';
exports.PRODUCT_TYPE_POSITIONING = 'POSITIONING';
exports.PRODUCT_TYPE_RESERVED_OA = 'RESERVED_OA';
exports.PRODUCT_TYPE_EVENT = 'SMS_EVENT';
exports.BALANCE_TYPE_DEBIT = 'DEBIT';
exports.BALANCE_TYPE_CREDIT = 'CREDIT';
exports.balanceTypes = [
    { id: exports.BALANCE_TYPE_DEBIT, name: 'Debit' },
    { id: exports.BALANCE_TYPE_CREDIT, name: 'Credit' },
];
exports.CHARGE_TYPE_PREPAID = 'PREPAID';
exports.CHARGE_TYPE_POSTPAID = 'POSTPAID';
exports.chargeTypes = [
    { id: exports.CHARGE_TYPE_PREPAID, name: 'Prepaid' },
    { id: exports.CHARGE_TYPE_POSTPAID, name: 'Postpaid' },
];
exports.FEE_TYPE_ESTABLISHMENT = 'ESTABLISHMENT';
exports.FEE_TYPE_MONTHLY = 'MONTHLY';
exports.PRODUCT_OVERVIEW_TYPE_ACCESS_NUMBER = 'accessNumber';
exports.PRODUCT_OVERVIEW_TYPE_ACCESS_NUMBER_RANGE = 'accessNumberRange';
exports.PRODUCT_OVERVIEW_TYPE_RESERVED_OA = 'reservedOa';
exports.PRODUCT_OVERVIEW_TYPE_RBM = 'rbm';
exports.PRODUCT_OVERVIEW_TYPE_GENERIC = 'generic';
