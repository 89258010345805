import React, {FC, useEffect} from 'react';
import {useParams, useLocation} from 'react-router-dom';
import {getLog} from '../../log';
import {ID} from '../../model';
import {usePreferences} from '../../hooks/usePreferences';
import PageTitle from '../common/PageTitle';

const log = getLog('Brand', 'INFO');

export const BrandFc: FC = (_) => {
  const {brandId} = useParams<{brandId: ID}>() as {brandId: ID};
  const {search, hash} = useLocation();
  const {setPreference, getPreference} = usePreferences(['brandId']);
  let redirectUrl = decodeURI(hash.substr(1));
  useEffect(() => {
    const currBrandId: string | undefined = getPreference('brandId');
    currBrandId && (redirectUrl = redirectUrl.replace(currBrandId.toLowerCase(), brandId.toLowerCase())); //Inject new brand into URL
    setPreference('brandId', brandId);
    window.location.href = redirectUrl;
  }, []);
  log.debug('render', {brandId, search, hash});
  return (
    <>
      <PageTitle title="Brand selection" />

      <p>Setting brand ID '{brandId}' user preference</p>
    </>
  );
};
