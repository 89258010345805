import {constants} from '@telia/cpa-web-common';
import {getLog} from '../log';
import {Fee, HasFeeModels} from '../model';

const {FEE_TYPE_ESTABLISHMENT, FEE_TYPE_MONTHLY} = constants;

const log = getLog('useFees', 'INFO');

export interface UseFees {
  getFee: (productType: HasFeeModels, feeTypeId: string) => Fee | undefined;
  getEstablishFee: (productType: HasFeeModels) => number | undefined;
  getMonthlyFee: (productType: HasFeeModels) => number | undefined;
}

export const useFees: () => UseFees = () => {
  const getFee: (productType: HasFeeModels, feeTypeId: string) => Fee | undefined = ({feeModels}, feeTypeId) => {
    return feeModels
      .map(({fees}) => fees)
      .flat()
      .find((fee) => fee.feeTypeId === feeTypeId);
  };

  const getEstablishFee: (productType: HasFeeModels) => number | undefined = (productType) => {
    const establishFee = getFee(productType, FEE_TYPE_ESTABLISHMENT);
    log.debug('getEstablishFee', {establishFee});
    return establishFee?.fixedAmount;
  };

  const getMonthlyFee: (productType: HasFeeModels) => number | undefined = (productType) => {
    const monthlyFee = getFee(productType, FEE_TYPE_MONTHLY);
    log.debug('getMonthlyFee', {monthlyFee});
    return monthlyFee?.fixedAmount;
  };

  const useFeeModels: UseFees = {
    getFee,
    getEstablishFee,
    getMonthlyFee,
  };

  return useFeeModels;
};
