import classnames from 'classnames';
import React, {Dispatch, FC, ReactElement} from 'react';

import {getLog} from '../../log';
import {ID} from '../../model';
import {AccordionItemContentFc} from './AccordionItemContent';
import {AccordionItemDescriptionFc} from './AccordionItemDescription';
import {AccordionItemHeaderFc} from './AccordionItemHeader';
import {Icon} from './Icon';
import {Field, FieldTypes} from './field';

const log = getLog('AccordionItem', 'INFO');

export interface AccordionItemFcProps {
  id: ID;
  header: ReactElement;
  headerRightElement?: ReactElement;
  content: ReactElement;
  description?: ReactElement;
  open?: boolean;
  isDisabled?: boolean;
  onToggleOpen?: () => void;
  checkboxAction?: Dispatch<boolean>;
  checkboxValue?: boolean;
  checkboxIsEditing?: boolean;
  classNames?: string;
  showOpenStateIcon?: boolean;
}

export const AccordionItemFc: FC<AccordionItemFcProps> = (props) => {
  log.debug('props', props);
  const {
    id,
    header,
    headerRightElement,
    content,
    description,
    open,
    isDisabled,
    onToggleOpen,
    checkboxAction,
    checkboxValue,
    checkboxIsEditing,
    showOpenStateIcon = true,
  } = props;
  return (
    <section className={classnames('accordion', {'accordion--open': open}, props.classNames)}>
      <AccordionItemHeaderFc
        id={`AccordionItemHeader_${id}`}
        open={!!open}
        onClick={onToggleOpen && !isDisabled ? onToggleOpen : () => {}}
        buttonClassName={description && 'accordion__header-button-description'}
        description={description}
      >
        {checkboxAction && (
          <Field
            type={FieldTypes.checkbox}
            isEditing={checkboxIsEditing}
            className={''}
            onChange={checkboxAction}
            value={checkboxValue}
          />
        )}
        {headerRightElement && <div className={'floatRight'}>{headerRightElement}</div>}
        {showOpenStateIcon && (
          <Icon icon={open ? 'arrow-small-down' : 'arrow-small-right'} className={classnames({disabled: isDisabled})} />
        )}
        {header}
      </AccordionItemHeaderFc>
      <AccordionItemContentFc id={`AccordionItemContent_${id}`} open={!!open}>
        {content}
      </AccordionItemContentFc>
    </section>
  );
};

export default AccordionItemFc;
