import React, {FC, PropsWithChildren} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';

import * as AppRoutes from '../appRoutes';
import {useModal} from '../hooks/useModal';
import {getLog} from '../log';

const log = getLog('ApolloLinkError', 'INFO');

export interface ApolloLinkErrorProps {
  content: string;
  title?: string;
}

export type ApolloLinkErrorContext = {
  showUnauthenticatedDialog: (title: string) => void;
  showErrorDialog: (props: ApolloLinkErrorProps) => void;
  showErrorToast: (title: string) => void;
};

export type ApolloLinkErrorContextRef = React.RefObject<ApolloLinkErrorContext>;
export const apolloLinkErrorContextRef: ApolloLinkErrorContextRef = React.createRef();

export const apolloLinkErrorContext = React.createContext<ApolloLinkErrorContext>({
  showUnauthenticatedDialog: (title) => {
    log.debug('showUnauthenticatedDialog', title);
  },
  showErrorDialog: ({title, content}) => {
    log.debug('showErrorDialog', title, content);
  },
  showErrorToast: (message: string) => {
    log.debug(message);
  },
});

export const ApolloLinkErrorFc: FC<PropsWithChildren> = (props) => {
  const {pathname, search} = useLocation();
  const navigate = useNavigate();
  const redirectPath = encodeURIComponent(pathname + search);
  const {showModal} = useModal();
  const apolloLinkErrorContext: ApolloLinkErrorContext = {
    showUnauthenticatedDialog: (title) => {
      showModal({
        show: true,
        name: 'Unauthenticated',
        title,
        content: <p>You need valid credentials to access Telia Bulk Messaging Web. Please login.</p>,
        confirmText: 'Login',
        onConfirm: () => navigate(`${AppRoutes.LOGIN}?redirectPath=${redirectPath}`),
      });
    },
    showErrorDialog: ({title, content}: ApolloLinkErrorProps) => {
      showModal({
        show: true,
        name: 'apolloLinkError',
        title: title ? title : 'Notice',
        content: <p>{content}</p>,
        confirmText: 'Reload',
        onConfirm: () => location.reload(),
      });
    },
    showErrorToast: (message) => toast.error(message),
  };
  React.useImperativeHandle(apolloLinkErrorContextRef, () => apolloLinkErrorContext);
  log.debug('render', {apolloLinkErrorContextRef});
  return <>{props.children}</>;
};
