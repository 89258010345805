import {format} from '@telia/cpa-web-common';
import {FullWidthTable, ModalDialog} from '@telia/styleguide';
import React, {FC, useMemo, useState} from 'react';

import {specialFieldComparators} from '../../comparators';
import {useCpaStatusCodes} from '../../hooks/useCpaStatusCodes';
import {useCustomerName} from '../../hooks/useCustomerName';
import {useMessageTypes} from '../../hooks/useMessageTypes';
import {getLog} from '../../log';
import {PropsWithClassName, ReportMessage} from '../../model';
import {Icon} from '../common/Icon';
import {InformationLineFc} from '../common/InformationLine';
import MessageStatusDisplayFc from '../common/MessageStatusDisplay';
import {ReportMessageDetails} from './ReportMessageDetails';

const log = getLog('ReportMessagesTable', 'INFO');

interface ReportMessagesTableProps extends PropsWithClassName {
  messages: ReportMessage[];
}

interface EnrichedReportMessage extends ReportMessage {
  customerName: string;
}

export const ReportMessagesTable: FC<ReportMessagesTableProps> = ({messages}) => {
  const {getCustomerName} = useCustomerName();
  const {getMessageTypeCompactName, getMessageTypeLongName} = useMessageTypes();
  const [message, setMessage] = useState<EnrichedReportMessage>();
  const {getStatusDescription} = useCpaStatusCodes();

  const enrichedMessages = useMemo(() => {
    log.debug('useMemo enrichedMessages', {messages, getCustomerName});
    if (!messages) {
      return undefined;
    }
    return messages.map(
      ({customerId, ...s}) =>
        ({
          ...s,
          customerId,
          customerName: customerId ? getCustomerName(customerId) : customerId,
        } as EnrichedReportMessage)
    );
  }, [messages, getCustomerName]);

  const [sortedMessages, getThSortableProps] = FullWidthTable.useThSortable(
    enrichedMessages || [],
    3,
    [],
    specialFieldComparators
  );

  log.debug('render', {messages, enrichedMessages, sortedMessages});

  return (
    <>
      {message && (
        <ModalDialog
          name={'message-details'}
          heading={'Message Details'}
          closeText="Close"
          onClose={() => setMessage(undefined)}
          size={'large'}
        >
          <ReportMessageDetails message={message} />
        </ModalDialog>
      )}

      <div className={'marginTop'}>
        {sortedMessages && (
          <FullWidthTable clickable={true}>
            <FullWidthTable.THead>
              <FullWidthTable.Tr>
                <FullWidthTable.ThSortable {...getThSortableProps('apiAcceptTime')}>Date</FullWidthTable.ThSortable>
                <FullWidthTable.ThSortable {...getThSortableProps('customerName')}>Customer</FullWidthTable.ThSortable>
                <FullWidthTable.ThSortable {...getThSortableProps('accessNumber')}>
                  Access Nr.
                </FullWidthTable.ThSortable>
                <FullWidthTable.ThSortable {...getThSortableProps('oa')}>Orig. Adr.</FullWidthTable.ThSortable>
                <FullWidthTable.ThSortable {...getThSortableProps('da')}>Dest. Adr.</FullWidthTable.ThSortable>
                <FullWidthTable.ThSortable {...getThSortableProps('daOperator')}>MNO</FullWidthTable.ThSortable>
                <FullWidthTable.ThSortable {...getThSortableProps('segments')}>Seg.</FullWidthTable.ThSortable>
                {/*<FullWidthTable.ThSortable {...getThSortableProps('productQosPriority')}>QoS</FullWidthTable.ThSortable>*/}
                <FullWidthTable.ThSortable {...getThSortableProps('messageType')}>Type</FullWidthTable.ThSortable>
                <FullWidthTable.ThSortable {...getThSortableProps('status')}>Status</FullWidthTable.ThSortable>
                <FullWidthTable.ThSortable {...getThSortableProps('deliverDurationMs')}>
                  Deliver
                </FullWidthTable.ThSortable>
                <FullWidthTable.ThSortable {...getThSortableProps('totalDurationMs')}>Notify</FullWidthTable.ThSortable>
              </FullWidthTable.Tr>
            </FullWidthTable.THead>

            <FullWidthTable.TBody>
              {sortedMessages?.map((message, i) => (
                <FullWidthTable.Tr key={i} onClick={() => setMessage(message)}>
                  <FullWidthTable.Td>{format.dateTime(message.apiAcceptTime)}</FullWidthTable.Td>
                  <FullWidthTable.Td>{message.customerName}</FullWidthTable.Td>
                  <FullWidthTable.Td>{message.accessNumber}</FullWidthTable.Td>
                  <FullWidthTable.Td>{message.oa}</FullWidthTable.Td>
                  <FullWidthTable.Td>{message.da}</FullWidthTable.Td>
                  <FullWidthTable.Td>{message.daOperator}</FullWidthTable.Td>
                  <FullWidthTable.Td>{format.integer(message.segmentCountTotal)}</FullWidthTable.Td>
                  {/*<FullWidthTable.Td>{format.integer(message.productQosPriority)}</FullWidthTable.Td>*/}
                  <FullWidthTable.Td>
                    <span title={getMessageTypeLongName(message.messageType)}>
                      {getMessageTypeCompactName(message.messageType)}
                    </span>
                  </FullWidthTable.Td>
                  <FullWidthTable.Td className={'noWrap'}>
                    <MessageStatusDisplayFc
                      status={message.status}
                      details={message.details ? message.details : '-'}
                    ></MessageStatusDisplayFc>
                  </FullWidthTable.Td>
                  <FullWidthTable.Td>
                    {message.deliverDurationMs ? format.durationCompact(message.deliverDurationMs) : '...'}
                  </FullWidthTable.Td>
                  <FullWidthTable.Td>
                    {message.totalDurationMs
                      ? format.durationCompact(message.totalDurationMs)
                      : ((event) =>
                          event?.type === 'SMS_NOTIFY_ERROR' ? (
                            <Icon
                              info={`${event.status}: ${getStatusDescription(event.status)}`}
                              icon={'alert'}
                              className={'redText absolute right0'}
                            />
                          ) : (
                            '...'
                          ))(message.events?.slice(-1)?.head())}
                  </FullWidthTable.Td>
                </FullWidthTable.Tr>
              ))}
            </FullWidthTable.TBody>
          </FullWidthTable>
        )}
        {messages && messages.isEmpty() && <InformationLineFc>No messages found</InformationLineFc>}
      </div>
    </>
  );
};
