"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Brands = void 0;
var Brands;
(function (Brands) {
    Brands["TELIA_NO"] = "TELIA_NO";
    Brands["TELIA_SE"] = "TELIA_SE";
})(Brands = exports.Brands || (exports.Brands = {}));
const brands = [
    {
        id: Brands.TELIA_NO,
        name: 'Telia (NO)',
        organizationNumber: '981929055',
        countryId: 'NO',
        currencyCode: 'NOK',
    },
    {
        id: Brands.TELIA_SE,
        name: 'Telia (SE)',
        organizationNumber: '556430-0142',
        countryId: 'SE',
        currencyCode: 'SEK',
    },
];
exports.default = brands;
