import {Button} from '@telia/styleguide';
import React, {FC} from 'react';

import {FormState} from '../../../hooks/useFormState';
import {useModal} from '../../../hooks/useModal';
import {getLog} from '../../../log';
import {ID, RECEIVER, TRANSCEIVER, TRANSMITTER} from '../../../model';
import FormColumn from '../../common/FormColumn';
import FormRow from '../../common/FormRow';
import {FieldTypes} from '../../common/field';
import {FieldWithFormState} from '../../common/field/FieldWithFormState';
import {SmppWindow} from './SmppWindow';
import {SubnumberProxy} from './SubnumberProxy';

const log = getLog('SmppAccount', 'INFO');

interface SmppAccountProps {
  parentId: ID;
  formState: FormState;
}

export const SmppAccount: FC<SmppAccountProps> = ({parentId, formState}) => {
  log.debug('render', {parentId, formState});

  const {showModal} = useModal();
  const showPassword = (password: string) =>
    showModal({
      title: `SMPP Account Password`,
      content: (
        <span>
          The current <b>password</b> is: <div className="purpleGreyBox">{password}</div>
        </span>
      ),
    });
  return (
    <>
      <FormRow>
        <FormColumn>
          <FieldWithFormState
            formState={formState}
            entityFieldId={`${parentId}.enabled`}
            label="Enabled"
            type={FieldTypes.checkbox}
          />
        </FormColumn>
      </FormRow>

      <FormRow>
        <FormColumn>
          <FieldWithFormState formState={formState} entityFieldId={`${parentId}.id`} label="Account ID" />
          <FieldWithFormState
            formState={formState}
            entityFieldId={`${parentId}.smppVersion`}
            label="SMPP Version"
            type={FieldTypes.select}
            options={['3.4', '5.0']}
          />
        </FormColumn>
      </FormRow>

      <FormRow>
        <FormColumn>
          <FieldWithFormState
            formState={formState}
            entityFieldId={`${parentId}.systemId`}
            label="System ID"
            tip="username"
          />
          <FieldWithFormState
            formState={formState}
            entityFieldId={`${parentId}.password`}
            label="Password"
            type={FieldTypes.password}
            autocomplete={`SMPP Account ${parentId} password`}
            button={
              <Button
                text="Show"
                className="floatRight"
                onClick={() => showPassword(formState.subEntityAt(`${parentId}.password`) as string)}
              />
            }
          />
        </FormColumn>
      </FormRow>

      <FormRow>
        <FormColumn>
          <FieldWithFormState
            formState={formState}
            entityFieldId={`${parentId}.comment`}
            label="Comment"
            type={FieldTypes.textarea}
          />
        </FormColumn>
      </FormRow>

      <FormRow>
        <FormColumn>
          <FieldWithFormState
            formState={formState}
            entityFieldId={`${parentId}.sessionCount`}
            label="Session Count per SMSC node"
            type={FieldTypes.number}
          />
          <FieldWithFormState
            formState={formState}
            entityFieldId={`${parentId}.sessionType`}
            label="Session Type"
            type={FieldTypes.select}
            options={[TRANSMITTER, RECEIVER, TRANSCEIVER]}
          />
        </FormColumn>
      </FormRow>

      <SmppWindow formState={formState} parentId={`${parentId}.smppWindow`} />
      <SubnumberProxy formState={formState} parentId={`${parentId}.subnumberProxy`} />
    </>
  );
};
