import {getLog} from '../log';
import {AccessNumberRange, ID, Product} from '../model';
import {useProductOverviews} from './useProductOverviews';
import {useProductTypes} from './useProductTypes';

const log = getLog('useFormattedText', 'INFO');

interface WithAccessNumberOrRange {
  accessNumber?: ID;
  accessNumberRange?: AccessNumberRange;
}

export interface UseFormattedText {
  accessNumberOrRange: (accessNumberOrRange: WithAccessNumberOrRange) => string;
  productSubject: (product: Product) => string;
}

export const useFormattedText: () => UseFormattedText = () => {
  const {getReservedOasByProductId} = useProductOverviews();
  const {canReserveOa, usesRbmAgentOwner} = useProductTypes();
  const accessNumberOrRange: (withAccessNumberOrRange: WithAccessNumberOrRange) => string = (obj) =>
    obj.accessNumber
      ? obj.accessNumber
      : obj.accessNumberRange
      ? `${obj.accessNumberRange?.from}-${obj.accessNumberRange?.to} (${obj.accessNumberRange?.count})`
      : 'No access Number';

  const productSubject: (product: Product) => string = (product) => {
    return usesRbmAgentOwner(product.productType)
      ? product.rbmAgentOwner || ''
      : canReserveOa(product.productType)
      ? getReservedOasByProductId(product.id) || ''
      : accessNumberOrRange(product);
  };
  return {accessNumberOrRange, productSubject};
};
