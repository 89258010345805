"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.rangeTo = exports.rangesOverlap = exports.rangeIncludes = exports.rangeValues = exports.isValid = void 0;
require("./utils");
const digitsReg = new RegExp('^\\d+$');
const isValid = ({ from, to }) => {
    return from <= to && from.length === to.length && digitsReg.test(from) && digitsReg.test(to);
};
exports.isValid = isValid;
const rangeValues = (range) => {
    if (!(0, exports.isValid)(range)) {
        throw new Error('Invalid access number range: ' + JSON.stringify(range));
    }
    const { from, to } = range;
    const { length } = from;
    return Array.range(parseInt(from), parseInt(to)).map((i) => i.toString().padStart(length, '0'));
};
exports.rangeValues = rangeValues;
const rangeIncludes = (range, accessNumber) => {
    if (!(0, exports.isValid)(range)) {
        // throw new Error('Invalid range range: ' + JSON.stringify(range));
        return false;
    }
    if (!accessNumber || !digitsReg.test(accessNumber)) {
        // throw new Error('Invalid access number: ' + accessNumber);
        return false;
    }
    const { from, to } = range;
    return from.length === accessNumber.length && from <= accessNumber && to >= accessNumber;
};
exports.rangeIncludes = rangeIncludes;
const rangesOverlap = (a, b) => {
    if (!(0, exports.isValid)(a) || !(0, exports.isValid)(b)) {
        // throw new Error('Invalid series range: ' + JSON.stringify(series));
        return false;
    }
    return a.from.length === b.from.length && ((a.from <= b.to && a.to >= b.from) || (b.from <= a.to && b.to >= a.from));
};
exports.rangesOverlap = rangesOverlap;
const rangeTo = (from, count) => {
    if (!count || count < 0) {
        throw new Error('Invalid count: ' + count);
    }
    if (!from || !digitsReg.test(from)) {
        throw new Error('Invalid from: ' + count);
    }
    return (parseInt(from) + count - 1).toString().padStart(from.length, '0');
};
exports.rangeTo = rangeTo;
