import dateformat, {i18n} from 'dateformat';
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
import numeral from 'numeral';

import {isValidDuration} from './dates';
import {Duration} from './model';

// @ts-ignore
momentDurationFormatSetup(moment); // configure the duration formatter plugin

const DATE_FORMAT = 'yyyy-mm-dd';
const DATE_TIME_FORMAT = 'yyyy-mm-dd HH:MM:ss';
const DATE_TIME_MS_FORMAT = 'yyyy-mm-dd HH:MM:ss.l';

const dateFormat: (date: Date | string | number, format: string, useUTC?: boolean) => string = (
  date,
  format,
  useUTC
) => {
  try {
    if (!date) {
      throw new Error('Empty date');
    }
    return dateformat(date, format, useUTC);
  } catch (e) {
    return 'Invalid date';
  }
};

export const date: (date: Date | string | number) => string = (date) => dateFormat(date, DATE_FORMAT);
export const dateTime: (date: Date | string | number, useUTC?: boolean) => string = (date, useUTC) =>
  dateFormat(date, DATE_TIME_FORMAT, useUTC);
export const dateTimeMs: (date: Date | string | number, useUTC?: boolean) => string = (date, useUTC) =>
  dateFormat(date, DATE_TIME_MS_FORMAT, useUTC);

export const monthMm: (date: Date) => string = (date) => dateformat(date, 'mm');

export const month: (date: Date) => string = (date) => dateformat(date, 'mmmm');

export const monthName: (mm: string) => string = (mm) => i18n.monthNames[Number(mm) - 1] || 'Invalid Month: ' + mm;

export const yearMonth: (yyyymm: string | number) => string = (yyyymm) =>
  monthName(yyyymm.toString().substring(4, 6)) + ' ' + yyyymm.toString().substring(0, 4);

export const decimal: (number?: number, hasTrailingZeros?: boolean) => string = (number, hasTrailingZeros = true) => {
  if (number !== undefined && (hasTrailingZeros || hasDecimals(number))) {
    return numeral(number).format('0,0.00').replace(/,/g, ' ').replace('.', ',');
  } else {
    return integer(number);
  }
};

export const integer: (number?: number) => string = (number) =>
  number === undefined ? '' : numeral(number).format('0,0').replace(/,/g, ' ');

export const duration: (duration: Duration | number) => string = (duration) =>
  isString(duration) && !isValidDuration(duration as string)
    ? 'Invalid duration'
    : moment.duration(duration).format('y [y] w [w] d [d] h [h] m [m] s [s] S [ms]', {
        trim: 'both',
      });

export const durationCompact: (duration: Duration | number, maxUnits?: number) => string = (value, maxUnits = 2) => {
  const fullDuration = duration(value);
  const splitDuration = fullDuration.split(' ');
  const maxSpaces = maxUnits * 2 - 1;
  return fullDuration === 'Invalid duration'
    ? 'Invalid'
    : splitDuration.length > maxSpaces + 1
    ? splitDuration
        .filter((_, i) => i <= maxSpaces)
        .join(' ')
        .concat('...')
    : fullDuration;
};

const integerWithDash = (number: number, hasTrailingDash?: boolean) =>
  hasTrailingDash ? integer(number) + ',-' : integer(number);

export const currency: (
  number: number,
  symbol: string | undefined,
  showDecimals?: boolean,
  hasTrailingDash?: boolean
) => string = (number, symbol = '€$£', showDecimals, hasTrailingDash) => {
  const value =
    (showDecimals !== undefined ? showDecimals : hasDecimals(number)) && (!hasTrailingDash || hasDecimals(number))
      ? decimal(number)
      : integerWithDash(number, hasTrailingDash);

  return `${symbol} ${value}`;
};

export const hasDecimals: (number: number) => boolean = (number) => number !== undefined && number % 1 !== 0;

export const possessiveForm = (str?: string): string | undefined => {
  return str && (str?.slice(-1).toLowerCase() === 's' ? str + "'" : str + "'s");
};

const format = {
  date,
  dateTime,
  dateTimeMs,
  month,
  monthMm,
  monthName,
  yearMonth,
  decimal,
  integer,
  duration,
  durationCompact,
  hasDecimals,
  currency,
  possessiveForm,
};

export default format;

type ID = string;
interface IdAndMaybeNameI {
  id: ID;
  name?: string | null;
}
export const contractDisplayName: (contract: IdAndMaybeNameI) => string = (c) =>
  `${c.id} ${c.name ? `- ${c.name}` : ''}`;

function isString(value: any) {
  return typeof value === 'string' || value instanceof String;
}
