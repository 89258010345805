import classnames from 'classnames';
import React, {FunctionComponent} from 'react';
import './Icon.css';
import {Icon as TeliaIcon, IconDefinition} from '@telia/styleguide';
import {Link} from 'react-router-dom';

interface IconLinkProps {
  icon: IconDefinition;
  linkTo: string;
  className?: string;
  info?: string;
}

export const IconLink: FunctionComponent<IconLinkProps> = ({icon, info, linkTo, className}) => (
  <Link to={linkTo} title={info}>
    <TeliaIcon icon={icon} title={info} className={classnames('Icon', className)} />
  </Link>
);
