import {ApolloLink} from '@apollo/client';
import {addBreadcrumb, Severity} from '../sentry';
import {getLog} from '../log';

const log = getLog('apolloLinkSentry', 'INFO');

const apolloLinkSentry = new ApolloLink((operation, forward) => {
  const {operationName, variables} = operation;
  log.debug('GraphQL request', {operationName, variables});
  addBreadcrumb({
    category: 'GraphQL',
    type: 'GraphQL',
    message: `GraphQL ${operationName} operation`,
    data: {operationName, variables},
    level: Severity.fromString('info'),
  });
  return forward(operation);
});
export default apolloLinkSentry;
