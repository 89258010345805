import * as Sentry from '@sentry/browser';
import {LOCALHOST} from '@telia/cpa-web-common/dist/environments';

import {version} from '../../../package.json';
import {User} from './model';
import {currentEnvironment} from './utils/host';

export const {Severity} = Sentry;

const environment = currentEnvironment();

const skipSentry = environment === LOCALHOST;

const sentryConfig = {
  dsn: 'https://21fc29c8881f4da485dd06d7a95d3f08@o47373.ingest.sentry.io/5312382',
  environment,
  release: version,
};

export const initSentry = () => {
  if (skipSentry) {
    return false;
  } else {
    Sentry.init(sentryConfig);
    Sentry.configureScope((scope) => {
      scope.setTag('hostname', window.location.hostname);
    });
    return sentryConfig;
  }
};

export const setSentryUser = (user: User) => {
  Sentry.configureScope((scope) => {
    scope.setUser(user);
  });
  user && reportToSentry({message: 'Logged user', data: user, level: 'INFO'});
};

export const addBreadcrumb = ({category, type, message, data, level}: Sentry.Breadcrumb) => {
  Sentry.addBreadcrumb({category, type, message, data, level});
};

export interface SentryReport {
  message?: string;
  error?: any;
  data?: any;
  level: string;
}

export const reportToSentry = ({message, error, data, level}: SentryReport) => {
  Sentry.withScope(function (scope) {
    scope.setLevel(Sentry.Severity.fromString(level));
    message && scope.setTag('message', message);
    data &&
      (Array.isArray(data) ? data : [data]).forEach((data, i) => {
        scope.setExtra('extra_data_' + (i + 1), data);
      });
    if (error) {
      Sentry.captureException(error);
    } else {
      Sentry.captureMessage(!!message ? message : 'No message');
    }
  });
};
