import {Button, Container} from '@telia/styleguide';
import classnames from 'classnames';
import React, {FC, useState} from 'react';

import {FormStateOptions, useFormState} from '../../hooks/useFormState';
import {getLog} from '../../log';
import Form from '../common/Form';
import FormColumn from '../common/FormColumn';
import FormRow from '../common/FormRow';
import Page from '../common/Page';
import PageSubtitle from '../common/PageSubtitle';
import {Field, FieldTypes, FieldWithFormState} from '../common/field';

const log = getLog('FieldShowroom', 'INFO');

const multiOptions = ['Checkbox A', {id: 'B', name: 'Checkbox B'}, 'Checkbox C'];
const selectTextOptions = ['Option A', 'Option B', 'Option C'];
const selectIdNameOptions = [
  {id: 'A', name: 'Option A'},
  {id: 'B', name: 'Option B'},
  {id: 'C', name: 'Option C'},
];

export const FieldShowroomFc: FC = () => {
  const formStateOptions: FormStateOptions = {isEditing: false};
  const formState = useFormState(formStateOptions);
  const {isEditing, entity, onEdit, onCancel, onChange, initialEntity} = formState;
  const [formCompact, setFormCompact] = useState<boolean>(true);
  const [showBothStates, setShowBothStates] = useState<boolean>(true);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);
  const [showTip, setShowTip] = useState<boolean>(false);
  const tip = showTip ? 'Tip on how to use this field' : undefined;
  const error = showError ? 'Some error in this field' : undefined;
  log.debug('render', formState);

  return (
    <Container size={'large'} className={classnames({'form--compact': formCompact})}>
      <Page>
        <React.Fragment>
          <PageSubtitle subtitle="Testing Fields" />
          <Form>
            <FormRow>
              <FormColumn>
                <Field
                  onChange={(value) => setShowBothStates(!!value)}
                  value={showBothStates}
                  label="Show both states"
                  // type={FieldTypes.checkbox}
                  type={FieldTypes.checkbox}
                  isEditing={true}
                />
                {entity && (
                  <div>
                    <Button
                      // style={showBothStates ? {} : {border: '1px solid'}}
                      type="button"
                      isDisabled={showBothStates}
                      kind={Button.kinds.primary}
                      text={isEditing ? 'Editing' : 'Displaying'}
                      onClick={() => (isEditing ? onCancel() : onEdit())}
                    />
                  </div>
                )}
              </FormColumn>
              <FormColumn>
                <Field
                  onChange={() => setFormCompact(!formCompact)}
                  value={formCompact}
                  label="Compact form"
                  type={FieldTypes.checkbox}
                  isEditing={true}
                />
              </FormColumn>
            </FormRow>
            <FormRow>
              <FormColumn>
                <Field
                  onChange={() => setShowTip(!showTip)}
                  value={showTip}
                  label="Show tips"
                  // type={FieldTypes.checkbox}
                  type={FieldTypes.checkbox}
                  isEditing={true}
                />
                <Field
                  onChange={() => setShowError(!showError)}
                  value={showError}
                  label="Show errors"
                  type={FieldTypes.checkbox}
                  isEditing={true}
                />
              </FormColumn>
              <FormColumn>
                <Field
                  onChange={() => setDisabled(!disabled)}
                  value={disabled}
                  label="Disabled"
                  type={FieldTypes.checkbox}
                  isEditing={true}
                />
              </FormColumn>
            </FormRow>
          </Form>
          {entity && (
            <Form>
              <FormRow>
                <FormColumn>
                  <h4 style={{borderBottom: '2px solid #990ae3', color: '#990ae3'}}>No defaults</h4>
                </FormColumn>
                <FormColumn>
                  <h4 style={{borderBottom: '2px solid #990ae3', color: '#990ae3'}}>With default value</h4>
                </FormColumn>
              </FormRow>
              <FormRow>
                <FormColumn>
                  <h4 style={{borderBottom: '2px solid #990ae3', color: '#990ae3'}}>
                    {showBothStates || isEditing ? 'Editing' : 'Displaying'}
                  </h4>
                  {showBothStates && <h4 style={{borderBottom: '2px solid #990ae3', color: '#990ae3'}}>Displaying</h4>}
                </FormColumn>
                <FormColumn>
                  <h4 style={{borderBottom: '2px solid #990ae3', color: '#990ae3'}}>
                    {showBothStates || isEditing ? 'Editing' : 'Displaying'}
                  </h4>
                  {showBothStates && <h4 style={{borderBottom: '2px solid #990ae3', color: '#990ae3'}}>Displaying</h4>}
                </FormColumn>
              </FormRow>
              <FormRow>
                <FormColumn>
                  <FieldWithFormState
                    formState={formState}
                    entityFieldId={'text'}
                    label="Text"
                    isEditing={showBothStates || isEditing}
                    error={error}
                    tip={tip}
                    isDisabled={disabled}
                  />
                  <FieldWithFormState
                    className={classnames({hidden: !showBothStates})}
                    formState={formState}
                    entityFieldId={'text'}
                    label="Text"
                    isEditing={false}
                    error={error}
                    tip={tip}
                    isDisabled={disabled}
                  />
                </FormColumn>
                <FormColumn>
                  <FieldWithFormState
                    formState={formState}
                    entityFieldId={'text'}
                    label="Text"
                    defaultValue="Default text"
                    isEditing={showBothStates || isEditing}
                    error={error}
                    tip={tip}
                    isDisabled={disabled}
                  />
                  <FieldWithFormState
                    className={classnames({hidden: !showBothStates})}
                    formState={formState}
                    entityFieldId={'text'}
                    label="Text"
                    defaultValue="Default text"
                    isEditing={false}
                    error={error}
                    tip={tip}
                    isDisabled={disabled}
                  />
                </FormColumn>
              </FormRow>
              {/* TODO: show icons*/}
              <FormRow>
                <FormColumn>
                  <FieldWithFormState
                    formState={formState}
                    entityFieldId={'number'}
                    label="Number"
                    type={FieldTypes.number}
                    isEditing={showBothStates || isEditing}
                    error={error}
                    tip={tip}
                    isDisabled={disabled}
                  />
                  <FieldWithFormState
                    className={classnames({hidden: !showBothStates})}
                    formState={formState}
                    entityFieldId={'number'}
                    label="Number"
                    type={FieldTypes.number}
                    isEditing={false}
                    error={error}
                    tip={tip}
                    isDisabled={disabled}
                  />
                </FormColumn>
                <FormColumn>
                  <FieldWithFormState
                    formState={formState}
                    entityFieldId={'number'}
                    label="Number"
                    type={FieldTypes.number}
                    defaultValue="9999"
                    isEditing={showBothStates || isEditing}
                    error={error}
                    tip={tip}
                    isDisabled={disabled}
                  />
                  <FieldWithFormState
                    className={classnames({hidden: !showBothStates})}
                    formState={formState}
                    entityFieldId={'number'}
                    label="Number"
                    type={FieldTypes.number}
                    defaultValue="9999"
                    isEditing={false}
                    error={error}
                    tip={tip}
                    isDisabled={disabled}
                  />
                </FormColumn>
              </FormRow>
              <FormRow>
                <FormColumn>
                  <FieldWithFormState
                    formState={formState}
                    entityFieldId={'currency'}
                    label="Currency"
                    type={FieldTypes.currency}
                    currencyCode={'NOK'}
                    isEditing={showBothStates || isEditing}
                    error={error}
                    tip={tip}
                    isDisabled={disabled}
                  />
                  <FieldWithFormState
                    className={classnames({hidden: !showBothStates})}
                    formState={formState}
                    entityFieldId={'currency'}
                    label="Currency"
                    type={FieldTypes.currency}
                    currencyCode={'NOK'}
                    isEditing={false}
                    error={error}
                    tip={tip}
                    isDisabled={disabled}
                  />
                </FormColumn>
                <FormColumn>
                  <FieldWithFormState
                    formState={formState}
                    entityFieldId={'currency'}
                    label="Currency"
                    type={FieldTypes.currency}
                    currencyCode={'NOK'}
                    defaultValue="9999.99"
                    isEditing={showBothStates || isEditing}
                    error={error}
                    tip={tip}
                    isDisabled={disabled}
                  />
                  <FieldWithFormState
                    className={classnames({hidden: !showBothStates})}
                    formState={formState}
                    entityFieldId={'currency'}
                    label="Currency"
                    type={FieldTypes.currency}
                    currencyCode={'NOK'}
                    defaultValue="9999.99"
                    isEditing={false}
                    error={error}
                    tip={tip}
                    isDisabled={disabled}
                  />
                </FormColumn>
              </FormRow>
              <FormRow>
                <FormColumn>
                  <FieldWithFormState
                    formState={formState}
                    entityFieldId={'startDate'}
                    label="Date"
                    type={FieldTypes.date}
                    // defaultValue={'since ever'}tip={tip}
                    isEditing={showBothStates || isEditing}
                    error={error}
                    tip={tip}
                    isDisabled={disabled}
                  />
                  <FieldWithFormState
                    className={classnames({hidden: !showBothStates})}
                    formState={formState}
                    entityFieldId={'startDate'}
                    label="Date"
                    type={FieldTypes.date}
                    // defaultValue={'since ever'}
                    isEditing={false}
                    error={error}
                    tip={tip}
                    isDisabled={disabled}
                  />
                </FormColumn>
                <FormColumn>
                  <FieldWithFormState
                    formState={formState}
                    entityFieldId={'startDate'}
                    label="Date"
                    type={FieldTypes.date}
                    defaultValue={'since ever'}
                    isEditing={showBothStates || isEditing}
                    error={error}
                    tip={tip}
                    isDisabled={disabled}
                  />
                  <FieldWithFormState
                    className={classnames({hidden: !showBothStates})}
                    formState={formState}
                    entityFieldId={'startDate'}
                    label="Date"
                    type={FieldTypes.date}
                    defaultValue={'since ever'}
                    isEditing={false}
                    error={error}
                    tip={tip}
                    isDisabled={disabled}
                  />
                </FormColumn>
              </FormRow>
              <FormRow>
                <FormColumn>
                  <FieldWithFormState
                    formState={formState}
                    entityFieldId={'time'}
                    label="Time"
                    type={FieldTypes.time}
                    // defaultValue={'all day'}tip={tip}
                    isEditing={showBothStates || isEditing}
                    error={error}
                    tip={tip}
                    isDisabled={disabled}
                  />
                  <FieldWithFormState
                    className={classnames({hidden: !showBothStates})}
                    formState={formState}
                    entityFieldId={'time'}
                    label="Time"
                    type={FieldTypes.time}
                    // defaultValue={'all day'}
                    isEditing={false}
                    error={error}
                    tip={tip}
                    isDisabled={disabled}
                  />
                </FormColumn>
                <FormColumn>
                  <FieldWithFormState
                    formState={formState}
                    entityFieldId={'time'}
                    label="Time"
                    type={FieldTypes.time}
                    defaultValue={'all day'}
                    isEditing={showBothStates || isEditing}
                    error={error}
                    tip={tip}
                    isDisabled={disabled}
                  />
                  <FieldWithFormState
                    className={classnames({hidden: !showBothStates})}
                    formState={formState}
                    entityFieldId={'time'}
                    label="Time"
                    type={FieldTypes.time}
                    defaultValue={'all day'}
                    isEditing={false}
                    error={error}
                    tip={tip}
                    isDisabled={disabled}
                  />
                </FormColumn>
              </FormRow>
              <FormRow>
                <FormColumn>
                  <FieldWithFormState
                    formState={formState}
                    entityFieldId={'password'}
                    label="Password"
                    type={FieldTypes.password}
                    // defaultValue="some password"tip={tip}
                    isEditing={showBothStates || isEditing}
                    error={error}
                    tip={tip}
                    isDisabled={disabled}
                  />
                  <FieldWithFormState
                    className={classnames({hidden: !showBothStates})}
                    formState={formState}
                    entityFieldId={'password'}
                    label="Password"
                    type={FieldTypes.password}
                    // defaultValue="some password"
                    isEditing={false}
                    error={error}
                    tip={tip}
                    isDisabled={disabled}
                  />
                </FormColumn>
                <FormColumn>
                  <FieldWithFormState
                    formState={formState}
                    entityFieldId={'password'}
                    label="Password"
                    type={FieldTypes.password}
                    defaultValue="some password"
                    isEditing={showBothStates || isEditing}
                    error={error}
                    tip={tip}
                    isDisabled={disabled}
                  />
                  <FieldWithFormState
                    className={classnames({hidden: !showBothStates})}
                    formState={formState}
                    entityFieldId={'password'}
                    label="Password"
                    type={FieldTypes.password}
                    defaultValue="some password"
                    isEditing={false}
                    error={error}
                    tip={tip}
                    isDisabled={disabled}
                  />
                </FormColumn>
              </FormRow>
              <FormRow>
                <FormColumn>
                  <FieldWithFormState
                    formState={formState}
                    entityFieldId={'duration'}
                    label="Duration"
                    type={FieldTypes.duration}
                    // defaultValue="some duration"tip={tip}
                    isEditing={showBothStates || isEditing}
                    error={error}
                    tip={tip}
                    isDisabled={disabled}
                  />
                  <FieldWithFormState
                    className={classnames({hidden: !showBothStates})}
                    formState={formState}
                    entityFieldId={'duration'}
                    label="Duration"
                    type={FieldTypes.duration}
                    // defaultValue="some duration"
                    isEditing={false}
                    error={error}
                    tip={tip}
                    isDisabled={disabled}
                  />
                </FormColumn>
                <FormColumn>
                  <FieldWithFormState
                    formState={formState}
                    entityFieldId={'duration'}
                    label="Duration"
                    type={FieldTypes.duration}
                    defaultValue="PT30S"
                    isEditing={showBothStates || isEditing}
                    error={error}
                    tip={tip}
                    isDisabled={disabled}
                  />
                  <FieldWithFormState
                    className={classnames({hidden: !showBothStates})}
                    formState={formState}
                    entityFieldId={'duration'}
                    label="Duration"
                    type={FieldTypes.duration}
                    defaultValue="PT30S"
                    isEditing={false}
                    error={error}
                    tip={tip}
                    isDisabled={disabled}
                  />
                </FormColumn>
              </FormRow>
              <FormRow>
                <FormColumn>
                  <FieldWithFormState
                    formState={formState}
                    entityFieldId={'productType'}
                    label="Select {id,name} opts"
                    type={FieldTypes.select}
                    options={selectIdNameOptions}
                    isEditing={showBothStates || isEditing}
                    error={error}
                    tip={tip}
                    isDisabled={disabled}
                  />
                  <FieldWithFormState
                    className={classnames({hidden: !showBothStates})}
                    formState={formState}
                    entityFieldId={'productType'}
                    label="Select {id,name} opts"
                    type={FieldTypes.select}
                    options={selectIdNameOptions}
                    isEditing={false}
                    error={error}
                    tip={tip}
                    isDisabled={disabled}
                  />
                </FormColumn>
                <FormColumn>
                  <FieldWithFormState
                    formState={formState}
                    entityFieldId={'productType'}
                    label="Select {id,name} opts"
                    defaultValue={'Default'}
                    type={FieldTypes.select}
                    options={selectIdNameOptions}
                    isEditing={showBothStates || isEditing}
                    error={error}
                    tip={tip}
                    isDisabled={disabled}
                  />
                  <FieldWithFormState
                    className={classnames({hidden: !showBothStates})}
                    formState={formState}
                    entityFieldId={'productType'}
                    label="Select {id,name} opts"
                    defaultValue={'Default'}
                    type={FieldTypes.select}
                    options={selectIdNameOptions}
                    isEditing={false}
                    error={error}
                    tip={tip}
                    isDisabled={disabled}
                  />
                </FormColumn>
              </FormRow>
              <FormRow>
                <FormColumn>
                  <FieldWithFormState
                    formState={formState}
                    entityFieldId={'selectSimple'}
                    label="Select 'text' options"
                    // defaultValue={'Default'}
                    type={FieldTypes.select}
                    options={selectTextOptions}
                    isEditing={showBothStates || isEditing}
                    error={error}
                    tip={tip}
                    isDisabled={disabled}
                  />
                  <FieldWithFormState
                    className={classnames({hidden: !showBothStates})}
                    formState={formState}
                    entityFieldId={'selectSimple'}
                    label="Select 'text' options"
                    // defaultValue={'Default'}
                    type={FieldTypes.select}
                    options={selectTextOptions}
                    isEditing={false}
                    error={error}
                    tip={tip}
                    isDisabled={disabled}
                  />
                </FormColumn>
                <FormColumn>
                  <FieldWithFormState
                    formState={formState}
                    entityFieldId={'selectSimple'}
                    label="Select 'text' options"
                    defaultValue={'Default'}
                    type={FieldTypes.select}
                    options={selectTextOptions}
                    isEditing={showBothStates || isEditing}
                    error={error}
                    tip={tip}
                    isDisabled={disabled}
                  />
                  <FieldWithFormState
                    className={classnames({hidden: !showBothStates})}
                    formState={formState}
                    entityFieldId={'selectSimple'}
                    label="Select 'text' options"
                    defaultValue={'Default'}
                    type={FieldTypes.select}
                    options={selectTextOptions}
                    isEditing={false}
                    error={error}
                    tip={tip}
                    isDisabled={disabled}
                  />
                </FormColumn>
              </FormRow>
              <FormRow>
                <FormColumn>
                  <FieldWithFormState
                    formState={formState}
                    entityFieldId={'selectNullable'}
                    label="Nullable Select"
                    // defaultValue={'Default'}
                    type={FieldTypes.select}
                    options={selectTextOptions}
                    isEditing={showBothStates || isEditing}
                    error={error}
                    tip={tip}
                    isDisabled={disabled}
                    isNullable={true}
                  />
                  <FieldWithFormState
                    className={classnames({hidden: !showBothStates})}
                    formState={formState}
                    entityFieldId={'selectNullable'}
                    label="Nullable Select"
                    // defaultValue={'Default'}
                    type={FieldTypes.select}
                    options={selectTextOptions}
                    isEditing={false}
                    error={error}
                    tip={tip}
                    isDisabled={disabled}
                    isNullable={true}
                  />
                </FormColumn>
                <FormColumn>
                  <FieldWithFormState
                    formState={formState}
                    entityFieldId={'selectNullable'}
                    label="Nullable Select"
                    defaultValue={'Default'}
                    type={FieldTypes.select}
                    options={selectTextOptions}
                    isEditing={showBothStates || isEditing}
                    error={error}
                    tip={tip}
                    isDisabled={disabled}
                    isNullable={true}
                  />
                  <FieldWithFormState
                    className={classnames({hidden: !showBothStates})}
                    formState={formState}
                    entityFieldId={'selectNullable'}
                    label="Nullable Select"
                    defaultValue={'Default'}
                    type={FieldTypes.select}
                    options={selectTextOptions}
                    isEditing={false}
                    error={error}
                    tip={tip}
                    isDisabled={disabled}
                    isNullable={true}
                  />
                </FormColumn>
              </FormRow>
              <FormRow>
                <FormColumn>
                  <FieldWithFormState
                    formState={formState}
                    entityFieldId={'checkbox'}
                    label="Checkbox"
                    type={FieldTypes.checkbox}
                    isEditing={showBothStates || isEditing}
                    error={error}
                    tip={tip}
                    isDisabled={disabled}
                  />
                  <FieldWithFormState
                    className={classnames({hidden: !showBothStates})}
                    formState={formState}
                    entityFieldId={'checkbox'}
                    label="Checkbox"
                    type={FieldTypes.checkbox}
                    isEditing={false}
                    error={error}
                    tip={tip}
                    isDisabled={disabled}
                  />
                </FormColumn>
              </FormRow>
              <FormRow>
                <FormColumn>
                  <FieldWithFormState
                    formState={formState}
                    entityFieldId={'multi'}
                    label="Event Types"
                    type={FieldTypes.multi}
                    options={multiOptions}
                    isEditing={showBothStates || isEditing}
                    error={error}
                    tip={tip}
                    isDisabled={disabled}
                  />
                  <FieldWithFormState
                    className={classnames({hidden: !showBothStates})}
                    formState={formState}
                    entityFieldId={'multi'}
                    label="Event Types"
                    type={FieldTypes.multi}
                    options={multiOptions}
                    isEditing={false}
                    error={error}
                    tip={tip}
                    isDisabled={disabled}
                  />
                </FormColumn>
              </FormRow>
              <FormRow>
                <FormColumn>
                  <FieldWithFormState
                    formState={formState}
                    entityFieldId={'element'}
                    label="Element"
                    type={FieldTypes.element}
                    value={
                      <div>
                        <Button text={'Button in field'} isDisabled={disabled} onClick={() => {}} />
                      </div>
                    }
                    isEditing={showBothStates || isEditing}
                    error={error}
                    tip={tip}
                    isDisabled={disabled}
                  />
                  <FieldWithFormState
                    className={classnames({hidden: !showBothStates})}
                    formState={formState}
                    entityFieldId={'element'}
                    label="Element"
                    type={FieldTypes.element}
                    value={
                      <div>
                        <Button text={'Button in field'} isDisabled={true} onClick={() => {}} />
                      </div>
                    }
                    isEditing={false}
                    error={error}
                    tip={tip}
                    isDisabled={disabled}
                  />
                </FormColumn>
              </FormRow>
              <FormRow>
                <FormColumn>
                  <FieldWithFormState
                    formState={formState}
                    entityFieldId={'textarea'}
                    label="Text area"
                    // defaultValue="Default text"
                    type={FieldTypes.textarea}
                    isEditing={showBothStates || isEditing}
                    error={error}
                    tip={tip}
                    isDisabled={disabled}
                  />
                  <FieldWithFormState
                    className={classnames({hidden: !showBothStates})}
                    formState={formState}
                    entityFieldId={'textarea'}
                    label="Text area"
                    // defaultValue="Default text"
                    type={FieldTypes.textarea}
                    isEditing={false}
                    error={error}
                    tip={tip}
                    isDisabled={disabled}
                  />
                </FormColumn>
                <FormColumn>
                  <FieldWithFormState
                    formState={formState}
                    entityFieldId={'textarea'}
                    label="Text area"
                    defaultValue="Default text"
                    type={FieldTypes.textarea}
                    isEditing={showBothStates || isEditing}
                    error={error}
                    tip={tip}
                    isDisabled={disabled}
                  />
                  <FieldWithFormState
                    className={classnames({hidden: !showBothStates})}
                    formState={formState}
                    entityFieldId={'textarea'}
                    label="Text area"
                    defaultValue="Default text"
                    type={FieldTypes.textarea}
                    isEditing={false}
                    error={error}
                    tip={tip}
                    isDisabled={disabled}
                  />
                </FormColumn>
              </FormRow>
            </Form>
          )}
        </React.Fragment>
      </Page>
    </Container>
  );
};
