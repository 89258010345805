import React, {createElement, FC, PropsWithChildren} from 'react';
import classnames from 'classnames';
import {PropsWithClassName, PropsWithId} from '../../model';

interface TableCellProps extends PropsWithId, PropsWithClassName, PropsWithChildren {
  isHeader?: boolean;
}

const TableCellFc: FC<TableCellProps> = (props) => {
  return createElement(
    props.isHeader ? 'th' : 'td',
    {
      id: props.id,
      className: classnames(props.className),
    },
    props.children
  );
};

export default TableCellFc;
