import React, {FC} from 'react';

import {getLog} from '../../log';
import PageSubtitle from '../common/PageSubtitle';
import {PageViewCounter} from '../metrics/PageViewCounter';
import {ReportSenderIdTable} from './ReportSenderIdTable';

const log = getLog('ReservedOas', 'INFO');

export const ReportReservedOasFc: FC<{}> = () => {
  log.debug('ReportReservedOasFc');
  return (
    <>
      <PageSubtitle subtitle="Reserved SenderID" />
      <PageViewCounter page="reserved-senderid" />

      <ReportSenderIdTable />
    </>
  );
};
