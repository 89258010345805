import {period} from '@telia/cpa-web-common';

import {getLog} from '../../log';
import {Contract} from '../../model';

const log = getLog('provisioning/utils', 'INFO');

export const filterInactiveContractsAndProducts: (
  contracts: Contract[] | undefined,
  showInactive: boolean
) => Contract[] = (contracts = [], showInactive) => {
  log.debug('filterContracts', showInactive);
  return contracts
    .filter((contract) => showInactive || period.isActive(contract))
    .map((contract) => ({
      ...contract,
      products: contract.products.filter((product) => showInactive || period.isActive(product)),
    }));
};
