"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useThSortable = void 0;
var _react = require("react");
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }
function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _toArray(arr) { return _arrayWithHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
var DEFAULT_MAX_SORTED_FIELDS = 2;
var ORDER_CORRECT = -1;
var ORDER_INDIFFERENT = 0;
var ORDER_WRONG = 1;
var updateSorting = function updateSorting(_ref, field, maxSortingFields) {
  var _ref2 = _toArray(_ref),
    sortingArray = _ref2.slice(0);
  return !field ? _toConsumableArray(sortingArray) : [{
    field: field,
    inverted: !sortingArray[0] || sortingArray[0].field !== field ? false : !sortingArray[0].inverted
  }].concat(_toConsumableArray(sortingArray.filter(function (s) {
    return s.field !== field;
  }))).slice(0, maxSortingFields);
};
var findFieldSorting = function findFieldSorting(field, sortingArray) {
  return !sortingArray ? undefined : sortingArray.reduce(function (found, sorting, index, array) {
    return found ? found : field === sorting.field ? {
      inverted: !!sorting.inverted,
      priority: array.length > 1 ? index + 1 : undefined
    } : undefined;
  }, undefined);
};
var createThSortablePropsGetter = function createThSortablePropsGetter(sortingArray, setSortingArray, maxSortingFields) {
  return function (field) {
    var fieldSorting = findFieldSorting(field, sortingArray);
    return _objectSpread({
      onSort: function onSort() {
        setSortingArray(updateSorting(sortingArray, field, maxSortingFields));
      }
    }, fieldSorting ? {
      isSorted: true,
      isSortedDesc: !!fieldSorting.inverted,
      sortPriority: fieldSorting.priority
    } : {
      isSorted: false
    });
  };
};
var hasValue = function hasValue(value) {
  return value !== null && typeof value !== 'undefined';
};
var createComparator = function createComparator(_ref3) {
  var field = _ref3.field,
    inverted = _ref3.inverted;
  var specialFieldComparators = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return function (a, b) {
    var valueA = a ? a[field] : null,
      valueB = b ? b[field] : null;
    var result = !field || !hasValue(valueA) && !hasValue(valueB) ? ORDER_INDIFFERENT : !hasValue(valueA) ? ORDER_CORRECT : !hasValue(valueB) ? ORDER_WRONG : specialFieldComparators[field] ? specialFieldComparators[field](valueA, valueB) : valueA.localeCompare //  String.localCompare
    ? valueA.localeCompare(valueB) : valueA - valueB;
    return (inverted ? -1 : 1) * result;
  };
};
var sortObjects = function sortObjects(objects, sortingArray, specialFieldComparators) {
  if (!objects || objects.length === 0 || !sortingArray || sortingArray.length === 0) {
    return objects;
  }
  var sortComparator = function sortComparator(a, b) {
    return sortingArray.reduce(function (res, sorting) {
      return res !== ORDER_INDIFFERENT ? res : createComparator(sorting, specialFieldComparators)(a, b);
    }, ORDER_INDIFFERENT);
  };
  return _toConsumableArray(objects).sort(sortComparator);
};
var parseInitialSorting = function parseInitialSorting(initialSorting) {
  if (initialSorting === null || typeof initialSorting === 'undefined') {
    return [];
  }
  return (Array.isArray(initialSorting) ? initialSorting : [initialSorting]).map(function (initialSorting) {
    return typeof initialSorting === 'string' ? initialSorting.startsWith('!') ? {
      field: initialSorting.substring(1),
      inverted: true
    } : {
      field: initialSorting
    } : initialSorting;
  }).filter(function (sorting) {
    return sorting.field;
  });
};
/**
 * Returns a sorted version of the objects parameter,
 * and a function to get ThSortableProps.
 *
 * @param objects   The original array of objects to be sorted.
 * @param maxSortedFields   The maximum number of sorting criteria.
 * @param initialSorting    The initial sorting array, expressed as [[string]] or [[Sorting]].
 *                          When using [[string]] format, the value is the name of the object's property,
 *                          prefix with '!' to invert the sorting.
 * @param specialFieldComparators   A dictionary object for special object's property comparators.
 */
var useThSortable = function useThSortable() {
  var objects = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var maxSortedFields = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : DEFAULT_MAX_SORTED_FIELDS;
  var initialSorting = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
  var specialFieldComparators = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
  var _useState = (0, _react.useState)(parseInitialSorting(initialSorting)),
    _useState2 = _slicedToArray(_useState, 2),
    sortingArray = _useState2[0],
    setSortingArray = _useState2[1];
  var sortedObjects = (0, _react.useMemo)(function () {
    return sortObjects(objects, sortingArray, specialFieldComparators);
  }, [objects, maxSortedFields, sortingArray, specialFieldComparators]);
  var thSortablePropsGetter = createThSortablePropsGetter(sortingArray, setSortingArray, maxSortedFields);
  return [sortedObjects, thSortablePropsGetter];
};
exports.useThSortable = useThSortable;
if (process.env.NODE_ENV === 'test') {
  module.exports.parseInitialSorting = parseInitialSorting;
  module.exports.updateSorting = updateSorting;
  module.exports.createComparator = createComparator;
  module.exports.sortObjects = sortObjects;
  module.exports.findFieldSorting = findFieldSorting;
  module.exports.thSortableProps = createThSortablePropsGetter;
}