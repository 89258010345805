import React from 'react';
import {FC} from 'react';

import {FormState} from '../../hooks/useFormState';
import {getLog} from '../../log';
import FormColumn from '../common/FormColumn';
import FormRow from '../common/FormRow';
import {MobileField} from '../common/MobileField';
import {FieldTypes} from '../common/field';
import {FieldWithFormState} from '../common/field/FieldWithFormState';

const log = getLog('SelfServiceUser', 'INFO');

export interface SelfServiceUserProps {
  formState: FormState;
}

export const SelfServiceUser: FC<SelfServiceUserProps> = (props) => {
  const {formState} = props;

  return (
    <>
      <FormRow>
        <FormColumn>
          <FieldWithFormState formState={formState} entityFieldId="firstName" label="First name" />
          <FieldWithFormState formState={formState} entityFieldId="lastName" label="Last name" />
        </FormColumn>
      </FormRow>
      <FormRow>
        <FormColumn>
          <FieldWithFormState formState={formState} entityFieldId="email" label="Email" isEditing={false} />
        </FormColumn>
      </FormRow>
      <FormRow>
        <FormColumn>
          <MobileField formState={formState} />
        </FormColumn>
      </FormRow>
      <FormRow>
        <FormColumn>
          <FieldWithFormState
            formState={formState}
            entityFieldId="description"
            label="Reason"
            tip={
              formState.isEditing ? 'Please specify your reason to getting access to Telia Bulk Messaging Web' : null
            }
            type={FieldTypes.textarea}
          />
        </FormColumn>
      </FormRow>
    </>
  );
};
