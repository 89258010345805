import {gql, useQuery} from '@apollo/client';
import {ProductSessions} from '@telia/cpa-web-common/dist/model';
import {FullWidthTable} from '@telia/styleguide';
import {useThSortable} from '@telia/styleguide/atoms/FullWidthTable/useThSortable';
import React, {FC, useMemo} from 'react';
import {useNavigate} from 'react-router-dom';

import productsSessionsQuery from '../../graphql/query/productsSessions.graphql';

import * as AppRoutes from '../../appRoutes';
import {getLog} from '../../log';
import Loading from '../Loading';
import InformationLineFc from '../common/InformationLine';
import SectionTitleFc from '../common/SectionTitle';

const log = getLog('SmppSummaryTables', 'INFO');

const {THead, Tr, ThSortable, TBody, Td} = FullWidthTable;

interface ProductsSessionsQuery {
  productsSessions: [ProductSessions];
}

export const SmppSummaryTables: FC = (_props) => {
  const {loading, data: {productsSessions} = {}} = useQuery<ProductsSessionsQuery>(gql(productsSessionsQuery));
  const navigate = useNavigate();
  const {formatWithBrand} = AppRoutes.useBrandFormat();

  const activeTableContent: ProductSessions[] = useMemo(
    () =>
      productsSessions
        ? productsSessions.filter(
            ({receiverCount, transmitterCount, transceiverCount}) =>
              receiverCount || transmitterCount || transceiverCount
          )
        : [],
    [productsSessions]
  );

  const inactiveTableContent: ProductSessions[] = useMemo(
    () =>
      productsSessions
        ? productsSessions.filter(
            ({receiverCount, transmitterCount, transceiverCount}) =>
              !receiverCount && !transmitterCount && !transceiverCount
          )
        : [],
    [productsSessions]
  );

  const [sortedActiveTableContent, getThActiveSortableProps] = useThSortable<ProductSessions>(
    activeTableContent,
    3,
    [],
    {}
  );

  const [sortedInactiveTableContent, getThInactiveSortableProps] = useThSortable<ProductSessions>(
    inactiveTableContent,
    3,
    [],
    {}
  );

  const getTable = (tableItems: ProductSessions[], getThSortableProps: any, title: string, errorMessage: string) => (
    <>
      <SectionTitleFc>{title} </SectionTitleFc>
      {!(tableItems && tableItems.length) ? (
        <InformationLineFc>{errorMessage}</InformationLineFc>
      ) : (
        <FullWidthTable clickable={true}>
          <THead>
            <Tr>
              <ThSortable {...getThSortableProps('productId')}>Service</ThSortable>
              <ThSortable {...getThSortableProps('customerId')}>Customer</ThSortable>
              <ThSortable {...getThSortableProps('customerName')}>Customer Name</ThSortable>
              <ThSortable {...getThSortableProps('receiverCount')}>Receivers</ThSortable>
              <ThSortable {...getThSortableProps('transmitterCount')}>Transmitters</ThSortable>
              <ThSortable {...getThSortableProps('transceiverCount')}> Transceivers</ThSortable>
              <ThSortable {...getThSortableProps('maxSessions')}>Max Sessions</ThSortable>
            </Tr>
          </THead>

          <TBody>
            {tableItems.map(
              (
                {productId, customerId, customerName, receiverCount, transmitterCount, transceiverCount, maxSessions},
                i
              ) => (
                <Tr
                  key={i}
                  onClick={() => navigate(formatWithBrand(AppRoutes.PROVISIONING_CUSTOMER__customerId, customerId))}
                >
                  <Td>{productId}</Td>
                  <Td>{customerId}</Td>
                  <Td>{customerName}</Td>
                  <Td>{receiverCount}</Td>
                  <Td>{transmitterCount}</Td>
                  <Td>{transceiverCount}</Td>
                  <Td>{maxSessions}</Td>
                </Tr>
              )
            )}
          </TBody>
        </FullWidthTable>
      )}
    </>
  );

  log.debug('productsSessions', {productsSessions});

  return (
    <>
      {loading && !productsSessions ? (
        <Loading />
      ) : (
        <>
          {getTable(
            sortedActiveTableContent,
            getThActiveSortableProps,
            'Products with active Sessions',
            'No Products with active Sessions found'
          )}
          {getTable(
            sortedInactiveTableContent,
            getThInactiveSortableProps,
            'Products with no Sessions',
            'No Products with SMPP configuration found'
          )}
        </>
      )}
    </>
  );
};
