import {OperationVariables, gql, useApolloClient} from '@apollo/client';
import {DocumentNode, FragmentDefinitionNode} from 'graphql';
import React, {useState} from 'react';

import * as AppRoutes from '../appRoutes';
import {getLog} from '../log';
import {ID} from '../model';
import {Entity, FormStateOptions} from './useFormState';

const log = getLog('useApolloCacheEntity', 'INFO');

export interface UseApolloCacheEntityProps {
  fragment: any;
  fragmentName?: string;
  fragmentType?: string;
  watchQuery?: DocumentNode;
  watchVariables?: OperationVariables;
  entityId: ID;
  newEntity: Entity;
}

export const useApolloCacheEntity: (props: UseApolloCacheEntityProps) => FormStateOptions = (props) => {
  const apolloClient = useApolloClient();
  const {fragment: fragmentGqlString, entityId, newEntity, watchQuery, watchVariables} = props;
  const fragment = gql(fragmentGqlString);
  const [initialEntity, setInitialEntity] = useState<Entity>();
  const fragmentName = props.fragmentName
    ? props.fragmentName
    : ((fragmentDefinitions) => {
        const fragmentName = (fragmentDefinitions[0] as FragmentDefinitionNode).name.value;
        log.debug(`extracted fragmentName '${fragmentName}' from fragment definitions`);
        return fragmentName;
      })(fragment.definitions);
  const fragmentType = props.fragmentType
    ? props.fragmentType
    : ((fragmentDefinitions) => {
        const fragmentType = (fragmentDefinitions[0] as FragmentDefinitionNode).typeCondition.name.value;
        log.debug(`extracted fragmentType '${fragmentType}' from fragment definitions`);
        return fragmentType;
      })(fragment.definitions);

  const isNewEntity = entityId === AppRoutes._CREATE;
  log.debug(isNewEntity ? `Is new ${fragmentType} Entity` : `Entity ID: ${entityId}`);
  if (initialEntity) {
    log.debug('initialEntity already set');
  } else if (isNewEntity) {
    log.debug('Setting initialEntity from newEntity');
    setInitialEntity({...newEntity, id: null});
  } else {
    const cacheId = `${fragmentType}:${entityId}`;
    log.debug(`Try to get entity from apollo client cache. Id: ${cacheId}, fragmentName: ${fragmentName}`);
    try {
      const cacheEntity = apolloClient.cache.readFragment({fragment, fragmentName, id: cacheId}) as Entity;
      if (cacheEntity) {
        log.info(`Found '${cacheId}' fragment in apollo client cache`, cacheEntity);
        setInitialEntity(cacheEntity);
      } else {
        if (watchQuery) {
          log.warn(`Couldn't find '${cacheId}' fragment '${fragmentType}' in apollo client cache`);
          apolloClient
            .watchQuery({fetchPolicy: 'cache-first', query: watchQuery, variables: watchVariables})
            .subscribe(() => {
              try {
                const cacheEntity = apolloClient.cache.readFragment({fragment, fragmentName, id: cacheId}) as Entity;
                if (cacheEntity) {
                  log.info(`Found '${cacheId}' fragment in apollo client cache after a query watch`, cacheEntity);
                  setInitialEntity(cacheEntity);
                } else {
                  log.warn(
                    `Couldn't find '${cacheId}' fragment '${fragmentType}' in apollo client cache after a query watch`
                  );
                }
              } catch (e) {
                log.error(
                  `Error getting fragment from apollo client cache after a query watch. Might be still fetching from server.`,
                  e.message
                );
              }
            });
        } else {
          log.error(`Missing watchQuery for entity of ID '${cacheId}' fragment '${fragmentType}'`);
        }
      }
    } catch (e) {
      log.error(`Error getting fragment from apollo client cache. Might be still fetching from server.`, e.message);
    }
  }

  const formStateOptions = {isEditing: isNewEntity, initialEntity};
  log.debug({props, formStateOptions});
  return formStateOptions;
};
