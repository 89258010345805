fragment productFragment on Product {
  id
  countryId
  brandId
  customerId
  contractId
  productType
  accessNumber
  accessNumberRange {
    from
    to
    count
  }
  rbmAgentOwner
  startDate
  endDate
  inheritsEndDate
  establishFee
  monthlyFee
  addressingPolicy
  name
  comment
  qosProfile {
    enabled
    maxSmsPerSecond
    messagePriority
  }
}
