import {gql, useQuery} from '@apollo/client';
import React, {FC, useEffect} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {Link} from 'react-router-dom';

import productQuery from '../../graphql/query/product.graphql';

import * as AppRoutes from '../../appRoutes';
import {useUser} from '../../hooks/useUser';
import {getLog} from '../../log';
import {ID, Product} from '../../model';
import {ALL_CUSTOMERS_READ} from '../../permissions';
import Loading from '../Loading';
import BreadCrumb from '../common/BreadCrumb';
import BreadCrumbs from '../common/BreadCrumbs';
import Error from '../common/Error';
import Page from '../common/Page';
import PageTitle from '../common/PageTitle';
import {PageViewCounter} from '../metrics/PageViewCounter';

const log = getLog('ProductRedirectFc', 'INFO');

type RouteParams = {
  productId: ID;
};

export const ServiceRedirect: FC = (props) => {
  const {productId} = useParams<RouteParams>() as RouteParams;
  const navigate = useNavigate();
  const {pathname} = useLocation();
  const {formatWithBrand} = AppRoutes.useBrandFormat();
  const {hasPermission} = useUser();
  const {loading, data, error} = useQuery<{product: Product}, {productId: ID}>(gql(productQuery), {
    variables: {productId},
  });

  const product = data?.product;
  useEffect(() => {
    if (!loading && product) {
      navigate(
        formatWithBrand(
          AppRoutes.PROVISIONING_CUSTOMER_SERVICE__customerId_contractId_productId,
          product.customerId,
          product.contractId,
          product.id
        ),
        {replace: true}
      );
    }
  }, [loading, product]);

  log.debug('render', {productId, product, error});

  return (
    <Page>
      <BreadCrumbs>
        {hasPermission(ALL_CUSTOMERS_READ) && (
          <BreadCrumb text="Provisioning" link={formatWithBrand(AppRoutes.PROVISIONING)} />
        )}
      </BreadCrumbs>

      <PageTitle title={'Service redirect'} />
      <PageViewCounter page="service-redirect" />

      {loading ? (
        <>
          <div>
            <span>Finding service's customer and contract information...</span>
          </div>
          <Loading />
        </>
      ) : error ? (
        <Error
          error={error}
          text={
            error.message.includes('Forbidden')
              ? `Authorization error trying to access a service of another customer!`
              : undefined
          }
        />
      ) : (
        <div>
          <span>Service found redirecting to services page under customer's contract section. </span>
          {product && (
            <span>
              Click{' '}
              <Link
                to={formatWithBrand(
                  AppRoutes.PROVISIONING_CUSTOMER_SERVICE__customerId_contractId_productId,
                  product.customerId,
                  product.contractId,
                  product.id
                )}
                className={'underline'}
              >
                here
              </Link>{' '}
              if the page doesn't redirect
            </span>
          )}
        </div>
      )}
    </Page>
  );
};
