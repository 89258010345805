import {getLog} from '../log';
import {ID} from '../model';

const log = getLog('useMessageTypes', 'INFO');

export interface UseMessageTypes {
  messageTypeDictionary: {id: ID; name: string}[];
  getMessageTypeCompactName: (id: ID) => string;
  getMessageTypeLongName: (id: ID) => string;
}

const messageTypeIds = ['SMS_MT', 'SMS_MO'];

export const useMessageTypes = () => {
  const getMessageTypeCompactName: (id: ID) => string = (id) => {
    return !id ? '?' : id === 'SMS_MT' ? 'MT' : id === 'SMS_MO' ? 'MO' : id;
  };

  const getMessageTypeLongName: (id: ID) => string = (id) => {
    return !id ? 'unknown' : id === 'SMS_MT' ? 'Mobile Terminated' : id === 'SMS_MO' ? 'Mobile Originated' : id;
  };

  const messageTypeDictionary = messageTypeIds.map((id) => ({id, name: getMessageTypeCompactName(id)}));

  const useMessageTypes: UseMessageTypes = {
    messageTypeDictionary,
    getMessageTypeCompactName,
    getMessageTypeLongName,
  };
  log.debug('render', useMessageTypes);
  return useMessageTypes;
};
