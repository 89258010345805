import {FetchResult, MutationFunctionOptions, MutationHookOptions, useMutation} from '@apollo/client';
import {DocumentNode} from 'graphql';
import React from 'react';
import {toast} from 'react-toastify';

import Loading from '../components/Loading';
import {getLog} from '../log';
import {mutationInputClean} from '../mutationClean';
import {useErrors} from './useErrors';
import {useModal} from './useModal';

const log = getLog('useMutationWrap', 'INFO');

interface MutationOptions<TData = any, TVariables = any> extends MutationFunctionOptions<TData, TVariables> {
  loadingText?: string;
  successText?: string;
}

type Mutation<TData = any, TVariables = any> = (
  options?: MutationOptions<TData, TVariables>
) => Promise<FetchResult<TData>>;
export type MutationWrap<TData = any, TVariables = any> = (
  // mutation: Mutation<TData, TVariables>,
  mutationDocumentNode: DocumentNode
) => Mutation<TData, TVariables>;

export function useMutationWrap<TData, TVariables>(
  mutationDocumentNode: DocumentNode,
  options?: MutationHookOptions
): Mutation<TData, TVariables> {
  const {showModal, hideModal} = useModal();
  const {clearErrors, onMutationError} = useErrors();
  const [graphQlMutation, _] = useMutation(mutationDocumentNode, options);

  const useMutationWrapHook: Mutation = async (options) => {
    log.debug('useMutationWrapHook called with', options);
    const {loadingText, successText, ...otherOptions} = options || {};

    log.debug('useMutationWrapHook clearError');
    clearErrors();

    log.debug(!!loadingText ? 'showing loading modal' : 'skip showing loading modal', {loadingText});
    const modalId = showModal({
      title: loadingText || '',
      content: <Loading />,
      onCancel: undefined,
      show: !!loadingText,
    });

    const cleanedOptions = mutationInputClean({...otherOptions});
    log.debug('Mutation options without __typeName', cleanedOptions);

    try {
      const graphQLResult = await graphQlMutation(cleanedOptions);
      log.debug('graphQLResult', graphQLResult);
      hideModal(modalId);
      log.debug(!!successText ? 'showing success toast' : 'skip showing success toast', {successText});
      !!successText && toast.success(successText, {autoClose: 2500}); //  TODO: useToast hook
      return graphQLResult; //  TODO: formState.onSave() ?
    } catch (error) {
      log.info('mutation error', error);
      error.graphQLErrors && onMutationError(error.graphQLErrors);
      hideModal(modalId);
      return {}; //  TODO: throw?
    }
  };

  return useMutationWrapHook;
}
