import {gql} from '@apollo/client';
import {urlPaths} from '@telia/cpa-web-common';
import {UserAccessStatusType} from '@telia/cpa-web-common/dist/model';
import {Button} from '@telia/styleguide';
import {Alert} from '@telia/styleguide';
import React, {FC} from 'react';

import saveUserMutation from '../../graphql/mutation/saveUser.graphql';

import '../report/ReportPendingInfo.scss';

import {Entity, asEntity, useFormState} from '../../hooks/useFormState';
import {useModal} from '../../hooks/useModal';
import {useMutationWrap} from '../../hooks/useMutationWrap';
import {useUser} from '../../hooks/useUser';
import {getLog} from '../../log';
import {User} from '../../model';
import {removeLocalFieldsFromUser} from '../../mutationClean';
import Loading from '../Loading';
import Form from '../common/Form';
import FormColumn from '../common/FormColumn';
import FormRow from '../common/FormRow';
import {MobileField} from '../common/MobileField';
import {Field, FieldTypes, FieldWithFormState} from '../common/field';

const log = getLog('LoginInfo', 'INFO');

export const LoginInfoFc: FC = (props) => {
  const {user, realUser, logout, currentBrandRealUserRoles, refetch} = useUser();
  const formState = useFormState({initialEntity: realUser as unknown as Entity, isEditing: false});
  const {entityAs, isEditing, entity: entityFormState, onEdit, onCancel, onChange} = formState;
  const entity = isEditing ? entityFormState : realUser;

  const saveUser = useMutationWrap<{saveUser: User}, {user: User}>(gql(saveUserMutation));
  const onSave = () => {
    const user = removeLocalFieldsFromUser(entityAs<User>());

    saveUser({variables: {user}, loadingText: 'Updating info', successText: 'Info updated'}).then(({data}) => {
      const {saveUser} = data || {saveUser: undefined};
      log.debug('saveUser resolved', {saveUser});
      saveUser && formState.onSaved(asEntity(saveUser));
      refetch();
    });
  };

  const {showModal} = useModal();

  const onChangePassword = () => {
    showModal({
      title: 'Change Password',
      content: <span>To change your password use the 'forgot your password?' feature of the login form.</span>,
      confirmText: "Go to 'Forgot your password?'",
      onConfirm: () => (window.location.href = urlPaths.CHANGE_PASSWORD),
    });
  };

  return !entity ? (
    <Loading />
  ) : (
    <React.Fragment>
      <Form>
        <FormRow>
          <FormColumn>
            <FieldWithFormState formState={formState} entityFieldId={'firstName'} label="Firstname" />
            <FieldWithFormState formState={formState} entityFieldId={'lastName'} label="Lastname" />
          </FormColumn>
        </FormRow>
        <FormRow>
          <FormColumn>
            <FieldWithFormState formState={formState} entityFieldId={'email'} label="Email" isEditing={false} />
          </FormColumn>
        </FormRow>
        <FormRow>
          <FormColumn>
            <MobileField formState={formState} />
          </FormColumn>
        </FormRow>
        {realUser?.userAccessStatusType === UserAccessStatusType.REJECTED ||
        realUser?.userAccessStatusType === UserAccessStatusType.PENDING ? (
          <div className="pending__info--container">
            <Alert kind="warning">
              {realUser.userAccessStatusType === UserAccessStatusType.PENDING ? (
                <p>
                  Your account is <b>pending</b> and waiting approval from a Telia admin. Once your account is approved
                  you will be able to access Telia Bulk Messaging Web.
                </p>
              ) : (
                realUser?.userAccessStatusType === UserAccessStatusType.REJECTED && (
                  <p>
                    Your account has been <b>rejected</b> by a Telia admin. Please contact
                    <a className="link text_inline" href="mailto:cpa-support@telia.no">
                      cpa-support@telia.no
                    </a>
                    if you think this was a mistake.
                  </p>
                )
              )}
            </Alert>
          </div>
        ) : (
          <FormRow>
            <FormColumn>
              {!currentBrandRealUserRoles.isEmpty() ? (
                <Field
                  value={currentBrandRealUserRoles}
                  label="Role"
                  isEditing={false}
                  type={FieldTypes.multi}
                  options={currentBrandRealUserRoles}
                />
              ) : (
                <Field defaultValue="No roles" label="Role" isEditing={false} />
              )}
            </FormColumn>
          </FormRow>
        )}

        <FormRow>
          <FormColumn>
            {!isEditing ? (
              <div>
                <Button text={'Logout'} onClick={logout} />
                {realUser?.isSSOUser === false && <Button text={'Change Password'} onClick={onChangePassword} />}
                <Button text={'Edit profile'} onClick={onEdit} kind={Button.kinds.cancel} />
              </div>
            ) : (
              <div>
                <Button text={'Save'} onClick={onSave} kind={Button.kinds.primary} />
                <Button text={'Cancel'} onClick={onCancel} kind={Button.kinds.cancel} />
              </div>
            )}
          </FormColumn>
        </FormRow>
      </Form>
    </React.Fragment>
  );
};
