import {format} from '@telia/cpa-web-common';
import {Button, FullWidthTable} from '@telia/styleguide';
import React, {FunctionComponent, useMemo} from 'react';
import {InformationLineFc} from '../common/InformationLine';
import Loading from '../Loading';
import {getLog} from '../../log';
import {specialFieldComparators} from '../../comparators';
import {ID} from '../../model';
import {useServiceCategories} from '../../hooks/useServiceCategories';
import './ReportDcbTable.scss';

const log = getLog('ReportDcbTable', 'INFO');
const {THead, Tr, ThSortable, TBody, Td} = FullWidthTable;

type _ReportDcbTableProps = ReportDcbTableProps;

export interface ReportDcbTableProps {
  loading: boolean;
  groupBy: AggregatedTransactionGrouping[];
  aggregatedTransactions: AggregatedTransaction[];
}

export interface AggregatedTransactionIds {
  serviceCategory: ID;
  categoryType: ID;
  accountNumber: ID;
  chargeType: 'PREPAID' | 'POSTPAID';
  balanceType: 'TOTAL' | 'DEBIT' | 'CREDIT';
}

export interface AggregatedTransactionTotals {
  count: number;
  amount: number;
}

export type AggregatedTransaction = AggregatedTransactionIds & AggregatedTransactionTotals;
export type AggregatedTransactionWithGroupId = AggregatedTransaction & {groupId: ID};
export type GroupedAggregatedTransaction = Partial<AggregatedTransactionIds> & AggregatedTransactionTotals;

export type AggregatedTransactionFields = keyof AggregatedTransaction;
export type AggregatedTransactionGrouping = keyof AggregatedTransactionIds;

export const sortedFields: AggregatedTransactionGrouping[] = [
  'balanceType',
  'chargeType',
  'accountNumber',
  'serviceCategory',
  'categoryType',
];

export const getFields: (groupBy: AggregatedTransactionGrouping[]) => AggregatedTransactionFields[] = (groupBy) => {
  const groupByFields = sortedFields.filter((field: AggregatedTransactionGrouping) => groupBy.includes(field));
  return [...groupByFields, 'count', 'amount'] as AggregatedTransactionFields[];
};

export const fieldName = (field: AggregatedTransactionFields) => {
  switch (field) {
    case 'serviceCategory':
      return 'Service category';
    case 'categoryType':
      return 'Category type';
    case 'accountNumber':
      return 'Account Num';
    case 'chargeType':
      return 'Charge type';
    case 'balanceType':
      return 'Balance type';
    case 'count':
      return 'Count';
    case 'amount':
      return 'NOK';
    default:
      return '';
  }
};

export const aggregatedTransactionGroupId: (
  aggregatedTransaction: AggregatedTransaction,
  groupBy: AggregatedTransactionGrouping[]
) => ID = (aggregatedTransaction, groupBy) => {
  return groupBy.map((fieldName) => aggregatedTransaction[fieldName]).join('-');
};

export const groupAggregatedTransactionReducer: (
  acc: AggregatedTransactionWithGroupId[],
  row: AggregatedTransactionWithGroupId
) => AggregatedTransactionWithGroupId[] = (acc, row) => {
  const prevRow = acc[acc.length - 1];
  const prevAcc = acc.slice(0, acc.length - 1); // acc array without prevRow
  return !prevRow
    ? [row]
    : prevRow.groupId !== row.groupId
    ? [...prevAcc, prevRow, row]
    : [...prevAcc, {...row, count: row.count + prevRow.count, amount: row.amount + prevRow.amount}];
};

export const groupAggregatedTransactions: (
  aggregatedTransactions: AggregatedTransaction[],
  groupBy: AggregatedTransactionGrouping[]
) => GroupedAggregatedTransaction[] = (aggregatedTransactions, groupBy) =>
  aggregatedTransactions
    .map(
      (aggregatedTransaction) =>
        ({
          ...aggregatedTransaction,
          groupId: aggregatedTransactionGroupId(aggregatedTransaction, groupBy),
        } as AggregatedTransactionWithGroupId)
    )
    .sort((a, b) => a.groupId.localeCompare(b.groupId))
    .reduce(groupAggregatedTransactionReducer, [])
    .map(
      (aggregatedTransaction) =>
        getFields(groupBy).reduce(
          (acc, fieldName) => ({
            ...acc,
            [`${fieldName}`]: aggregatedTransaction[fieldName],
          }),
          {}
        ) as GroupedAggregatedTransaction
    );

const fieldClassName = (field: AggregatedTransactionFields) => (['count', 'amount'].includes(field) ? 'right' : '');

const _ReportDcbTable: FunctionComponent<_ReportDcbTableProps> = (props) => {
  log.debug('render', props);
  const {loading, groupBy, aggregatedTransactions} = props;
  const fields: AggregatedTransactionFields[] = getFields(groupBy);
  const _groupedAggregatedTransactions: GroupedAggregatedTransaction[] = useMemo(
    () => groupAggregatedTransactions(aggregatedTransactions, groupBy),
    [aggregatedTransactions, groupBy]
  );
  const [sortedAggregatedTransactions, getThSortableProps] = FullWidthTable.useThSortable<GroupedAggregatedTransaction>(
    _groupedAggregatedTransactions,
    3,
    [],
    specialFieldComparators
  );

  const {serviceCategoryDisplayName, categoryTypeDisplayName} = useServiceCategories();
  const fieldValue = (field: AggregatedTransactionFields, aggregatedTransaction: GroupedAggregatedTransaction) => {
    const value = aggregatedTransaction[field];
    switch (field) {
      case 'serviceCategory':
        return serviceCategoryDisplayName(value as ID);
      case 'categoryType':
        return categoryTypeDisplayName(aggregatedTransaction.serviceCategory, value as ID);
      case 'count':
      case 'amount':
        return format.integer(value as number);
      default:
        return value;
    }
  };

  return (
    <React.Fragment>
      <div className={'marginTop'}>
        <FullWidthTable id={'reportDcbTable'}>
          <THead>
            <Tr>
              {fields.map((field) => (
                <ThSortable {...getThSortableProps(field)} className={fieldClassName(field)} key={field}>
                  {fieldName(field)}
                </ThSortable>
              ))}
            </Tr>
          </THead>
          <TBody>
            {sortedAggregatedTransactions &&
              sortedAggregatedTransactions.map((aggregatedTransaction, i) => (
                <Tr key={i} className={aggregatedTransaction.amount < 0 ? 'negative' : ''}>
                  {fields.map((field) => (
                    <Td className={fieldClassName(field)} key={field + 'i'}>
                      {fieldValue(field, aggregatedTransaction)}
                    </Td>
                  ))}
                </Tr>
              ))}
          </TBody>
        </FullWidthTable>
        {loading && <Loading />}
        {aggregatedTransactions &&
          (aggregatedTransactions.isEmpty() ? (
            <InformationLineFc>No statistics found</InformationLineFc>
          ) : (
            <Button text={'Download as CSV'} onClick={() => downloadAsCsv(sortedAggregatedTransactions, fields)} />
          ))}
      </div>
    </React.Fragment>
  );
};

const downloadAsCsv = (
  aggregatedTransactions: GroupedAggregatedTransaction[],
  fields: AggregatedTransactionFields[]
) => {
  const header = fields.map((field) => `"${fieldName(field)}"`);
  const rows = aggregatedTransactions.map((aggregatedTransaction, i) =>
    fields.map((field) => aggregatedTransaction[field])
  );
  const content = [header, ...rows];
  const csv = content.map((line) => line.join(';')).join('\r\n');

  const filename = 'report.csv';
  const blob = new Blob([csv], {type: 'text/csv;charset=utf-8;'});
  const link = document.createElement('a');
  if (link.download !== undefined) {
    // support HTML5 download attribute
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', filename);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

export default _ReportDcbTable;
