"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const model_1 = require("../model");
const roles = [
    {
        id: 'CUSTOMER_FINANCIAL',
        userType: model_1.UserType.CUSTOMER,
        name: 'Financial',
        description: 'Role for customers side financial reports',
        permissions: ['CUSTOMER_SETTLEMENTS_READ', 'CUSTOMER_STATISTICS_REPORT'],
    },
    {
        id: 'CUSTOMER_USER',
        userType: model_1.UserType.CUSTOMER,
        name: 'User',
        description: 'Role for reading customer info',
        permissions: ['CUSTOMER_STATISTICS_REPORT'],
    },
    {
        id: 'CUSTOMER_ADMIN',
        userType: model_1.UserType.CUSTOMER,
        name: 'Administrator',
        description: 'Role for managing customer options',
        permissions: ['CUSTOMER_SETTLEMENTS_READ', 'CUSTOMER_USERS_MANAGE', 'CUSTOMER_STATISTICS_REPORT'],
    },
    {
        id: 'CUSTOMER_TECHNICAL',
        userType: model_1.UserType.CUSTOMER,
        name: 'Technical',
        description: 'Role for customers side technical configuration',
        permissions: ['CUSTOMER_TECHNICAL_MANAGE', 'CUSTOMER_STATISTICS_REPORT'],
    },
    {
        id: 'TELIA_ADMIN',
        userType: model_1.UserType.TELIA,
        name: 'Administrator',
        description: 'Role for managing all customers and Telia configurations',
        permissions: [
            'ALL_CUSTOMERS_READ',
            'ALL_CUSTOMERS_MANAGE',
            'ROLES_ASSUME',
            'CUSTOMER_COMPANY_MANAGE',
            'CUSTOMER_CONTRACTS_MANAGE',
            'CUSTOMER_PRODUCTS_MANAGE',
            'CUSTOMER_SETTLEMENTS_READ',
            'CUSTOMER_TECHNICAL_MANAGE',
            'CUSTOMER_USERS_MANAGE',
            'CUSTOMER_STATISTICS_REPORT',
            'TELIA_USERS_MANAGE',
            'ACCESS_NUMBERS_MANAGE',
            'MESSAGE_CHANNELS_MANAGE',
            'RESERVED_OA_MANAGE',
        ],
    },
    {
        id: 'TELIA_USER',
        userType: model_1.UserType.TELIA,
        name: 'User',
        description: 'Role for managing all customers',
        permissions: [
            'ALL_CUSTOMERS_READ',
            'CUSTOMER_COMPANY_MANAGE',
            'CUSTOMER_PRODUCTS_MANAGE',
            'CUSTOMER_SETTLEMENTS_READ',
            'CUSTOMER_USERS_MANAGE',
            'CUSTOMER_STATISTICS_REPORT',
            'ROLES_ASSUME',
            'CUSTOMER_CONTRACTS_MANAGE',
            'ACCESS_NUMBERS_MANAGE',
            'ALL_CUSTOMERS_MANAGE',
        ],
    },
    {
        id: 'TELIA_APPROVER',
        userType: model_1.UserType.TELIA,
        name: 'Settlement approver',
        description: 'Role for approving or rejecting settlements',
        permissions: ['SETTLEMENTS_APPROVE'],
    },
    {
        id: 'TELIA_ADJUSTER',
        userType: model_1.UserType.TELIA,
        name: 'Settlement adjuster',
        description: 'Role for adjusting and pre-approving settlements',
        permissions: ['SETTLEMENTS_ADJUST'],
    },
];
exports.default = roles;
