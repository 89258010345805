import {getLog} from '../log';
import {Dict, ID, ProductOverview, AccessNumberOverview, ReservedOaOverview} from '../model';
import {useCallback, useMemo} from 'react';
import {useCustomerOverviews} from './useCustomerOverviews';
import {useProductOverviewTypes} from './useProductOverviewTypes';

const log = getLog('useProductOverviews', 'INFO');

export interface ProductOverviewsHook {
  loading: boolean;
  getProductOverview: (productId: ID) => ProductOverview | undefined;
  getReservedOaProductsOverview: () => ProductOverview[];
  getCustomerIdByProductId: (productId: ID) => ID | undefined;
  getCompanyNameByProductId: (productId: ID) => string | undefined;
  getAccessNumberByProductId: (productId: ID) => ID | undefined;
  getReservedOasByProductId: (productId: ID) => ID | undefined;
}

export const useProductOverviews = () => {
  const {loading, customerOverviews} = useCustomerOverviews();

  const {isReservedOaOverview} = useProductOverviewTypes();

  const productOverviewMap: Dict<ProductOverview> = useMemo(
    () =>
      (customerOverviews &&
        customerOverviews
          .flatMap<ProductOverview[] | undefined>(({productOverviews, name}) => [
            productOverviews?.map((overview) => ({...overview, companyName: name})),
          ])
          .flatMap<ProductOverview | undefined>((overviews) => overviews)
          .filter((overview) => overview)
          .reduce<Dict<ProductOverview>>(
            (map, overview: ProductOverview) => ({...map, [`${overview.productId}`]: overview}),
            {}
          )) ||
      {},
    [customerOverviews]
  );
  const getProductOverview: (productId: ID) => ProductOverview | undefined = (productId: ID) =>
    productOverviewMap[productId];
  const getCustomerIdByProductId = useCallback(
    (productId: ID) => (loading ? 'loading...' : getProductOverview(productId)?.customerId),
    [loading]
  );
  const getCompanyNameByProductId = useCallback(
    (productId: ID) => (loading ? 'loading...' : getProductOverview(productId)?.companyName),
    [loading]
  );
  const getAccessNumberByProductId = useCallback(
    (productId: ID) =>
      loading ? 'loading...' : (getProductOverview(productId) as AccessNumberOverview | undefined)?.accessNumber,
    [loading]
  );
  const getReservedOasByProductId = useCallback(
    (productId: ID) =>
      loading
        ? 'loading...'
        : (getProductOverview(productId) as ReservedOaOverview | undefined)?.reservedOas?.join(', '),
    [loading]
  );

  const getReservedOaProductsOverview: () => ProductOverview[] = () =>
    Object.values(productOverviewMap).filter(
      (productOverview) =>
        isReservedOaOverview(productOverview) && productOverview.reservedOas && productOverview.reservedOas.length > 0
    );

  const useProductOverviewsHook: ProductOverviewsHook = {
    loading,
    getProductOverview,
    getReservedOaProductsOverview,
    getCustomerIdByProductId,
    getCompanyNameByProductId,
    getAccessNumberByProductId,
    getReservedOasByProductId,
  };
  log.debug(productOverviewMap);
  return useProductOverviewsHook;
};
