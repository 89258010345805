import {UserType} from '@telia/cpa-web-common/src/model';
import {Button} from '@telia/styleguide';
import React, {FC, useMemo} from 'react';
import {Link, matchPath} from 'react-router-dom';

import * as AppRoutes from '../../appRoutes';
import {domId} from '../../dom';
import {useFormState} from '../../hooks/useFormState';
import {useUser} from '../../hooks/useUser';
import {getLog} from '../../log';
import {User} from '../../model';
import {CUSTOMER_USERS_MANAGE, TELIA_USERS_MANAGE} from '../../permissions';
import FormColumn from '../common/FormColumn';
import FormRow from '../common/FormRow';
import PageSubtitle from '../common/PageSubtitle';
import {FieldTypes, FieldWithFormState} from '../common/field';
import {PageViewCounter} from '../metrics/PageViewCounter';
import {UserListFc} from './UserList';

const log = getLog('Users', 'INFO');

interface UsersFcProps {
  users?: User[];
  usersBaseUrl: string;
}

export const UsersFc: FC<UsersFcProps> = (props) => {
  const {users = [], usersBaseUrl} = props;
  const {hasPermission} = useUser();

  const formState = useFormState({isEditing: true, preferenceFieldNames: ['showWithoutRoles']});

  const filteredUsers = useMemo(
    () =>
      users.filter(
        (user) =>
          formState.entity.showWithoutRoles ||
          (user.type === UserType.TELIA
            ? user.rolesMap && !user.rolesMap?.isEmpty()
            : user.roles && !user.roles?.isEmpty())
      ),
    [formState.entity]
  );

  log.debug('Users', props);
  return (
    <React.Fragment>
      <PageSubtitle subtitle="Users" />
      <PageViewCounter page="users" />

      <p>
        List of people who may access this Telia Bulk Messaging Web application. Users may have different roles which
        grants them different permissions.
      </p>

      <FormRow>
        <FormColumn>
          <FieldWithFormState
            formState={formState}
            entityFieldId="showWithoutRoles"
            type={FieldTypes.checkbox}
            label="Include users without roles"
          />
        </FormColumn>
      </FormRow>

      <UserListFc users={filteredUsers} usersBaseUrl={usersBaseUrl} />

      {(!!matchPath(AppRoutes.MANAGEMENT_USERS, usersBaseUrl)
        ? hasPermission(TELIA_USERS_MANAGE)
        : hasPermission(CUSTOMER_USERS_MANAGE)) && (
        <FormRow>
          <FormColumn>
            <div id={domId('createUser')}>
              <Link to={AppRoutes.join(usersBaseUrl, AppRoutes._CREATE)}>
                <Button text="Create user" />
              </Link>
            </div>
          </FormColumn>
        </FormRow>
      )}
    </React.Fragment>
  );
};
