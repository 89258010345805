import {gql} from '@apollo/client';
import {Button} from '@telia/styleguide';
import React, {FC, useMemo} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {Link} from 'react-router-dom';

import userFragment from '../../graphql/fragment/user.graphql';
import deleteUserMutation from '../../graphql/mutation/deleteUser.graphql';
import saveUserMutation from '../../graphql/mutation/saveUser.graphql';
import customerQuery from '../../graphql/query/customer.graphql';
import teliaUsersQuery from '../../graphql/query/teliaUsers.graphql';

import '../management/Permissions.scss';
import './User.scss';

import * as AppRoutes from '../../appRoutes';
import {UseApolloCacheEntityProps, useApolloCacheEntity} from '../../hooks/useApolloCacheEntity';
import {useBrands} from '../../hooks/useBrands';
import {EntityFieldValue, asEntity, useFormState} from '../../hooks/useFormState';
import {useModal} from '../../hooks/useModal';
import {useMutationWrap} from '../../hooks/useMutationWrap';
import {useResourceRoles} from '../../hooks/useResourceRoles';
import {useUser} from '../../hooks/useUser';
import {getLog} from '../../log';
import {Customer, ID, ResourceRoles, Role, User, UserType} from '../../model';
import {removeLocalFieldsFromUser} from '../../mutationClean';
import {CUSTOMER_USERS_MANAGE, TELIA_USERS_MANAGE} from '../../permissions';
import Loading from '../Loading';
import Form from '../common/Form';
import FormColumn from '../common/FormColumn';
import FormRow from '../common/FormRow';
import {MobileField} from '../common/MobileField';
import PageSubtitle from '../common/PageSubtitle';
import {Field, FieldTypes, FieldWithFormState} from '../common/field';
import {PageViewCounter} from '../metrics/PageViewCounter';
import {RolesTable} from './RolesTable';

const log = getLog('User', 'INFO');
const customerQueryGql = gql(customerQuery);

interface UserFcProps {
  type: UserType;
  customerId?: ID;
  roles: Role[];
  goBackUrl: string;
}

const teliaUsersQueryGql = gql(teliaUsersQuery);
export const UserFc: FC<UserFcProps> = (props) => {
  const {type, goBackUrl} = props;
  const {customerId, userId} = useParams<{customerId: ID; userId: ID}>() as {customerId: ID; userId: ID};
  const navigate = useNavigate();
  const {formatWithBrand} = AppRoutes.useBrandFormat();
  const {hasPermission, user} = useUser();
  const {showModal} = useModal();
  const {brands} = useBrands();

  const brandOptions = useMemo(() => [{id: '*', name: 'All Brands'}, ...(brands || [])], [brands]);

  const {getBrandRoles} = useResourceRoles();

  const useApolloCacheEntityProps: UseApolloCacheEntityProps = {
    fragment: userFragment,
    entityId: userId,
    newEntity: {
      customerId,
      firstName: null,
      lastName: null,
      email: null,
      phone: null,
      roles: customerId ? [] : null,
      rolesMap: customerId ? null : [],
      comment: null,
    },
  };
  const formStateOptions = useApolloCacheEntity(useApolloCacheEntityProps);
  const formState = useFormState({
    ...formStateOptions,
    validators: {
      phone: {
        regex: /^\+\d{5,20}$/,
        error: 'Phone number must start with (+) international code and followed by 5 to 20 digits (without spaces)',
      },
    },
  });

  const saveUser = useMutationWrap<{saveUser: User}, {user: User; customerId: ID | undefined; pageType: UserType}>(
    gql(saveUserMutation),
    {refetchQueries: user?.id === userId ? ['user'] : []} // If logged in user is modifying their own user, force update of user query to get updated data
  );

  const onSave = () => {
    const saveUser = formState.entityAs<User>();

    const currAdminRoles =
      user?.rolesMap &&
      brandOptions?.reduce(
        (prev, {id}) => (getBrandRoles(id, user.rolesMap, true).includes('TELIA_ADMIN') ? [...prev, id] : prev),
        []
      );
    const newAdminRoles = brandOptions?.reduce(
      (prev, {id}) => (getBrandRoles(id, saveUser.rolesMap, true).includes('TELIA_ADMIN') ? [...prev, id] : prev),
      []
    );

    const deletingAdminRole = currAdminRoles && newAdminRoles && currAdminRoles.some((b) => !newAdminRoles.includes(b));
    const hasAllBrandAdminRole = newAdminRoles?.includes('*');

    if (saveUser.id === user?.id && deletingAdminRole && !hasAllBrandAdminRole) {
      showModal({
        title: 'Delete your own administrator role?',
        content: (
          <div>
            You are about to delete your own administrator role, are you sure you want to continue? You will not be able
            to edit your roles for this brand after this.
          </div>
        ),
        confirmType: Button.kinds.primary,
        confirmText: 'Confirm',
        cancelText: 'Cancel',
        onConfirm: onConfirmSave,
      });
    } else {
      onConfirmSave();
    }
  };

  const onConfirmSave = () => {
    const user = removeLocalFieldsFromUser(formState.entityAs<User>());

    if (!formState.validate()) {
      return;
    }
    // delete user.lastLogin;
    log.debug('onSave', {user, customerId, type});

    saveUser({
      loadingText: 'Saving user...',
      successText: 'User saved',
      variables: {
        user: {
          ...user,
          type: (user.type ? user.type : props.type) as UserType,
          customerId: (user.customerId ? user.customerId : props.customerId) as ID,
        },
        customerId: customerId,
        pageType: type,
      },
      update: (proxy, {data}) => {
        const {saveUser} = data || {};
        log.debug('saveUserMutation update', saveUser);
        if (saveUser && !formState.entity.id) {
          //  create new user
          if (type === 'CUSTOMER') {
            //  TODO: move the update list of users to parent component?
            const data = proxy.readQuery<{customer: Customer}>({
              query: customerQueryGql,
              variables: {customerId},
            });
            const updatedUsers = [...(data?.customer?.users || []), saveUser];
            log.debug('data', updatedUsers);
            proxy.writeQuery({query: customerQueryGql, data: {customer: {users: updatedUsers}}});
            navigate(
              formatWithBrand(AppRoutes.PROVISIONING_CUSTOMER_USER__customerId_userId, customerId, saveUser.id),
              {replace: true}
            );
          } else if (type === 'TELIA') {
            const data = proxy.readQuery<{teliaUsers: User[]}>({query: teliaUsersQueryGql, variables: {}});
            // data?.teliaUsers.push(saveUser);
            const updatedUsers = [...(data?.teliaUsers || []), saveUser];
            proxy.writeQuery({query: teliaUsersQueryGql, data: {teliaUsers: updatedUsers}});
            navigate(formatWithBrand(AppRoutes.MANAGEMENT_USER__userId, saveUser.id), {replace: true});
          } else {
            log.error('unrecognized user type', type);
          }
        }
      },
    }).then(({data}) => {
      const {saveUser} = data || {saveUser: undefined};
      log.debug('saveUserMutate resolved', saveUser);
      saveUser && formState.onSaved(asEntity(saveUser));
      return saveUser;
    });
    // .catch(formState.onError),
  };

  const onDelete = () => {
    log.debug('onDelete');
    const user = formState.entity;
    showModal({
      title: 'Delete user',
      content: <div>{`Are you sure you want to delete user '${user.firstName} ${user.lastName}' ?`}</div>,
      confirmType: Button.kinds.negative,
      confirmText: 'Delete',
      onConfirm: onConfirmDelete,
    });
  };

  const deleteUser = useMutationWrap<{deleteUser: ID}, {userId: ID; customerId: ID; pageType: UserType}>(
    gql(deleteUserMutation)
  );
  const onConfirmDelete = () => {
    log.debug('onConfirmDelete', {userId});
    deleteUser({
      loadingText: 'Deleting user...',
      successText: 'User deleted',
      variables: {
        userId,
        customerId,
        pageType: type,
      },
      update: (proxy, {data}) => {
        const {deleteUser: userId} = data || {};
        log.debug('deleteUserMutation update', userId);
        if (type === 'CUSTOMER') {
          //  TODO: move the update list of users to parent component?
          const data = proxy.readQuery<{customer: Customer}>({
            query: customerQueryGql,
            variables: {customerId},
          });
          if (data) {
            const users = data.customer?.users?.filter((user: User) => user.id !== userId);
            proxy.writeQuery({query: customerQueryGql, data: {customer: {users}}, overwrite: true});
          }
        } else if (type === 'TELIA') {
          const data = proxy.readQuery<{teliaUsers: User[]}>({query: teliaUsersQueryGql, variables: {}});
          log.debug('data', data);
          if (data) {
            const teliaUsers = data.teliaUsers.filter((user: User) => user.id !== userId);
            proxy.writeQuery({query: teliaUsersQueryGql, variables: {}, data: {teliaUsers}, overwrite: true});
          }
        } else {
          log.error('unrecognized user type', type);
        }
      },
    }).then(({data}) => {
      const {deleteUser} = data || {deleteUser: undefined};
      log.debug('deleteUser resolved', deleteUser);
      navigate(goBackUrl);
    });
    // .catch(formState.onError),
  };

  log.debug('props', props);
  const {isEditing, entity, onEdit, onCancel, onChange} = formState;
  const isCreating = entity && !entity.id;
  const roleOptions = props.roles || [];
  return (
    <React.Fragment>
      <PageSubtitle subtitle="User Info" />
      <PageViewCounter page="user" />

      {!entity ? (
        <Loading />
      ) : (
        <Form>
          <FormRow>
            <FormColumn>
              <FieldWithFormState
                formState={formState}
                entityFieldId={'firstName'}
                label="Firstname"
                className="form__field"
              />
              <FieldWithFormState
                formState={formState}
                entityFieldId={'lastName'}
                label="Lastname"
                className="form__field"
              />
            </FormColumn>
          </FormRow>
          <FormRow>
            <FormColumn>
              <FieldWithFormState
                formState={formState}
                entityFieldId={'email'}
                label="Email"
                isEditing={isEditing && isCreating} //  email is un-mutable after creation
                className="form__field"
              />
            </FormColumn>
          </FormRow>
          <FormRow>
            <FormColumn>
              <MobileField formState={formState} className="form__field" />
              <div /> {/* To force field to be half as wide */}
            </FormColumn>
          </FormRow>
          <FormRow>
            <FormColumn>
              {customerId !== undefined ? (
                <FieldWithFormState
                  formState={formState}
                  entityFieldId={'roles'}
                  label="Role"
                  options={roleOptions}
                  type={FieldTypes.multi}
                />
              ) : (
                <RolesTable formState={formState} roles={roleOptions} />
              )}
            </FormColumn>
          </FormRow>

          {/*//  TODO: last login (hs been removed from user entity table)*/}
          {/*<FormRow>*/}
          {/*  <FormColumn>*/}
          {/*    <Field*/}
          {/*      label="Last login"*/}
          {/*      value={entity.lastLogin ? format.dateTime((entity as Entity).lastLogin as string) : 'Never'}*/}
          {/*      isEditing={false}*/}
          {/*      onChange={onChange('lastLogin')}*/}
          {/*    />*/}
          {/*  </FormColumn>*/}
          {/*</FormRow>*/}

          {!isEditing ? (
            <div>
              {(formState.entityAs<User>().type === 'TELIA'
                ? hasPermission(TELIA_USERS_MANAGE)
                : hasPermission(CUSTOMER_USERS_MANAGE)) && (
                <React.Fragment>
                  <Button text={'Edit'} onClick={onEdit} />
                  <Button text={'Delete'} onClick={onDelete} kind={Button.kinds.negative} />
                </React.Fragment>
              )}
              <Link to={goBackUrl}>
                <Button text={'Back to Users'} kind={Button.kinds.cancel} />
              </Link>
            </div>
          ) : (
            <div>
              <Button text={'Save'} onClick={onSave} kind={Button.kinds.primary} />
              <Button text={'Cancel'} onClick={onCancel} kind={Button.kinds.cancel} />
            </div>
          )}
        </Form>
      )}
    </React.Fragment>
  );
};
