import classnames from 'classnames';
import React, {FC, PropsWithChildren, ReactElement} from 'react';
import {getLog} from '../../log';
import {ID} from '../../model';
import {useSelectedIds} from '../../hooks/useSelectedIds';
import {AccordionItemFcProps} from './AccordionItem';
import {isArray} from 'util';
import './Accordion.scss';

const log = getLog('AccordtionList', 'INFO');

interface AccordionListFcProps extends PropsWithChildren {
  id?: ID;
  label?: string;
  className?: string;
  multipleOpen?: boolean;
  initialAllOpen?: boolean;
  initialOpenItemId?: ID;
  rightElement?: ReactElement;
}

export const AccordionListFc: FC<AccordionListFcProps> = (props) => {
  const {id, label, className, multipleOpen, initialAllOpen, initialOpenItemId, children, rightElement} = props;
  const {isSelected, selectedIds, onToggleSelected} = useSelectedIds(
    initialOpenItemId
      ? [initialOpenItemId]
      : initialAllOpen && children
      ? (children as AccordionItemFcProps[]).map(({id}) => id)
      : []
  );

  const childrenArray = !children ? [] : isArray(children) ? children : [children];
  log.debug('render', {...props, selectedIds, childrenArray});
  return (
    <div className={classnames('accordion-list', className)} id={id || 'accordion-list'}>
      {label && <label className={`label accordion-list__label`}>{label}</label>}
      {rightElement && <div className={`accordion-list__right-element`}>{rightElement}</div>}
      {childrenArray.map((child: ReactElement) => ({
        ...child,
        props: {
          ...child.props,
          open: !child.props.isDisabled && isSelected(child.props),
          onToggleOpen: () => !child.props.isDisabled && onToggleSelected(child.props),
        },
      }))}
    </div>
  );
};
