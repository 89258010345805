import {format} from '@telia/cpa-web-common';
import classNames from 'classnames';
import React, {FC} from 'react';

import '../Settlement.scss';

import {getLog} from '../../../../log';
import {ProductFee} from '../../../../model';
import {feeName} from '../settlementUtils';

const log = getLog('SettlementFeesTableGroup', 'INFO');

interface CountAvgTotalGroupFcProps {
  quantity: number;
  fee: number;
  total: number;
}

const CountAvgTotalGroupFc: FC<CountAvgTotalGroupFcProps> = ({quantity, fee, total}) => {
  return (
    <>
      <td className="rightText width0-on-small-only">
        <span>{format.integer(quantity)} &times;</span>
      </td>
      <td className="rightText width0-on-small-only">
        <span>{format.decimal(fee)} =</span>
      </td>
      <td className="rightText noWrap">{format.decimal(total)}</td>
    </>
  );
};

interface FeesTableGroupFcProps {
  name: string;
  total?: number;
  fees: ProductFee[];
  otherYearsData?: {
    year: number;
    data: {name: string; total: number; fees: ProductFee[]};
  }[];
  highlight?: boolean;
}

export const SettlementFeesTableGroupFc: FC<FeesTableGroupFcProps> = ({
  name,
  total,
  fees,
  otherYearsData = [],
  highlight = false,
}) => {
  otherYearsData = otherYearsData.slice().sort((a, b) => (a.year < b.year ? 1 : -1));

  const allFeeIds = new Set(fees?.filter(({total: feeTotal}) => !!feeTotal).map((fee) => fee.feeId));
  otherYearsData.forEach(({data: {fees}}) => fees.forEach(({feeId}) => allFeeIds.add(feeId)));

  return (
    <React.Fragment key={name}>
      <tr
        className={classNames(
          'settlement__list__item settlement__list__item--total',
          highlight && 'settlement__list__item--selected'
        )}
      >
        <td colSpan={3}>{name}</td>
        <td className="rightText noWrap">{format.decimal(total)}</td>
        {otherYearsData.map(({data}, i) => {
          return data.name === name ? (
            <React.Fragment key={'other_year_' + i}>
              <td colSpan={2} />
              <td className="rightText noWrap">{format.decimal(data.total)}</td>
            </React.Fragment>
          ) : (
            <td key={'other_year_' + i} colSpan={3} />
          );
        })}
      </tr>

      {Array.from(allFeeIds).map((feeId, i) => {
        const foundMainFee = fees.find((fee) => fee.feeId === feeId);
        return (
          <tr
            className={classNames(
              'settlement__list__item settlement__list__item--details',
              highlight && 'settlement__list__item--selected'
            )}
            key={`transaction_${i}`}
          >
            <td>&nbsp; &nbsp;{feeName(feeId)}</td>
            {foundMainFee ? (
              <CountAvgTotalGroupFc
                quantity={foundMainFee.quantity}
                fee={foundMainFee.fee}
                total={foundMainFee.total}
              />
            ) : (
              <td colSpan={3} />
            )}
            {otherYearsData.map(({data}, i) => {
              const foundFee = data.fees.find((fee) => data.name === name && fee.feeId === feeId);
              return foundFee ? (
                <CountAvgTotalGroupFc
                  key={'count_avg_group_' + i}
                  quantity={foundFee.quantity}
                  fee={foundFee.fee}
                  total={foundFee.total}
                />
              ) : (
                <td key={'count_avg_group_' + i} colSpan={3} />
              );
            })}
          </tr>
        );
      })}
    </React.Fragment>
  );
};
