import {useApolloClient} from '@apollo/client';
import {constants, dates, format} from '@telia/cpa-web-common';
import classNames from 'classnames';
import gql from 'graphql-tag';
import React, {FC, useEffect, useState} from 'react';

import settlementsQuery from '../../graphql/query/settlements.graphql';

import {useProductTypes} from '../../hooks/useProductTypes';
import {useUser} from '../../hooks/useUser';
import {getLog} from '../../log';
import {Settlement, SettlementMonthTotal} from '../../model';
import Loading from '../Loading';
import {InformationLineFc} from '../common/InformationLine';
import {SettlementFeesTableGroupFc} from '../settlement/common/component/SettlementFeesTableGroup';
import {generateMonthTotal} from '../settlement/common/settlementUtils';

const log = getLog('ReportMonthSummariesTableFc', 'INFO');
const {ALL} = constants;

interface SettlementsQuery {
  settlements: Settlement[];
}

interface SettlementMonthSummary {
  year: number;
  monthSummary: SettlementMonthTotal;
}

interface ReportMonthSummariesTableFcProps {
  years: [string];
  month: string;
  contractType: string;
  productType: string;
}

export const ReportMonthSummariesTableFc: FC<ReportMonthSummariesTableFcProps> = (props) => {
  const {years, month, contractType, productType: selectedProductType} = props;

  log.debug('props', {props});

  const {getName, productTypes} = useProductTypes();
  const {currentUserBrand} = useUser();

  const client = useApolloClient();

  const [yearlyMonthSummaries, setYearlyMonthSummaries] = useState<SettlementMonthSummary[]>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const summaries: SettlementMonthSummary[] = [];
    setLoading(true);
    currentUserBrand &&
      Promise.all(
        years.map((year) =>
          client
            .query<SettlementsQuery>({
              query: gql(settlementsQuery),
              variables: {
                year: parseInt(year),
                month: parseInt(month),
                contractType: contractType !== ALL ? contractType : undefined,
              },
            })
            .then(({data: {settlements}}) => {
              log.debug('settlements', {settlements});
              summaries.push({year: parseInt(year), monthSummary: generateMonthTotal(settlements || [])});
            })
        )
      ).then(() => {
        setYearlyMonthSummaries(summaries.slice().sort((a, b) => (a.year > b.year ? -1 : 1)));
        setLoading(false);
      });
  }, [years, currentUserBrand]);

  log.debug('yearlyMonthSummaries', {yearlyMonthSummaries});

  const hasAdjustments = !!yearlyMonthSummaries?.some(({monthSummary}) => monthSummary.adjustmentSummary.total !== 0);

  return loading ? (
    <Loading />
  ) : !yearlyMonthSummaries || yearlyMonthSummaries.isEmpty() ? ( //!settlements || settlements.isEmpty() ? (
    <InformationLineFc description={`No approved settlements for this month` /* on ${month} ${year}*/} />
  ) : (
    <section className="settlement__aggregate">
      <table className="settlement__list">
        <thead>
          <tr>
            <th>{dates.monthNames[parseInt(month) - 1]}</th>
            {yearlyMonthSummaries.map(({year}) => (
              <th colSpan={3} className="centerText" key={'th' + year}>
                {year}
              </th>
            ))}
          </tr>
          <tr>
            <th>Income type summary</th>
            {yearlyMonthSummaries.map(({year}) => (
              <React.Fragment key={'count-avg-tot' + year}>
                <th className="rightText width0-on-small-only">Count</th>
                <th className="rightText">Average</th>
                <th className="rightText">Total</th>
              </React.Fragment>
            ))}
          </tr>
        </thead>
        <tbody className={classNames(!hasAdjustments && 'settlement__list__body--padding__bottom')}>
          {productTypes.map((productType, i) => {
            const firstYearSummary = yearlyMonthSummaries[0].monthSummary.productTypeSummaries.find(
              ({productTypeId}) => productTypeId === productType.id
            );

            const otherYearsData = yearlyMonthSummaries
              .slice(1)
              .reduce((data, {year, monthSummary: {productTypeSummaries}}) => {
                const foundSummary = productTypeSummaries.find(({productTypeId}) => productTypeId === productType.id);

                return foundSummary
                  ? [
                      ...data,
                      {
                        year,
                        data: {
                          name: getName(foundSummary.productTypeId),
                          total: foundSummary.total,
                          fees: foundSummary.fees,
                        },
                      },
                    ]
                  : data;
              }, []);

            return (
              (firstYearSummary || !otherYearsData.isEmpty()) && (
                <SettlementFeesTableGroupFc
                  name={getName(productType.id)}
                  total={firstYearSummary?.total}
                  fees={firstYearSummary?.fees || []}
                  key={'table_group_' + i}
                  otherYearsData={otherYearsData}
                  highlight={productType.id === selectedProductType}
                />
              )
            );
          })}

          {hasAdjustments && (
            <tr className="settlement__list__item settlement__list__item--total">
              <td key={'adjustments'}>Adjustments</td>
              {yearlyMonthSummaries.map(({monthSummary}, i) =>
                monthSummary.adjustmentSummary.total !== 0 ? (
                  <React.Fragment key={'adjustment_' + i}>
                    <td className="rightText noWrap">{format.integer(monthSummary.adjustmentSummary.count)} &times;</td>
                    <td className="rightText noWrap">
                      {format.decimal(monthSummary.adjustmentSummary.total / monthSummary.adjustmentSummary.count)} =
                    </td>
                    <td className="rightText noWrap">{format.decimal(monthSummary.adjustmentSummary.total)}</td>
                  </React.Fragment>
                ) : (
                  <td key={'adjustment_' + i} colSpan={3} />
                )
              )}
            </tr>
          )}
        </tbody>
        <tfoot>
          <tr className="settlement__list__item settlement__list__item--sum settlement__list__item--total">
            {yearlyMonthSummaries.map(({monthSummary}, i) => (
              <React.Fragment key={'summary_' + i}>
                {i == 0 && <th>Month Total (excl. VAT)</th>}
                <th></th>
                <th className="rightText noWrap">{currentUserBrand?.currencyCode}</th>
                <th className="rightText settlement__list__item--sum">{format.decimal(monthSummary.total)}</th>
              </React.Fragment>
            ))}
          </tr>
        </tfoot>
      </table>
    </section>
  );
};
