import classnames from 'classnames';
import React, {FunctionComponent, MutableRefObject, RefObject, useEffect, useRef} from 'react';

import {getLog} from '../../../log';
import Field, {FieldInputProps, FieldProps, FieldTypes} from './Field';

const log = getLog('Textarea');

const setInitialMinHeight = (textareaRef: MutableRefObject<HTMLTextAreaElement | undefined>) => {
  if (textareaRef?.current) {
    textareaRef.current.style.minHeight = textareaRef.current.style.height;
  }
};

const adjustTextAreaHeight = (textareaRef: MutableRefObject<HTMLTextAreaElement | undefined>) => {
  if (textareaRef?.current) {
    textareaRef.current.style.height = '';
    textareaRef.current.style.height = textareaRef.current.scrollHeight + 'px';
  }
};

export interface FieldTextareaProps extends Omit<FieldInputProps, 'type'> {
  type: typeof FieldTypes.textarea;
  value?: string;
  defaultValue?: string; //  What to shown when field has no (or null) value
}

export function isFieldTextareaProps(props: FieldProps): props is FieldTextareaProps {
  return props.type === FieldTypes.textarea;
}

export const Textarea: FunctionComponent<FieldTextareaProps> = (props) => {
  const {autocomplete, id, isDisabled = false, label, name, onChange} = props;
  const value = Field.resolveValue(props);

  const textareaRef = useRef<HTMLTextAreaElement>();

  useEffect(() => setInitialMinHeight(textareaRef), [textareaRef.current]);
  useEffect(() => adjustTextAreaHeight(textareaRef), [value]);

  return (
    <textarea
      id={id}
      name={name || label}
      className={classnames('field__value', props.valueClassName)}
      disabled={isDisabled}
      placeholder={Field.resolvePlaceholder(props)}
      value={value}
      onChange={(e) => onChange && onChange(e.target.value)}
      ref={textareaRef as RefObject<HTMLTextAreaElement>}
      autoComplete={autocomplete}
    />
  );
};
