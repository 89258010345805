"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const customers = [
    {
        id: '123',
        countryId: 'NO',
        brandId: 'TELIA_NO',
        company: {
            id: '10101',
            brandId: 'TELIA_NO',
            countryId: 'NO',
            organizationNumber: '123456789',
            name: 'Test AB',
            businessAddress: {
                streetAddress: 'Testvägen 1',
                postCode: '12345',
                city: 'Test',
                country: 'Norway',
            },
            differentBillingAddress: false,
        },
    },
];
exports.default = customers;
