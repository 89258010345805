import {IconDefinition} from '@telia/styleguide';
import React, {useMemo} from 'react';

import {getLog} from '../log';
import {ReservedOaFormatClassifier} from '../model';

const log = getLog('useSenderIdClassifier', 'INFO');

interface SenderIdClassifier {
  id: ReservedOaFormatClassifier;
  name: string;
  description: string;
  match?: RegExp;
  icon: IconDefinition;
}

const classifierData: {
  [c in ReservedOaFormatClassifier]: SenderIdClassifier;
} = {
  ALPHANUM: {
    id: 'ALPHANUM',
    name: 'Lowercase alphanumeric text',
    description: 'Text match for lowercase alphanumeric string',
    icon: 'spell-check',
  },
  NUMBER: {
    id: 'NUMBER',
    name: 'Phone with country code',
    description: 'Phone number match including country code prefix',
    match: /^\+{1}[\d]+$/,
    icon: 'roaming',
  },
  SHORTCODE: {
    id: 'SHORTCODE',
    name: 'Short number',
    description: 'Short code match of 3 or 6 digit code',
    match: /^(\d{3,6}){1}$/,
    icon: 'smartphone',
  },
};

export interface UseSenderIdClassifier {
  classifiers: SenderIdClassifier[];
  getName: (id: ReservedOaFormatClassifier) => string;
  getIcon: (id: ReservedOaFormatClassifier) => IconDefinition;
  getClassifierFromSenderId: (senderId: string | undefined) => ReservedOaFormatClassifier | null;
}

export const useSenderIdClassifier = (): UseSenderIdClassifier => {
  const getClassifier = (id: ReservedOaFormatClassifier): SenderIdClassifier => {
    return classifierData[id];
  };

  const getName = (id: ReservedOaFormatClassifier): string => {
    return getClassifier(id).name;
  };

  const getIcon = (id: ReservedOaFormatClassifier): IconDefinition => {
    return getClassifier(id).icon;
  };

  const getClassifierFromSenderId = (senderId: string | undefined): ReservedOaFormatClassifier | null => {
    if (!senderId || !senderId.length) return null;

    if (classifierData.SHORTCODE.match?.test(senderId)) {
      return classifierData.SHORTCODE.id;
    } else if (classifierData.NUMBER.match?.test(senderId)) {
      return classifierData.NUMBER.id;
    } else {
      return classifierData.ALPHANUM.id;
    }
  };

  const useSenderIdClassifierHook: UseSenderIdClassifier = {
    classifiers: Object.values(classifierData),
    getName,
    getIcon,
    getClassifierFromSenderId,
  };
  log.debug('render', useSenderIdClassifierHook);
  return useSenderIdClassifierHook;
};
