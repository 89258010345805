import React, {FC, FormEvent, PropsWithChildren} from 'react';

// import {Form as _Form} from '@telia/styleguide';
// export const Form = _Form;
//  TODO: use '@telia/styleguide' or VOCA

interface FormFcProps extends PropsWithChildren {
  onSubmit?: (event: FormEvent) => void;
}

export const FormFc: FC<FormFcProps> = (props) => {
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        props.onSubmit && props.onSubmit(e);
      }}
    >
      {props.children}
    </form>
  );
};

export default FormFc;
