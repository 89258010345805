import {DispatchWithoutAction, useContext} from 'react';

import {extractDomainModelError, isDomainModelError} from '../apollo/apolloUtils';
import {Errors, ErrorsContext, ErrorsState} from '../context/Errors';
import {getLog} from '../log';
import {ID} from '../model';

const log = getLog('useErrors', 'INFO');

export type PutError = (key: ID, message: string) => void;

export interface ErrorsHook {
  findError: (key: ID) => string | undefined;
  putError: PutError;
  clearError: (key: ID) => void;
  clearErrors: DispatchWithoutAction;
  onMutationError: (graphQLErrors: any[]) => void;
}

export const _findError = (errors: Errors) => (errorKey: ID) => {
  return errors[`${errorKey}`];
};

export function useErrors(): ErrorsHook {
  const errorsState = useContext<ErrorsState>(ErrorsContext);
  const [errors, setErrors] = errorsState;

  const putError = (key: ID, message: string) => {
    log.debug(`Put error '${key}': ${message}`);
    setErrors((errors) => ({...errors, [`${key}`]: message}));
  };

  const findError = (errorKey: ID) => {
    const errorValue = _findError(errors)(errorKey);
    errorValue ? log.debug(`Found error '${errorKey}': ${errorValue}`) : log.debug(`No error '${errorKey}'`);
    return errorValue;
  };

  const clearError = (key: ID) => {
    log.debug(`Clear error '${key}'`);
    setErrors((errors) => {
      const cleanedValue = {...errors};
      cleanedValue && delete cleanedValue[`${key}`];
      return cleanedValue;
    });
  };

  const clearErrors = () => {
    log.debug('clearErrors');
    setErrors({});
  };

  /**
   * Gathers all DomainModelErrors (in graphQLErrors) with entity and field data (from the response's GraphQL errors).
   * Creates the errors map of error messages, where the keys are a combination of the Entity and Field fields:
   *  - "errors.<entity_without_DTO_suffix>.<field> = <error_message>"
   *  ex: errors['COMPANY.NAME'] = "Company name cannot be empty"
   *  ex: errors['CUSTOMER.COMPANY.NAME'] = "Company name cannot be empty"
   *
   * @param graphQLErrors array of GraphQL (mutation) errors
   * @private
   */
  const onMutationError = (graphQLErrors: any[]) => {
    log.debug('onMutationError', graphQLErrors);
    graphQLErrors
      .map(extractDomainModelError)
      .filter(isDomainModelError)
      .filter((e) => e.entity)
      .filter((e) => e.field)
      .map((e) => ({
        key: e.field,
        // key: `${e.entity.replace(/DTO$/, '')}.${e.field}`,
        message: e.message,
      }))
      .forEach(({key, message}) => {
        putError(key, message);
      });
  };

  const errorsHook: ErrorsHook = {
    findError,
    putError,
    clearErrors,
    clearError,
    onMutationError,
  };

  return errorsHook;
}
