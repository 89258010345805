"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createParamExtractor = void 0;
const path_to_regexp_1 = require("path-to-regexp");
function isKey(token) {
    return !!token.name;
}
const getParamIndex = (key, dict) => {
    return dict[key];
};
const createParamExtractor = (pathDef) => {
    const tokens = (0, path_to_regexp_1.parse)(pathDef);
    const paramIndexDict = tokens.filter(isKey).reduce((paramsMap, { name }, index, e) => {
        return Object.assign(Object.assign({}, paramsMap), { [`${name}`]: index + 1 });
    }, {});
    return (paramName, pathValue) => {
        const pathRegExp = (0, path_to_regexp_1.pathToRegexp)(pathDef);
        const pathMatches = pathRegExp.exec(pathValue);
        const paramIndex = getParamIndex(paramName, paramIndexDict);
        return pathMatches === null || paramIndex === undefined || pathMatches[paramIndex] === undefined
            ? null
            : pathMatches[paramIndex];
    };
};
exports.createParamExtractor = createParamExtractor;
