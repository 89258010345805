fragment companyFragment on Company {
  id
  countryId
  brandId
  name
  organizationNumber
  businessAddress {
    streetAddress
    postCode
    city
    country
  }
  billingAddress {
    streetAddress
    postCode
    city
    country
  }
  differentBillingAddress
}
