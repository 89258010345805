import React, {FC, PropsWithChildren, ReactNode} from 'react';

interface InformationLineFcProps extends PropsWithChildren {
  description?: string | ReactNode;
  buttons?: ReactNode;
}

export const InformationLineFc: FC<InformationLineFcProps> = ({buttons, description, children}) => (
  <div className="pop-up-line">
    <div className="pop-up-line__container container container--small">
      <div className="pop-up-line__description">{description || children}</div>
      <div className="pop-up-line__buttons">{buttons}</div>
    </div>
  </div>
);

export default InformationLineFc;
