import {Button} from '@telia/styleguide';
import gql from 'graphql-tag';
import React, {FC, useState} from 'react';

import verifyPasswordMutation from '../../graphql/mutation/verifyPassword.graphql';

import {useFormState} from '../../hooks/useFormState';
import {useMutationWrap} from '../../hooks/useMutationWrap';
import {getLog} from '../../log';
import Loading from '../Loading';
import FormColumn from '../common/FormColumn';
import FormRow from '../common/FormRow';
import {FieldTypes, FieldWithFormState} from '../common/field';

const log = getLog('VerifyPassword', 'INFO');

interface VerifyPasswordProps {
  onVerified: () => void;
  onCancel: () => void;
}

export const VerifyPassword: FC<VerifyPasswordProps> = ({onVerified, onCancel}) => {
  const [loading, setLoading] = useState<boolean>(false);

  const verifyPassword = useMutationWrap<{verifyPassword: {verified: boolean}}, {password: string}>(
    gql(verifyPasswordMutation)
  );

  const formState = useFormState({
    isEditing: true,
    initialEntity: {
      password: '',
    },
  });

  const onVerifyPassword = () => {
    setLoading(true);
    verifyPassword({
      variables: {
        password: formState.entity.password?.toString() || '',
      },
    }).then(({data}) => {
      setLoading(false);
      if (data?.verifyPassword) onVerified();
    });
  };

  return (
    <>
      <FormRow>
        <FormColumn>
          <FieldWithFormState
            formState={formState}
            entityFieldId="password"
            label="Enter your password"
            type={FieldTypes.password}
            autocomplete="off"
          />
        </FormColumn>
      </FormRow>

      <FormRow>
        <FormColumn>
          <div>
            {loading ? (
              <Loading />
            ) : (
              <>
                <Button kind={Button.kinds.primary} onClick={onVerifyPassword} text="Continue" />
                <Button kind={Button.kinds.cancel} onClick={onCancel} text="Cancel" />
              </>
            )}
          </div>
        </FormColumn>
      </FormRow>
    </>
  );
};
