import classnames from 'classnames';
import React, {FC, useState} from 'react';

import './Tooltip.scss';

let uniqueId = 0;

interface TooltipProps {
  buttonContent?: string;
  children?: React.ReactElement;
  className?: string;
  isExpanded?: boolean;
  label?: string;
  text?: string;
}

export const Tooltip: FC<TooltipProps> = (props) => {
  const [expanded, setExpanded] = useState(props.isExpanded);
  const [id] = useState(uniqueId++);
  const {children, className, buttonContent, label, text} = props;

  return (
    <div className={classnames('tooltip', {'tooltip--show': expanded}, className)}>
      <div className="tooltip__button-container">
        {label && <label htmlFor={`tooltip__button-${id}`}>{label}</label>}
        <button
          type="button"
          id={`tooltip__button-${id}`}
          className="tooltip__button"
          aria-controls={`tooltip__box-${id}`}
          aria-expanded={expanded}
          onClick={(e) => {
            setExpanded(!expanded);
            e.stopPropagation();
          }}
          onMouseOver={() => setExpanded(true)}
          onMouseOut={() => setExpanded(false)}
        >
          {buttonContent || 'i'}
        </button>
      </div>
      <div className="tooltip__box" id={`tooltip__box-${id}`}>
        {children || text}
      </div>
    </div>
  );
};

export default Tooltip;
