#import '../fragment/productTechnical.graphql'

mutation saveProductTechnical(
  $customerId: String!
  $contractId: String!
  $productId: String!
  $productTechnical: ProductTechnicalInput!
) {
  saveProductTechnical(
    customerId: $customerId
    contractId: $contractId
    productId: $productId
    productTechnical: $productTechnical
  ) {
    ...productTechnicalFragment
  }
}
