import {gql, useQuery} from '@apollo/client';
import {Operator} from '@telia/cpa-web-common/src/model';
import React, {useMemo} from 'react';

import operatorsQuery from '../graphql/query/operators.graphql';

import {getLog} from '../log';
import {TrafficFeeType} from '../model';

const log = getLog('useOperators', 'INFO');

interface OperatorsQuery {
  operators: Operator[];
}

type NetType = 'On net' | 'Off net' | 'Foreign';

export interface UseOperators {
  loading: boolean;
  operators: Operator[] | undefined;
  onNetCodes: number[];
  netTypeFromOperatorCode: (code?: string) => NetType;
  netTypeFromTrafficFee: (trafficFeeType?: TrafficFeeType) => NetType;
}

export const useOperators = () => {
  const {loading, data: {operators} = {}} = useQuery<OperatorsQuery>(gql(operatorsQuery));

  const onNetCodes = useMemo(() => operators?.flatMap<number>(({codes}) => codes).sort() || [], [operators]);

  const netTypeFromOperatorCode = (code: string | undefined): NetType => {
    if (!code || Number.isNaN(parseInt(code))) return 'Foreign';
    if (onNetCodes?.includes(parseInt(code))) return 'On net';
    return 'Off net';
  };

  const netTypeFromTrafficFee = (trafficFeeType?: TrafficFeeType): NetType => {
    if (trafficFeeType === 'HOME') return 'On net';
    if (trafficFeeType === 'DOMESTIC') return 'Off net';
    return 'Foreign';
  };

  const useOperatorsHook: UseOperators = {
    loading,
    operators,
    onNetCodes,
    netTypeFromOperatorCode,
    netTypeFromTrafficFee,
  };
  log.debug('render', useOperatorsHook);
  return useOperatorsHook;
};
