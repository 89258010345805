import React, {FC, PropsWithChildren, useEffect, useState} from 'react';

import {VerifyPassword} from './VerifyPassword';

interface PasswordGuardProps extends PropsWithChildren {
  onCancel: () => void;
}

export const PasswordGuard: FC<PasswordGuardProps> = ({children, onCancel}) => {
  const [isVerified, setIsVerified] = useState(false);

  return isVerified ? (
    <>{children}</>
  ) : (
    <VerifyPassword
      onVerified={() => setIsVerified(true)}
      onCancel={() => {
        setIsVerified(false);
        onCancel();
      }}
    />
  );
};
