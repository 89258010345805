import {format} from '@telia/cpa-web-common';
import {ActiveStatus, activeStatus} from '@telia/cpa-web-common/dist/period';
import {FullWidthTable} from '@telia/styleguide';
import classnames from 'classnames';
import classNames from 'classnames';
import React, {FC, useMemo} from 'react';
import {useNavigate, useParams} from 'react-router-dom';

import './ServicesTable.scss';

import * as AppRoutes from '../../appRoutes';
import {useFormattedText} from '../../hooks/useFormattedText';
import {useProductTypes} from '../../hooks/useProductTypes';
import {getLog} from '../../log';
import {Contract, FormattedDate, ID} from '../../model';
import {Icon} from '../common/Icon';
import Tooltip from '../common/Tooltip';

const log = getLog('ServicesTable', 'INFO');

interface ServicesTableProps {
  contracts: Contract[];
}

export const ServicesTable: FC<ServicesTableProps> = (props) => {
  const {contracts} = props;
  const {getEstablishFee, getMonthlyFee, getName} = useProductTypes();
  const {productSubject} = useFormattedText();
  const navigate = useNavigate();
  const {formatWithBrand} = AppRoutes.useBrandFormat();
  const {customerId} = useParams<{customerId: ID}>() as {customerId: ID};

  log.debug('render', {contracts});

  interface TableContent {
    isContract: boolean;
    contractId: ID;
    productId?: ID;
    productType: string;
    subject: string;
    comment?: string;
    establishFee?: number;
    monthlyFee?: number;
    startDate: FormattedDate;
    endDate?: FormattedDate;
    activeStatus: ActiveStatus;
  }

  const tableContent: TableContent[] = useMemo(
    () =>
      contracts.flatMap<TableContent>(({id, name, startDate, endDate, products}) => {
        const enrichedContract = {
          isContract: true,
          productType: 'Contract ' + id,
          contractId: id,
          subject: name || '',
          startDate: startDate || '',
          endDate: endDate || '',
          activeStatus: activeStatus({startDate, endDate}),
        };
        const enrichedProducts = products.map((product) => ({
          ...product,
          isContract: false,
          productId: product.id,
          productType: getName(product.productType),
          subject: productSubject(product),
          establishFee:
            product.establishFee !== undefined && product.establishFee !== null
              ? product.establishFee
              : getEstablishFee(product.productType),
          monthlyFee:
            product.monthlyFee !== undefined && product.monthlyFee !== null
              ? product.monthlyFee
              : getMonthlyFee(product.productType),
          activeStatus: activeStatus(product),
        }));
        return contracts.length > 1 ? [enrichedContract, ...enrichedProducts] : enrichedProducts;
      }),
    [contracts, getName, productSubject, getEstablishFee, getMonthlyFee, activeStatus]
  );

  const [sortedTableContent, getThSortableProps] = FullWidthTable.useThSortable<TableContent>(tableContent, 3, [], {});

  log.debug('sortedTableContent', {sortedTableContent});

  return (
    <div>
      {contracts && !contracts.isEmpty() && (
        <FullWidthTable clickable={false}>
          <FullWidthTable.THead>
            <FullWidthTable.Tr>
              <FullWidthTable.ThSortable {...getThSortableProps('productType')}>Service Type</FullWidthTable.ThSortable>
              <FullWidthTable.ThSortable {...getThSortableProps('subject')}>Subject</FullWidthTable.ThSortable>
              <FullWidthTable.Th className="table__cell--min-width marginRight">
                <Tooltip text={`Display service's comment if available`} />
              </FullWidthTable.Th>
              <FullWidthTable.ThSortable {...getThSortableProps('establishFee')}>
                Establish Fee
              </FullWidthTable.ThSortable>
              <FullWidthTable.ThSortable {...getThSortableProps('monthlyFee')}>Monthly Fee</FullWidthTable.ThSortable>
              <FullWidthTable.ThSortable {...getThSortableProps('startDate')}>Start date</FullWidthTable.ThSortable>
              <FullWidthTable.ThSortable {...getThSortableProps('endDate')}>End date</FullWidthTable.ThSortable>
              <FullWidthTable.ThSortable {...getThSortableProps('activeStatus')}>
                Active Status
              </FullWidthTable.ThSortable>
            </FullWidthTable.Tr>
          </FullWidthTable.THead>

          <FullWidthTable.TBody>
            {sortedTableContent.map(
              (
                {
                  isContract,
                  contractId,
                  productId,
                  productType,
                  subject,
                  comment,
                  establishFee,
                  monthlyFee,
                  startDate,
                  endDate,
                  activeStatus,
                },
                i
              ) => (
                <FullWidthTable.Tr
                  key={i}
                  className={classnames({inactive: activeStatus !== 'ACTIVE'})}
                  onClick={() =>
                    navigate(
                      isContract
                        ? formatWithBrand(
                            AppRoutes.PROVISIONING_CUSTOMER_CONTRACT__customerId_contractId,
                            customerId,
                            contractId
                          )
                        : formatWithBrand(
                            AppRoutes.PROVISIONING_CUSTOMER_SERVICE__customerId_contractId_productId,
                            customerId,
                            contractId,
                            productId!
                          )
                    )
                  }
                >
                  <FullWidthTable.Td>
                    {isContract ? (
                      <span className={'bold'}>
                        <Icon icon={'business'} className={'vertical-align-bottom'} /> {productType}
                      </span>
                    ) : (
                      productType
                    )}
                  </FullWidthTable.Td>
                  <FullWidthTable.Td>{subject}</FullWidthTable.Td>
                  <FullWidthTable.Td className={'table__cell--min-width'}>
                    {comment && <Tooltip text={comment} />}
                  </FullWidthTable.Td>
                  <FullWidthTable.Td>{format.integer(establishFee)}</FullWidthTable.Td>
                  <FullWidthTable.Td>{format.integer(monthlyFee)}</FullWidthTable.Td>
                  <FullWidthTable.Td className={classnames({disabled: !startDate})}>
                    {startDate ? format.date(startDate) : 'no start'}
                  </FullWidthTable.Td>
                  <FullWidthTable.Td className={classnames({disabled: !endDate})}>
                    {endDate ? format.date(endDate) : 'no end'}
                  </FullWidthTable.Td>
                  <FullWidthTable.Td className={classNames(activeStatus.toLowerCase() + 'Period')}>
                    {activeStatus}
                  </FullWidthTable.Td>
                </FullWidthTable.Tr>
              )
            )}
          </FullWidthTable.TBody>
        </FullWidthTable>
      )}
    </div>
  );
};
