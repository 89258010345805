import React, {FC} from 'react';
import {format} from '@telia/cpa-web-common';
import './SettlementLine.scss';
import {Icon} from '../../../common/Icon';
import {getLog} from '../../../../log';
import {Settlement} from '../../../../model';

const log = getLog('SettlementDateTotalLine', 'INFO');

interface SettlementDateTotalLineFcProps {
  settlement: Settlement;
  showCompanyName?: boolean;
}

export const SettlementDateTotalLineFc: FC<SettlementDateTotalLineFcProps> = (props) => {
  const {settlement, showCompanyName} = props;
  log.debug('settlement', settlement);
  return (
    <div className={'settlement__name'}>
      <span className="settlement__Name_date">
        {format.yearMonth(settlement.yearMonth)} <Icon icon={'calendar'} />
      </span>
      <span className="settlement__name__total">
        {format.currency(settlement.summary.totalExclVat, settlement.currencyCode)}
      </span>
      <span className="settlement__name__id">{settlement.id} Settl#</span>
      {showCompanyName && settlement.customer.company.name}
    </div>
  );
};
