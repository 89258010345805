"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.userComparator = exports.rbmOverviewsComparator = exports.reservedOaOverviewsComparator = exports.productOverviewsComparator = exports.sortedProductOverviewTypes = exports.sortByProductFeeIdComparator = exports.sortedProductFeeIds = exports.sortByProductTypeIdComparator = exports.sortedProductTypeIds = exports.sortByIdComparator = exports.sortByNameComparator = exports.accessNumbersSortComparator = exports.withAccessNumbersSortComparator = exports.productAccessNumberCompare = void 0;
const constants_1 = require("./constants");
const productAccessNumberCompare = (a, b) => {
    var _a, _b;
    return (0, exports.accessNumbersSortComparator)((a.accessNumber || ((_a = a.accessNumberRange) === null || _a === void 0 ? void 0 : _a.from)), (b.accessNumber || ((_b = b.accessNumberRange) === null || _b === void 0 ? void 0 : _b.from)));
};
exports.productAccessNumberCompare = productAccessNumberCompare;
const withAccessNumbersSortComparator = (a, b) => (0, exports.accessNumbersSortComparator)(a.accessNumber, b.accessNumber);
exports.withAccessNumbersSortComparator = withAccessNumbersSortComparator;
const accessNumbersSortComparator = (a, b) => a === undefined || b === undefined
    ? a === undefined && b === undefined
        ? 0
        : a === undefined
            ? -1
            : 1
    : a.length !== b.length
        ? a.length - b.length
        : a.localeCompare(b);
exports.accessNumbersSortComparator = accessNumbersSortComparator;
const sortByNameComparator = (a, b) => {
    if (!a || !a.name) {
        return -1;
    }
    if (!b || !b.name) {
        return 1;
    }
    return a.name.localeCompare(b.name);
};
exports.sortByNameComparator = sortByNameComparator;
const sortByIdComparator = (a, b) => {
    if (!a || !a.id) {
        return -1;
    }
    if (!b || !b.id) {
        return 1;
    }
    return a.id.localeCompare(b.id);
};
exports.sortByIdComparator = sortByIdComparator;
exports.sortedProductTypeIds = [
    'SMS_BULK',
    'MO_LONG_NUMBER',
    'MO_LONG_NUMBER_RANGE',
    'MO_DIRECT_CHARGE_RANGE',
    'POSITIONING',
    'SMS_EVENT',
    'RESERVED_OA',
    'RBM',
];
const findSortedProductTypeIndex = (productTypeId) => {
    const index = exports.sortedProductTypeIds.findIndex((sortedProductTypeId) => productTypeId.startsWith(sortedProductTypeId));
    return index >= 0 ? index : exports.sortedProductTypeIds.length; // If index is not found in the sorted IDs list, place at end
};
const sortByProductTypeIdComparator = (a, b) => {
    // If indexes are identical, use localCompare for sorting on suffix (..._NO, ..._SE, etc)
    return (findSortedProductTypeIndex(a.productTypeId) - findSortedProductTypeIndex(b.productTypeId) ||
        a.productTypeId.localeCompare(b.productTypeId));
};
exports.sortByProductTypeIdComparator = sortByProductTypeIdComparator;
exports.sortedProductFeeIds = [
    'HOME',
    'DOMESTIC',
    'FOREIGN',
    'BLOCKED',
    'RBM_BASIC_MESSAGE',
    'RBM_SINGLE_MESSAGE',
    'RBM_A2P_CONVERSATION',
    'RBM_AGENT',
    'MONTHLY',
    'ESTABLISHMENT',
];
const findSortedProductFeeIdIndex = (productFeeId) => {
    return exports.sortedProductFeeIds.findIndex((sortedProductFeeId) => sortedProductFeeId === productFeeId);
};
const sortByProductFeeIdComparator = (a, b) => {
    return findSortedProductFeeIdIndex(a.feeId) - findSortedProductFeeIdIndex(b.feeId);
};
exports.sortByProductFeeIdComparator = sortByProductFeeIdComparator;
exports.sortedProductOverviewTypes = [
    constants_1.PRODUCT_OVERVIEW_TYPE_ACCESS_NUMBER,
    constants_1.PRODUCT_OVERVIEW_TYPE_ACCESS_NUMBER_RANGE,
    constants_1.PRODUCT_OVERVIEW_TYPE_RESERVED_OA,
    constants_1.PRODUCT_OVERVIEW_TYPE_RBM,
    constants_1.PRODUCT_OVERVIEW_TYPE_GENERIC,
];
const findSortedProductOverviewTypeIndex = (overviewType) => {
    return exports.sortedProductOverviewTypes.findIndex((sortedOverviewType) => sortedOverviewType === overviewType);
};
const productOverviewsComparator = (a, b) => {
    return (findSortedProductOverviewTypeIndex(a.productOverviewType) -
        findSortedProductOverviewTypeIndex(b.productOverviewType));
};
exports.productOverviewsComparator = productOverviewsComparator;
const reservedOaOverviewsComparator = (a, b) => {
    var _a, _b, _c;
    return !a.reservedOas || !a.reservedOas.length || !b.reservedOas || !b.reservedOas.length
        ? !((_a = a.reservedOas) === null || _a === void 0 ? void 0 : _a.length) && !((_b = b.reservedOas) === null || _b === void 0 ? void 0 : _b.length)
            ? 0
            : ((_c = a.reservedOas) === null || _c === void 0 ? void 0 : _c.length)
                ? -1
                : 1
        : a.reservedOas[0].localeCompare(b.reservedOas[0]);
};
exports.reservedOaOverviewsComparator = reservedOaOverviewsComparator;
const rbmOverviewsComparator = (a, b) => {
    return a.rbmAgentOwner.localeCompare(b.rbmAgentOwner);
};
exports.rbmOverviewsComparator = rbmOverviewsComparator;
const userComparator = (a, b) => {
    return (a.firstName.localeCompare(b.firstName) ||
        (a.lastName && b.lastName ? a.lastName.localeCompare(b.lastName) : a.lastName ? 1 : b.lastName ? -1 : 0));
};
exports.userComparator = userComparator;
