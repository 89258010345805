import {Button} from '@telia/styleguide';
import React, {FC, useState} from 'react';

import {useFormState} from '../../hooks/useFormState';
import {useModal} from '../../hooks/useModal';
import {useProductOverviews} from '../../hooks/useProductOverviews';
import {useProductTypes} from '../../hooks/useProductTypes';
import {useUser} from '../../hooks/useUser';
import {getLog} from '../../log';
import {ID, PolicyEffect, ReservedOa} from '../../model';
import {RESERVED_OA_MANAGE} from '../../permissions';
import Form from '../common/Form';
import FormColumn from '../common/FormColumn';
import FormRow from '../common/FormRow';
import {FieldWithFormState} from '../common/field';
import {PickProductByAccessNumber} from '../provisioning/PickProductByAccessNumber';
import {COMMA_SEPARATOR, parseCommaSeparatedIds} from './ReservedOas';
import {SenderIdInput} from './SenderIdInput';

const log = getLog('ReservedOaForm', 'INFO');

interface ReservedOaFormFcProps {
  ownerProductId: ID;
  effect: PolicyEffect;
  onSaveReservedOa: (reservedOa: ReservedOa) => Promise<ReservedOa | undefined>;
}

export const ReservedOaFormFc: FC<ReservedOaFormFcProps> = ({ownerProductId, effect, onSaveReservedOa}) => {
  const {hasPermission, currentUserBrand} = useUser();
  const formState = useFormState({
    isEditing: false,
    fixedFields: {ownerProductId, effect},
  });
  const {entity, isEditing, onEdit, onCancel, subEntityAt} = formState;
  const [isCreating, setIsCreating] = useState(false);
  const {getAccessNumberByProductId} = useProductOverviews();
  const {brandHasCanSendProductType} = useProductTypes();

  //  Allowed sender product ID pick by access number
  const {showModal, hideModal} = useModal();
  let modalId: ID;
  const onPickButtonClick = (accessNumberField: ID) => () => {
    const config = {
      title: `Pick allowed products by short number`,
      content: (
        <PickProductByAccessNumber
          selectedProductIds={parseCommaSeparatedIds(subEntityAt<string>('allowedProductIds'))}
          onPickProductByAccessNumber={onPickAccessNumber(accessNumberField)}
        />
      ),
    };
    modalId = showModal(config);
  };

  const onPickAccessNumber = (accessNumberField: ID) => (accessNumber: ID) => {
    log.info('onPickAccessNumber', {accessNumberField, accessNumber});
    formState.onChange(accessNumberField)(accessNumber);
    hideModal(modalId);
  };

  const onSave = () => {
    const reservedOa = {
      ...formState.entityAs<ReservedOa>(),
      allowedProductIds:
        effect === 'DENY' ? undefined : parseCommaSeparatedIds(subEntityAt<string>('allowedProductIds')),
    };
    log.debug('onSave', {reservedOa});

    onSaveReservedOa(reservedOa).then((saveReservedOa) => {
      saveReservedOa && formState.onCancel();
      saveReservedOa && setIsCreating(false);
      return saveReservedOa;
    });
  };

  log.debug('ReservedOaFormFc', {isCreating, formState});

  return !hasPermission(RESERVED_OA_MANAGE) ? null : (
    <div className={'marginBottom2'}>
      {isEditing ? (
        <Form>
          <FormRow>
            <FormColumn>
              <SenderIdInput formState={formState} effect={effect} />
            </FormColumn>
            <FormColumn>
              {entity.effect === 'ALLOW' && currentUserBrand && brandHasCanSendProductType(currentUserBrand?.id) && (
                <FieldWithFormState
                  formState={formState}
                  entityFieldId={'allowedProductIds'}
                  label="Allowed Short Num."
                  isDisabled={true}
                  isEditing={false}
                  value={
                    entity.effect === 'ALLOW'
                      ? parseCommaSeparatedIds(subEntityAt<string>('allowedProductIds'))
                          .map((allowedProductId) => getAccessNumberByProductId(allowedProductId) || '???')
                          .join(COMMA_SEPARATOR)
                      : 'na'
                  }
                  button={
                    isEditing && (
                      <Button text={'Pick'} className="floatRight" onClick={onPickButtonClick('allowedProductIds')} />
                    )
                  }
                />
              )}
              <div />
            </FormColumn>
          </FormRow>

          <div>
            <Button
              text={entity.effect === 'ALLOW' ? 'Reserve' : 'Block'}
              onClick={onSave}
              kind={Button.kinds.primary}
              isDisabled={!formState.entity?.oaFormatClassifier}
            />
            <Button text={'Cancel'} onClick={onCancel} kind={Button.kinds.cancel} />
          </div>
        </Form>
      ) : (
        <Button text={effect === 'DENY' ? 'Block SenderID' : 'Reserve SenderID'} onClick={onEdit} kind={'normal'} />
      )}
    </div>
  );
};
