import React, {FC} from 'react';

import './Support.scss';

import * as AppRoutes from '../appRoutes';
import {getLog} from '../log';
import FormColumn from './common/FormColumn';
import FormRow from './common/FormRow';
import ListFc from './common/List';
import ListItemFc from './common/ListItem';
import PageTitle from './common/PageTitle';
import SectionTitleFc from './common/SectionTitle';
import {Field, FieldTypes} from './common/field';
import {PageViewCounter} from './metrics/PageViewCounter';

const log = getLog('Support', 'INFO');

interface SupportContact {
  country: string;
  email: string;
  message?: string;
}
export const Support: FC = () => {
  const supportContacts: SupportContact[] = [
    {country: 'Norway', email: 'cpa-support@telia.no'},
    {
      country: 'Sweden',
      email: 'bulk-messaging@telia.se',
      message:
        'In the subject field of the e-mail, please describe the ticket type e.g. Order, Invoice, Support and Company name and if possible, company organizational number.',
    },
  ];

  log.debug('render');

  return (
    <div>
      <PageTitle title="Support" />
      <PageViewCounter page="support" />

      <p>For questions related to the Telia Bulk Messaging product or this portal you can contact:</p>
      {supportContacts.map(({country, email, message}) => (
        <section className="form-group">
          <SectionTitleFc title={country} />
          <FormRow>
            <FormColumn>
              <Field type={FieldTypes.element} label="Email">
                <a className="link support__link" href={`mailto:${email}`}>
                  {email}
                </a>
              </Field>
            </FormColumn>
          </FormRow>
          <FormRow>
            <FormColumn>
              <div>
                <p>{message}</p>
              </div>
            </FormColumn>
          </FormRow>
        </section>
      ))}

      <p className="details">
        More details are found in the Bulk Messaging agreement appendix 2, found in the{' '}
        <a className="link support__link" href={AppRoutes.DOCUMENTS}>
          Documents
        </a>{' '}
        page.
      </p>
    </div>
  );
};
