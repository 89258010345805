import classnames from 'classnames';
import React, {FunctionComponent, MouseEvent} from 'react';
import './Icon.css';
import {Icon as TeliaIcon, IconDefinition} from '@telia/styleguide';

interface IconProps {
  icon: IconDefinition;
  className?: string;
  info?: string;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
}

export const Icon: FunctionComponent<IconProps> = ({icon, info, onClick, className}) => (
  <span onClick={onClick}>
    <TeliaIcon icon={icon} title={info} className={classnames('Icon', className)} />
  </span>
);
