import React, {FC} from 'react';

import {FormState} from '../../../hooks/useFormState';
import {getLog} from '../../../log';
import {ID} from '../../../model';
import FormColumn from '../../common/FormColumn';
import FormRow from '../../common/FormRow';
import SectionTitle from '../../common/SectionTitle';
import {FieldTypes} from '../../common/field';
import {FieldWithFormState} from '../../common/field/FieldWithFormState';

const log = getLog('SubnumberProxy', 'INFO');

interface SubnumberProxyProps {
  parentId: ID;
  formState: FormState;
}

export const SubnumberProxy: FC<SubnumberProxyProps> = ({parentId, formState}) => {
  log.debug('render', {parentId, formState});

  return (
    <section className="form-group">
      <SectionTitle title="Subnumber Proxy" />
      <FormRow>
        <FormColumn>
          <FieldWithFormState
            formState={formState}
            entityFieldId={`${parentId}.enabled`}
            label="Enabled"
            type={FieldTypes.checkbox}
          />
        </FormColumn>
      </FormRow>

      <FormRow>
        <FormColumn>
          <FieldWithFormState
            formState={formState}
            entityFieldId={`${parentId}.prefix`}
            label="Prefix"
            type={FieldTypes.input}
            onChangeAlso={(prefix: string) => {
              formState.onChange(`${parentId}.prefix`)(prefix.replace(/\D/g, ''));
            }}
          />
        </FormColumn>
      </FormRow>

      <FormRow>
        <FormColumn>
          <FieldWithFormState
            formState={formState}
            entityFieldId={`${parentId}.matchExpression`}
            label="Match Expression"
            type={FieldTypes.input}
          />
        </FormColumn>
      </FormRow>
    </section>
  );
};
