import {DEV, LOCALHOST, PROD, STAGE} from '@telia/cpa-web-common/dist/environments';
import {
  DEV_BUCKET,
  DEV_HOSTS,
  LOCALHOST_BUCKET,
  LOCALHOST_HOSTS,
  PROD_BUCKET,
  PROD_HOSTS,
  STAGE_BUCKET,
  STAGE_HOST,
  STAGE_HOSTS,
} from '@telia/cpa-web-common/dist/hosts';

const getOrigin = (): string => window.location.origin;

const isOriginInHosts = (hosts: string[]): boolean => hosts.some((host) => getOrigin().includes(host));

export const isLocalhost = (): boolean => isOriginInHosts(LOCALHOST_HOSTS);
export const isDev = (): boolean => isOriginInHosts(DEV_HOSTS);
export const isStage = (): boolean => isOriginInHosts(STAGE_HOSTS);
export const isProd = (): boolean => isOriginInHosts(PROD_HOSTS);

export const realHost = (): string => (isLocalhost() ? STAGE_HOST : getOrigin());

export const currentEnvironment = (): string =>
  isLocalhost() ? LOCALHOST : isDev() ? DEV : isStage() ? STAGE : isProd() ? PROD : window.location.hostname;

export const getBucket = (): string =>
  isLocalhost() ? LOCALHOST_BUCKET : isDev() ? DEV_BUCKET : isStage() ? STAGE_BUCKET : isProd() ? PROD_BUCKET : '';
