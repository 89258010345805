import {FeatureBoxes} from '@telia/styleguide';
import React, {FC, PropsWithChildren} from 'react';
import {getLog} from '../../log';

const log = getLog('OverviewBoxGridFc', 'INFO');

export const OverviewBoxGridFc: FC<PropsWithChildren> = (props) => {
  const children = Array.isArray(props.children) ? props.children.filter((c) => !!c) : props.children;
  log.debug('render', {props}, {children});
  //  @ts-ignore
  return children ? <FeatureBoxes>{children}</FeatureBoxes> : null;
};
