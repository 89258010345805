import React, {FC} from 'react';

import {FormState} from '../../hooks/useFormState';
import {useSenderIdClassifier} from '../../hooks/useSenderIdClassifier';
import {getLog} from '../../log';
import {PolicyEffect} from '../../model';
import {FieldTypes, FieldWithFormState} from '../common/field';

const log = getLog('SenderIdInput', 'INFO');

export interface SenderIdInputProps {
  formState: FormState;
  isEditing?: boolean;
  entityFieldIdPrefix?: string;
  showLabels?: boolean;
  effect?: PolicyEffect;
}

export const SenderIdInput: FC<SenderIdInputProps> = ({
  formState,
  showLabels = true,
  isEditing = true,
  entityFieldIdPrefix = '',
  effect,
}) => {
  const {classifiers, getClassifierFromSenderId} = useSenderIdClassifier();
  log.debug('SenderIdInput', {formState});

  return (
    <>
      <FieldWithFormState
        formState={formState}
        isEditing={isEditing}
        entityFieldId={`${entityFieldIdPrefix}senderId`}
        {...(showLabels
          ? {
              label: 'SenderID',
              tip: `Unique SenderID (in lower case). Max length is 11 characters.${
                effect === 'DENY' ? ' Wildcard (*) can be used to block multiple variations.' : ''
              }`,
            }
          : {placeholder: 'SenderID'})}
        onChangeAlso={(senderId: string) => {
          formState.onChange(`${entityFieldIdPrefix}oaFormatClassifier`)(getClassifierFromSenderId(senderId));
        }}
      />
      <FieldWithFormState
        formState={formState}
        entityFieldId={`${entityFieldIdPrefix}oaFormatClassifier`}
        type={FieldTypes.select}
        isEditing={false}
        options={classifiers}
        {...(showLabels && {
          label: 'Classifier',
          tip: (
            <ul>
              {classifiers.map(({name, description}) => (
                <li>
                  <b>{name}</b>: {description}
                </li>
              ))}
            </ul>
          ),
        })}
      />
    </>
  );
};
