import {ID, Resource, ResourceRoles} from '@telia/cpa-web-common/dist/model';
import {createParamExtractor} from '@telia/cpa-web-common/dist/pathParams';
import {
  RESOURCE_BRAND_DEF,
  RESOURCE_BRAND_PARAM,
  RESOURCE_BRAND_PREFIX,
  RESOURCE_WILDCARD,
} from '@telia/cpa-web-common/dist/permissions';

import {getLog} from '../log';

const log = getLog('useResourceRoles', 'INFO');

interface UseResourceRoles {
  getBrandRoles: (brandId: ID | undefined, rolesMap?: ResourceRoles[] | null, exact?: boolean) => ID[];
  addBrandRole: (roleId: ID, brandId: ID, rolesMap?: ResourceRoles[] | null) => ResourceRoles[];
  removeBrandRole: (roleId: ID, brandId: ID, rolesMap?: ResourceRoles[] | null) => ResourceRoles[];
}

export const useResourceRoles = () => {
  const getBrandId = (resource: Resource) => createParamExtractor(RESOURCE_BRAND_DEF)(RESOURCE_BRAND_PARAM, resource);

  const isMatch = (param: string, extractedParam: string | null) => {
    return extractedParam && (extractedParam === param || extractedParam === RESOURCE_WILDCARD);
  };

  const addBrandRole = (roleId: ID, brandId: ID, rolesMap?: ResourceRoles[] | null): ResourceRoles[] =>
    _addResourceRole(roleId, RESOURCE_BRAND_PREFIX + brandId, rolesMap || []);

  const removeBrandRole = (roleId: ID, brandId: ID, rolesMap?: ResourceRoles[] | null): ResourceRoles[] =>
    _removeResourceRole(roleId, RESOURCE_BRAND_PREFIX + brandId, rolesMap || []);

  const getBrandRoles: (
    brandId: ID | undefined,
    rolesMap?: ResourceRoles[] | null,
    exact?: boolean //If true will only match exact resource and not * brand
  ) => ID[] = (brandId = RESOURCE_WILDCARD, rolesMap, exact = false) => {
    return rolesMap
      ? exact
        ? rolesMap?.find(({resource}) => brandId === getBrandId(resource))?.roles || []
        : rolesMap.reduce(
            (prev, {resource, roles}) =>
              isMatch(brandId, getBrandId(resource))
                ? [...prev, ...roles.filter((role) => !prev.includes(role))]
                : prev,
            [] as ID[]
          )
      : [];
  };

  const useResourceRoles: UseResourceRoles = {
    getBrandRoles,
    addBrandRole,
    removeBrandRole,
  };

  return useResourceRoles;
};

const _addResourceRole = (roleId: ID, resource: Resource, rolesMap: ResourceRoles[]): ResourceRoles[] => {
  let updatedRolesMap = rolesMap.slice();
  //Look for existing resourceRoles object to add role to
  const resourceRoles = rolesMap.find((map) => map.resource === resource);
  if (resourceRoles) {
    //Only append role if it is not in list
    if (!resourceRoles.roles.includes(roleId)) {
      updatedRolesMap.remove(resourceRoles);
      updatedRolesMap.push({...resourceRoles, roles: [...resourceRoles.roles, roleId]});
      // resourceRoles.roles.push(roleId);
    }
  } else {
    updatedRolesMap.push({resource, roles: [roleId]});
  }
  return updatedRolesMap;
};

const _removeResourceRole = (roleId: ID, resource: Resource, rolesMap: ResourceRoles[]): ResourceRoles[] => {
  let updatedRolesMap = rolesMap.slice();
  const resourceRoles = rolesMap.find((map) => map.resource === resource);
  if (resourceRoles) {
    const updatedResourceRoles = {...resourceRoles, roles: resourceRoles.roles.slice().remove(roleId)};
    updatedRolesMap.remove(resourceRoles);
    !updatedResourceRoles.roles.isEmpty() && updatedRolesMap.push(updatedResourceRoles);
  }
  return updatedRolesMap;
};
