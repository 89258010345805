import {ApolloProvider} from '@apollo/client';
import {ModalDialogProvider} from '@telia/styleguide';
import './resources/styles/style.scss';
import React, {FC} from 'react';
import {Route, BrowserRouter, Routes} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {createApolloClient} from './apollo/client';
import {AppFc} from './App';
import {SettlementPrintFc} from './components/settlement/SettlementPrint';
import {FieldShowroomFc} from './components/showroom/FieldShowroom';
import {apolloLinkErrorContextRef, ApolloLinkErrorFc} from './context/ApolloLinkError';
import {ErrorsProvider} from './context/Errors';
import {ModalProvider} from './context/Modal';
import {UserProvider} from './hooks/useUser';
import {getLog} from './log';
import * as AppRoutes from './appRoutes';

const log = getLog('Root', 'INFO');

export const RootFc: FC = (props) => {
  log.debug('render', {apolloLinkErrorContextRef});
  return (
    <ApolloProvider client={createApolloClient(apolloLinkErrorContextRef)}>
      <ErrorsProvider>
        <BrowserRouter>
          <UserProvider>
            {
              // @ts-ignore
              <ModalDialogProvider>
                <ModalProvider>
                  <ApolloLinkErrorFc />
                  <ToastContainer hideProgressBar={true} autoClose={8000} />
                  <Routes>
                    {/* TODO: move to Telia Voca */}
                    <Route path="/fieldshowroom" element={<FieldShowroomFc />} />
                    <Route
                      path={AppRoutes.PRINTABLE_SETTLEMENT__customerId_settlementId}
                      element={<SettlementPrintFc />}
                    />
                    <Route path="*" element={<AppFc />} />
                  </Routes>
                </ModalProvider>
              </ModalDialogProvider>
            }
          </UserProvider>
        </BrowserRouter>
      </ErrorsProvider>
    </ApolloProvider>
  );
};
