import {gql, useQuery} from '@apollo/client';
import {format} from '@telia/cpa-web-common';
import {Button, FullWidthTable} from '@telia/styleguide';
import React, {FC, useState} from 'react';
import JSONPretty from 'react-json-pretty';

import detailedMessageQuery from '../../graphql/query/detailedMessage.graphql';

import './ReportMessageDetails.scss';

import * as AppRoutes from '../../appRoutes';
import {useCustomerName} from '../../hooks/useCustomerName';
import {useProductTypes} from '../../hooks/useProductTypes';
import {useUser} from '../../hooks/useUser';
import {getLog} from '../../log';
import {ReportMessage} from '../../model';
import {mutationInputClean} from '../../mutationClean';
import Loading from '../Loading';
import Error from '../common/Error';
import FormColumn from '../common/FormColumn';
import FormRow from '../common/FormRow';
import {InformationLineFc} from '../common/InformationLine';
import MessageStatusDisplayFc from '../common/MessageStatusDisplay';
import {Tooltip} from '../common/Tooltip';
import {Field, FieldTypes} from '../common/field';

const {THead, Tr, Th, TBody, Td} = FullWidthTable;

const log = getLog('ReportMessageDetails', 'INFO');

interface DetailedMessageQuery {
  detailedMessage: ReportMessage;
}

interface ReportMessageDetailsProps {
  message: Partial<ReportMessage>;
}

export const ReportMessageDetails: FC<ReportMessageDetailsProps> = (props) => {
  const messageId = props.message.messageId;

  const {getName} = useProductTypes();
  const [showJson, setShowJson] = useState(false);
  const {getCustomerName} = useCustomerName();
  const {formatWithBrand} = AppRoutes.useBrandFormat();
  const {isCustomer, user, currentUserBrand} = useUser();

  const {loading, error, data} = useQuery<DetailedMessageQuery>(gql(detailedMessageQuery), {
    skip: !messageId || !currentUserBrand?.id,
    variables: {
      id: messageId,
      isCustomer: isCustomer(),
      customerId: isCustomer() && user ? user.customerId : null, //  might be overwritten by parameters
    },
  });

  const loadingPlaceholder = loading ? '...' : 'unknown';
  const message = {...props.message, ...data?.detailedMessage};

  log.debug('render', {message});

  return (
    <div className={'form--compact'}>
      {loading && Object.keys(message).length <= 1 ? (
        <Loading />
      ) : error ? (
        <Error error={error} />
      ) : !loading && !data?.detailedMessage ? (
        <InformationLineFc>Couldn't get the report detailed message</InformationLineFc>
      ) : (
        <>
          <FormRow>
            <FormColumn>
              <Field
                label="Message ID"
                value={message.messageId}
                linkTo={formatWithBrand(AppRoutes.REPORT_MESSAGE__messageId)}
              />
            </FormColumn>
            <FormColumn>
              <Field label="Submit ID" value={message.submitId} defaultValue={loadingPlaceholder} />
            </FormColumn>
            <FormColumn>
              <Field label="Customer ref." value={message.cref} defaultValue={loadingPlaceholder} />
            </FormColumn>
            <FormColumn>
              <Field
                label="API accept date"
                value={message.apiAcceptTime && format.dateTime(message.apiAcceptTime)}
                defaultValue={loadingPlaceholder}
              />
              <div />
            </FormColumn>
            <FormColumn>
              <Field
                label="Customer ID"
                value={message.customerId && message.customerId + ' - ' + getCustomerName(message.customerId)}
                defaultValue={loadingPlaceholder}
              />
              <Field label="Contract ID" value={message.contractId} defaultValue={loadingPlaceholder} />
            </FormColumn>
            <FormColumn>
              <Field label="Service ID" value={message.productId} defaultValue={loadingPlaceholder} />
              <Field
                label="Service Type"
                value={message.productTypeId && getName(message.productTypeId)}
                defaultValue={loadingPlaceholder}
              />
            </FormColumn>
            <FormColumn>
              <Field label="Access Nr." value={message.accessNumber} defaultValue={loadingPlaceholder} />
              <Field label="Orig. Adr." value={message.oa} defaultValue={loadingPlaceholder} />
            </FormColumn>
            <FormColumn>
              <Field label="Dest. Adr." value={message.da} defaultValue={loadingPlaceholder} />
              <Field label="MNO" value={message.daOperator} defaultValue={loadingPlaceholder} />
            </FormColumn>
            <FormColumn>
              <Field label="Segments" value={message.segmentCountTotal} defaultValue={loadingPlaceholder} />
              <Field label="Message Type" value={message.messageType} defaultValue={loadingPlaceholder} />
            </FormColumn>
            <FormColumn>
              <Field label="Report version" value={message.version} defaultValue={loadingPlaceholder} />
              {message.status ? (
                <Field
                  label="Status"
                  type={FieldTypes.element}
                  value={
                    <MessageStatusDisplayFc status={message.status} details={message.details}></MessageStatusDisplayFc>
                  }
                />
              ) : (
                <Field label="Status" defaultValue={loadingPlaceholder} />
              )}
              {/* <Field label="Service QoS priority" value={message.productQosPriority || 0} /> */}
            </FormColumn>
          </FormRow>
          <FormRow>
            {message.messageType === 'SMS_MT' && (
              <FormColumn>
                <Field
                  label="Submit duration"
                  value={message.submitDurationMs ? format.duration(message.submitDurationMs) : 'Not sent yet'}
                  tip={'Time span between accepting the api request and submitting the sent request to SMSC'}
                />
                <Field
                  label="Deliver duration"
                  value={message.deliverDurationMs ? format.duration(message.deliverDurationMs) : 'Not delivered yet'}
                  tip={'Time span between accepting the api request and receiving delivered callback from SMSC'}
                />
              </FormColumn>
            )}
            <FormColumn>
              <Field
                label="Notify (DR/MO) duration"
                value={message.totalDurationMs ? format.duration(message.totalDurationMs) : 'Not notified yet'}
                tip={'Time span between accepting the api request and notifying the DR/MO endpoint'}
              />
              <div />
            </FormColumn>
          </FormRow>
          {message.messageParts && !message.messageParts.isEmpty() && (
            <>
              <h4>Message Parts</h4>
              <FullWidthTable>
                <THead>
                  <Tr>
                    <Th>Message part ID</Th>
                    <Th>Segments</Th>
                    {/*<Th>Status</Th>*/}
                    {/*<Th>Last update</Th>*/}
                    {/*<Th>Delay</Th>*/}
                  </Tr>
                </THead>
                <TBody>
                  {message.messageParts?.map((messagePart, i) => (
                    <Tr key={i}>
                      <Td className={'noWrap'}>{messagePart.id}</Td>
                      <Td>{messagePart.segmentCount}</Td>
                      {/*<Td className={'noWrap'}>{messagePart.status}</Td>*/}
                      {/*<Td className={'noWrap'}>*/}
                      {/*  {messagePart.lastUpdated ? format.dateTimeMs(messagePart.lastUpdated) : 'never'}*/}
                      {/*</Td>*/}
                      {/*<Td className={'noWrap'}>*/}
                      {/*  {messagePart.lastUpdated &&*/}
                      {/*    format.duration(Date.parse(messagePart.lastUpdated) - Date.parse(message.date))}*/}
                      {/*</Td>*/}
                    </Tr>
                  ))}
                </TBody>
              </FullWidthTable>
            </>
          )}

          <h4>Events</h4>
          <FullWidthTable>
            <THead>
              <Tr>
                <Th>Date</Th>
                <Th>Delay</Th>
                <Th>Type</Th>
                <Th>Status</Th>
                <Th>Details</Th>
              </Tr>
            </THead>
            <TBody>
              {message.events?.map((event, i) => (
                <Tr key={i}>
                  <Td className={'noWrap'}>{format.dateTimeMs(event.time)}</Td>
                  <Td className={'noWrap'}>
                    {message.apiAcceptTime
                      ? format.duration(Date.parse(event.time) - Date.parse(message.apiAcceptTime))
                      : loadingPlaceholder}
                  </Td>
                  <Td className={'noWrap'}>{event.type}</Td>
                  <Td className={'noWrap'}>
                    <MessageStatusDisplayFc status={event.status}></MessageStatusDisplayFc>
                  </Td>
                  <Td className={'noWrap'}>
                    {event.details && (
                      <Tooltip text={`Details: ${event.details}`} buttonContent={'d'} className={`tooltip--S`} />
                    )}
                  </Td>
                </Tr>
              ))}
            </TBody>
          </FullWidthTable>

          <Button
            onClick={() => setShowJson(!showJson)}
            text={showJson ? 'Hide JSON' : 'Show JSON'}
            className={'marginTop marginRight'}
          />
          {showJson && <JSONPretty data={mutationInputClean(message)} />}
        </>
      )}
    </div>
  );
};
