"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MfaConfig = exports.resolutions = exports.ResolutionId = exports.TrafficFeeType = exports.hasMessage = exports.hasName = exports.TRANSCEIVER = exports.RECEIVER = exports.TRANSMITTER = exports.DefaultCharset = exports.endpointProtocolOptions = exports.EndpointProtocol = exports.hasFeeModels = exports.UserAccessStatusType = exports.allCountries = exports.SE = exports.NO = exports.LT = exports.FI = exports.EE = exports.DK = exports.TELIA_NO = exports.UserType = exports.isWithId = exports.isID = void 0;
function isID(id) {
    return !!id && typeof id === 'string';
}
exports.isID = isID;
function isWithId(a) {
    return !!a && typeof a === 'object' && isID(a.id);
}
exports.isWithId = isWithId;
var UserType;
(function (UserType) {
    UserType["TELIA"] = "TELIA";
    UserType["CUSTOMER"] = "CUSTOMER";
})(UserType = exports.UserType || (exports.UserType = {}));
exports.TELIA_NO = 'TELIA_NO';
exports.DK = { id: 'DK', countryCode: '45', name: 'Denmark' };
exports.EE = { id: 'EE', countryCode: '372', name: 'Estonia' };
exports.FI = { id: 'FI', countryCode: '358', name: 'Finland' };
exports.LT = { id: 'LT', countryCode: '370', name: 'Lithuania' };
exports.NO = { id: 'NO', countryCode: '47', name: 'Norway' };
exports.SE = { id: 'SE', countryCode: '46', name: 'Sweden' };
exports.allCountries = {
    DK: exports.DK,
    EE: exports.EE,
    FI: exports.FI,
    LT: exports.LT,
    NO: exports.NO,
    SE: exports.SE,
};
var UserAccessStatusType;
(function (UserAccessStatusType) {
    UserAccessStatusType["APPLYING"] = "APPLYING";
    UserAccessStatusType["PENDING"] = "PENDING";
    UserAccessStatusType["GRANTED"] = "GRANTED";
    UserAccessStatusType["REJECTED"] = "REJECTED";
})(UserAccessStatusType = exports.UserAccessStatusType || (exports.UserAccessStatusType = {}));
function hasFeeModels(obj) {
    return !!(obj === null || obj === void 0 ? void 0 : obj.feeModels);
}
exports.hasFeeModels = hasFeeModels;
var EndpointProtocol;
(function (EndpointProtocol) {
    EndpointProtocol["SOAP"] = "SOAP";
    EndpointProtocol["REST"] = "REST";
    EndpointProtocol["REST_V2"] = "REST_V2";
    EndpointProtocol["SMPP"] = "SMPP";
})(EndpointProtocol = exports.EndpointProtocol || (exports.EndpointProtocol = {}));
exports.endpointProtocolOptions = [
    { id: EndpointProtocol.SOAP, name: 'SOAP' },
    { id: EndpointProtocol.REST, name: 'REST' },
    { id: EndpointProtocol.REST_V2, name: 'REST v2' },
    { id: EndpointProtocol.SMPP, name: 'SMPP' },
];
var DefaultCharset;
(function (DefaultCharset) {
    DefaultCharset["GSM8"] = "GSM8";
    DefaultCharset["GSM7"] = "GSM7";
    DefaultCharset["ISO_8859_1"] = "ISO_8859_1";
    DefaultCharset["ISO_8859_15"] = "ISO_8859_15";
})(DefaultCharset = exports.DefaultCharset || (exports.DefaultCharset = {}));
exports.TRANSMITTER = 'TRANSMITTER';
exports.RECEIVER = 'RECEIVER';
exports.TRANSCEIVER = 'TRANSCEIVER';
function hasName(obj) {
    return !!obj.name;
}
exports.hasName = hasName;
function hasMessage(obj) {
    return !!obj.message;
}
exports.hasMessage = hasMessage;
var TrafficFeeType;
(function (TrafficFeeType) {
    TrafficFeeType["HOME"] = "HOME";
    TrafficFeeType["DOMESTIC"] = "DOMESTIC";
    TrafficFeeType["FOREIGN"] = "FOREIGN";
})(TrafficFeeType = exports.TrafficFeeType || (exports.TrafficFeeType = {}));
var ResolutionId;
(function (ResolutionId) {
    ResolutionId["Delivered"] = "DELIVERED";
    ResolutionId["Expired"] = "EXPIRED";
    ResolutionId["Rejected"] = "REJECTED";
})(ResolutionId = exports.ResolutionId || (exports.ResolutionId = {}));
exports.resolutions = [
    { id: ResolutionId.Delivered, name: 'Delivered' },
    { id: ResolutionId.Rejected, name: 'Rejected' },
    { id: ResolutionId.Expired, name: 'Expired' },
];
var MfaConfig;
(function (MfaConfig) {
    MfaConfig["ON"] = "ON";
    MfaConfig["OPTIONAL"] = "OPTIONAL";
    MfaConfig["OFF"] = "OFF";
})(MfaConfig = exports.MfaConfig || (exports.MfaConfig = {}));
