"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.nextMonthMm = exports.isValidDuration = exports.dateOfLastDayOfPreviousMonth = exports.settlementPeriodFromHuman = exports.settlementPeriod = exports.settlementPeriodMonth = exports.settlementPeriodYear = exports.monthMm = exports.yearsFrom = exports.years = exports.monthOptionValues = exports.monthNames = void 0;
require("./utils");
const dateformat_1 = require("dateformat");
exports.monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];
exports.monthOptionValues = exports.monthNames.map((monthName, i) => ({ id: `${i + 1}`.padStart(2, '0'), name: monthName }));
exports.years = Array.range(2018, new Date().getFullYear());
const yearsFrom = (startYear) => Array.range(startYear, new Date().getFullYear());
exports.yearsFrom = yearsFrom;
const monthMm = (month) => {
    let monthNumber;
    if (typeof month === 'number') {
        monthNumber = month;
    }
    else if (month instanceof Date) {
        monthNumber = month.getMonth() + 1;
    }
    else {
        monthNumber = parseInt(month);
        if (isNaN(monthNumber)) {
            monthNumber = (dateformat_1.i18n.monthNames.indexOf(month) % 12) + 1;
        }
    }
    return monthNumber.toString().padStart(2, '0');
};
exports.monthMm = monthMm;
const settlementPeriodYear = (yyyyMm) => yyyyMm.toString().substring(0, 4);
exports.settlementPeriodYear = settlementPeriodYear;
const settlementPeriodMonth = (yyyyMm) => yyyyMm.toString().substring(4, 6);
exports.settlementPeriodMonth = settlementPeriodMonth;
const settlementPeriod = (year, month) => `${year}${(0, exports.monthMm)(month)}`;
exports.settlementPeriod = settlementPeriod;
const settlementPeriodFromHuman = (humanMonthYear) => {
    const [monthName, year] = humanMonthYear.split(' ');
    const mm = monthName !== undefined ? (0, exports.monthMm)(monthName) : '00';
    const yyyy = year !== undefined ? year : '0000';
    return (0, exports.settlementPeriod)(yyyy, mm);
};
exports.settlementPeriodFromHuman = settlementPeriodFromHuman;
const dateOfLastDayOfPreviousMonth = () => {
    const date = new Date(); //  today
    date.setDate(0); //  last day of previous month;
    return date;
};
exports.dateOfLastDayOfPreviousMonth = dateOfLastDayOfPreviousMonth;
const iso8601DurationRegex = /^(-?)P(?=\d|T\d)(?:(\d+)Y)?(?:(\d+)M)?(?:(\d+)([DW]))?(?:T(?:(\d+)H)?(?:(\d+)M)?(?:(\d+(?:[.,]\d+)?)S)?)?$/;
const isValidDuration = (duration) => !!duration.match(iso8601DurationRegex);
exports.isValidDuration = isValidDuration;
const nextMonthMm = (mm) => {
    const monthIndex = parseInt(mm.toString());
    const nextMonthIndex = monthIndex === 12 ? 1 : monthIndex + 1;
    return nextMonthIndex.toString().padStart(2, '0');
};
exports.nextMonthMm = nextMonthMm;
