import {useState} from 'react';

import {getLog} from '../log';
import {ID, WithId, isID} from '../model';

const log = getLog('useSelectedIds', 'INFO');

export const useSelectedIds = <T = object>(initialSelectedIds: ID[]) => {
  const [selectedIds, setSelectedIds] = useState<ID[]>(initialSelectedIds);

  const getId: (id: ID | WithId<T>) => ID = (id) => (isID(id) ? id : id.id);

  const onSelectId = (idx: ID | WithId<T>) => (value: boolean) => {
    const id = getId(idx);
    log.debug('onSelectId', id, value);
    setSelectedIds((selected) => (value ? selected.cloneIncluding(id) : selected.cloneExcluding(id)));
  };

  const onSelectAll = (objectsWithId: WithId<T>[]) => (value: boolean) => {
    log.debug('onSelectAll', value);
    setSelectedIds((_) => (!value || !objectsWithId ? [] : objectsWithId.map(({id}) => id)));
  };

  const isSelected = (idx: ID | WithId<T>) => selectedIds.includes(getId(idx));

  const areAllSelected = (objectsWithId: WithId<T>[]) =>
    objectsWithId &&
    objectsWithId.length > 0 &&
    selectedIds &&
    selectedIds.length > 0 &&
    objectsWithId.length == selectedIds.length;

  const onToggleSelected = (idx: ID | WithId<T>) => {
    log.debug('onToggleSelected', {idx, currentlySelected: isSelected(idx)});
    onSelectId(idx)(!isSelected(idx));
  };

  return {selectedIds, onSelectId, onToggleSelected, onSelectAll, isSelected, areAllSelected};
};
