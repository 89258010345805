"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const settlementTotals = [
    {
        period: '202201',
        isComplete: true,
        isApproved: true,
        products: [
            {
                productId: 'ALL',
                EXTERNAL: 356108,
                INTERNAL: 116009,
            },
            {
                productId: 'RESERVED_OA_TELIA_SE',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RBM_TELIA_AT_SE',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RBM_TELIA_AT',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'POSITIONING',
                EXTERNAL: 40000,
                INTERNAL: 0,
            },
            {
                productId: 'RESERVED_OA_AT_TELIA_AT_NO',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RBM_TELIA_NO',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'SMS_EVENT_TELIA_NO',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RESERVED_OA',
                EXTERNAL: 65000,
                INTERNAL: 0,
            },
            {
                productId: 'MO_DIRECT_CHARGE_RANGE',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RBM_TELIA_SE',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'MO_LONG_NUMBER',
                EXTERNAL: 1497,
                INTERNAL: 10998,
            },
            {
                productId: 'MO_LONG_NUMBER_RANGE',
                EXTERNAL: 1500,
                INTERNAL: 0,
            },
            {
                productId: 'SMS_BULK',
                EXTERNAL: 248111,
                INTERNAL: 105011,
            },
            {
                productId: 'SMS_BULK_TELIA_AT_NO',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
        ],
    },
    {
        period: '202101',
        isComplete: true,
        isApproved: true,
        products: [
            {
                productId: 'ALL',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RESERVED_OA_TELIA_SE',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RBM_TELIA_AT_SE',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RBM_TELIA_AT',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'POSITIONING',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RESERVED_OA_AT_TELIA_AT_NO',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RBM_TELIA_NO',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'SMS_EVENT_TELIA_NO',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RESERVED_OA',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'MO_DIRECT_CHARGE_RANGE',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RBM_TELIA_SE',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'MO_LONG_NUMBER',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'MO_LONG_NUMBER_RANGE',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'SMS_BULK',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'SMS_BULK_TELIA_AT_NO',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
        ],
    },
    {
        period: '202202',
        isComplete: true,
        isApproved: true,
        products: [
            {
                productId: 'ALL',
                EXTERNAL: 331149,
                INTERNAL: 136019.5,
            },
            {
                productId: 'RESERVED_OA_TELIA_SE',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RBM_TELIA_AT_SE',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RBM_TELIA_AT',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'POSITIONING',
                EXTERNAL: 40000,
                INTERNAL: 0,
            },
            {
                productId: 'RESERVED_OA_AT_TELIA_AT_NO',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RBM_TELIA_NO',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'SMS_EVENT_TELIA_NO',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RESERVED_OA',
                EXTERNAL: 40000,
                INTERNAL: 0,
            },
            {
                productId: 'MO_DIRECT_CHARGE_RANGE',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RBM_TELIA_SE',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'MO_LONG_NUMBER',
                EXTERNAL: 1497,
                INTERNAL: 10998,
            },
            {
                productId: 'MO_LONG_NUMBER_RANGE',
                EXTERNAL: 1500,
                INTERNAL: 0,
            },
            {
                productId: 'SMS_BULK',
                EXTERNAL: 248111,
                INTERNAL: 125021.5,
            },
            {
                productId: 'SMS_BULK_TELIA_AT_NO',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
        ],
    },
    {
        period: '202102',
        isComplete: true,
        isApproved: true,
        products: [
            {
                productId: 'ALL',
                EXTERNAL: 250997,
                INTERNAL: 120794.6,
            },
            {
                productId: 'RESERVED_OA_TELIA_SE',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RBM_TELIA_AT_SE',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RBM_TELIA_AT',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'POSITIONING',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RESERVED_OA_AT_TELIA_AT_NO',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RBM_TELIA_NO',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'SMS_EVENT_TELIA_NO',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RESERVED_OA',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'MO_DIRECT_CHARGE_RANGE',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RBM_TELIA_SE',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'MO_LONG_NUMBER',
                EXTERNAL: 1497,
                INTERNAL: 10998,
            },
            {
                productId: 'MO_LONG_NUMBER_RANGE',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'SMS_BULK',
                EXTERNAL: 249500,
                INTERNAL: 109796.6,
            },
            {
                productId: 'SMS_BULK_TELIA_AT_NO',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
        ],
    },
    {
        period: '202203',
        isComplete: true,
        isApproved: true,
        products: [
            {
                productId: 'ALL',
                EXTERNAL: 331108,
                INTERNAL: 128105.07999999999,
            },
            {
                productId: 'RESERVED_OA_TELIA_SE',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RBM_TELIA_AT_SE',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RBM_TELIA_AT',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'POSITIONING',
                EXTERNAL: 40000,
                INTERNAL: 0,
            },
            {
                productId: 'RESERVED_OA_AT_TELIA_AT_NO',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RBM_TELIA_NO',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'SMS_EVENT_TELIA_NO',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RESERVED_OA',
                EXTERNAL: 40000,
                INTERNAL: 0,
            },
            {
                productId: 'MO_DIRECT_CHARGE_RANGE',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RBM_TELIA_SE',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'MO_LONG_NUMBER',
                EXTERNAL: 1497,
                INTERNAL: 10998,
            },
            {
                productId: 'MO_LONG_NUMBER_RANGE',
                EXTERNAL: 1500,
                INTERNAL: 0,
            },
            {
                productId: 'SMS_BULK',
                EXTERNAL: 248111,
                INTERNAL: 117107.07999999999,
            },
            {
                productId: 'SMS_BULK_TELIA_AT_NO',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
        ],
    },
    {
        period: '202103',
        isComplete: true,
        isApproved: true,
        products: [
            {
                productId: 'ALL',
                EXTERNAL: 250997,
                INTERNAL: 495317.6,
            },
            {
                productId: 'RESERVED_OA_TELIA_SE',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RBM_TELIA_AT_SE',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RBM_TELIA_AT',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'POSITIONING',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RESERVED_OA_AT_TELIA_AT_NO',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RBM_TELIA_NO',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'SMS_EVENT_TELIA_NO',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RESERVED_OA',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'MO_DIRECT_CHARGE_RANGE',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RBM_TELIA_SE',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'MO_LONG_NUMBER',
                EXTERNAL: 1497,
                INTERNAL: 10998,
            },
            {
                productId: 'MO_LONG_NUMBER_RANGE',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'SMS_BULK',
                EXTERNAL: 249500,
                INTERNAL: 484319.6,
            },
            {
                productId: 'SMS_BULK_TELIA_AT_NO',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
        ],
    },
    {
        period: '202204',
        isComplete: true,
        isApproved: true,
        products: [
            {
                productId: 'ALL',
                EXTERNAL: 331108,
                INTERNAL: 1073106.1600000001,
            },
            {
                productId: 'RESERVED_OA_TELIA_SE',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RBM_TELIA_AT_SE',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RBM_TELIA_AT',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'POSITIONING',
                EXTERNAL: 40000,
                INTERNAL: 0,
            },
            {
                productId: 'RESERVED_OA_AT_TELIA_AT_NO',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RBM_TELIA_NO',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'SMS_EVENT_TELIA_NO',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RESERVED_OA',
                EXTERNAL: 40000,
                INTERNAL: 0,
            },
            {
                productId: 'MO_DIRECT_CHARGE_RANGE',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RBM_TELIA_SE',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'MO_LONG_NUMBER',
                EXTERNAL: 1497,
                INTERNAL: 10998,
            },
            {
                productId: 'MO_LONG_NUMBER_RANGE',
                EXTERNAL: 1500,
                INTERNAL: 0,
            },
            {
                productId: 'SMS_BULK',
                EXTERNAL: 248111,
                INTERNAL: 1062108.1600000001,
            },
            {
                productId: 'SMS_BULK_TELIA_AT_NO',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
        ],
    },
    {
        period: '202104',
        isComplete: true,
        isApproved: true,
        products: [
            {
                productId: 'ALL',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RESERVED_OA_TELIA_SE',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RBM_TELIA_AT_SE',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RBM_TELIA_AT',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'POSITIONING',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RESERVED_OA_AT_TELIA_AT_NO',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RBM_TELIA_NO',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'SMS_EVENT_TELIA_NO',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RESERVED_OA',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'MO_DIRECT_CHARGE_RANGE',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RBM_TELIA_SE',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'MO_LONG_NUMBER',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'MO_LONG_NUMBER_RANGE',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'SMS_BULK',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'SMS_BULK_TELIA_AT_NO',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
        ],
    },
    {
        period: '202205',
        isComplete: true,
        isApproved: true,
        products: [
            {
                productId: 'ALL',
                EXTERNAL: 330316,
                INTERNAL: 155404.96,
            },
            {
                productId: 'RESERVED_OA_TELIA_SE',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RBM_TELIA_AT_SE',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RBM_TELIA_AT',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'POSITIONING',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RESERVED_OA_AT_TELIA_AT_NO',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RBM_TELIA_NO',
                EXTERNAL: 0,
                INTERNAL: 52037.020000000004,
            },
            {
                productId: 'SMS_EVENT_TELIA_NO',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RESERVED_OA',
                EXTERNAL: 5000,
                INTERNAL: 12500,
            },
            {
                productId: 'MO_DIRECT_CHARGE_RANGE',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RBM_TELIA_SE',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'MO_LONG_NUMBER',
                EXTERNAL: 0,
                INTERNAL: 10998,
            },
            {
                productId: 'MO_LONG_NUMBER_RANGE',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'SMS_BULK',
                EXTERNAL: 211000,
                INTERNAL: 76214.94,
            },
            {
                productId: 'SMS_BULK_TELIA_AT_NO',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
        ],
    },
    {
        period: '202105',
        isComplete: true,
        isApproved: true,
        products: [
            {
                productId: 'ALL',
                EXTERNAL: 299608,
                INTERNAL: 113498,
            },
            {
                productId: 'RESERVED_OA_TELIA_SE',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RBM_TELIA_AT_SE',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RBM_TELIA_AT',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'POSITIONING',
                EXTERNAL: 45000,
                INTERNAL: 0,
            },
            {
                productId: 'RESERVED_OA_AT_TELIA_AT_NO',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RBM_TELIA_NO',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'SMS_EVENT_TELIA_NO',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RESERVED_OA',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'MO_DIRECT_CHARGE_RANGE',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RBM_TELIA_SE',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'MO_LONG_NUMBER',
                EXTERNAL: 1497,
                INTERNAL: 10998,
            },
            {
                productId: 'MO_LONG_NUMBER_RANGE',
                EXTERNAL: 2500,
                INTERNAL: 0,
            },
            {
                productId: 'SMS_BULK',
                EXTERNAL: 249500,
                INTERNAL: 102500,
            },
            {
                productId: 'SMS_BULK_TELIA_AT_NO',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
        ],
    },
    {
        period: '202206',
        isComplete: true,
        isApproved: true,
        products: [
            {
                productId: 'ALL',
                EXTERNAL: 226000,
                INTERNAL: 182620.74,
            },
            {
                productId: 'RESERVED_OA_TELIA_SE',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RBM_TELIA_AT_SE',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RBM_TELIA_AT',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'POSITIONING',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RESERVED_OA_AT_TELIA_AT_NO',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RBM_TELIA_NO',
                EXTERNAL: 10000,
                INTERNAL: 32500,
            },
            {
                productId: 'SMS_EVENT_TELIA_NO',
                EXTERNAL: 0,
                INTERNAL: 51000,
            },
            {
                productId: 'RESERVED_OA',
                EXTERNAL: 5000,
                INTERNAL: 2500,
            },
            {
                productId: 'MO_DIRECT_CHARGE_RANGE',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RBM_TELIA_SE',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'MO_LONG_NUMBER',
                EXTERNAL: 0,
                INTERNAL: 10998,
            },
            {
                productId: 'MO_LONG_NUMBER_RANGE',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'SMS_BULK',
                EXTERNAL: 211000,
                INTERNAL: 85622.74,
            },
            {
                productId: 'SMS_BULK_TELIA_AT_NO',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
        ],
    },
    {
        period: '202106',
        isComplete: true,
        isApproved: true,
        products: [
            {
                productId: 'ALL',
                EXTERNAL: 269997,
                INTERNAL: 557200.4,
            },
            {
                productId: 'RESERVED_OA_TELIA_SE',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RBM_TELIA_AT_SE',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RBM_TELIA_AT',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'POSITIONING',
                EXTERNAL: 20000,
                INTERNAL: 0,
            },
            {
                productId: 'RESERVED_OA_AT_TELIA_AT_NO',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RBM_TELIA_NO',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'SMS_EVENT_TELIA_NO',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RESERVED_OA',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'MO_DIRECT_CHARGE_RANGE',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RBM_TELIA_SE',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'MO_LONG_NUMBER',
                EXTERNAL: 1497,
                INTERNAL: 10998,
            },
            {
                productId: 'MO_LONG_NUMBER_RANGE',
                EXTERNAL: 1500,
                INTERNAL: 0,
            },
            {
                productId: 'SMS_BULK',
                EXTERNAL: 247000,
                INTERNAL: 546202.4,
            },
            {
                productId: 'SMS_BULK_TELIA_AT_NO',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
        ],
    },
    {
        period: '202207',
        isComplete: true,
        isApproved: true,
        products: [
            {
                productId: 'ALL',
                EXTERNAL: 226500,
                INTERNAL: 153724.65,
            },
            {
                productId: 'RESERVED_OA_TELIA_SE',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RBM_TELIA_AT_SE',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RBM_TELIA_AT',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'POSITIONING',
                EXTERNAL: 0,
                INTERNAL: 45000,
            },
            {
                productId: 'RESERVED_OA_AT_TELIA_AT_NO',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RBM_TELIA_NO',
                EXTERNAL: 10000,
                INTERNAL: 15000,
            },
            {
                productId: 'SMS_EVENT_TELIA_NO',
                EXTERNAL: 1000,
                INTERNAL: 1000,
            },
            {
                productId: 'RESERVED_OA',
                EXTERNAL: 5000,
                INTERNAL: 2500,
            },
            {
                productId: 'MO_DIRECT_CHARGE_RANGE',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RBM_TELIA_SE',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'MO_LONG_NUMBER',
                EXTERNAL: 0,
                INTERNAL: 10998,
            },
            {
                productId: 'MO_LONG_NUMBER_RANGE',
                EXTERNAL: 0,
                INTERNAL: 1500,
            },
            {
                productId: 'SMS_BULK',
                EXTERNAL: 208500,
                INTERNAL: 77726.65000000001,
            },
            {
                productId: 'SMS_BULK_TELIA_AT_NO',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
        ],
    },
    {
        period: '202107',
        isComplete: true,
        isApproved: true,
        products: [
            {
                productId: 'ALL',
                EXTERNAL: 271108,
                INTERNAL: 123500.4,
            },
            {
                productId: 'RESERVED_OA_TELIA_SE',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RBM_TELIA_AT_SE',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RBM_TELIA_AT',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'POSITIONING',
                EXTERNAL: 20000,
                INTERNAL: 0,
            },
            {
                productId: 'RESERVED_OA_AT_TELIA_AT_NO',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RBM_TELIA_NO',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'SMS_EVENT_TELIA_NO',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RESERVED_OA',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'MO_DIRECT_CHARGE_RANGE',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RBM_TELIA_SE',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'MO_LONG_NUMBER',
                EXTERNAL: 1497,
                INTERNAL: 10998,
            },
            {
                productId: 'MO_LONG_NUMBER_RANGE',
                EXTERNAL: 1500,
                INTERNAL: 0,
            },
            {
                productId: 'SMS_BULK',
                EXTERNAL: 247000,
                INTERNAL: 112502.4,
            },
            {
                productId: 'SMS_BULK_TELIA_AT_NO',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
        ],
    },
    {
        period: '202208',
        isComplete: true,
        isApproved: true,
        products: [
            {
                productId: 'ALL',
                EXTERNAL: 294037.02,
                INTERNAL: 127502.74,
            },
            {
                productId: 'RESERVED_OA_TELIA_SE',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RBM_TELIA_AT_SE',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RBM_TELIA_AT',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'POSITIONING',
                EXTERNAL: 0,
                INTERNAL: 20000,
            },
            {
                productId: 'RESERVED_OA_AT_TELIA_AT_NO',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RBM_TELIA_NO',
                EXTERNAL: 24537.02,
                INTERNAL: 15000,
            },
            {
                productId: 'SMS_EVENT_TELIA_NO',
                EXTERNAL: 1000,
                INTERNAL: 1000,
            },
            {
                productId: 'RESERVED_OA',
                EXTERNAL: 35000,
                INTERNAL: 2500,
            },
            {
                productId: 'MO_DIRECT_CHARGE_RANGE',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RBM_TELIA_SE',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'MO_LONG_NUMBER',
                EXTERNAL: 0,
                INTERNAL: 10998,
            },
            {
                productId: 'MO_LONG_NUMBER_RANGE',
                EXTERNAL: 0,
                INTERNAL: 500,
            },
            {
                productId: 'SMS_BULK',
                EXTERNAL: 233500,
                INTERNAL: 77504.74,
            },
            {
                productId: 'SMS_BULK_TELIA_AT_NO',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
        ],
    },
    {
        period: '202108',
        isComplete: true,
        isApproved: true,
        products: [
            {
                productId: 'ALL',
                EXTERNAL: 399715,
                INTERNAL: 116124.82,
            },
            {
                productId: 'RESERVED_OA_TELIA_SE',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RBM_TELIA_AT_SE',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RBM_TELIA_AT',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'POSITIONING',
                EXTERNAL: 110000,
                INTERNAL: 0,
            },
            {
                productId: 'RESERVED_OA_AT_TELIA_AT_NO',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RBM_TELIA_NO',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'SMS_EVENT_TELIA_NO',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RESERVED_OA',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'MO_DIRECT_CHARGE_RANGE',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RBM_TELIA_SE',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'MO_LONG_NUMBER',
                EXTERNAL: 1497,
                INTERNAL: 10998,
            },
            {
                productId: 'MO_LONG_NUMBER_RANGE',
                EXTERNAL: 1500,
                INTERNAL: 0,
            },
            {
                productId: 'SMS_BULK',
                EXTERNAL: 285611,
                INTERNAL: 40005.82,
            },
            {
                productId: 'SMS_BULK_TELIA_AT_NO',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
        ],
    },
    {
        period: '202209',
        isComplete: true,
        isApproved: true,
        products: [
            {
                productId: 'ALL',
                EXTERNAL: 302000,
                INTERNAL: 157554.08,
            },
            {
                productId: 'RESERVED_OA_TELIA_SE',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RBM_TELIA_AT_SE',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RBM_TELIA_AT',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'POSITIONING',
                EXTERNAL: 0,
                INTERNAL: 20000,
            },
            {
                productId: 'RESERVED_OA_AT_TELIA_AT_NO',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RBM_TELIA_NO',
                EXTERNAL: 10000,
                INTERNAL: 7500,
            },
            {
                productId: 'SMS_EVENT_TELIA_NO',
                EXTERNAL: 1000,
                INTERNAL: 1000,
            },
            {
                productId: 'RESERVED_OA',
                EXTERNAL: 25000,
                INTERNAL: 40000,
            },
            {
                productId: 'MO_DIRECT_CHARGE_RANGE',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RBM_TELIA_SE',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'MO_LONG_NUMBER',
                EXTERNAL: 0,
                INTERNAL: 10998,
            },
            {
                productId: 'MO_LONG_NUMBER_RANGE',
                EXTERNAL: 0,
                INTERNAL: 500,
            },
            {
                productId: 'SMS_BULK',
                EXTERNAL: 266000,
                INTERNAL: 77556.08,
            },
            {
                productId: 'SMS_BULK_TELIA_AT_NO',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
        ],
    },
    {
        period: '202109',
        isComplete: true,
        isApproved: true,
        products: [
            {
                productId: 'ALL',
                EXTERNAL: 298608,
                INTERNAL: 116000.4,
            },
            {
                productId: 'RESERVED_OA_TELIA_SE',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RBM_TELIA_AT_SE',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RBM_TELIA_AT',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'POSITIONING',
                EXTERNAL: 40000,
                INTERNAL: 0,
            },
            {
                productId: 'RESERVED_OA_AT_TELIA_AT_NO',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RBM_TELIA_NO',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'SMS_EVENT_TELIA_NO',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RESERVED_OA',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'MO_DIRECT_CHARGE_RANGE',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RBM_TELIA_SE',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'MO_LONG_NUMBER',
                EXTERNAL: 1497,
                INTERNAL: 10998,
            },
            {
                productId: 'MO_LONG_NUMBER_RANGE',
                EXTERNAL: 1500,
                INTERNAL: 0,
            },
            {
                productId: 'SMS_BULK',
                EXTERNAL: 255611,
                INTERNAL: 105002.4,
            },
            {
                productId: 'SMS_BULK_TELIA_AT_NO',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
        ],
    },
    {
        period: '202210',
        isComplete: true,
        isApproved: true,
        products: [
            {
                productId: 'ALL',
                EXTERNAL: 264498,
                INTERNAL: 125030.04000000001,
            },
            {
                productId: 'RESERVED_OA_TELIA_SE',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RBM_TELIA_AT_SE',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RBM_TELIA_AT',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'POSITIONING',
                EXTERNAL: 0,
                INTERNAL: 20000,
            },
            {
                productId: 'RESERVED_OA_AT_TELIA_AT_NO',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RBM_TELIA_NO',
                EXTERNAL: 7500,
                INTERNAL: 7500,
            },
            {
                productId: 'SMS_EVENT_TELIA_NO',
                EXTERNAL: 1000,
                INTERNAL: 1000,
            },
            {
                productId: 'RESERVED_OA',
                EXTERNAL: 30000,
                INTERNAL: 7500,
            },
            {
                productId: 'MO_DIRECT_CHARGE_RANGE',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RBM_TELIA_SE',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'MO_LONG_NUMBER',
                EXTERNAL: 2498,
                INTERNAL: 10998,
            },
            {
                productId: 'MO_LONG_NUMBER_RANGE',
                EXTERNAL: 0,
                INTERNAL: 500,
            },
            {
                productId: 'SMS_BULK',
                EXTERNAL: 223500,
                INTERNAL: 77532.04000000001,
            },
            {
                productId: 'SMS_BULK_TELIA_AT_NO',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
        ],
    },
    {
        period: '202110',
        isComplete: true,
        isApproved: true,
        products: [
            {
                productId: 'ALL',
                EXTERNAL: 291108,
                INTERNAL: 130281.09,
            },
            {
                productId: 'RESERVED_OA_TELIA_SE',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RBM_TELIA_AT_SE',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RBM_TELIA_AT',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'POSITIONING',
                EXTERNAL: 40000,
                INTERNAL: 0,
            },
            {
                productId: 'RESERVED_OA_AT_TELIA_AT_NO',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RBM_TELIA_NO',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'SMS_EVENT_TELIA_NO',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RESERVED_OA',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'MO_DIRECT_CHARGE_RANGE',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RBM_TELIA_SE',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'MO_LONG_NUMBER',
                EXTERNAL: 1497,
                INTERNAL: 10998,
            },
            {
                productId: 'MO_LONG_NUMBER_RANGE',
                EXTERNAL: 1500,
                INTERNAL: 0,
            },
            {
                productId: 'SMS_BULK',
                EXTERNAL: 248111,
                INTERNAL: 119283.09,
            },
            {
                productId: 'SMS_BULK_TELIA_AT_NO',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
        ],
    },
    {
        period: '202211',
        isComplete: true,
        isApproved: true,
        products: [],
    },
    {
        period: '202111',
        isComplete: true,
        isApproved: true,
        products: [
            {
                productId: 'ALL',
                EXTERNAL: 291108,
                INTERNAL: 116033.64,
            },
            {
                productId: 'RESERVED_OA_TELIA_SE',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RBM_TELIA_AT_SE',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RBM_TELIA_AT',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'POSITIONING',
                EXTERNAL: 40000,
                INTERNAL: 0,
            },
            {
                productId: 'RESERVED_OA_AT_TELIA_AT_NO',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RBM_TELIA_NO',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'SMS_EVENT_TELIA_NO',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RESERVED_OA',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'MO_DIRECT_CHARGE_RANGE',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RBM_TELIA_SE',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'MO_LONG_NUMBER',
                EXTERNAL: 1497,
                INTERNAL: 10998,
            },
            {
                productId: 'MO_LONG_NUMBER_RANGE',
                EXTERNAL: 1500,
                INTERNAL: 0,
            },
            {
                productId: 'SMS_BULK',
                EXTERNAL: 248111,
                INTERNAL: 105035.64,
            },
            {
                productId: 'SMS_BULK_TELIA_AT_NO',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
        ],
    },
    {
        period: '202212',
        isComplete: false,
        isApproved: false,
        products: [],
    },
    {
        period: '202112',
        isComplete: true,
        isApproved: true,
        products: [
            {
                productId: 'ALL',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RESERVED_OA_TELIA_SE',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RBM_TELIA_AT_SE',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RBM_TELIA_AT',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'POSITIONING',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RESERVED_OA_AT_TELIA_AT_NO',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RBM_TELIA_NO',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'SMS_EVENT_TELIA_NO',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RESERVED_OA',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'MO_DIRECT_CHARGE_RANGE',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'RBM_TELIA_SE',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'MO_LONG_NUMBER',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'MO_LONG_NUMBER_RANGE',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'SMS_BULK',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
            {
                productId: 'SMS_BULK_TELIA_AT_NO',
                EXTERNAL: 0,
                INTERNAL: 0,
            },
        ],
    },
];
exports.default = settlementTotals;
