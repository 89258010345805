import {gql, useQuery} from '@apollo/client';
import classnames from 'classnames';
import React, {FC} from 'react';
import {useParams} from 'react-router-dom';

import settlementsQuery from '../../graphql/query/settlements.graphql';

import {domId} from '../../dom';
import {useCustomerOverviews} from '../../hooks/useCustomerOverviews';
import {getLog} from '../../log';
import {ID, PropsWithClassName, Settlement} from '../../model';
import Loading from '../Loading';
import AccordionItem from '../common/AccordionItem';
import {AccordionListFc} from '../common/AccordionList';
import {InformationLineFc} from '../common/InformationLine';
import PageSubtitle from '../common/PageSubtitle';
import {PageViewCounter} from '../metrics/PageViewCounter';
import {SettlementFc} from '../settlement/common/Settlement';
import {SettlementDateTotalLineFc} from '../settlement/common/accordionHeader/SettlementDateTotalLine';

const log = getLog('CustomerSettlementApprovedList', 'INFO');

interface SettlementsQuery {
  settlements: Settlement[];
}

export const CustomerSettlementListFc: FC<PropsWithClassName> = (props) => {
  const {customerId} = useParams<{customerId: ID}>() as {customerId: ID};
  const {getCustomerOverview} = useCustomerOverviews();
  const customerOverview = getCustomerOverview(customerId);
  const {loading, data: {settlements} = {}} = useQuery<SettlementsQuery>(gql(settlementsQuery), {
    skip: !customerOverview,
    variables: {
      customerId,
    },
  });

  const sortedSettlements = settlements?.slice().sort((a, o) => {
    return o.yearMonth.localeCompare(a.yearMonth);
  });
  return (
    <div className={classnames('CustomerSettlementApprovedList', props.className)}>
      <PageSubtitle subtitle="Settlements" />
      <PageViewCounter page="settlements" />

      {loading ? (
        <Loading />
      ) : !sortedSettlements || sortedSettlements.isEmpty() ? (
        <InformationLineFc description={`No settlements for this customer` /* on ${month} ${year}*/} />
      ) : (
        <AccordionListFc
          multipleOpen={false}
          initialOpenItemId={sortedSettlements.length === 1 ? sortedSettlements[0].id : undefined}
        >
          {sortedSettlements.map((settlement) => (
            <AccordionItem
              id={domId(settlement.id, 'settlement')}
              key={settlement.id}
              header={<SettlementDateTotalLineFc settlement={settlement} />}
              content={<SettlementFc settlementId={settlement.id} customerId={customerId} />}
            />
          ))}
        </AccordionListFc>
      )}
    </div>
  );
};
