#import '../fragment/customerDocument.graphql'

mutation saveCustomerDocument(
  $bucket: String!
  $customerId: ID!
  $document: DocumentInput!
  $fileKey: String
  $tags: [TagInput!]
) {
  saveCustomerDocument(bucket: $bucket, customerId: $customerId, document: $document, fileKey: $fileKey, tags: $tags) {
    ...customerDocumentFragment
  }
}
