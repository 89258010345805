"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const operators = [
    {
        id: 'some-operator',
        codes: [100, 101, 102],
    },
    {
        id: 'tts',
        codes: [704, 757, 869, 842, 892],
    },
    {
        id: 'mycall',
        codes: [734],
    },
    {
        id: 'phonero',
        codes: [707, 708],
    },
    {
        id: 'onecall',
        codes: [848],
    },
    {
        id: 'telia',
        codes: [702, 703, 721, 722, 725, 736, 749, 755, 809, 815, 817, 879, 885, 886, 896, 898],
    },
];
exports.default = operators;
