import {Button} from '@telia/styleguide';
import classNames from 'classnames';
import React, {FC, useEffect} from 'react';

import {Entity, FormState} from '../../hooks/useFormState';
import {getLog} from '../../log';
import {SenderId} from '../../model';
import FormColumn from '../common/FormColumn';
import FormRow from '../common/FormRow';
import {Icon} from '../common/Icon';
import {SenderIdInput} from './SenderIdInput';

const log = getLog('SenderIdForm', 'INFO');

interface SenderIdFormProps {
  formState: FormState;
}

export const SenderIdForm: FC<SenderIdFormProps> = ({formState}) => {
  const {subEntityAt} = formState;

  const deleteRow = (rowIndex: number) => {
    const updatedsenderIds = subEntityAt<SenderId[]>('senderIds').clone();
    updatedsenderIds.splice(rowIndex, 1);
    formState.onChange('senderIds')(updatedsenderIds as unknown as Entity[]);
  };

  const addRow = () => {
    const senderIds = subEntityAt<SenderId[]>('senderIds');
    formState.onChange('senderIds')([...senderIds, {senderId: '', oaFormatClassifier: null}] as Entity[]);
  };

  const senderIds = subEntityAt<SenderId[]>('senderIds');

  return (
    <div>
      {senderIds?.map(({id}, i) => (
        <div key={`senderid-row-${i}`}>
          <FormRow>
            <FormColumn>
              <div className="product__technical__features__icon--wrapper">
                {(senderIds.length > 1 || id !== undefined) && (
                  <Icon
                    icon="trash"
                    onClick={() => deleteRow(i)}
                    info="Delete"
                    className={classNames(
                      'product__technical__features__icon',
                      'product__technical__features__icon--delete'
                    )}
                  />
                )}
              </div>
              <SenderIdInput
                formState={formState}
                entityFieldIdPrefix={`senderIds[${i}].`}
                isEditing={!id}
                showLabels={i === 0}
              />
            </FormColumn>
          </FormRow>
        </div>
      ))}
      <FormRow>
        <FormColumn>
          <div>
            <Button kind="normal" text="Add row" onClick={addRow} />
          </div>
        </FormColumn>
      </FormRow>
    </div>
  );
};
