import {constants} from '@telia/cpa-web-common';
import {getLog} from '../log';
import {
  AccessNumberOverview,
  AccessNumberRangeOverview,
  GenericOverview,
  Overview,
  RbmOverview,
  ReservedOaOverview,
} from '../model';

const {
  PRODUCT_OVERVIEW_TYPE_ACCESS_NUMBER,
  PRODUCT_OVERVIEW_TYPE_ACCESS_NUMBER_RANGE,
  PRODUCT_OVERVIEW_TYPE_RESERVED_OA,
  PRODUCT_OVERVIEW_TYPE_RBM,
  PRODUCT_OVERVIEW_TYPE_GENERIC,
} = constants;

const log = getLog('useProductOverviewTypes', 'INFO');

export interface UseProductOverviewTypes {
  isAccessNumberOverview: (overview: Overview) => overview is AccessNumberOverview;
  isAccessNumberRangeOverview: (overview: Overview) => overview is AccessNumberRangeOverview;
  isReservedOaOverview: (overview: Overview) => overview is ReservedOaOverview;
  isRbmOverview: (overview: Overview) => overview is RbmOverview;
  isGenericOverview: (overview: Overview) => overview is GenericOverview;
}

export const useProductOverviewTypes = (): UseProductOverviewTypes => {
  function isAccessNumberOverview(overview: Overview): overview is AccessNumberOverview {
    const {productOverviewType} = overview;
    log.debug('isAccessNumberOverview', {productOverviewType});
    return productOverviewType === PRODUCT_OVERVIEW_TYPE_ACCESS_NUMBER;
  }

  function isAccessNumberRangeOverview(overview: Overview): overview is AccessNumberRangeOverview {
    const {productOverviewType} = overview;
    log.debug('isAccessNumberRangeOverview', {productOverviewType});
    return productOverviewType === PRODUCT_OVERVIEW_TYPE_ACCESS_NUMBER_RANGE;
  }

  function isReservedOaOverview(overview: Overview): overview is ReservedOaOverview {
    const {productOverviewType} = overview;
    log.debug('isReservedOaOverview', {productOverviewType});
    return productOverviewType === PRODUCT_OVERVIEW_TYPE_RESERVED_OA;
  }

  function isRbmOverview(overview: Overview): overview is RbmOverview {
    const {productOverviewType} = overview;
    log.debug('isRbmOverview', {productOverviewType});
    return productOverviewType === PRODUCT_OVERVIEW_TYPE_RBM;
  }

  function isGenericOverview(overview: Overview): overview is GenericOverview {
    const {productOverviewType} = overview;
    log.debug('isGenericOverview', {productOverviewType});
    return productOverviewType === PRODUCT_OVERVIEW_TYPE_GENERIC;
  }

  return {
    isAccessNumberOverview,
    isAccessNumberRangeOverview,
    isReservedOaOverview,
    isRbmOverview,
    isGenericOverview,
  };
};
