import {Button} from '@telia/styleguide';
import React, {FC} from 'react';
import {Link, useParams} from 'react-router-dom';

import * as AppRoutes from '../../appRoutes';
import {domId} from '../../dom';
import {useUser} from '../../hooks/useUser';
import {getLog} from '../../log';
import {Channel, Contact, ID} from '../../model';
import {CUSTOMER_USERS_MANAGE} from '../../permissions';
import {InformationLineFc} from '../common/InformationLine';
import List from '../common/List';
import ListItem from '../common/ListItem';
import Page from '../common/Page';
import PageSubtitle from '../common/PageSubtitle';
import {PageViewCounter} from '../metrics/PageViewCounter';

const log = getLog('Contacts', 'INFO');

interface ContactsFcProps {
  contacts?: Contact[];
  channels?: Channel[];
}

export const ContactsFc: FC<ContactsFcProps> = ({channels, contacts}) => {
  const {customerId} = useParams<{customerId: ID}>() as {customerId: ID};
  const {hasPermission} = useUser();
  const {formatWithBrand} = AppRoutes.useBrandFormat();
  log.debug('props', {channels, contacts});
  return (
    <Page>
      <>
        <PageSubtitle subtitle="Communication contacts" />
        <PageViewCounter page="contacts" />

        {contacts && !contacts.isEmpty() ? (
          <List id="contactesList">
            {contacts.map((aContact) => (
              <ListItem
                id={domId(aContact.id, 'contact')}
                key={aContact.id}
                link={formatWithBrand(
                  AppRoutes.PROVISIONING_CUSTOMER_CONTACT__customerId_contactId,
                  customerId,
                  aContact.id
                )}
              >
                {aContact.name ? (
                  <div className="bold">{aContact.name}</div>
                ) : (
                  <div className="bold missing">{'no name'}</div>
                )}
                {aContact.email && (
                  <div>
                    <span className="longLabelPrefix">Email </span> {aContact.email}
                  </div>
                )}
                {aContact.phone && (
                  <div>
                    <span className="longLabelPrefix">Phone </span> {aContact.phone}
                  </div>
                )}
                <div>
                  <span className="longLabelPrefix">Contact type </span>{' '}
                  {aContact.channels && !aContact.channels.isEmpty() ? (
                    channels
                      ?.filter(({id}) => aContact.channels?.includes(id))
                      .map(({name}) => name)
                      .join(', ')
                  ) : (
                    <span className={'redText'}>No subjects!</span>
                  )}
                </div>
                {aContact.comment && (
                  <div>
                    <span className="longLabelPrefix">Comment </span> {aContact.comment}
                  </div>
                )}
              </ListItem>
            ))}
          </List>
        ) : (
          <InformationLineFc>No communication contacts found</InformationLineFc>
        )}

        {hasPermission(CUSTOMER_USERS_MANAGE) && (
          <div id="createContact">
            <Link
              to={formatWithBrand(
                AppRoutes.PROVISIONING_CUSTOMER_CONTACT__customerId_contactId,
                customerId,
                AppRoutes._CREATE
              )}
            >
              <Button text="Create communication contact" />
            </Link>
          </div>
        )}
      </>
    </Page>
  );
};
