import {format} from '@telia/cpa-web-common';
import {Button} from '@telia/styleguide';
import gql from 'graphql-tag';
import React, {FC, useEffect, useState} from 'react';

import associateSoftwareTokenMutation from '../../../../graphql/mutation/associateSoftwareToken.graphql';
import verifySoftwareTokenMutation from '../../../../graphql/mutation/verifySoftwareToken.graphql';

import '../MfaInfo.scss';

import {useFormState} from '../../../../hooks/useFormState';
import {useMutationWrap} from '../../../../hooks/useMutationWrap';
import {useUser} from '../../../../hooks/useUser';
import {getLog} from '../../../../log';
import Loading from '../../../Loading';
import FormColumn from '../../../common/FormColumn';
import FormRow from '../../../common/FormRow';
import {FieldWithFormState} from '../../../common/field';
import {MfaQrCode} from './MfaQrCode';

const log = getLog('MfaAppEditForm', 'INFO');

interface MfaAppEditFormProps {
  onClose: () => void;
}

export const MfaAppEditForm: FC<MfaAppEditFormProps> = ({onClose}) => {
  const [loading, setLoading] = useState<boolean>(false);

  const {realUser, refetch} = useUser();

  const associateSoftwareToken = useMutationWrap<{associateSoftwareToken: {secretCode: string}}, {}>(
    gql(associateSoftwareTokenMutation)
  );
  const verifySoftwareToken = useMutationWrap<
    {verifySoftwareToken: {verified: boolean}},
    {userCode: string; deviceName?: string}
  >(gql(verifySoftwareTokenMutation));

  const [secretCode, setSecretCode] = useState<string | undefined>();

  useEffect(() => {
    associateSoftwareToken().then(({data}) => {
      setSecretCode(data?.associateSoftwareToken.secretCode);
    });
  }, []);

  const formState = useFormState({isEditing: true});
  const {entityAs, putError} = formState;

  const onConfirm = async () => {
    setLoading(true);
    const {userCode, deviceName} = entityAs<{userCode: string; deviceName?: string}>();

    if (!userCode) {
      putError('usercode', 'Please enter a one time code from your authenticator app.');
      return;
    }

    const response = await verifySoftwareToken({
      successText: 'Authenticator app added',
      variables: {
        userCode,
        deviceName,
      },
    });

    if (response.data?.verifySoftwareToken?.verified) {
      await refetch();
      onClose();
    }

    setLoading(false);
  };
  return !secretCode ? (
    <FormRow>
      <FormColumn>
        <div>
          <Loading />
        </div>
      </FormColumn>
    </FormRow>
  ) : (
    <>
      <FormRow>
        <FormColumn>
          <p className="noMarginTop">
            Scan the QR code below using your authenticator app. Alternatively you can enter the secret key manually in
            your authenticator app.
          </p>
        </FormColumn>
      </FormRow>
      <MfaQrCode secretCode={secretCode} />
      <FormRow>
        <FormColumn>
          <FieldWithFormState
            formState={formState}
            entityFieldId="deviceName"
            label="Friendly Device Name - Optional"
            placeholder={`${format.possessiveForm(realUser?.firstName)} phone`}
            tip={`A name for your MFA device, for example "${format.possessiveForm(realUser?.firstName)} phone".`}
          />
        </FormColumn>
      </FormRow>

      <FormRow>
        <FormColumn>
          <FieldWithFormState
            formState={formState}
            entityFieldId="userCode"
            label="Enter a code from your authenticator app"
            placeholder="000000"
          />
        </FormColumn>
      </FormRow>

      <FormRow>
        <FormColumn>
          <div>
            {loading ? (
              <Loading />
            ) : (
              <>
                <Button text="Confirm" onClick={onConfirm} kind={Button.kinds.primary} />
                <Button text="Cancel" onClick={onClose} kind={Button.kinds.cancel} />
              </>
            )}
          </div>
        </FormColumn>
      </FormRow>
    </>
  );
};
