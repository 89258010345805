import {gql, useQuery} from '@apollo/client';
import {Button} from '@telia/styleguide';
import classnames from 'classnames';
import React, {FC, MouseEvent} from 'react';

import smscsQuery from '../../../graphql/query/smscs.graphql';

import {Entity, FormState} from '../../../hooks/useFormState';
import {useModal} from '../../../hooks/useModal';
import {getLog} from '../../../log';
import {SmppAccount} from '../../../model';
import AccordionItem from '../../common/AccordionItem';
import {AccordionListFc} from '../../common/AccordionList';
import FormColumn from '../../common/FormColumn';
import FormRow from '../../common/FormRow';
import {Icon} from '../../common/Icon';
import {InformationLineFc} from '../../common/InformationLine';
import {Field, FieldTypes, FieldWithFormState} from '../../common/field';
import {SmscsQuery} from '../SmscList';
import {SmppAccount as SmppAccountFc} from './SmppAccount';

const log = getLog('SmppConfig', 'INFO');

interface SmppConfigProps {
  formState: FormState;
}

export const SmppConfig: FC<SmppConfigProps> = ({formState}) => {
  const parentId = 'smppConfig';
  const accountsPath = `${parentId}.smppAccounts`;
  const smppAccounts: SmppAccount[] =
    (formState?.subEntityAt<SmppAccount[]>(accountsPath) && [...formState?.subEntityAt<SmppAccount[]>(accountsPath)]) ||
    [];
  const {loading, data: {smscs} = {}} = useQuery<SmscsQuery>(gql(smscsQuery));
  const {showModal} = useModal();
  const onCreateAccount = () => {
    log.debug('onCreateAccount', smppAccounts);
    formState.onChange(accountsPath)([...smppAccounts, {}] as Entity[]);
  };

  const onDeleteAccount = (index: number) => {
    log.debug('onDeleteAccount', {index});
    showModal({
      show: true,
      title: 'Delete SMPP Account',
      content: <span>Are you sure you want to delete the SMPP Account?</span>,
      confirmType: 'negative',
      confirmText: 'Delete',
      onConfirm: () => onConfirmDeleteAccount(index),
    });
  };
  const onConfirmDeleteAccount = (index: number) => {
    log.debug('onConfirmDeleteAccount', {index});
    formState.onChange(accountsPath)(smppAccounts.removeIndex(index) as unknown[] as Entity[]);
  };

  log.debug('SmppConfigFc', {smppAccounts, formState});
  return (
    <>
      <FormRow>
        <FormColumn>
          <FieldWithFormState
            formState={formState}
            entityFieldId={`${parentId}.smscId`}
            label="SMSC"
            type={FieldTypes.select}
            options={smscs}
            tip="The list of SMSC may be updated from Management > SMSCs"
          />
        </FormColumn>
      </FormRow>

      <br />
      <AccordionListFc
        label={'SMPP Accounts'}
        multipleOpen={true}
        initialAllOpen={false}
        className={'pendingSettlementsList compactSettlementsList noMarginLeft'}
      >
        {smppAccounts.isEmpty() ? (
          <InformationLineFc>No SMPP accounts</InformationLineFc>
        ) : (
          smppAccounts.map((smppAccount, i) => (
            <AccordionItem
              id={`${parentId}.smppAccount-${i}`}
              key={`${parentId}.smppAccount-${i}`}
              header={<span>{smppAccount.id}</span>}
              description={
                <div
                  className={classnames({
                    disabled: !smppAccount.enabled,
                  })}
                >
                  {smppAccount.comment}
                  <div className="floatRight">
                    <Field label="Enabled" value={smppAccount.enabled} isEditing={false} type={FieldTypes.checkbox} />
                  </div>
                </div>
              }
              headerRightElement={
                !formState.isEditing ? undefined : (
                  <Icon
                    icon="trash"
                    onClick={(e: MouseEvent<HTMLButtonElement>) => {
                      log.warn('trach ', e);
                      onDeleteAccount(i);
                      e.stopPropagation();
                    }}
                    info="Delete"
                    className="floatRight settlement__adjustment__icon settlement__adjustment__icon--delete"
                  />
                )
              }
              content={
                <SmppAccountFc
                  formState={formState}
                  parentId={`${parentId}.smppAccounts[${i}]`}
                  key={'smppAccount' + i}
                />
              }
              classNames="hideTransition"
            />
          ))
        )}
      </AccordionListFc>
      {formState.isEditing && (
        <Button className="marginBottom" text="Create SMPP Account" onClick={onCreateAccount} kind="normal" />
      )}
    </>
  );
};
