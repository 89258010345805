"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getEventTypeName = exports.mtStatusEventTypes = exports.messageEventTypes = exports.eventTypes = void 0;
exports.eventTypes = [
    { id: 'BULK_SMS_SENT', name: 'MT Sent' },
    { id: 'BULK_SMS_DELIVERED', name: 'MT Delivered Status' },
    { id: 'BULK_SMS_EXPIRED', name: 'MT Expired Status' },
    { id: 'BULK_SMS_REJECTED', name: 'MT Rejected Status' },
    { id: 'BULK_SMS_RECEIVED', name: 'MO Received' },
    { id: 'MO_LONG_NUMBER_SMS_RECEIVED', name: 'MO Long Nr. Received' },
];
exports.messageEventTypes = [
    { id: 'BULK_SMS_SENT', name: 'MT Sent' },
    { id: 'BULK_SMS_RECEIVED', name: 'MO Received' },
    { id: 'MO_LONG_NUMBER_SMS_RECEIVED', name: 'MO Long Nr. Received' },
];
exports.mtStatusEventTypes = [
    { id: 'BULK_SMS_DELIVERED', name: 'MT Delivered Status' },
    { id: 'BULK_SMS_EXPIRED', name: 'MT Expired Status' },
    { id: 'BULK_SMS_REJECTED', name: 'MT Rejected Status' },
];
const getEventTypeName = (id) => { var _a; return ((_a = exports.eventTypes.find((event) => event.id === id)) === null || _a === void 0 ? void 0 : _a.name) || id || 'Invalid event'; };
exports.getEventTypeName = getEventTypeName;
