fragment smscFragment on Smsc {
  id
  name
  description
  smscNodes {
    ...smscNodeFragment
  }
  smscConnection {
    ...smscConnectionFragment
  }
  healthConfig {
    ...healthConfigFragment
  }
  timeZone
  countryId
}

fragment healthConfigFragment on HealthConfig {
  enquireLinkInterval
  enquireLinkTimeout
}

fragment smscNodeFragment on SmscNode {
  id
  enabled
  host
  port
  name
  comment
}

fragment smscConnectionFragment on SmscConnection {
  connectTimeout
  bindTimeout
  requestTimeout
  windowMonitorInterval
}
