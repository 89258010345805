import {gql, useQuery} from '@apollo/client';
import {useMemo} from 'react';

import serviceCategoriesQuery from '../graphql/query/serviceCategories.graphql';

import {getLog} from '../log';
import {ID, ServiceCategory, TELIA_NO} from '../model';
import {useUser} from './useUser';

const log = getLog('useServiceCategories', 'INFO');

interface ServiceCategoryQuery {
  serviceCategories: ServiceCategory[];
}

export function useServiceCategories(): {
  loading: boolean;
  serviceCategories: ServiceCategory[] | undefined;
  isServiceCategoriesVisible: boolean;
  serviceCategoryDisplayName: (serviceCategoryId: ID) => string;
  categoryTypeDisplayName: (serviceCategoryId: ID | undefined, categoryTypeId: ID) => string;
} {
  const {currentUserBrand} = useUser();
  const isServiceCategoriesVisible = useMemo(() => TELIA_NO === currentUserBrand?.id, [currentUserBrand]);
  const {loading, data: {serviceCategories} = {}} = useQuery<ServiceCategoryQuery>(gql(serviceCategoriesQuery));
  log.debug('useServiceCategories', {loading, serviceCategories, isServiceCategoriesVisible});

  const getServiceCategory = (serviceCategoryId: ID) => serviceCategories?.find(({id}) => id === serviceCategoryId);
  const serviceCategoryDisplayName = (serviceCategoryId: ID) => {
    const serviceCategory = getServiceCategory(serviceCategoryId);

    return serviceCategoryId + (serviceCategory ? ` ${serviceCategory.name}` : '');
  };

  const categoryTypeDisplayName = (serviceCategoryId: ID, categoryTypeId: ID) => {
    const serviceCategory = getServiceCategory(serviceCategoryId);
    const categoryType = serviceCategory?.types?.find(({typeId}) => typeId === categoryTypeId);

    return categoryTypeId + (categoryType ? ` ${categoryType.name}` : '');
  };

  return {loading, serviceCategories, isServiceCategoriesVisible, serviceCategoryDisplayName, categoryTypeDisplayName};
}
