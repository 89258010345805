import {ApolloError} from '@apollo/client';
import {GraphQLError} from 'graphql';
import React, {FC, PropsWithChildren} from 'react';
import {getLog} from '../../log';

const log = getLog('Error', 'INFO');

interface ErrorFcProps extends PropsWithChildren {
  error?: ApolloError;
  text?: string;
}

export const ErrorFc: FC<ErrorFcProps> = (props) => {
  log.debug('render', props);
  const {error, text, children} = props;
  return (
    <div className="pop-up-line pop-up-line--error">
      <div className="pop-up-line__container container container--small">
        <div className="pop-up-line__description">
          {error &&
            error.graphQLErrors &&
            error.graphQLErrors.map((error: GraphQLError, i: number) => <div key={i}>{error.message}</div>)}
          {text || children}
        </div>
      </div>
    </div>
  );
};

export default ErrorFc;
