import {format} from '@telia/cpa-web-common';
import React, {FC} from 'react';

import '../Settlement.scss';

import {getLog} from '../../../../log';
import {Settlement} from '../../../../model';
import teliaLogo from '../../../../resources/images/telia-logo.png';

const log = getLog('SettlementHeader');

interface SettlementHeaderFcProps {
  settlement: Settlement;
}

export const SettlementHeaderFc: FC<SettlementHeaderFcProps> = (props) => {
  // log.debug('render', props);
  const {settlement} = props;
  if (!settlement) {
    return null;
  }
  const {
    id: settlementId,
    state,
    yearMonth,
    date: settlementDate,
    currencyCode,
    customer: {
      id: customerId,
      company: {name: companyName, organizationNumber},
    },
    contract: {id: contractId, invoiceReference, invoiceAccountId, invoiceIncludeVat},
    summary: {totalInclVat},
  } = settlement;
  return (
    <section className="settlement__header">
      <div className="settlement__logo">
        <img className="" src={teliaLogo} alt="Telia logo" />
      </div>

      <table id="settlementHeaderTable" className="settlement__fields">
        <tbody>
          <tr>
            <td className="settlement__field settlement__field--label">Customer name</td>
            <td className="settlement__field settlement__field--value">{companyName}</td>
          </tr>
          <tr>
            <td className="settlement__field settlement__field--label">Customer num.</td>
            <td className="settlement__field settlement__field--value">{customerId}</td>
          </tr>
          <tr>
            <td className="settlement__field settlement__field--label">Organization num.</td>
            <td className="settlement__field settlement__field--value">{organizationNumber}</td>
          </tr>
          <tr>
            <td className="settlement__field settlement__field--label">Contract num.</td>
            <td className="settlement__field settlement__field--value">{contractId}</td>
          </tr>
          <tr>
            <td className="settlement__field settlement__field--label">Settlement num.</td>
            <td className="settlement__field settlement__field--value">{settlementId}</td>
          </tr>
          <tr>
            <td className="settlement__field settlement__field--label">Settlement month</td>
            <td className="settlement__field settlement__field--value">{yearMonth}</td>
          </tr>
          <tr>
            <td className="settlement__field settlement__field--label">Settlement date</td>
            <td className="settlement__field settlement__field--value">{format.date(settlementDate)}</td>
          </tr>
          {/*<tr>*/}
          {/*<td className='settlement__field settlement__field--label'>Bank account</td>*/}
          {/*<td className='settlement__field settlement__field--value'>{bankAccount}</td>*/}
          {/*</tr>*/}
          <tr>
            <td className="settlement__field settlement__field--label">Customer Invoice ref.</td>
            <td className="settlement__field settlement__field--value">{invoiceReference}</td>
          </tr>
          {/*<tr>*/}
          {/*  <td className="settlement__field settlement__field--label">Invoice account ID</td>*/}
          {/*  <td className="settlement__field settlement__field--value">{invoiceAccountId}</td>*/}
          {/*</tr>*/}
          {/*<tr>*/}
          {/*<td className='settlement__field settlement__field--label'>Access num.</td>*/}
          {/*<td className='settlement__field settlement__field--value'>02014, 02095, 02099, 02100, 02125, 02130, 02142, 02157, 02222,*/}
          {/*02244, 02247, 02248, 02270, 02390, 02440, 02455, 02480, 02999,*/}
          {/*03002, 03123, 03124, 03345, 03370, 04002, 04325, 05060, 05700,*/}
          {/*06421, 07040, 07250, 07273, 07300, 07301, 07373, 07570, 07780,*/}
          {/*08008, 08020, 08525, 08530, 09111, 09595, 19126, 19159, 26606,*/}
          {/*27772</td>*/}
          {/*</tr>*/}
          <tr>
            <td className="settlement__field settlement__field--label bold">Invoice total</td>
            <td className="settlement__field settlement__field--value bold">
              {currencyCode} {format.decimal(totalInclVat)}
            </td>
          </tr>
        </tbody>
      </table>

      {/*<table className='settlement__fields settlement__fields--total'>*/}
      {/*<tbody>*/}
      {/*<tr>*/}
      {/*<td className='settlement__field settlement__field--label bold'>Invoice total</td>*/}
      {/*<td className='settlement__field settlement__field--value bold'>{format.decimal(invoiceTotal)}</td>*/}
      {/*</tr>*/}
      {/*</tbody>*/}
      {/*</table>*/}
    </section>
  );
};
