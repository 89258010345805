import React, {FC, PropsWithChildren} from 'react';

import './SectionTitle.scss';

import {PropsWithClassName} from '../../model';

interface SectionTitleProps extends PropsWithClassName {
  title?: string;
}

const SectionTitleFc: FC<PropsWithChildren<SectionTitleProps>> = (props) => {
  return <h3 className="section__title heading heading--level-3">{props.title || props.children}</h3>;
};

export default SectionTitleFc;
