import {PropsWithChildren} from 'react';

export * from '@telia/cpa-web-common/dist/model';

export interface _GraphQLQuery {
  loading: boolean;
  error?: any;
}
export type GraphQLQuery<T> = Partial<T> & _GraphQLQuery;

export interface PropsWithClassName extends PropsWithChildren {
  className?: string;
}

export interface PropsWithId {
  id?: string;
}
