import React, {FC} from 'react';

import {FormState} from '../../../hooks/useFormState';
import {getLog} from '../../../log';
import {ID} from '../../../model';
import FormColumn from '../../common/FormColumn';
import FormRow from '../../common/FormRow';
import SectionTitle from '../../common/SectionTitle';
import {FieldTypes} from '../../common/field';
import {FieldWithFormState} from '../../common/field/FieldWithFormState';

const log = getLog('SmppWindow', 'INFO');

interface SmppWindowProps {
  parentId: ID;
  formState: FormState;
}

export const SmppWindow: FC<SmppWindowProps> = ({parentId, formState}) => {
  log.debug('render', {parentId, formState});

  return (
    <section className="form-group">
      <SectionTitle title="SMPP Window" />

      <FormRow>
        <FormColumn>
          <FieldWithFormState
            formState={formState}
            entityFieldId={`${parentId}.size`}
            label="Size"
            type={FieldTypes.number}
          />
        </FormColumn>
      </FormRow>

      <FormRow>
        <FormColumn>
          <FieldWithFormState
            formState={formState}
            entityFieldId={`${parentId}.waitTimeout`}
            label="Wait Timeout"
            type={FieldTypes.duration}
          />
          <FieldWithFormState
            formState={formState}
            entityFieldId={`${parentId}.monitorInterval`}
            label="Monitor Interval"
            type={FieldTypes.duration}
          />
        </FormColumn>
      </FormRow>
      <FormRow>
        <FormColumn>
          <FieldWithFormState
            formState={formState}
            entityFieldId={`${parentId}.submitOfferTimeout`}
            label="Submit Offer Timeout"
            type={FieldTypes.duration}
          />
          <FieldWithFormState
            formState={formState}
            entityFieldId={`${parentId}.enquireLinkOfferTimeout`}
            label="Enquire Link Offer Interval"
            type={FieldTypes.duration}
          />
        </FormColumn>
      </FormRow>
    </section>
  );
};
