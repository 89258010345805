import {useUser} from './useUser';
import {getLog} from '../log';
import {Customer} from '../model';

const log = getLog('useUserCustomer', 'INFO');

interface CustomerQuery {
  customer: Customer;
}

export interface UseUserCustomer {
  loading: boolean;
  customer: Customer | undefined;
  customerName: string | undefined;
}

export const useUserCustomer = () => {
  const {isCustomer, customerId, customer} = useUser();
  const customerName = customer?.company?.name || customerId;
  const useUserCustomer: UseUserCustomer = {loading: !!isCustomer() && !customer, customer, customerName};
  log.debug(useUserCustomer);
  return useUserCustomer;
};
