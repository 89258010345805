import {gql, useMutation} from '@apollo/client';
import React, {FC, useEffect} from 'react';
import {matchPath, useLocation, useParams} from 'react-router-dom';

import incrPageViewMetricMutation from '../../graphql/mutation/incrPageViewMetric.graphql';

import * as AppRoutes from '../../appRoutes';
import {useUser} from '../../hooks/useUser';
import {getLog} from '../../log';
import {ID} from '../../model';

const log = getLog('PageViewCounter', 'DEBUG');

export enum Heading {
  PROVISIONING = 'Provisioning',
  SETTLEMENT = 'Settlement',
  REPORT = 'Report',
  MANAGEMENT = 'Management',
  DOCUMENTS = 'Documents',
  SUPPORT = 'Support',
}

interface PageViewCounterProps {
  page?: string;
}

type RouteParams = {
  customerId: ID;
};

export const PageViewCounter: FC<PageViewCounterProps> = ({page}) => {
  const {customerId} = useParams<RouteParams>();
  const {pathname} = useLocation();
  const {realUser, currentUserBrand} = useUser();

  const [incrMetric] = useMutation(gql(incrPageViewMetricMutation));

  const getHeadingFromPath = (): Heading | undefined => {
    const routes = [
      {path: AppRoutes.PROVISIONING, heading: Heading.PROVISIONING},
      {path: AppRoutes.SETTLEMENT, heading: Heading.SETTLEMENT},
      {path: AppRoutes.REPORT, heading: Heading.REPORT},
      {path: AppRoutes.MANAGEMENT, heading: Heading.MANAGEMENT},
      {path: AppRoutes.DOCUMENTS, heading: Heading.DOCUMENTS},
      {path: AppRoutes.SUPPORT, heading: Heading.SUPPORT},
    ];

    return routes.find(({path}) => matchPath(path + '/*', pathname))?.heading;
  };

  useEffect(() => {
    incrMetric({
      variables: {
        userType: realUser?.type,
        heading: getHeadingFromPath(),
        page,
        brandId: currentUserBrand?.id,
        customerId,
      },
    });
  }, [pathname]);

  return null;
};
