import {gql} from '@apollo/client';
import {format} from '@telia/cpa-web-common';
import {Button, InputError} from '@telia/styleguide';
import classnames from 'classnames';
import numeral from 'numeral';
import React, {FC, useEffect, useMemo, useRef, useState} from 'react';

import adjustSettlementMutation from '../../../../graphql/mutation/adjustSettlement.graphql';
import settlementQuery from '../../../../graphql/query/settlement.graphql';

import '../Settlement.scss';

import {EntityFieldValue} from '../../../../hooks/useFormState';
import {useMutationWrap} from '../../../../hooks/useMutationWrap';
import {useProductTypes} from '../../../../hooks/useProductTypes';
import {useUser} from '../../../../hooks/useUser';
import {getLog} from '../../../../log';
import {ID, Settlement, SettlementAdjustment, SettlementAdjustmentFeeCategory} from '../../../../model';
import {Icon} from '../../../common/Icon';
import {Field, FieldTypes} from '../../../common/field';
import {feeName} from '../settlementUtils';

const log = getLog('SettlementAdjustments', 'INFO');

interface SettlementAdjustmentsFcProps {
  adjustments?: SettlementAdjustment[];
  settlement?: Settlement;
  isAdjusting?: boolean;
  onDoneAdjusting?: () => void;
}

interface NewSettlementAdjustment extends SettlementAdjustment {
  status: 'EXTERNAL' | 'NEW' | 'DELETED';
  productTypeIdError?: string;
  feeIdError?: string;
  commentError?: string;
  priceError?: string;
  uncompletedError?: boolean;
}

const emptyNewAdjustment: NewSettlementAdjustment = {
  comment: '',
  price: 0.0,
  status: 'NEW',
};

interface AdjustSettlementMutation {
  adjustSettlement: Settlement;
}

export const SettlementAdjustmentsFc: FC<SettlementAdjustmentsFcProps> = (props) => {
  const {getName: productTypeName, getBrandProductTypes, getFees} = useProductTypes();
  const {currentUserBrand} = useUser();
  const [adjustments, setAdjustments] = useState<NewSettlementAdjustment[]>([]);
  const [newAdjustment, setNewAdjustment] = useState<NewSettlementAdjustment>(emptyNewAdjustment);
  // const isAdjusting = !!newAdjustment;
  const isAdjusting = props.isAdjusting;

  const adjustmentInputRef = useRef<HTMLInputElement>();
  // useLayoutEffect(() => {
  //   log.debug('useEffect adjustmentInputRef?.current', adjustmentInputRef.current);
  //   adjustmentInputRef.current?.focus();
  // }, [adjustmentInputRef.current]);

  const initAdjustments = (adjustments?: SettlementAdjustment[]) => {
    log.debug('initAdjustments', adjustments);
    adjustments && setAdjustments(adjustments?.map((adj) => ({status: 'EXTERNAL', ...adj})));
  };

  useEffect(() => {
    initAdjustments(props.adjustments);
  }, [props.adjustments, props.isAdjusting]);

  const adjustSettlement = useMutationWrap<
    {adjustSettlement: Settlement},
    {id: ID; adjustments: SettlementAdjustment[]}
  >(gql(adjustSettlementMutation));
  const onAdjustSettlement = (id: ID, adjustments: NewSettlementAdjustment[]) => {
    log.info('onAdjustSettlement', {id, adjustments});
    adjustSettlement({
      loadingText: 'Adjusting settlement...',
      successText: 'Settlement adjusted',
      variables: {id, adjustments},
      update: (proxy, {data}) => {
        const {adjustSettlement} = data || {};
        log.info('adjustSettlement update', adjustSettlement);
        adjustSettlement &&
          proxy.writeQuery({
            query: gql(settlementQuery),
            data: {settlement: adjustSettlement},
            variables: {id: adjustSettlement.id, customerId: adjustSettlement.customer.id},
          });
      },
    }).then(({data}) => {
      const {adjustSettlement} = data || {};
      log.debug('adjustSettlement resolved', adjustSettlement);
      initAdjustments(adjustSettlement?.adjustments || []);
      props.onDoneAdjusting && props.onDoneAdjusting();
      return adjustSettlement;
    });
  };

  const onAddAdjustment = () => {
    const adjustment = {
      ...(newAdjustment as NewSettlementAdjustment),
    };
    if (adjustment.feeCategory?.productTypeId && !adjustment.feeCategory?.feeId) {
      setNewAdjustment({...adjustment, feeIdError: 'Please select a fee'});
      return;
    }
    if (!adjustment.comment || adjustment.comment.isEmpty()) {
      setNewAdjustment({...adjustment, commentError: 'Please write an adjustment comment'});
      return; // Prevent adding Adjustment without comment
    }
    if (adjustment.price === undefined || adjustment.price.toString().isEmpty()) {
      setNewAdjustment({...adjustment, priceError: 'Invalid price'});
      return; // Prevent adding Adjustment without price
    }

    const parsedPrice = numeral(adjustment.price.toString().replace(',', '.'));
    if (parsedPrice.value() === null) {
      setNewAdjustment({...adjustment, priceError: 'Invalid price'});
      return; // Prevent adding Adjustment with invalid price value
    }
    adjustment.price = parsedPrice.value() || 0;
    setNewAdjustment(emptyNewAdjustment);
    setAdjustments((adjustments) => [...adjustments, adjustment]);
  };

  const onDeleteAdjustment = (i: number) => () =>
    setAdjustments((adjustments) => {
      if (adjustments[i].status === 'EXTERNAL') {
        adjustments[i].status = 'DELETED';
        return [...adjustments];
      } else if (adjustments[i].status === 'NEW') {
        return adjustments.filter((adj, j) => j !== i);
      } else {
        log.error('Unexpected adjustment status onDeleteAdjustment', adjustments[i].status);
        return [...adjustments];
      }
    });

  const onUndoDeleteAdjustment = (i: number) => () =>
    setAdjustments((adjustments) => {
      if (adjustments[i].status === 'DELETED') {
        adjustments[i].status = 'EXTERNAL';
        return [...adjustments];
      } else {
        log.error('Unexpected adjustment status onUndoDeleteAdjustment', adjustments[i].status);
        return [...adjustments];
      }
    });

  const onChangeAdjustment =
    (field: keyof SettlementAdjustment | keyof SettlementAdjustmentFeeCategory) => (value: EntityFieldValue) => {
      log.debug('onChangeAdjustment', field, value);

      setNewAdjustment((newAdjustment) => {
        return field === 'productTypeId'
          ? ({
              ...newAdjustment!,
              productTypeIdError: '',
              feeCategory: {productTypeId: value},
            } as any) //TODO: as any
          : field === 'feeId'
          ? {...newAdjustment!, feeIdError: '', feeCategory: {...newAdjustment?.feeCategory!, feeId: value}}
          : {...newAdjustment!, [`${field}Error`]: '', [field]: value};
      });
    };

  const onSaveAdjustments = () => {
    console.log(newAdjustment);
    if (
      newAdjustment &&
      ((newAdjustment.feeCategory && !newAdjustment.feeCategory?.productTypeId.isEmpty()) ||
        (newAdjustment.feeCategory && !newAdjustment.feeCategory?.feeId.isEmpty()) ||
        !newAdjustment.comment.isEmpty() ||
        newAdjustment.price !== 0)
    ) {
      setNewAdjustment({...newAdjustment, uncompletedError: true});
      return;
    }
    const finalAdjustments: NewSettlementAdjustment[] = adjustments
      .filter(({status}) => status !== 'DELETED')
      .map(({feeCategory, comment, price, status}) => ({
        feeCategory,
        comment,
        price,
        status,
      }));
    log.info('onSaveAdjustments', finalAdjustments);
    // onCancelAdjusting();
    if (!props.settlement?.id) {
      log.error('Prop settlement required for adjusting settlement');
    } else {
      onAdjustSettlement(props.settlement.id, finalAdjustments);
    }
  };

  return !isAdjusting && (!adjustments || adjustments.isEmpty()) ? null : (
    <section className="settlement__aggregate">
      <table id="settlementAdjustmentsTable" className="settlement__list">
        <thead>
          <tr>
            <th colSpan={isAdjusting ? 5 : 4}>Adjustments</th>
          </tr>
          <tr>
            <th>Comment</th>
            <th>Service Type</th>
            <th>Fee</th>
            <th className="rightText">Cost</th>
            {isAdjusting && <th>&nbsp;</th>}
          </tr>
        </thead>
        <tbody>
          {adjustments?.map(({feeCategory, comment, price, status}, i) => (
            <tr
              className={classnames(
                'settlement__list__item',
                {'settlement__adjustment--new': status === 'NEW'},
                {'settlement__adjustment--deleted': status === 'DELETED'}
              )}
              key={i}
            >
              <td>{comment}</td>
              <td>{productTypeName(feeCategory?.productTypeId || '--')}</td>
              <td>{feeName(feeCategory?.feeId || '--')}</td>
              <td className="rightText noWrap">{format.decimal(price)}</td>
              {isAdjusting && (
                <td width="1%">
                  {status === 'DELETED' ? (
                    <Icon
                      icon={'undo'}
                      onClick={onUndoDeleteAdjustment(i)}
                      info={'Undo'}
                      className={'settlement__adjustment__icon settlement__adjustment__icon--undo'}
                    />
                  ) : (
                    <Icon
                      icon={'trash'}
                      onClick={onDeleteAdjustment(i)}
                      info={'Delete'}
                      className={'settlement__adjustment__icon settlement__adjustment__icon--delete'}
                    />
                  )}
                </td>
              )}
            </tr>
          ))}
          {isAdjusting && (
            <>
              <tr className="settlement__list__item settlement__list__item--adjusting">
                <td>
                  <Field
                    className="settlement__adjustment__text"
                    value={newAdjustment?.comment}
                    onChange={onChangeAdjustment('comment')}
                    isEditing={true}
                    inputRef={adjustmentInputRef}
                    error={newAdjustment?.commentError}
                  />
                </td>
                <td>
                  <Field
                    className="settlement__adjustment__producttype"
                    type={FieldTypes.select}
                    value={newAdjustment?.feeCategory?.productTypeId}
                    error={newAdjustment?.productTypeIdError}
                    onChange={onChangeAdjustment('productTypeId')}
                    isEditing={true}
                    options={currentUserBrand && getBrandProductTypes(currentUserBrand?.id)}
                  />
                </td>
                <td>
                  <Field
                    className="settlement__adjustment__fee"
                    type={FieldTypes.select}
                    value={newAdjustment?.feeCategory?.feeId}
                    error={newAdjustment?.feeIdError}
                    onChange={onChangeAdjustment('feeId')}
                    isEditing={!!newAdjustment?.feeCategory?.productTypeId}
                    defaultValue={!newAdjustment?.feeCategory?.productTypeId ? 'Pick Service Type' : ''}
                    options={getFees(newAdjustment?.feeCategory?.productTypeId || '').map(({feeTypeId}) => ({
                      id: feeTypeId,
                      name: feeName(feeTypeId),
                    }))}
                  />
                </td>
                <td className="rightText noWrap" width="1%">
                  <Field
                    className="settlement__adjustment__price"
                    value={newAdjustment?.price}
                    onChange={onChangeAdjustment('price')}
                    type={FieldTypes.number}
                    isEditing={true}
                    error={newAdjustment?.priceError}
                  />
                </td>
                <td>
                  <Icon
                    icon={'add-circle'}
                    onClick={onAddAdjustment}
                    info={'Add'}
                    className={'settlement__adjustment__icon settlement__adjustment__icon--add'}
                  />
                </td>
              </tr>
              {newAdjustment.uncompletedError && (
                <tr>
                  <td colSpan={2} />
                  <td colSpan={3} align="right">
                    <span className="input-error settlement__adjustment_add--tip">
                      Complete adjustment by pressing the Add (+) button
                    </span>
                  </td>
                </tr>
              )}
            </>
          )}
        </tbody>
      </table>
      {isAdjusting && (
        <div className={'settlement__adjustment__buttons'}>
          <Button text="Save Adjustments" onClick={onSaveAdjustments} kind={Button.kinds.primary} />
          <Button text="Cancel Adjustments" onClick={props.onDoneAdjusting} kind={Button.kinds.cancel} />
        </div>
      )}
    </section>
  );
};
