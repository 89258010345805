"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PROMETHEUS_METRICS = exports.ONECALL_MYCALL_OLD_CPA = exports.CHECK = exports.CONFIG = exports.ENV_CONFIG = exports.CHANGE_PASSWORD = exports.LOGOUT = exports.LOGIN = exports.HEALTH = exports.GRAPHQL = exports.AUTH = exports.HOME = void 0;
exports.HOME = '/';
exports.AUTH = '/auth';
exports.GRAPHQL = '/graphql';
exports.HEALTH = '/health';
exports.LOGIN = '/login';
exports.LOGOUT = '/logout';
exports.CHANGE_PASSWORD = '/changePassword';
exports.ENV_CONFIG = '/envConfig';
exports.CONFIG = '/config';
exports.CHECK = '/check';
exports.ONECALL_MYCALL_OLD_CPA = '/web';
exports.PROMETHEUS_METRICS = '/metrics';
