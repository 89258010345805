import {CustomerDocument} from '@telia/cpa-web-common/dist/model';
import {Button} from '@telia/styleguide';
import {FullWidthTable} from '@telia/styleguide';
import {useThSortable} from '@telia/styleguide/atoms/FullWidthTable/useThSortable';
import React, {FC} from 'react';
import {Link} from 'react-router-dom';

import CustomerDocumentsQuery from '../../graphql/query/customerDocuments.graphql';

import './DocumentsTable.scss';

import * as AppRoutes from '../../appRoutes';
import {_subEntityAt} from '../../hooks/useFormState';
import {useShowInactive} from '../../hooks/useShowInactive';
import {useUser} from '../../hooks/useUser';
import {CUSTOMER_DOCUMENTS_MANAGE} from '../../permissions';
import {InformationLineFc} from '../common/InformationLine';
import Page from '../common/Page';
import SectionTitleFc from '../common/SectionTitle';
import {PageViewCounter} from '../metrics/PageViewCounter';

const {THead, Tr, ThSortable, TBody, Td} = FullWidthTable;

interface CustomerDocumentsQuery {
  customerId: string;
  documents: CustomerDocument[];
}

export const DocumentsTable: FC<CustomerDocumentsQuery> = ({customerId, documents}) => {
  const {hasPermission} = useUser();
  const {formatWithBrand} = AppRoutes.useBrandFormat();
  const {showInactiveComponent, showInactive} = useShowInactive();

  const filteredDocuments = showInactive ? documents : documents.filter((doc) => doc.active);
  const [sortedDocuments, getThSortableProps] = useThSortable<CustomerDocument>(filteredDocuments, 3, [], {});

  return (
    <Page>
      <SectionTitleFc>Documents</SectionTitleFc>
      <PageViewCounter page="documents" />

      {showInactiveComponent}

      {!(sortedDocuments && sortedDocuments.length) ? (
        <InformationLineFc>No documents found</InformationLineFc>
      ) : (
        <FullWidthTable clickable={true}>
          <THead>
            <Tr>
              <ThSortable {...getThSortableProps('name')}>Filename</ThSortable>
              <ThSortable {...getThSortableProps('date')}>Date</ThSortable>
              <ThSortable {...getThSortableProps('uploader')}>Uploader</ThSortable>
            </Tr>
          </THead>

          <TBody>
            {sortedDocuments.map((document, i) => (
              <Tr key={document.id}>
                <Link
                  to={formatWithBrand(
                    AppRoutes.PROVISIONING_CUSTOMER_DOCUMENT__customerId_documentId,
                    customerId,
                    document.id
                  )}
                  style={{display: 'contents', textDecoration: 'none', color: 'inherit'}}
                >
                  <Td className="break-word">{document.name}</Td>
                  <Td>{document.date}</Td>
                  <Td>{document.uploader}</Td>
                </Link>
              </Tr>
            ))}
          </TBody>
        </FullWidthTable>
      )}

      {hasPermission(CUSTOMER_DOCUMENTS_MANAGE) && (
        <div id="createDocument">
          <Link to={formatWithBrand(AppRoutes.PROVISIONING_CUSTOMER_DOCUMENT_CREATE__customerId, customerId)}>
            <Button text="Upload document" />
          </Link>
        </div>
      )}
    </Page>
  );
};
