import React, {FC} from 'react';

import {ID} from '../../model';
import {FieldWithFormState, FieldWithFormStateProps} from './field';

type MobileFieldProps = Omit<FieldWithFormStateProps, 'entityFieldId'> & {
  entityFieldId?: ID;
};

export const MobileField: FC<MobileFieldProps> = ({
  formState,
  entityFieldId,
  className,
  label,
  isEditing,
  tip,
  value,
}) => {
  return (
    <FieldWithFormState
      formState={formState}
      isEditing={isEditing}
      entityFieldId={entityFieldId || 'phone'}
      label={label || 'Mobile'}
      placeholder="+0000000000"
      value={value}
      tip={
        tip
          ? tip
          : (isEditing !== undefined && isEditing) || formState.isEditing
          ? 'Phone number must start with (+) international code and followed by 5 to 20 digits (without spaces)'
          : null
      }
      className={className}
    />
  );
};
