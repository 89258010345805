import classNames from 'classnames';
import React, {FC, PropsWithChildren, ReactElement} from 'react';

import {ID} from '../../model';
import {AccordionItemDescriptionFc} from './AccordionItemDescription';

interface AccordionItemHeaderFcProps extends PropsWithChildren {
  id: ID;
  open: boolean;
  onClick: () => void;
  buttonClassName?: string;
  description?: ReactElement;
}

export const AccordionItemHeaderFc: FC<AccordionItemHeaderFcProps> = (props) => {
  return (
    <h3 className="accordion__header heading heading--level-3" onClick={props.onClick}>
      <button
        className={classNames('accordion__header-button', props.buttonClassName)}
        aria-expanded={props.open}
        aria-controls={props.id}
      >
        {props.children}
        <AccordionItemDescriptionFc open={props.open} description={props.description} />
      </button>
    </h3>
  );
};
