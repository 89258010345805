import React, {FC} from 'react';

import SectionTitleFc from '../../../common/SectionTitle';
import {PasswordGuard} from '../../PasswordGuard';
import {MfaSmsEditForm} from './MfaSmsEditForm';

interface MfaSmsEditProps {
  isAdded: boolean;
  onClose: () => void;
}

export const MfaSmsEdit: FC<MfaSmsEditProps> = ({isAdded, onClose}) => {
  return (
    <section className="form-group">
      <SectionTitleFc title={`${isAdded ? 'Edit' : 'Add'} MFA phone number`} />
      <>
        <PasswordGuard onCancel={onClose}>
          <MfaSmsEditForm onClose={onClose} />
        </PasswordGuard>
      </>
    </section>
  );
};
