import {period, range} from '@telia/cpa-web-common';
import classnames from 'classnames';
import React, {FunctionComponent, useCallback, useMemo, useState} from 'react';

import {useAccessNumberHistory} from '../../hooks/useAccessNumberHistory';
import {useAccessNumberSeries} from '../../hooks/useAccessNumberSeries';
import {getLog} from '../../log';
import {ID} from '../../model';
import Loading from '../Loading';
import Error from '../common/Error';
import {InformationLineFc} from '../common/InformationLine';
import List from '../common/List';
import ListItem from '../common/ListItem';

const log = getLog('PickAccessNumber', 'INFO');

interface PickAccessNumberProps {
  productType: ID;
  productId?: ID;
  rangeCount?: number;
  startDate?: string | null;
  endDate?: string | null;
  onPickAccessNumber: (accessNumber: ID) => void;
}

export const PickAccessNumber: FunctionComponent<PickAccessNumberProps> = (props) => {
  const {productType, productId, startDate, endDate, onPickAccessNumber, rangeCount} = props;
  const [selectedSeriesId, setSelectedSeriesId] = useState<ID>();
  const [processingSelectedSeries, setProcessingSelectedSeries] = useState(false);
  const {loading: loadingAccessNumberHistory, accessNumberHistoryList} = useAccessNumberHistory();
  const {loading: loadingAccessNumberSeries, accessNumberSeriesList} = useAccessNumberSeries();
  const filteredAccessNumberSeriesList = useMemo(
    () => accessNumberSeriesList?.filter(({productTypes}) => productTypes?.includes(productType)),
    [productType, accessNumberSeriesList]
  );
  const selectedSeries = useMemo(
    () => (!selectedSeriesId ? undefined : filteredAccessNumberSeriesList?.find(({id}) => id === selectedSeriesId)),
    [filteredAccessNumberSeriesList, selectedSeriesId]
  );

  const selectedSeriesValues = useMemo<string[] | undefined>(
    () => selectedSeries && range.rangeValues(selectedSeries),
    [selectedSeries]
  );
  const selectedSeriesHistory = useMemo(
    () =>
      selectedSeries &&
      accessNumberHistoryList?.filter((h) =>
        h.accessNumber
          ? range.rangeIncludes(selectedSeries, h.accessNumber)
          : h.accessNumberRange
          ? range.rangesOverlap(selectedSeries, h.accessNumberRange)
          : undefined
      ),
    [selectedSeries, accessNumberHistoryList]
  );
  useCallback(
    () => setProcessingSelectedSeries(selectedSeriesId !== undefined && selectedSeriesHistory === undefined),
    [selectedSeriesId, selectedSeriesHistory]
  );
  const [hoveredIndex, setHoveredIndex] = useState<number>();

  log.debug('render', {
    props,
    loadingAccessNumberHistory,
    loadingAccessNumberSeries,
    accessNumberHistoryList,
    filteredAccessNumberSeriesList,
    accessNumberSeriesList,
    selectedSeriesId,
    selectedSeries,
    selectedSeriesValues,
    selectedSeriesHistory,
    processingSelectedSeries,
    rangeCount,
  });
  return (
    <React.Fragment>
      {loadingAccessNumberSeries || loadingAccessNumberHistory ? (
        <Loading />
      ) : (
        <div>
          {!filteredAccessNumberSeriesList ? (
            <InformationLineFc>{'Preparing access number series...'}</InformationLineFc>
          ) : filteredAccessNumberSeriesList.isEmpty() ? (
            <Error>No access number series found for service type {productType}</Error>
          ) : !selectedSeriesId ? (
            <div id="customer-list__wrapper" className={'customer-list--wrapper'}>
              <List className={'customer-list'}>
                {filteredAccessNumberSeriesList.map((series) => (
                  <ListItem
                    className={'customer-list__item'}
                    key={series.from}
                    onClick={() => setSelectedSeriesId(series.id)}
                  >
                    <div>
                      <span className="bold width8rem noWrap">{series.name || series.id}</span>{' '}
                      <span className="width8rem rightText">{series.from}</span> -{' '}
                      <span className="width8rem"> {series.to}</span>
                    </div>
                    <div className={'default'}>{series.comment}</div>
                  </ListItem>
                ))}
              </List>
            </div>
          ) : (
            <div>
              {processingSelectedSeries ? (
                <Loading>Processing Series...</Loading>
              ) : !selectedSeries || !selectedSeriesValues || !selectedSeriesHistory ? (
                <Error>Unexpected state selecting series values</Error>
              ) : (
                <div>
                  Available <span className="bold">{selectedSeries.name || selectedSeries.id}</span> numbers{' '}
                  <span className={'bold'}>
                    {endDate ? `between ${startDate} and ${endDate}` : `from ${startDate}`}
                  </span>
                  :
                  <div>
                    {selectedSeriesValues.map((seriesAccessNumber, i) => {
                      const isTaken = selectedSeriesHistory
                        .filter((h) =>
                          h.accessNumber
                            ? h.accessNumber === seriesAccessNumber
                            : h.accessNumberRange && range.rangeIncludes(h.accessNumberRange, seriesAccessNumber)
                        )
                        .some((h) =>
                          h.productHistory.some(
                            (ph) =>
                              (!productId || (productId && ph.id !== productId)) &&
                              period.overlap(ph, {startDate, endDate})
                          )
                        );
                      const isRange = rangeCount !== undefined;
                      const rangeTo =
                        rangeCount === undefined ? undefined : range.rangeTo(seriesAccessNumber, rangeCount);
                      const isRangeOutOfSeries = rangeTo && rangeTo.localeCompare(selectedSeries.to) > 0;
                      const isAnyInRangeTaken =
                        !isTaken &&
                        rangeTo &&
                        selectedSeriesHistory
                          .filter((h) =>
                            h.accessNumber
                              ? range.rangeIncludes({from: seriesAccessNumber, to: rangeTo}, h.accessNumber)
                              : h.accessNumberRange &&
                                range.rangesOverlap(h.accessNumberRange, {
                                  from: seriesAccessNumber,
                                  to: rangeTo,
                                })
                          )
                          .some((h) =>
                            h.productHistory.some(
                              (ph) =>
                                (!productId || (productId && ph.id !== productId)) &&
                                period.overlap(ph, {startDate, endDate})
                            )
                          );
                      const isAnyInRangeUnavailable = isRangeOutOfSeries || isAnyInRangeTaken;
                      const isInHoveredRange =
                        hoveredIndex && isRange && i >= hoveredIndex && i - hoveredIndex < rangeCount;
                      return (
                        <React.Fragment key={seriesAccessNumber}>
                          {i % 10 === 0 && <br />}
                          <span
                            onMouseOver={() => !isTaken && !isAnyInRangeUnavailable && setHoveredIndex(i)}
                            onMouseOut={() => setHoveredIndex(undefined)}
                            className={classnames(
                              isTaken ? 'forbidden' : isAnyInRangeUnavailable ? 'unavailable' : 'pick',
                              isInHoveredRange && 'highlight'
                            )}
                            title={
                              isTaken
                                ? 'Access Number in use'
                                : isAnyInRangeUnavailable
                                ? 'Access Number Range partially unavailable'
                                : isRange
                                ? `Available range start for ${rangeCount} numbers`
                                : 'Available'
                            }
                            onClick={() =>
                              !isTaken && !isAnyInRangeUnavailable ? onPickAccessNumber(seriesAccessNumber) : {}
                            }
                          >
                            {seriesAccessNumber}
                          </span>
                          &nbsp;
                        </React.Fragment>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </React.Fragment>
  );
};
