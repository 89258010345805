import {Button} from '@telia/styleguide';
import React, {FC, useState} from 'react';
import QRCode from 'react-qr-code';

import '../MfaInfo.scss';

import {useUser} from '../../../../hooks/useUser';
import {isProd} from '../../../../utils/host';
import FormColumn from '../../../common/FormColumn';
import FormRow from '../../../common/FormRow';

interface MfaQrCodeProps {
  secretCode: string;
}

export const MfaQrCode: FC<MfaQrCodeProps> = ({secretCode}) => {
  const {realUser} = useUser();

  const [showSecretCode, setShowSecretCode] = useState<boolean>(false);

  return (
    <>
      <FormRow>
        <FormColumn>
          <div className="mfa--qr--code__wrapper">
            <div style={{height: 'auto', margin: '0 auto', maxWidth: '128', width: '100%'}}>
              <QRCode
                size={128}
                value={`otpauth://totp/Telia Bulk Messaging Web${isProd() ? '' : ' - Stage'}:${
                  realUser?.email
                }?secret=${secretCode}`}
                style={{height: 'auto', maxWidth: '100%', width: '100%'}}
              />
            </div>
            <Button
              text={`${showSecretCode ? 'Hide' : 'Show'} secret key`}
              kind={Button.kinds.link}
              onClick={() => setShowSecretCode(!showSecretCode)}
            />
          </div>
          <div />
        </FormColumn>
      </FormRow>

      {showSecretCode && (
        <FormRow>
          <FormColumn>
            <div>
              <p>
                Secret Key:
                <span className="mfa--setup--secret--key__value"> {secretCode}</span>
              </p>
            </div>
          </FormColumn>
        </FormRow>
      )}
    </>
  );
};
