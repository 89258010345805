import {gql, useQuery} from '@apollo/client';
import {CustomerDocument} from '@telia/cpa-web-common/dist/model';
import React, {FC, useEffect} from 'react';
import {Navigate, Outlet, Route, Routes, useLocation, useNavigate, useParams} from 'react-router-dom';

import channelsQuery from '../../graphql/query/channels.graphql';
import customerQuery from '../../graphql/query/customer.graphql';
import CustomerDocumentsQuery from '../../graphql/query/customerDocuments.graphql';

import * as AppRoutes from '../../appRoutes';
import {useRoles} from '../../hooks/useRoles';
import {useUser} from '../../hooks/useUser';
import {getLog} from '../../log';
import {Channel, Customer, ID, UserType} from '../../model';
import {
  ALL_CUSTOMERS_READ,
  CUSTOMER_CONTRACTS_MANAGE,
  CUSTOMER_DOCUMENTS_READ,
  CUSTOMER_SETTLEMENTS_READ,
  CUSTOMER_TECHNICAL_MANAGE,
  SMPP_CONNECTIONS_READ,
} from '../../permissions';
import ErrorBoundary from '../ErrorBoundary';
import Loading from '../Loading';
import BreadCrumb from '../common/BreadCrumb';
import BreadCrumbs from '../common/BreadCrumbs';
import Error from '../common/Error';
import Page from '../common/Page';
import PageTitle from '../common/PageTitle';
import {SubmenuFc, SubmenuItemFc} from '../common/Submenu';
import {RerouterFc} from '../management/Rerouter';
import {SmppConnections} from '../shared/SmppConnections';
import {UserFc} from '../shared/User';
import {UsersFc} from '../shared/Users';
import {AccessControlList} from './AccessControlList';
import {CompanyFc} from './Company';
import {ContactFc} from './Contact';
import {ContactsFc} from './Contacts';
import {ContractFc} from './Contract';
import {ContractsFc} from './Contracts';
import {CustomerSettlementListFc} from './CustomerSettlementApprovedList';
import {Document} from './Document';
import {DocumentsTable} from './DocumentsTable';
import {ProvisioningOverviewFc} from './ProvisioningOverview';
import {Service} from './Service';
import {Services} from './Services';

const log = getLog('Customer', 'INFO');

type RouteParams = {
  customerId: ID;
};

export const CustomerFc: FC = (props) => {
  const {customerId} = useParams<RouteParams>() as RouteParams;
  const {pathname} = useLocation();
  const {currentUserBrand, isCustomer, hasPermission} = useUser();
  const {customerRoles} = useRoles();
  const {loading, data, error, refetch} = useQuery<{customer: Customer}, {customerId: ID}>(gql(customerQuery), {
    variables: {customerId},
    skip: customerId === AppRoutes._CREATE,
  });

  const isDocumentPath = pathname.includes('documents');
  const {loading: loadingDocuments, data: documentsData} = useQuery<{customerDocuments: CustomerDocument[]}>(
    gql(CustomerDocumentsQuery),
    {
      variables: {
        customerId,
      },
      skip: !isDocumentPath || !data?.customer?.brandId,
      fetchPolicy: 'cache-and-network',
    }
  );

  const refetchCustomer = () => refetch();

  const customer = data?.customer;
  const documents = documentsData?.customerDocuments || [];
  const {loading: loadingChannels, data: {channels = []} = {}} = useQuery<{channels: Channel[]}>(gql(channelsQuery));
  const isCreatingCustomer = customerId === AppRoutes._CREATE;
  const subPath = AppRoutes.useSubPath(AppRoutes.PROVISIONING_CUSTOMER__customerId);
  const {formatWithBrand} = AppRoutes.useBrandFormat();
  const navigate = useNavigate();
  useEffect(() => {
    if (currentUserBrand?.id && customer && currentUserBrand.id !== customer?.brandId) {
      isCustomer() ? navigate('/') : navigate(formatWithBrand(AppRoutes.PROVISIONING_LIST));
    }
  }, [currentUserBrand, customer]);
  log.debug('render', {customerId, customer, channels, isCreatingCustomer});
  const pageTitle = loading
    ? 'Loading Customer'
    : isCreatingCustomer
    ? 'Creating customer'
    : customer && customer.company
    ? customer.company.name
      ? customer.company.name
      : 'missing company name'
    : 'No customer';

  return (
    <Page>
      <BreadCrumbs>
        {hasPermission(ALL_CUSTOMERS_READ) && (
          <BreadCrumb text="Provisioning" link={formatWithBrand(AppRoutes.PROVISIONING)} />
        )}
      </BreadCrumbs>

      <PageTitle title={pageTitle} />
      {loading || loadingDocuments ? (
        <Loading />
      ) : error ? (
        <Error error={error} />
      ) : isCreatingCustomer ? (
        <Routes>
          <Route path={subPath(AppRoutes.PROVISIONING_CUSTOMER_COMPANY__customerId)} element={<CompanyFc />} />
        </Routes>
      ) : (
        <div>
          <SubmenuFc ariaLabel={'Customer submenu'}>
            <SubmenuItemFc
              link={formatWithBrand(AppRoutes.PROVISIONING_CUSTOMER_OVERVIEW__customerId, customerId)}
              text="Overview"
            />
            <SubmenuItemFc
              link={formatWithBrand(AppRoutes.PROVISIONING_CUSTOMER_COMPANY__customerId, customerId)}
              text="Company"
            />
            <SubmenuItemFc
              link={formatWithBrand(AppRoutes.PROVISIONING_CUSTOMER_USERS__customerId, customerId)}
              text="Users"
            />
            {(hasPermission(CUSTOMER_CONTRACTS_MANAGE) ||
              (customer && customer.contracts && customer.contracts.length >= 1)) && (
              <SubmenuItemFc
                link={formatWithBrand(AppRoutes.PROVISIONING_CUSTOMER_CONTRACTS__customerId, customerId)}
                text="Contracts"
              />
            )}
            <SubmenuItemFc
              link={formatWithBrand(AppRoutes.PROVISIONING_CUSTOMER_SERVICES__customerId, customerId)}
              text="Services"
            />
            <SubmenuItemFc
              link={formatWithBrand(AppRoutes.PROVISIONING_CUSTOMER_CONTACTS__customerId, customerId)}
              text="Communication"
            />
            {hasPermission(CUSTOMER_SETTLEMENTS_READ) && (
              <SubmenuItemFc
                link={formatWithBrand(AppRoutes.PROVISIONING_CUSTOMER_SETTLEMENTS__customerId, customerId)}
                text="Settlements"
              />
            )}
            {hasPermission(CUSTOMER_TECHNICAL_MANAGE) && customer && (
              <SubmenuItemFc
                link={formatWithBrand(AppRoutes.PROVISIONING_CUSTOMER_ACL__customerId, customer.id)}
                text="ACL"
              />
            )}
            {(hasPermission(SMPP_CONNECTIONS_READ) || hasPermission(CUSTOMER_TECHNICAL_MANAGE)) && (
              <SubmenuItemFc
                link={formatWithBrand(AppRoutes.PROVISIONING_CUSTOMER_SMPP_CONNECTIONS__customerId, customerId)}
                text="Smpp Connections"
              />
            )}
            {hasPermission(CUSTOMER_DOCUMENTS_READ) && (
              <SubmenuItemFc
                link={formatWithBrand(AppRoutes.PROVISIONING_CUSTOMER_DOCUMENTS__customerId, customerId)}
                text="Documents"
              />
            )}
          </SubmenuFc>
          <ErrorBoundary key={pathname}>
            <Routes>
              <Route
                path={subPath(AppRoutes.PROVISIONING_CUSTOMER__customerId)}
                element={
                  <Navigate
                    replace
                    to={formatWithBrand(AppRoutes.PROVISIONING_CUSTOMER_OVERVIEW__customerId, customerId)}
                  />
                }
              />
              <Route
                path={subPath(AppRoutes.PROVISIONING_CUSTOMER_OVERVIEW__customerId)}
                element={<ProvisioningOverviewFc customer={customer} />}
              />
              <Route path={subPath(AppRoutes.PROVISIONING_CUSTOMER_COMPANY__customerId)} element={<CompanyFc />} />
              <Route
                path={subPath(AppRoutes.PROVISIONING_CUSTOMER_USER__customerId_userId)}
                element={
                  <UserFc
                    type={UserType.CUSTOMER}
                    customerId={customerId}
                    roles={customerRoles}
                    goBackUrl={formatWithBrand(AppRoutes.PROVISIONING_CUSTOMER_USERS__customerId, customerId)}
                  />
                }
              />
              <Route
                path={subPath(AppRoutes.PROVISIONING_CUSTOMER_USERS__customerId)}
                element={<UsersFc users={customer?.users} usersBaseUrl={pathname} />}
              />

              {/* Support for legacy route with "products" in the url instead of "services" */}
              {[
                AppRoutes.PROVISIONING_CUSTOMER_PRODUCT__customerId_contractId_productId,
                AppRoutes.PROVISIONING_CUSTOMER_PRODUCTS__customerId,
              ].map((path) => (
                <Route path={subPath(path)} element={<Navigate to={pathname.replace('products', 'services')} />} />
              ))}

              <Route
                path={subPath(AppRoutes.PROVISIONING_CUSTOMER_SERVICE__customerId_contractId_productId)}
                element={
                  !customer ? (
                    <Error text={'Customer is undefined, ID: ' + customerId} />
                  ) : (
                    <Service countryId={customer.countryId} brandId={customer.brandId} />
                  )
                }
              />

              <Route
                path={subPath(AppRoutes.PROVISIONING_CUSTOMER_SERVICES__customerId)}
                element={<Services contracts={customer?.contracts} />}
              />
              <Route
                path={subPath(AppRoutes.PROVISIONING_CUSTOMER_CONTRACT__customerId_contractId)}
                element={<ContractFc contracts={customer?.contracts} refetchCustomer={refetchCustomer} />}
              />
              <Route
                path={subPath(AppRoutes.PROVISIONING_CUSTOMER_CONTRACTS__customerId)}
                element={<ContractsFc contracts={customer?.contracts} customerId={customerId} />}
              />
              <Route
                path={subPath(AppRoutes.PROVISIONING_CUSTOMER_CONTACT__customerId_contactId)}
                element={<ContactFc channels={channels} />}
              />
              <Route
                path={subPath(AppRoutes.PROVISIONING_CUSTOMER_CONTACTS__customerId)}
                element={<ContactsFc contacts={customer?.contacts} channels={channels} />}
              />
              <Route
                path={subPath(AppRoutes.PROVISIONING_CUSTOMER_SETTLEMENTS__customerId)}
                element={<CustomerSettlementListFc />}
              />
              <Route
                path={subPath(AppRoutes.PROVISIONING_CUSTOMER_ACL__customerId)}
                element={
                  <RerouterFc
                    isDisplayed={hasPermission(CUSTOMER_TECHNICAL_MANAGE)}
                    navigateTo={formatWithBrand(AppRoutes.PROVISIONING_CUSTOMER_OVERVIEW__customerId, customerId)}
                  >
                    <AccessControlList />
                  </RerouterFc>
                }
              />
              <Route
                path={subPath(AppRoutes.PROVISIONING_CUSTOMER_DOCUMENT__customerId_documentId)}
                element={<Document />}
              />
              <Route
                path={subPath(AppRoutes.PROVISIONING_CUSTOMER_DOCUMENTS__customerId)}
                element={<DocumentsTable customerId={customerId} documents={documents} />}
              />
              <Route
                path={subPath(AppRoutes.PROVISIONING_CUSTOMER_SMPP_CONNECTIONS__customerId)}
                element={<SmppConnections customerId={customerId} />}
              />
            </Routes>
            <Outlet />
          </ErrorBoundary>
        </div>
      )}
    </Page>
  );
};
