// import {Form} from '@telia/styleguide';
// export default Form.FormRow;

import React, {FC, PropsWithChildren} from 'react';

export const FormRow: FC<PropsWithChildren> = ({children}) => {
  return <div className="form__row">{children}</div>;
};

export default FormRow;
