import React, {FC} from 'react';

import {getLog} from '../../../../log';
import SectionTitle from '../../../common/SectionTitle';
import {PasswordGuard} from '../../PasswordGuard';
import {MfaAppEditForm} from './MfaAppEditForm';

const log = getLog('MfaAppEdit', 'INFO');

interface MfaAppEditProps {
  isConfigured: boolean;
  onClose: () => void;
}

export const MfaAppEdit: FC<MfaAppEditProps> = ({isConfigured, onClose}) => {
  return (
    <section className="form-group">
      <SectionTitle title={`${isConfigured ? 'Reset' : 'Setup'} Authenticator App`} />
      <>
        <PasswordGuard onCancel={onClose}>
          <MfaAppEditForm onClose={onClose} />
        </PasswordGuard>
      </>
    </section>
  );
};
