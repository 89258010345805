import {gql} from '@apollo/client';
import {Button} from '@telia/styleguide';
import React, {FC} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {Link} from 'react-router-dom';

import messageChannelFragment from '../../../graphql/fragment/messageChannel.graphql';
import saveMessageChannelMutation from '../../../graphql/mutation/saveMessageChannel.graphql';
import messageChannelsQuery from '../../../graphql/query/messageChannels.graphql';

import * as AppRoutes from '../../../appRoutes';
import {UseApolloCacheEntityProps, useApolloCacheEntity} from '../../../hooks/useApolloCacheEntity';
import {useBrands} from '../../../hooks/useBrands';
import {useCountries} from '../../../hooks/useCountries';
import {asEntity, useFormState} from '../../../hooks/useFormState';
import {useModal} from '../../../hooks/useModal';
import {useMutationWrap} from '../../../hooks/useMutationWrap';
import {useUser} from '../../../hooks/useUser';
import {getLog} from '../../../log';
import {ID, MessageChannel} from '../../../model';
import {MESSAGE_CHANNELS_MANAGE} from '../../../permissions';
import Loading from '../../Loading';
import FormColumn from '../../common/FormColumn';
import FormRow from '../../common/FormRow';
import PageSubtitle from '../../common/PageSubtitle';
import {FieldTypes, FieldWithFormState} from '../../common/field';
import {PageViewCounter} from '../../metrics/PageViewCounter';
import {SmppConfig} from './SmppConfig';

const log = getLog('Message Channel', 'INFO');

const smsConfigs = [
  ['smsMt', 'SMS MT', 'MT'],
  ['smsDr', 'SMS DR', 'DR'],
  ['smsMo', 'SMS MO', 'MO'],
];

const messageChannelsQueryGql = gql(messageChannelsQuery);

export const MessageChannelFc: FC = (props) => {
  const {hasPermission, currentUserBrand} = useUser();
  const {getCountryId} = useBrands();
  const {formatWithBrand} = AppRoutes.useBrandFormat();
  const {countries} = useCountries();
  const params = useParams<{messageChannel: ID}>() as {messageChannel: ID};
  const useApolloCacheEntityProps: UseApolloCacheEntityProps = {
    fragment: messageChannelFragment,
    // fragmentName: 'messageChannel',
    watchQuery: messageChannelsQueryGql,
    watchVariables: {
      countryId: currentUserBrand?.countryId,
    },
    entityId: params.messageChannel,
    newEntity: {
      id: null,
      ...(currentUserBrand ? {countryId: getCountryId(currentUserBrand.id)} : {}),
    },
  };
  const formStateOptions = useApolloCacheEntity(useApolloCacheEntityProps);
  const formState = useFormState(formStateOptions);
  const {isEditing, entity, onEdit, onCancel, onChange, isCreating} = formState;
  const navigate = useNavigate();
  const saveMessageChannel = useMutationWrap<{saveMessageChannel: MessageChannel}, {messageChannel: MessageChannel}>(
    gql(saveMessageChannelMutation)
  );
  const {showModal} = useModal();
  const onSave = () => {
    const messageChannel = formState.entityAs<MessageChannel>();
    log.debug('onSave', {messageChannel, isCreating});
    saveMessageChannel({
      loadingText: 'Saving message channel...',
      successText: 'Message channel saved',
      variables: {
        messageChannel,
      },
      update: (proxy, {data}) => {
        const {saveMessageChannel} = data || {};
        if (!saveMessageChannel) {
          log.error('Save MessageChannel mutation did not return the updated MessageChannel');
          return;
        }
        if (isCreating) {
          log.debug('saveMessageChannel update. New messageChannel created', saveMessageChannel);
          const data = proxy.readQuery<{messageChannels: MessageChannel[]}>({
            query: messageChannelsQueryGql,
          });
          const messageChannels = [...(data?.messageChannels || []), saveMessageChannel];
          proxy.writeQuery({query: messageChannelsQueryGql, data: {messageChannels}});
          navigate(formatWithBrand(AppRoutes.MANAGEMENT_MESSAGE_CHANNELS__messageChannel, saveMessageChannel.id), {
            replace: true,
          });
        } else {
          log.debug('saveMessageChannel update. Existing messageChannel updated', saveMessageChannel);
        }
      },
    }).then(({data}) => {
      const {saveMessageChannel} = data || {saveMessageChannel: undefined};
      log.debug('saveMessageChannelMutate resolved', saveMessageChannel);
      saveMessageChannel && formState.onSaved(asEntity(saveMessageChannel));
    });
    // .catch(formState.onError);
  };

  log.debug('render', {params, isEditing, entity, onEdit, onCancel, isCreating});

  const formButtons = !entity ? null : !isEditing ? (
    <div>
      {hasPermission(MESSAGE_CHANNELS_MANAGE) && <Button text="Edit" className="" onClick={onEdit} />}
      <Link to={formatWithBrand(AppRoutes.MANAGEMENT_MESSAGE_CHANNELS)}>
        <Button text="Back to List" kind="cancel" />
      </Link>
    </div>
  ) : (
    <div>
      {hasPermission(MESSAGE_CHANNELS_MANAGE) && <Button text="Save" onClick={onSave} kind="primary" />}
      <Button text="Cancel" onClick={onCancel} kind="cancel" />
    </div>
  );

  const removeSmsConfig = (id: ID, title: string) => {
    log.debug('removeSmsConfig', {id, title});
    showModal({
      show: true,
      title: `Remove '${title}' SMS Config`,
      content: <span>Are you sure you want to remove the '${title}' SMS Config?</span>,
      confirmType: 'negative',
      confirmText: 'Delete',
      onConfirm: () => onChange(id)(null),
    });
  };

  return (
    <>
      <PageSubtitle>
        Message Channel
        <div className="inlineBlock marginLeft">{formButtons}</div>
      </PageSubtitle>
      <PageViewCounter page="message-channel" />

      {!entity ? (
        <Loading />
      ) : (
        <>
          <FormRow>
            <FormColumn>
              <FieldWithFormState formState={formState} entityFieldId="id" label="ID" isDisabled={!isCreating} />
            </FormColumn>
          </FormRow>
          <FormRow>
            <FormColumn>
              <FieldWithFormState formState={formState} entityFieldId="name" label="Name" />
            </FormColumn>
          </FormRow>
          <FormRow>
            <FormColumn>
              <FieldWithFormState
                formState={formState}
                entityFieldId="description"
                label="Description"
                type={FieldTypes.textarea}
              />
            </FormColumn>
          </FormRow>
          <FormRow>
            <FormColumn>
              <FieldWithFormState
                formState={formState}
                entityFieldId="countryId"
                label="Country"
                type={FieldTypes.select}
                options={countries}
                isEditing={false}
              />
              <FormColumn>
                <FieldWithFormState formState={formState} entityFieldId="provider" label="Provider" />
              </FormColumn>
            </FormColumn>
          </FormRow>

          <SmppConfig formState={formState} />

          <FormRow>
            <FormColumn>{formButtons}</FormColumn>
          </FormRow>
        </>
      )}
    </>
  );
};
