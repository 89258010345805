import {Alert, Button} from '@telia/styleguide';
import classnames from 'classnames';
import React, {FC, useEffect, useRef, useState} from 'react';
import './Settlement.scss';
import {SettlementPerProductDetailsFc} from './component/SettlementPerProductDetails';
import {SettlementProductTypesSummaryFc} from './component/SettlementProductTypesSummary';
import {SettlementTotalSummaryFc} from './component/SettlementTotalSummary';
import {Field, FieldTypes} from '../../common/field';
import FormColumn, {FormColumnSizeDouble} from '../../common/FormColumn';
import FormRow from '../../common/FormRow';
import {InformationLineFc} from '../../common/InformationLine';
import {useUser} from '../../../hooks/useUser';
import {getLog} from '../../../log';
import {SETTLEMENTS_ADJUST, SETTLEMENTS_APPROVE} from '../../../permissions';
import {Settlement} from '../../../model';
import {SettlementAdjustmentsFc} from './component/SettlementAdjustments';

const log = getLog('PendingSettlement', 'INFO');

interface PendingSettlementFcProps {
  settlement: Settlement;
  onAccept?: () => void;
  onApprove?: () => void;
  onReject?: (comment: string) => void;
}

export const PendingSettlementFc: FC<PendingSettlementFcProps> = (props) => {
  const {settlement} = props;
  const [showPerProductDetails, setShowPerProductDetails] = useState(false);
  const [isAdjusting, setIsAdjusting] = useState(false);
  const [rejectComment, setRejectComment] = useState<string>();
  const isRejecting = rejectComment !== undefined;
  const {hasPermission} = useUser();

  log.debug({isRejecting, rejectComment});

  const adjustmentInputRef = useRef<HTMLInputElement>();
  useEffect(() => {
    log.debug('useEffect adjustmentInputRef?.current', adjustmentInputRef?.current);
    adjustmentInputRef?.current?.focus();
  }, [adjustmentInputRef]);

  const onShowPerProductDetailsToggle = () => {
    setShowPerProductDetails((prevState) => !prevState);
  };

  const onStartRejecting = () => {
    setRejectComment('');
  };

  const onCancelRejecting = () => {
    setRejectComment(undefined);
  };

  const onChangeRejectComment = (value: string) => {
    setRejectComment(value);
  };

  const onConfirmRejection = () => {
    if (!rejectComment) {
      log.error('Cannot reject a settlement without a comment');
    } else {
      props.onReject && props.onReject(rejectComment);
      setRejectComment(undefined);
    }
  };

  if (!settlement) {
    return <InformationLineFc description={'No settlement found'} />;
  }
  const {state, stateAction} = settlement;
  const approved = state === 'APPROVED';
  const rejected = stateAction && stateAction.action === 'REJECT';
  // const adjustments = isAdjusting ? this.state.adjustments : settlementAdjustments;
  // const adjustments = this.state.adjustments;
  return (
    <section
      className={classnames('settlement', {'settlement--pending': !approved}, {'settlement--adjusting': isAdjusting})}
    >
      {state === 'PENDING' ? (
        <section className="settlement__approval">
          {hasPermission(SETTLEMENTS_ADJUST) && props.onAccept ? (
            <InformationLineFc
              description={
                <>
                  <span>
                    {!isAdjusting ? 'Review the settlement before acceptance.' : 'Adjusting settlement...'}
                    <span className={'settlement__show_details__button'} onClick={onShowPerProductDetailsToggle}>
                      {showPerProductDetails ? 'Hide' : 'Show'}
                    </span>{' '}
                    details.
                  </span>
                </>
              }
              buttons={
                !isAdjusting ? (
                  <>
                    <Button
                      text="Accept"
                      onClick={props.onAccept}
                      isDisabled={!props.onAccept}
                      kind={Button.kinds.primary}
                    />
                    <Button text="Adjust" onClick={() => setIsAdjusting(true)} />
                  </>
                ) : (
                  <>
                    {/*<Button text="Save Adjustments" onClick={onSaveAdjustments} kind={Button.kinds.primary} />*/}
                    <Button
                      text="Cancel Adjustments"
                      onClick={() => setIsAdjusting(false)}
                      kind={Button.kinds.cancel}
                    />
                  </>
                )
              }
            />
          ) : (
            <InformationLineFc description={`This settlement hasn't been accepted for approval yet`} />
          )}
        </section>
      ) : (
        state === 'READY_FOR_APPROVAL' && (
          <section className="settlement__approval">
            {hasPermission(SETTLEMENTS_APPROVE) ? (
              <InformationLineFc
                description={
                  <>
                    <span>
                      {!isAdjusting ? 'Review the settlement before approval.' : 'Adjusting settlement...'}
                      <span className={'settlement__show_details__button'} onClick={onShowPerProductDetailsToggle}>
                        {showPerProductDetails ? 'Hide' : 'Show'}
                      </span>{' '}
                      details.
                    </span>
                  </>
                }
                buttons={
                  !isRejecting ? (
                    <>
                      <Button
                        text="Approve & invoice"
                        onClick={props.onApprove}
                        isDisabled={!props.onApprove}
                        kind={Button.kinds.primary}
                      />
                      <Button
                        text="Reject"
                        onClick={onStartRejecting}
                        isDisabled={!props.onReject}
                        kind={Button.kinds.negative}
                      />
                    </>
                  ) : (
                    <>
                      <Button
                        text="Reject"
                        onClick={onConfirmRejection}
                        isDisabled={!props.onReject}
                        kind={Button.kinds.negative}
                      />
                      <Button text="Cancel" onClick={onCancelRejecting} kind={Button.kinds.cancel} />
                    </>
                  )
                }
              />
            ) : (
              <InformationLineFc description={`This settlement hasn't been approved yet`} />
            )}
          </section>
        )
      )}
      {(rejected || isRejecting) && (
        //  @ts-ignore
        <Alert kind={'negative'}>
          {isRejecting ? (
            <FormRow>
              <FormColumn size={FormColumnSizeDouble}>
                <Field
                  type={FieldTypes.textarea}
                  isEditing={true}
                  placeholder={'Write here the rejection reason'}
                  label={'Reject'}
                  value={rejectComment}
                  onChange={onChangeRejectComment}
                />
              </FormColumn>
            </FormRow>
          ) : (
            <React.Fragment>
              <p>
                <span className={'bold'}>REJECTED: </span>
                {stateAction?.comment}
              </p>
            </React.Fragment>
          )}
        </Alert>
      )}
      {settlement.productTypeSummaries && (
        <SettlementProductTypesSummaryFc productTypeSummaries={settlement.productTypeSummaries} />
      )}
      <SettlementPerProductDetailsFc
        settlement={settlement}
        onShowPerProductDetailsToggle={onShowPerProductDetailsToggle}
        showPerProductDetails={showPerProductDetails}
      />
      <SettlementAdjustmentsFc
        adjustments={settlement.adjustments}
        settlement={settlement}
        isAdjusting={isAdjusting}
        onDoneAdjusting={() => {
          setIsAdjusting(false);
        }}
      />
      <SettlementTotalSummaryFc settlement={settlement} showVat={false} />
    </section>
  );
};
