import classnames from 'classnames';
import React, {FunctionComponent} from 'react';
import {getLog} from '../../../log';
import {FieldCommonProps, FieldProps, FieldTypes} from './Field';

const log = getLog('Checkbox');

export interface FieldCheckboxProps extends FieldCommonProps {
  type: typeof FieldTypes.checkbox;
  value?: boolean;
}

export function isFieldCheckboxProps(props: FieldProps): props is FieldCheckboxProps {
  return props.type === FieldTypes.checkbox;
}

export const Checkbox: FunctionComponent<FieldCheckboxProps> = (props) => {
  const {id, isDisabled = false, isEditing, inputRef, label, name, onChange, value} = props;
  return (
    <div
      className={classnames('field__value__wrapper', {
        'field__value__wrapper--unchecked': !isEditing && !value,
      })}
    >
      <input
        id={id}
        name={name}
        type="checkbox"
        className={classnames('visuallyHidden', props.valueClassName)}
        value="on"
        onChange={(e) => {
          log.debug('input onChange', label);
          isEditing && onChange && onChange(e.target.checked);
        }}
        onClick={(e) => {
          log.debug('onClick', e);
          e.stopPropagation();
        }}
        checked={!!value}
        disabled={!isEditing || isDisabled}
        ref={inputRef}
      />

      <label htmlFor={id} className="label">
        <svg className="check-box-with-label__svg" width="20px" height="20px" viewBox="0 0 20 20">
          <path
            className="check-box-with-label__frame"
            d="M16,19H4c-1.7,0-3-1.4-3-3V4c0-1.7,1.3-3,3-3h12c1.6,0,3,1.3,3,3v12C19,17.6,17.6,19,16,19z"
          ></path>
          <path
            className="check-box-with-label__check-mark"
            d="M5,7.7c1.5,2.4,3.1,4.8,4.6,7.3c2.1-3.3,4.3-6.7,6.4-10"
          ></path>
          <path className="check-box-with-label__cross" d="M6,6L14,14M6,14L14,6"></path>
        </svg>
        {label}
      </label>
    </div>
  );
};
