#import '../fragment/smppConfig.graphql'

fragment messageChannelFragment on MessageChannel {
  id
  name
  description
  countryId
  provider
  endpointUrl
  callbackUrl
  smppConfig {
    ...smppConfigFragment
  }
}
