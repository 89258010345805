import React, {FC, PropsWithChildren} from 'react';
import PropTypes from 'prop-types';
import './pageSubtitle.scss';

interface PageSubtitleProps {
  subtitle?: string;
}

const PageSubtitleFc: FC<PropsWithChildren<PageSubtitleProps>> = (props) => {
  return (
    <h2 className="page__pageSubtitle heading heading--level-2">
      {props.subtitle || props.children}
      <br />
    </h2>
  );
};

export default PageSubtitleFc;
