import {format, period} from '@telia/cpa-web-common';
import {Button} from '@telia/styleguide';
import classnames from 'classnames';
import React, {FC, useMemo} from 'react';
import {Link} from 'react-router-dom';
//@ts-ignore
import timeAgo from 'time-ago';

import * as AppRoutes from '../../appRoutes';
import {domId} from '../../dom';
import {useShowInactive} from '../../hooks/useShowInactive';
import {useUser} from '../../hooks/useUser';
import {getLog} from '../../log';
import {Contract, ID} from '../../model';
import {CUSTOMER_CONTRACTS_MANAGE} from '../../permissions';
import FormColumn from '../common/FormColumn';
import FormRow from '../common/FormRow';
import {InformationLineFc} from '../common/InformationLine';
import List from '../common/List';
import ListItem from '../common/ListItem';
import Page from '../common/Page';
import PageSubtitle from '../common/PageSubtitle';
import {PageViewCounter} from '../metrics/PageViewCounter';

const {isActive, isExpired, isFuture, isValid} = period;

const log = getLog('Contracts', 'INFO');

interface ContractsFcProps {
  customerId: ID;
  contracts?: Contract[];
}

export const ContractsFc: FC<ContractsFcProps> = ({customerId, contracts: unfilteredContracts}) => {
  const {hasPermission} = useUser();
  const {formatWithBrand} = AppRoutes.useBrandFormat();
  const {showInactiveComponent, showInactive} = useShowInactive();
  const contracts = useMemo(
    () => unfilteredContracts?.filter((contract) => showInactive || isActive(contract)),
    [unfilteredContracts, showInactive]
  );

  log.debug('props', {customerId, unfilteredContracts, contracts});

  return (
    <Page>
      <React.Fragment>
        <PageSubtitle subtitle="Contracts" />
        <PageViewCounter page="contracts" />

        <FormRow>
          <FormColumn>{showInactiveComponent}</FormColumn>
        </FormRow>

        <div>
          {contracts && !contracts.isEmpty() ? (
            <List id="contractList">
              {contracts.map((contract) => (
                <ListItem
                  id={domId(contract.id, 'contract')}
                  key={contract.id}
                  link={formatWithBrand(
                    AppRoutes.PROVISIONING_CUSTOMER_CONTRACT__customerId_contractId,
                    customerId,
                    contract.id
                  )}
                  className={classnames({inactive: !isActive(contract)})}
                >
                  <div>
                    <span className="bold">{format.contractDisplayName(contract)}</span>
                  </div>
                  {!isValid(contract) ? (
                    <div>
                      <span className="redText">Invalid contract dates </span>Start: {contract.startDate} - End:{' '}
                      {contract.endDate}
                    </div>
                  ) : isExpired(contract) ? (
                    <div>
                      <span className="labelPrefix">Ended </span>
                      {timeAgo.ago(contract.endDate)}
                    </div>
                  ) : isFuture(contract) ? (
                    <div>
                      <span className="labelPrefix">Starts </span>
                      {timeAgo.ago(contract.startDate)}
                    </div>
                  ) : (
                    <div>
                      <span className="labelPrefix">Started </span>
                      {timeAgo.ago(contract.startDate)}
                      {contract.endDate ? (
                        <span>
                          <span className="greyText"> ends </span>
                          {timeAgo.ago(contract.endDate)}
                        </span>
                      ) : (
                        <span className="greyText"> no end </span>
                      )}
                    </div>
                  )}
                  <div>
                    <span className="labelPrefix">Services</span>
                    {contract.products.length} service{contract.products.length === 1 ? '' : 's'}
                  </div>
                </ListItem>
              ))}
            </List>
          ) : (
            <InformationLineFc>No contracts found</InformationLineFc>
          )}
        </div>

        {hasPermission(CUSTOMER_CONTRACTS_MANAGE) && (
          <div id="createContract">
            <Link to={formatWithBrand(AppRoutes.PROVISIONING_CUSTOMER_CONTRACT_CREATE__customerId, customerId)}>
              <Button text="Create contract" />
            </Link>
          </div>
        )}
      </React.Fragment>
    </Page>
  );
};
