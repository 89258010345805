import React, {FC, useMemo} from 'react';

import * as AppRoutes from '../../appRoutes';
import {useShowInactive} from '../../hooks/useShowInactive';
import {useUser} from '../../hooks/useUser';
import {getLog} from '../../log';
import {Customer} from '../../model';
import {
  CUSTOMER_CONTRACTS_MANAGE,
  CUSTOMER_DOCUMENTS_READ,
  CUSTOMER_SETTLEMENTS_READ,
  CUSTOMER_TECHNICAL_MANAGE,
  SMPP_CONNECTIONS_READ,
} from '../../permissions';
import Loading from '../Loading';
import {OverviewBoxFc} from '../common/OverviewBox';
import {OverviewBoxGridFc} from '../common/OverviewBoxGrid';
import PageSubtitle from '../common/PageSubtitle';
import {PageViewCounter} from '../metrics/PageViewCounter';
import {filterInactiveContractsAndProducts} from './utils';

const log = getLog('ProvisioningOverview', 'INFO');

interface ProvisioningOverviewFcProps {
  customer?: Customer;
}

export const ProvisioningOverviewFc: FC<ProvisioningOverviewFcProps> = ({customer}) => {
  if (!customer) {
    return <Loading />;
  }
  const {showInactive} = useShowInactive();
  const contracts = useMemo(() => filterInactiveContractsAndProducts(customer.contracts, showInactive), [customer]);
  const {hasPermission} = useUser();
  const titleCompany = customer.company.name ? 'Company information' : 'missing company name';
  const totalContracts = contracts.length;
  const totalUsers = customer.users?.length;
  const totalContacts = customer.contacts?.length;
  const totalProducts = contracts.reduce((total, contract) => total + contract.products.length, 0);
  const {formatWithBrand} = AppRoutes.useBrandFormat();
  log.debug('render', {customer, showInactive});

  return (
    <div className={'ProvisioningOverviewFc'}>
      <PageSubtitle subtitle="Overview" />
      <PageViewCounter page="overview" />
      <OverviewBoxGridFc>
        <OverviewBoxFc
          icon="company"
          title={titleCompany}
          warning={!customer?.company.name}
          link={formatWithBrand(AppRoutes.PROVISIONING_CUSTOMER_COMPANY__customerId, customer.id)}
        />
        <OverviewBoxFc
          icon="group"
          title={`${totalUsers} User${totalUsers === 1 ? '' : 's'}`}
          warning={totalUsers === 0}
          link={formatWithBrand(AppRoutes.PROVISIONING_CUSTOMER_USERS__customerId, customer.id)}
        />
        {(hasPermission(CUSTOMER_CONTRACTS_MANAGE) ||
          (customer && customer.contracts && customer.contracts.length >= 1)) && (
          <OverviewBoxFc
            icon="business"
            title={`${totalContracts} Contract${totalContracts === 1 ? '' : 's'}`}
            warning={totalContracts === 0}
            link={formatWithBrand(AppRoutes.PROVISIONING_CUSTOMER_CONTRACTS__customerId, customer.id)}
          />
        )}
        <OverviewBoxFc
          icon="shoppingcart"
          title={`${totalProducts} Service${totalProducts === 1 ? '' : 's'}`}
          warning={totalProducts === 0}
          link={formatWithBrand(AppRoutes.PROVISIONING_CUSTOMER_SERVICES__customerId, customer.id)}
          // subTitle={accessNumberLinks}
        />
        <OverviewBoxFc
          icon="chat"
          title={`${totalContacts} Communication contacts`}
          link={formatWithBrand(AppRoutes.PROVISIONING_CUSTOMER_CONTACTS__customerId, customer.id)}
        />
        {hasPermission(CUSTOMER_SETTLEMENTS_READ) && (
          <OverviewBoxFc
            icon="money"
            title="Settlements"
            link={formatWithBrand(AppRoutes.PROVISIONING_CUSTOMER_SETTLEMENTS__customerId, customer.id)}
          />
        )}
        {hasPermission(CUSTOMER_TECHNICAL_MANAGE) && (
          <OverviewBoxFc
            icon="list-view"
            title="ACL"
            link={formatWithBrand(AppRoutes.PROVISIONING_CUSTOMER_ACL__customerId, customer.id)}
          />
        )}
        {(hasPermission(SMPP_CONNECTIONS_READ) || hasPermission(CUSTOMER_TECHNICAL_MANAGE)) && (
          <OverviewBoxFc
            icon="signal"
            title={`Smpp Connections`}
            link={formatWithBrand(AppRoutes.PROVISIONING_CUSTOMER_SMPP_CONNECTIONS__customerId, customer.id)}
          />
        )}
        {hasPermission(CUSTOMER_DOCUMENTS_READ) && (
          <OverviewBoxFc
            icon="document"
            title={`Documents`}
            link={formatWithBrand(AppRoutes.PROVISIONING_CUSTOMER_DOCUMENTS__customerId, customer.id)}
          />
        )}
      </OverviewBoxGridFc>
    </div>
  );
};
