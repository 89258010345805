query serviceCategories {
  serviceCategories {
    id
    name
    types {
      id
      typeId
      name
      invoiceText
    }
  }
}
