import {getLog} from './log';
import {Dict, ID} from './model';

const log = getLog('cachedLocalStorage');

export const AUTH_TOKEN = 'x-token';
export const BRAND_ID = 'brandId';

type Cookies = Dict<string>;

const cookies = (() => {
  const c = document.cookie.split('; ');
  let cookies: Cookies = {};

  for (let i = c.length - 1; i >= 0; i--) {
    const C = c[i].split('=');
    cookies[C[0]] = C[1];
  }
  log.debug(`Read cookies`);
  return cookies;
})();

const removeCookie = function (name: string) {
  document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  // cookies.remove(name);
  delete cookies[name];
};

const formatLogValue = (value: string | null) => {
  return value && value.length > 300 ? `${value.substring(0, 40)}...` : value;
};

const cache: Dict<string | null> = {};
const cachedKeys: ID[] = [AUTH_TOKEN, BRAND_ID];

const _set = (key: string, value: string | null) => {
  cache[key] = value;
};

const _get = (key: string) => {
  return cache[key];
};

const _initKey = (key: string) => {
  const cookieValue = cookies[key];
  if (cookieValue) {
    localStorage.setItem(key, cookieValue);
    removeCookie(key);
    log.debug(`Moved cookie '${key}' to localStorage`);
  }
  const value = localStorage.getItem(key);
  _set(key, value); //  TODO: JSON parse string?
  log.debug(`Initialized key '${key}' with localStorage value '${formatLogValue(value)}'`);
};

const keys = () => cachedKeys;

const init = () => {
  cachedKeys.forEach((key) => _initKey(key));
  log.info(`Initialized cache from localStore`);
};

// TODO: any
const get = (key: string) => {
  const value = _get(key);
  // log.debug(`Get item with key '${key}' and value '${formatLogValue(value)}'`);
  return value;
};

const set = (key: string, value: string) => {
  _set(key, value);
  localStorage.setItem(key, value); //  TODO: JSON stringify?
  log.debug(`Set item with key '${key}' to value '${formatLogValue(value)}`);
};

const remove = (key: string) => {
  _set(key, null);
  localStorage.removeItem(key);
  log.debug(`Remove item with key '${key}'`);
};

export const cachedLocalStorage = {
  keys,
  init,
  get,
  set,
  remove,
};
