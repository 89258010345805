import {useUser} from './useUser';
import {getLog} from '../log';
import {ID} from '../model';
import {useCustomerOverviews} from './useCustomerOverviews';
import {useUserCustomer} from './useUserCustomer';
import {useCallback} from 'react';

const log = getLog('useCustomerName', 'INFO');

export interface UseCustomerName {
  getCustomerName: (customerId?: ID) => string;
}

export const useCustomerName: () => UseCustomerName = () => {
  const {isCustomer} = useUser();
  const {customerName} = useUserCustomer();
  const {loading, customerOverviews, getName} = useCustomerOverviews();

  const getCustomerName: (customerId?: ID) => string = useCallback(
    (customerId) => (!customerId ? '' : isCustomer() ? customerName || customerId : getName(customerId)),
    [isCustomer, customerName, getName]
  );

  const useCustomerName: UseCustomerName = {getCustomerName};
  log.debug(useCustomerName);
  return useCustomerName;
};
