"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const channels = [
    {
        id: 'COMMERCIAL',
        name: 'Commercial',
        description: 'Channel for commercial notifications',
    },
    {
        id: 'SUPPORT',
        name: 'Support',
        description: 'Channel for support related notifications',
    },
    {
        id: 'TECHNICAL',
        name: 'Technical',
        description: 'Channel for technical announcements',
    },
    {
        id: 'OPERATIONS',
        name: 'Operations',
        description: 'Channel for operation announcements',
    },
];
exports.default = channels;
