import React, {FC} from 'react';
import {getLog} from '../../../log';

const log = getLog('_DurationTip', 'INFO');

export const DurationTipFc: FC = (props) => (
  <div>
    ISO 8601 duration format:
    <ul>
      <li>PT0.5S: 500 milliseconds</li>
      <li>PT20S: 20 seconds</li>
      <li>PT1M20S: 1 minute and 20 seconds</li>
    </ul>
  </div>
);
