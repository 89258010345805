import React, {FC} from 'react';
import {useParams} from 'react-router-dom';

import {getLog} from '../../log';
import {ID} from '../../model';
import {PageViewCounter} from '../metrics/PageViewCounter';
import {SettlementFc} from './common/Settlement';

const log = getLog('SettlementPrint');

export const SettlementPrintFc: FC = () => {
  const {customerId, settlementId} = useParams<{customerId: ID; settlementId: ID}>() as {
    customerId: ID;
    settlementId: ID;
  };
  log.debug('render', {customerId, settlementId});
  return (
    <>
      <PageViewCounter page="settlement-print" />
      <SettlementFc settlementId={settlementId} customerId={customerId} pdf={true} />;
    </>
  );
};
