import {EndpointProtocol} from './model';

declare global {
  interface String {
    isEmpty(): boolean;
    capitalize(): String;
    equalsIgnoreCase(other: string): boolean;
    before(search: string): String;
  }
}

String.prototype.isEmpty = function () {
  return !this.length;
};

String.prototype.capitalize = function () {
  return this[0] !== undefined ? this[0].toUpperCase() + this.slice(1) : this;
};

String.prototype.equalsIgnoreCase = function (other: string) {
  return !!other && other.toUpperCase && this.toUpperCase() === other.toUpperCase();
};

String.prototype.before = function (search: string) {
  const index = this.indexOf(search);
  return index < 0 ? this : this.substring(0, index);
};

declare global {
  interface Array<T> {
    isEmpty(): boolean;
    includes(item: T): boolean;
    clone(): Array<T>;
    remove(item: T): Array<T>;
    removeIndex(index: number): Array<T>;
    cloneExcluding(...items: T[]): Array<T>;
    cloneIncluding(...items: T[]): Array<T>;
    cloneAfter(item: T): Array<T>;
    cloneToggle(item: T): Array<T>;
    flatMap(callback: (value: T, index: number, array: T[]) => any, thisArg?: any): Array<T>;
    head(): T;
  }
  interface ArrayConstructor {
    range(start: number, end: number): Array<number>;
  }
}

Array.prototype.isEmpty = function () {
  return !this.length;
};

Array.prototype.includes = function (arg: any) {
  return this.indexOf(arg) > -1;
};

Array.prototype.clone = function () {
  return this.slice();
};

Array.prototype.removeIndex = function (index: number) {
  this.splice(index, 1);
  return this;
};

Array.prototype.remove = function <T>(item: T) {
  const i = this.indexOf(item);
  if (i !== -1) {
    this.removeIndex(i);
  }
  return this;
};

Array.prototype.cloneExcluding = function <T>(...items: T[]) {
  const clone = this.clone();
  items.forEach((item) => clone.remove(item));
  return clone;
};

Array.prototype.cloneIncluding = function <T>(...items: T[]) {
  const clone = this.clone();
  items.forEach((item) => clone.includes(item) || clone.push(item));
  return clone;
};

Array.prototype.cloneAfter = function <T>(item: T) {
  const i = this.indexOf(item);
  return i === -1 ? [] : this.clone().splice(i + 1, this.length);
};

Array.prototype.cloneToggle = function <T>(item: T) {
  let clone = this.slice();
  clone.includes(item) ? clone.remove(item) : clone.push(item);
  return clone;
};

Array.prototype.flatMap = function (lambda: any) {
  return [].concat.apply([], this.map(lambda));
};

Array.prototype.head = function () {
  return this[0];
};

Array.range = (start: number, end: number) => Array.from({length: end - start + 1}, (v, k) => k + start);

export function objectProperty<T, K extends keyof T>(obj: T, key: K) {
  return obj[key];
}

export function cloneObjectWithoutNullProperties<T extends object>(obj?: T): T {
  if (obj === null || obj === undefined) {
    return {} as T;
  }
  const keysOfObject = Object.keys(obj) as (keyof T)[];
  let clone: T = keysOfObject.reduce((clone, key) => {
    const prop = objectProperty(obj, key);
    if (prop !== null && prop !== undefined) {
      clone[key] = obj[key];
    }
    return clone;
  }, {} as T);
  return clone;
}

export function cloneObjectDeepness(obj?: any, deepness?: number): any {
  if (obj === null || obj === undefined || typeof obj === 'string' || typeof obj === 'number') {
    return obj;
  }
  if (obj instanceof Function) {
    return 'Function';
  }
  if (Array.isArray(obj)) {
    return 'Array';
  }

  //  last recursion deepness
  if (deepness === 0) {
    if (obj instanceof Error) {
      return 'Error: ' + obj.message;
    }
    return 'Object';
  }

  let clone: any = Object.keys(obj).reduce((clone: any, key) => {
    clone[key] = cloneObjectDeepness(obj[key], deepness && deepness - 1);
    return clone;
  }, {});
  return clone;
}

export function getIsProtocolCredentialsRequired(protocol?: string): boolean {
  return !(protocol === EndpointProtocol.SMPP || !protocol);
}
