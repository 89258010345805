import {format} from '@telia/cpa-web-common';
import React, {FC} from 'react';

import '../Settlement.scss';

import {getLog} from '../../../../log';
import {Settlement} from '../../../../model';

const log = getLog('SettlementTotalSummary');

interface SettlementTotalSummaryFcProps {
  settlement: Settlement;
  showVat?: boolean;
}

export const SettlementTotalSummaryFc: FC<SettlementTotalSummaryFcProps> = (props) => {
  // log.debug('render', props);
  const {settlement, showVat = true} = props;
  // const {loading, settlement} = props.settlementQuery;
  if (!settlement) {
    return null;
  }
  const {
    currencyCode,
    contract: {invoiceIncludeVat = false} = {},
    summary: {settlementTotal, adjustmentTotal, totalExclVat, vat, vatPercentage, totalInclVat},
  } = settlement;
  return (
    <section className="settlement__aggregate">
      <table id="settlementTotalSummaryTable" className="settlement__list">
        {(showVat || adjustmentTotal !== 0) && (
          <thead>
            <tr>
              <th>Totals summary</th>
              <th className="rightText">Cost</th>
            </tr>
          </thead>
        )}
        <tbody>
          {adjustmentTotal !== 0 && (
            <React.Fragment>
              <tr className="settlement__list__item">
                <td>Settlement sum</td>
                <td className="rightText noWrap">{format.decimal(settlementTotal)}</td>
              </tr>
              <tr className="settlement__list__item">
                <td>Adjustments sum</td>
                <td className="rightText noWrap">{format.decimal(adjustmentTotal)}</td>
              </tr>
            </React.Fragment>
          )}
          {showVat ? (
            <React.Fragment>
              <tr className="settlement__list__item">
                <td>Invoice base</td>
                <td className="rightText noWrap">{format.decimal(totalExclVat)}</td>
              </tr>
              <tr className="settlement__list__item">
                <td>VAT {format.decimal(vatPercentage)}%</td>
                <td className="rightText">{format.decimal(vat)}</td>
              </tr>
              <tr className="settlement__list__item settlement__list__item--sum bold">
                <td>Invoice total</td>
                <td className="rightText noWrap">
                  {currencyCode} {format.decimal(totalInclVat)}
                </td>
              </tr>
            </React.Fragment>
          ) : (
            <tr className="settlement__list__item settlement__list__item--sum bold">
              <td>Total excluding VAT</td>
              <td className="rightText noWrap">
                {currencyCode} {format.decimal(totalExclVat)}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </section>
  );
};
