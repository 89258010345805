import React, {FC} from 'react';

import {getLog} from '../../log';
import {Document} from '../../model';
import {realHost} from '../../utils/host';
import List from '../common/List';
import ListItem from '../common/ListItem';
import Page from '../common/Page';
import PageSubtitle from '../common/PageSubtitle';
import PageTitle from '../common/PageTitle';
import {PageViewCounter} from '../metrics/PageViewCounter';
import {DocumentsCommercial} from './DocumentsCommercial';

const log = getLog('DocumentsMain');

const guides: Document[] = [
  {name: 'Bulk Messaging Implementation Guide', url: 'developer/index.html'},
  {name: 'Bulk SMS REST API v2', url: 'api/restv2/bulk.html'},
  {name: 'Bulk SMS Callback REST API v2', url: 'api/restv2/bulk-callback.html'},
];

const legacyGuides: Document[] = [
  {name: 'Bulk SMS Implementation guide (SOAP)', url: 'api/soap/cpa-api-guide.html'},
  {name: 'Bulk SMS REST API v1', url: 'api/rest/bulk.html'},
  {name: 'Bulk SMS Callback REST API v1', url: 'api/rest/bulk-callback.html'},
];

export const DocumentsMainFc: FC = (props) => {
  log.debug('render', props);

  const host = realHost();

  return (
    <Page>
      <PageTitle title="Documents" />
      <PageViewCounter page="documents" />

      <PageSubtitle subtitle="API guides" />
      <List>
        {guides.map(({name, url}) => (
          <ListItem key={name}>
            <a href={`${host}/documents/${url}`} target="_blank">
              {name}
            </a>
          </ListItem>
        ))}
      </List>

      <PageSubtitle subtitle="Legacy guides" />
      <List>
        {legacyGuides.map(({name, url}) => (
          <ListItem key={name}>
            <a href={`${host}/documents/${url}`} target="_blank">
              {name}
            </a>
          </ListItem>
        ))}
      </List>

      <DocumentsCommercial />
    </Page>
  );
};
