import classnames from 'classnames';
import React, {FC} from 'react';
import {Link} from 'react-router-dom';
import {Icon} from './Icon';
import './OverviewBox.scss';
import {domId} from '../../dom';
import {getLog} from '../../log';
import {IconDefinition} from '@telia/styleguide';
const log = getLog('OverviewBoxFc', 'INFO');

interface OverviewBoxFcProps {
  className?: string;
  icon: IconDefinition;
  link: string;
  subTitle?: string;
  title: string;
  warning?: boolean;
}

export const OverviewBoxFc: FC<OverviewBoxFcProps> = (props) => {
  log.debug('render', {props});
  return (
    <div
      id={domId(props.title, 'overviewBox')}
      className={classnames(
        'overview-box feature-box',
        'feature-box--medium',
        {'overview-box--warning': props.warning},
        props.className,
        {'feature-box--border': true}
      )}
    >
      <Link to={props.link} className={'overview-box__link'}>
        <div className="feature-box__icon overview-box__icon">
          <Icon icon={props.icon} />
        </div>
        <h3 className="feature-box__heading heading heading--level-3">{props.title}</h3>
        {props.subTitle && <div className={'overview-box__subTitle'}>{props.subTitle}</div>}
      </Link>
    </div>
  );
};
