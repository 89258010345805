import React, {FC, PropsWithChildren} from 'react';
import {Navigate} from 'react-router-dom';

import {getLog} from '../../log';

const log = getLog('Rerouter', 'INFO');

export interface RerouterProps extends PropsWithChildren {
  isDisplayed: boolean;
  navigateTo: string;
}

export const RerouterFc: FC<RerouterProps> = (props) => {
  const {isDisplayed, navigateTo, children} = props;

  log.debug('render', props);

  if (isDisplayed) {
    return <>{children}</>;
  }

  return <Navigate to={navigateTo} />;
};
