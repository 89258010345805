import {format} from '@telia/cpa-web-common';
import {FullWidthTable} from '@telia/styleguide';
import React, {FC, useMemo} from 'react';

import {getLog} from '../../log';
import {ID, Settlement, WithId} from '../../model';
import {Icon} from '../common/Icon';
import {Field} from '../common/field';

const {THead, Th, Tr, ThSortable, TBody, Td} = FullWidthTable;

const log = getLog('SettlementTable', 'INFO');

interface SettlementTableProps {
  settlements: Settlement[];
  onShowSettlement: (settlement: Settlement) => void;
  isSelected: (id: ID) => boolean;
  onSelectId?: (id: ID | WithId) => (value: boolean) => void;
}

export const SettlementTableFc: FC<SettlementTableProps> = (props) => {
  const {settlements, onShowSettlement, isSelected, onSelectId} = props;

  const enrichedSettlements = useMemo(
    () =>
      settlements?.map((s) => ({
        customerName: s.customer.company.name,
        total: s.summary.totalExclVat,
        adjustmentTotal: s.summary.adjustmentTotal,
        contractId: s.contract.id,
        type: s.contract.type,
        ...s,
      })),
    [settlements]
  );
  const [sortedSettlements, getThSortableProps] = FullWidthTable.useThSortable(enrichedSettlements || [], 3, []);

  log.debug('render');

  return (
    <FullWidthTable clickable={true}>
      <THead>
        <Tr>
          <Th className={'minWidth'}> </Th>
          <ThSortable {...getThSortableProps('customerName')}>Customer</ThSortable>
          <ThSortable {...getThSortableProps('total')} className={'right'}>
            Total
          </ThSortable>
          <ThSortable {...getThSortableProps('adjustmentTotal')} className={'right'}>
            Adjustments
          </ThSortable>
          <ThSortable {...getThSortableProps('yearMonth')} className={'right'}>
            Period
          </ThSortable>
          <ThSortable {...getThSortableProps('id')} className={'right'}>
            Sett. ID
          </ThSortable>
          <ThSortable {...getThSortableProps('contractId')} className={'right'}>
            Contract ID
          </ThSortable>
          <ThSortable {...getThSortableProps('type')} className={'right minWidth'}>
            <Icon info={'Contract Type: internal | external'} icon={'business'} className={'Icon--small'} />
          </ThSortable>
        </Tr>
      </THead>
      <TBody>
        {sortedSettlements.map((settlement, i) => (
          <Tr key={i} className={settlement.summary.adjustmentTotal !== 0 ? 'darkPurpleText' : ''}>
            <Td className={'minWidth'}>
              <Field
                type={'_field_type_checkbox'}
                value={isSelected(settlement.id)}
                onChange={onSelectId && onSelectId(settlement.id)}
                isEditing={true}
                isDisabled={!onSelectId}
              />
            </Td>
            <Td className={'noWrap'} onClick={() => onShowSettlement(settlement)}>
              {settlement.customerName}
            </Td>
            <Td className={'right'} onClick={() => onShowSettlement(settlement)}>
              {format.decimal(settlement.total, false)}
            </Td>
            <Td className={'right'} onClick={() => onShowSettlement(settlement)}>
              {format.decimal(settlement.adjustmentTotal, false)}
            </Td>
            <Td className={'right'} onClick={() => onShowSettlement(settlement)}>
              {format.yearMonth(settlement.yearMonth)}
            </Td>
            <Td className={'right'} onClick={() => onShowSettlement(settlement)}>
              {settlement.id}
            </Td>
            <Td className={'right'} onClick={() => onShowSettlement(settlement)}>
              {settlement.contractId} {settlement.contract.name}
            </Td>
            <Td className={'right minWidth'} onClick={() => onShowSettlement(settlement)}>
              {settlement.type.substring(0, 3)}
            </Td>
          </Tr>
        ))}
      </TBody>
    </FullWidthTable>
  );
};
