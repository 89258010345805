import classnames from 'classnames';
import React, {FC, useMemo} from 'react';

import '../Settlement.scss';

import {useProductOverviews} from '../../../../hooks/useProductOverviews';
import {useProductTypes} from '../../../../hooks/useProductTypes';
import {getLog} from '../../../../log';
import {ReservedOaOverview, Settlement} from '../../../../model';
import {SettlementFeesTableGroupFc} from './SettlementFeesTableGroup';

const log = getLog('SettlementPerProductDetails', 'INFO');

interface SettlementPerProductDetailsFcProps {
  settlement: Settlement;
  pdf?: boolean;
  showPerProductDetails: boolean;
  onShowPerProductDetailsToggle: () => void;
}

export const SettlementPerProductDetailsFc: FC<SettlementPerProductDetailsFcProps> = ({
  settlement: propSettlement,
  pdf,
  showPerProductDetails,
  onShowPerProductDetailsToggle,
}) => {
  const {getName} = useProductTypes();
  const {canReserveOa} = useProductTypes();
  const {loading: loadingProductOverview, getProductOverview} = useProductOverviews();

  const settlement = useMemo(
    () =>
      propSettlement && {
        ...propSettlement,
        productDetails: propSettlement.productDetails?.map((productDetail) =>
          !canReserveOa(productDetail.productTypeId)
            ? productDetail
            : {
                ...productDetail,
                productDetailId: (getProductOverview(productDetail.productId) as ReservedOaOverview)?.productName,
              }
        ),
      },
    [propSettlement, loadingProductOverview]
  );

  const {productDetails} = settlement;
  log.debug('render', {showPerProductDetails, pdf, productDetails});
  return (
    <section className={classnames('settlement__aggregate')}>
      <table id="settlementPerProductDetailsTable" className="settlement__list">
        <thead>
          <tr>
            <th>
              Per service details
              {pdf || (
                <span className={'settlement__show_details__button'} onClick={onShowPerProductDetailsToggle}>
                  {showPerProductDetails ? 'hide' : 'show'}
                </span>
              )}
            </th>
            {(pdf || showPerProductDetails) && (
              <React.Fragment>
                <th className="rightText width0-on-small-only">
                  <span>Count</span>
                </th>
                <th className="rightText width0-on-small-only">
                  <span>Fee</span>
                </th>
                <th className="rightText">Total</th>
              </React.Fragment>
            )}
          </tr>
        </thead>
        {(pdf || showPerProductDetails) && (
          <tbody>
            {productDetails &&
              productDetails.map(({total, productTypeId, productDetailId, fees}, i) => (
                <SettlementFeesTableGroupFc
                  name={`${getName(productTypeId)} ${productDetailId ? ' \xb7 ' + productDetailId : ''}`}
                  total={total}
                  fees={fees}
                  key={i}
                />
              ))}
          </tbody>
        )}
      </table>
    </section>
  );
};
