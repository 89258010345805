"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const serviceCategories = [
    {
        id: '00',
        name: 'Unspecified',
        types: [
            {
                id: '00000',
                typeId: '000',
                name: 'Unspecified by merchant',
                invoiceText: 'Uspesifisert',
            },
        ],
    },
];
exports.default = serviceCategories;
