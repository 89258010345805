import {User} from '@telia/cpa-web-common/dist/model';
import {Button, Icon} from '@telia/styleguide';
import classNames from 'classnames';
import gql from 'graphql-tag';
import React, {FC, useState} from 'react';

import saveUserMutation from '../../../graphql/mutation/saveUser.graphql';

import './MfaInfo.scss';

import {useMutationWrap} from '../../../hooks/useMutationWrap';
import {useUser} from '../../../hooks/useUser';
import {getLog} from '../../../log';
import {removeLocalFieldsFromUser} from '../../../mutationClean';
import FormColumn from '../../common/FormColumn';
import FormRow from '../../common/FormRow';
import PageSubtitle from '../../common/PageSubtitle';
import {MfaOptionRow} from './MfaOptionRow';
import {MfaAppEdit} from './app/MfaAppEdit';
import {MfaSmsEdit} from './sms/MfaSmsEdit';

const log = getLog('MfaInfo', 'INFO');

export const MfaInfo: FC = () => {
  const {realUser} = useUser();
  const [showAppEdit, setShowAppEdit] = useState<boolean>(false);
  const [showSmsEdit, setShowSmsEdit] = useState<boolean>(false);

  return (
    <>
      <PageSubtitle>
        <Icon icon="security" className={classNames('blueText', 'vertical-align-bottom')} />
        Multi-factor Authentication
      </PageSubtitle>
      {!showSmsEdit ? (
        <FormRow>
          <FormColumn>
            <MfaOptionRow
              isConfigured={!!realUser?.mfaOptions?.sms.enabled}
              title="SMS"
              rightElement={
                <Button
                  text={!!realUser?.mfaOptions?.sms.enabled ? 'Edit' : 'Add'}
                  onClick={() => setShowSmsEdit(true)}
                  kind={!!realUser?.mfaOptions?.sms.enabled ? Button.kinds.normal : Button.kinds.primary}
                />
              }
            />
          </FormColumn>
        </FormRow>
      ) : (
        <MfaSmsEdit isAdded={!!realUser?.mfaOptions?.sms.enabled} onClose={() => setShowSmsEdit(false)} />
      )}
      {!showAppEdit ? (
        <FormRow>
          <FormColumn>
            <MfaOptionRow
              isConfigured={!!realUser?.mfaOptions?.app.enabled}
              title="Authenticator app"
              rightElement={
                <Button
                  text={!!realUser?.mfaOptions?.app.enabled ? 'Reset' : 'Add'}
                  onClick={() => setShowAppEdit(true)}
                  kind={!!realUser?.mfaOptions?.app.enabled ? Button.kinds.normal : Button.kinds.primary}
                />
              }
            />
          </FormColumn>
        </FormRow>
      ) : (
        <MfaAppEdit isConfigured={!!realUser?.mfaOptions?.app.enabled} onClose={() => setShowAppEdit(false)} />
      )}
    </>
  );
};
