#import '../fragment/getSignedUploadUrl.graphql'

mutation getSignedUploadUrl(
  $bucket: String!
  $brandId: String!
  $customerId: String!
  $fileName: String!
  $fileSize: Int!
  $fileType: String!
  $tags: [TagInput!]!
) {
  getSignedUploadUrl(
    bucket: $bucket
    brandId: $brandId
    customerId: $customerId
    fileName: $fileName
    fileSize: $fileSize
    fileType: $fileType
    tags: $tags
  ) {
    ...getSignedUploadUrl
  }
}
