import React, {FC, PropsWithChildren} from 'react';
import Error from './common/Error';
import {getLog} from '../log';

const log = getLog('ErrorBoundary');

interface ErrorBoundaryState {
  hasError: boolean;
}

export default class ErrorBoundary extends React.Component<PropsWithChildren, ErrorBoundaryState> {
  constructor(props: PropsWithChildren) {
    super(props);
    this.state = {hasError: false};
  }

  static getDerivedStateFromError(error: Error) {
    return {hasError: true};
  }

  render() {
    if (this.state.hasError) {
      return <Error text="Error in component" />;
    }
    return this.props.children;
  }
}
