import 'core-js/stable'; //  newer polyfill for Microsoft Edge
import 'regenerator-runtime/runtime'; //  Standalone runtime for Regenerator-compiled generator and async functions.
import React from 'react';
import {RootFc} from './Root';
import {initSentry} from './sentry';
import {cachedLocalStorage} from './cachedLocalStorage';
import {utils} from '@telia/cpa-web-common';
import {getLog} from './log';
import {createRoot} from 'react-dom/client';

const log = getLog('index', 'INFO');
utils;
cachedLocalStorage.init();

const sentryConfig = initSentry();
sentryConfig ? log.info('Initialized Sentry', sentryConfig) : log.info('Skipping Sentry initialization');

const render = (rootElement: React.ReactElement) =>
  createRoot(document.getElementById('reactAppRootContainer')!).render(rootElement);
render(<RootFc />);

if (module.hot) {
  module.hot.accept(); //  Hot Module Replacement (HMR) for ParcelJs 2
}
