import {gql, useLazyQuery} from '@apollo/client';
import {Alert, Button} from '@telia/styleguide';
import React, {FC, useEffect, useState} from 'react';

import customerUsersQuery from '../../graphql/query/customerUsers.graphql';
import userQuery from '../../graphql/query/user.graphql';

import {useCustomerOverviews} from '../../hooks/useCustomerOverviews';
import {useUser} from '../../hooks/useUser';
import {getLog} from '../../log';
import {CustomerOverview, ID, User} from '../../model';
import Loading from '../Loading';
import Form from '../common/Form';
import FormColumn from '../common/FormColumn';
import FormRow from '../common/FormRow';
import PageSubtitle from '../common/PageSubtitle';
import {Field, FieldTypes} from '../common/field';
import {UserListFc} from '../shared/UserList';

const log = getLog('ImpersonateUser', 'INFO');

interface CustomerUsersQuery {
  customerUsers: User[];
}

interface UserQuery {
  user: User;
}

export const ImpersonateUserFc: FC = () => {
  const [customer, setCustomer] = useState<CustomerOverview>();
  const {user, isImpersonating, currentUserBrand, setImpersonatedUser} = useUser();
  const {getCustomerOverview, customerOverviews} = useCustomerOverviews({skipQuery: !currentUserBrand});

  const [fetchCustomerUsers, {loading, data: {customerUsers = undefined} = {}}] = useLazyQuery<CustomerUsersQuery>(
    gql(customerUsersQuery),
    {
      notifyOnNetworkStatusChange: true,
    }
  );
  useEffect(() => {
    customer && fetchCustomerUsers({variables: {customerId: customer.id}});
  }, [customer]);
  const [fetchFullCustomerUser] = useLazyQuery<UserQuery>(gql(userQuery), {
    fetchPolicy: 'no-cache',
  });

  const onImpersonate = (user: User) => {
    fetchFullCustomerUser({
      variables: {userId: user.id},
      onCompleted: ({user}) => {
        log.debug('User to impersonate:', {user});
        setImpersonatedUser(user);
      },
    });
  };

  log.debug('render', {user, isImpersonating});
  return (
    <>
      <PageSubtitle
        subtitle={
          isImpersonating ? 'Viewing Telia Bulk Messaging Web as:' : 'View Telia Bulk Messaging Web as a Customer User'
        }
      />
      {isImpersonating ? (
        <>
          <Form>
            <FormRow>
              <FormColumn>
                <Field label="Firstname" value={user?.firstName} />
                <Field label="Lastname" value={user?.lastName} />
              </FormColumn>
            </FormRow>
            <FormRow>
              <FormColumn>
                <Field label="Email" value={user?.email} />
              </FormColumn>
            </FormRow>
            <FormRow>
              <FormColumn>
                <Field label="Mobile" value={user?.phone} />
              </FormColumn>
            </FormRow>
            <FormRow>
              <FormColumn>
                <Field label="Role" value={user?.roles || []} type={FieldTypes.multi} options={user?.roles || []} />
              </FormColumn>
            </FormRow>
            <FormRow>
              <FormColumn>
                <div>
                  <Button
                    text={'Stop impersonating'}
                    onClick={() => {
                      setImpersonatedUser(undefined);
                    }}
                  />
                </div>
              </FormColumn>
            </FormRow>
          </Form>
        </>
      ) : (
        <>
          {!currentUserBrand && (
            <Alert className="inlineBlock" kind="warning">
              <p>To impersonate a user, first you have to select a brand</p>
            </Alert>
          )}
          <FormRow>
            <FormColumn>
              <div>
                <Field
                  label={'Customer'}
                  isEditing={true}
                  isDisabled={!currentUserBrand}
                  type={FieldTypes.select}
                  options={customerOverviews}
                  value={customer?.id}
                  onChange={(customerId: ID) => setCustomer(getCustomerOverview(customerId))}
                />
              </div>
            </FormColumn>
          </FormRow>

          {loading && <Loading />}
          {customer && customerUsers && <UserListFc users={customerUsers} onSelectUser={onImpersonate} />}
        </>
      )}
    </>
  );
};
