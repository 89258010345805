import {gql, useQuery} from '@apollo/client';
import {Smsc} from '@telia/cpa-web-common/src/model';
import React, {FC, useMemo} from 'react';
import {Navigate, Route, Routes, useLocation} from 'react-router-dom';

import messageChannelsQuery from '../../graphql/query/messageChannels.graphql';
import pendingUsersQuery from '../../graphql/query/pendingUsers.graphql';
import smscsQuery from '../../graphql/query/smscs.graphql';
import teliaUsersQuery from '../../graphql/query/teliaUsers.graphql';

import * as AppRoutes from '../../appRoutes';
import {useRoles} from '../../hooks/useRoles';
import {useServiceCategories} from '../../hooks/useServiceCategories';
import {useUser} from '../../hooks/useUser';
import {getLog} from '../../log';
import {MessageChannel} from '../../model';
import {User, UserType} from '../../model';
import {SMPP_CONNECTIONS_READ, TELIA_USERS_MANAGE} from '../../permissions';
import ErrorBoundary from '../ErrorBoundary';
import Loading from '../Loading';
import BreadCrumb from '../common/BreadCrumb';
import BreadCrumbs from '../common/BreadCrumbs';
import Page from '../common/Page';
import PageTitle from '../common/PageTitle';
import {SubmenuFc, SubmenuItemFc} from '../common/Submenu';
import {SmppConnections} from '../shared/SmppConnections';
import {UserFc} from '../shared/User';
import {UsersFc} from '../shared/Users';
import {OverviewFc} from './ManagementOverview';
import {NotificationsFc} from './Notifications';
import {PendingUser} from './PendingUser';
import {PendingUsers} from './PendingUsers';
import {PermissionsFc} from './Permissions';
import {RerouterFc} from './Rerouter';
import {ServiceCategoriesFc} from './ServiceCategories';
import {SmscFc} from './Smsc';
import {SmscListFc} from './SmscList';
import {MessageChannelFc} from './messageChannel/MessageChannel';
import {MessageChannelListFc} from './messageChannel/MessageChannelList';

const log = getLog('Management', 'INFO');

interface TeliaUsersQuery {
  teliaUsers: User[];
}

interface PendingUsersQuery {
  pendingUsers: User[];
}

interface MessageChannelsQuery {
  messageChannels: MessageChannel[];
}

interface SmscsQuery {
  smscs: Smsc[];
}

export const ManagementFc: FC = (props) => {
  const {loadingUser, currentUserBrand, user, hasBrandPermission, hasPermission} = useUser();

  const {loading: loadingPendingUsers, data: {pendingUsers} = {}} = useQuery<PendingUsersQuery>(gql(pendingUsersQuery));
  const {loading: loadingTeliaUsers, data: {teliaUsers} = {}} = useQuery<TeliaUsersQuery>(gql(teliaUsersQuery), {
    skip: !currentUserBrand,
  });
  const {loading: loadingMessageChannels, data: {messageChannels} = {}} = useQuery<MessageChannelsQuery>(
    gql(messageChannelsQuery),
    {
      variables: {
        countryId: currentUserBrand?.countryId,
      },
      skip: !currentUserBrand,
    }
  );
  const {loading: loadingSmscs, data: {smscs} = {}} = useQuery<SmscsQuery>(gql(smscsQuery), {
    variables: {
      countryId: currentUserBrand?.countryId,
    },
    skip: !currentUserBrand,
  });
  const {loading: loadingRoles, teliaRoles} = useRoles();
  const location = useLocation();
  const subPath = AppRoutes.useSubPath(AppRoutes.MANAGEMENT);
  const {formatWithBrand} = AppRoutes.useBrandFormat();

  const {loading: loadingCategories, isServiceCategoriesVisible} = useServiceCategories();

  const isTeliaAdmin = useMemo(
    () => hasBrandPermission(TELIA_USERS_MANAGE, '*'),
    [loadingTeliaUsers, loadingUser, loadingRoles, loadingCategories, user]
  );

  log.debug('render', {loadingTeliaUsers, teliaUsers, teliaRoles});
  return (
    <Page>
      <BreadCrumbs>
        <BreadCrumb text="Management" link={formatWithBrand(AppRoutes.MANAGEMENT)} />
      </BreadCrumbs>

      <PageTitle title={'Management'} />
      {loadingTeliaUsers || loadingPendingUsers || loadingUser || loadingRoles || loadingCategories ? (
        <Loading />
      ) : !teliaUsers || teliaUsers.isEmpty() || !pendingUsers ? (
        <div>no admin users info</div>
      ) : (
        <div>
          <SubmenuFc ariaLabel={'Management submenu'}>
            <SubmenuItemFc link={formatWithBrand(AppRoutes.MANAGEMENT_OVERVIEW)} text="Overview" />
            <SubmenuItemFc link={formatWithBrand(AppRoutes.MANAGEMENT_NOTIFICATIONS)} text="Communication" />
            {isTeliaAdmin && (
              <SubmenuItemFc link={formatWithBrand(AppRoutes.MANAGEMENT_MESSAGE_CHANNELS)} text="Message Channels" />
            )}
            <SubmenuItemFc link={formatWithBrand(AppRoutes.MANAGEMENT_PERMISSIONS)} text="Permissions & Roles" />
            {isServiceCategoriesVisible && (
              <SubmenuItemFc
                link={formatWithBrand(AppRoutes.MANAGEMENT_SERVICE_CATEGORIES)}
                text="Service Categories"
              />
            )}
            {isTeliaAdmin && <SubmenuItemFc link={formatWithBrand(AppRoutes.MANAGEMENT_SMSCS)} text="SMSCs" />}
            <SubmenuItemFc link={formatWithBrand(AppRoutes.MANAGEMENT_USERS)} text="Users" />
            {isTeliaAdmin && (
              <SubmenuItemFc
                link={formatWithBrand(AppRoutes.MANAGEMENT_PENDING_USERS)}
                text="Pending Users"
                badge={pendingUsers && pendingUsers.length > 0 ? pendingUsers.length : null}
              />
            )}
            {hasPermission(SMPP_CONNECTIONS_READ) && (
              <SubmenuItemFc link={formatWithBrand(AppRoutes.MANAGEMENT_SMPP_CONNECTIONS)} text="Smpp connections" />
            )}
          </SubmenuFc>

          <ErrorBoundary key={location.pathname}>
            <Routes>
              <Route
                path={subPath(AppRoutes.MANAGEMENT)}
                element={<Navigate replace to={formatWithBrand(AppRoutes.MANAGEMENT_OVERVIEW)} />}
              />
              <Route
                path={subPath(AppRoutes.MANAGEMENT_OVERVIEW)}
                element={<OverviewFc users={teliaUsers} pendingUsers={pendingUsers} />}
              />
              <Route path={subPath(AppRoutes.MANAGEMENT_NOTIFICATIONS)} element={<NotificationsFc />} />
              <Route
                path={subPath(AppRoutes.MANAGEMENT_MESSAGE_CHANNELS__messageChannel)}
                element={
                  <RerouterFc isDisplayed={isTeliaAdmin} navigateTo={formatWithBrand(AppRoutes.MANAGEMENT)}>
                    <MessageChannelFc />
                  </RerouterFc>
                }
              />
              <Route
                path={subPath(AppRoutes.MANAGEMENT_MESSAGE_CHANNELS)}
                element={
                  <RerouterFc isDisplayed={isTeliaAdmin} navigateTo={formatWithBrand(AppRoutes.MANAGEMENT)}>
                    <MessageChannelListFc messageChannels={messageChannels} loading={loadingMessageChannels} />
                  </RerouterFc>
                }
              />
              <Route path={subPath(AppRoutes.MANAGEMENT_PERMISSIONS)} element={<PermissionsFc />} />
              <Route
                path={subPath(AppRoutes.MANAGEMENT_SERVICE_CATEGORIES)}
                element={
                  <RerouterFc
                    isDisplayed={isServiceCategoriesVisible}
                    navigateTo={formatWithBrand(AppRoutes.MANAGEMENT)}
                  >
                    <ServiceCategoriesFc />
                  </RerouterFc>
                }
              />
              <Route
                path={subPath(AppRoutes.MANAGEMENT_SMSCS__smsc)}
                element={
                  <RerouterFc isDisplayed={isTeliaAdmin} navigateTo={formatWithBrand(AppRoutes.MANAGEMENT)}>
                    <SmscFc />
                  </RerouterFc>
                }
              />
              <Route
                path={subPath(AppRoutes.MANAGEMENT_SMSCS)}
                element={
                  <RerouterFc isDisplayed={isTeliaAdmin} navigateTo={formatWithBrand(AppRoutes.MANAGEMENT)}>
                    <SmscListFc smscs={smscs} loading={loadingSmscs} />
                  </RerouterFc>
                }
              />
              <Route
                path={subPath(AppRoutes.MANAGEMENT_USER__userId)}
                element={
                  <UserFc
                    type={UserType.TELIA}
                    roles={teliaRoles}
                    goBackUrl={formatWithBrand(AppRoutes.MANAGEMENT_USERS)}
                  />
                }
              />
              <Route
                path={subPath(AppRoutes.MANAGEMENT_USERS)}
                element={<UsersFc users={teliaUsers} usersBaseUrl={formatWithBrand(AppRoutes.MANAGEMENT_USERS)} />}
              />
              <Route
                path={subPath(AppRoutes.MANAGEMENT_PENDING_USERS)}
                element={
                  <RerouterFc isDisplayed={isTeliaAdmin} navigateTo={formatWithBrand(AppRoutes.MANAGEMENT)}>
                    <PendingUsers users={pendingUsers} />
                  </RerouterFc>
                }
              />
              <Route path={subPath(AppRoutes.MANAGEMENT_PENDING_USER__userId)} element={<PendingUser />} />
              <Route
                path={subPath(AppRoutes.MANAGEMENT_SMPP_CONNECTIONS)}
                element={
                  <RerouterFc
                    isDisplayed={hasPermission(SMPP_CONNECTIONS_READ)}
                    navigateTo={formatWithBrand(AppRoutes.MANAGEMENT)}
                  >
                    <SmppConnections />
                  </RerouterFc>
                }
              />
            </Routes>
          </ErrorBoundary>
        </div>
      )}
    </Page>
  );
};
