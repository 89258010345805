import classnames from 'classnames';
import React, {FC, useMemo} from 'react';

import '../management/Permissions.scss';
import './RolesTable.scss';

import {useBrands} from '../../hooks/useBrands';
import {FormState} from '../../hooks/useFormState';
import {useResourceRoles} from '../../hooks/useResourceRoles';
import {useUser} from '../../hooks/useUser';
import {getLog} from '../../log';
import {ID, ResourceRoles, Role, User} from '../../model';
import {TELIA_USERS_MANAGE} from '../../permissions';
import Table from '../common/Table';
import TableCell from '../common/TableCell';
import TableRow from '../common/TableRow';
import Tooltip from '../common/Tooltip';
import {Field, FieldTypes} from '../common/field';

const log = getLog('RolesTable', 'INFO');

interface RolesTable {
  formState: FormState;
  isEditing?: boolean;
  roles: Role[];
}

export const RolesTable: FC<RolesTable> = (props) => {
  const {formState} = props;
  const {isEditing} = props.isEditing ? props : formState;
  const {addBrandRole, removeBrandRole, getBrandRoles} = useResourceRoles();
  const {brands} = useBrands();
  const {hasBrandPermission, userBrands} = useUser();

  const brandOptions = useMemo(() => [{id: '*', name: 'All Brands'}, ...(brands || [])], [brands]);

  const hasRoleInAny = (roleId: ID) =>
    userBrands?.some((brand) => getBrandRoles(brand.id, formState.entityAs<User>().rolesMap).includes(roleId));
  const roleOptions = props.roles || [];

  return (
    <Table id="permissionsTable" className="roles__table">
      <thead>
        <TableRow className="roles__table--row">
          <TableCell isHeader className="label">
            Role
          </TableCell>
          <TableCell isHeader className="roles__table--spacing" />
          {brandOptions.map((brand) => (
            <TableCell key={brand.id + 'Header'} className="rotate-45" isHeader>
              <div>
                <span>{brand.name}</span>
              </div>
            </TableCell>
          ))}
        </TableRow>
      </thead>
      <tbody>
        {roleOptions.map((roleOption, i) => (
          <TableRow key={roleOption.id} className="roles__table--row">
            <TableCell
              className={classnames('roles__table--name', 'label', {
                inactive: !hasRoleInAny(roleOption.id),
              })}
            >
              {roleOption.name}
            </TableCell>
            <TableCell className="roles__table--tip">
              <Tooltip text={roleOption.description} />
            </TableCell>
            {brandOptions.map((brand) => (
              <TableCell key={roleOption.id + brand.id}>
                <Field
                  value={getBrandRoles(brand.id, formState.entityAs<User>().rolesMap, true).includes(roleOption.id)}
                  onChange={(checked) => {
                    const rolesMap = formState.subEntityAt<ResourceRoles[]>('rolesMap');
                    const updatedRolesMap = checked
                      ? addBrandRole(roleOption.id, brand.id, rolesMap)
                      : removeBrandRole(roleOption.id, brand.id, rolesMap);
                    log.debug('updated rolesMap', updatedRolesMap);
                    formState.onChange('rolesMap')(updatedRolesMap as any); //TODO: as any
                  }}
                  type={FieldTypes.checkbox}
                  isEditing={isEditing && hasBrandPermission(TELIA_USERS_MANAGE, brand.id)}
                />
              </TableCell>
            ))}
          </TableRow>
        ))}
      </tbody>
    </Table>
  );
};
