import {gql, useQuery} from '@apollo/client';
import {Button} from '@telia/styleguide';
import classnames from 'classnames';
import React, {FC, useState} from 'react';

import settlementQuery from '../../../graphql/query/settlement.graphql';

import './Settlement.scss';

import * as AppRoutes from '../../../appRoutes';
import {getLog} from '../../../log';
import {ID, Settlement} from '../../../model';
import Loading from '../../Loading';
import {InformationLineFc} from '../../common/InformationLine';
import {SettlementAdjustmentsFc} from './component/SettlementAdjustments';
import {SettlementHeaderFc} from './component/SettlementHeader';
import {SettlementPerProductDetailsFc} from './component/SettlementPerProductDetails';
import {SettlementProductTypesSummaryFc} from './component/SettlementProductTypesSummary';
import {SettlementTotalSummaryFc} from './component/SettlementTotalSummary';

const log = getLog('Settlement', 'INFO');

interface SettlementQuery {
  settlement: Settlement;
}

interface SettlementFcProps {
  settlementId: ID;
  customerId: ID;
  pdf?: boolean;
}

export const SettlementFc: FC<SettlementFcProps> = (props) => {
  const {settlementId, customerId, pdf} = props;
  const [showPerProductDetails, setShowPerProductDetails] = useState(false);
  const {formatWithBrand} = AppRoutes.useBrandFormat();
  const onShowPerProductDetailsToggle = () => {
    setShowPerProductDetails((prevState) => !prevState);
  };

  const {loading, data: {settlement} = {}} = useQuery<SettlementQuery>(gql(settlementQuery), {
    variables: {
      id: settlementId,
      customerId,
    },
  });
  log.debug('render', {showPerProductDetails, props, loading, settlement});
  if (loading) {
    return <Loading />;
  }
  if (!settlement) {
    return (
      <InformationLineFc
        description={`Couldn't fetch settlement ID '${settlementId}' from customer ID '${customerId}'`}
      />
    );
  }
  const {state} = settlement;
  return (
    <section
      className={classnames('settlement', {'settlement--pending': state !== 'APPROVED'}, {'settlement--pdf': pdf})}
    >
      <>
        {pdf ? null : state === 'APPROVED' ? (
          <section className="settlement__approval">
            <InformationLineFc
              description={`Settlement ready to print`}
              buttons={
                <Button
                  text={'Printable Version'}
                  onClick={() =>
                    window.open(
                      AppRoutes.format(
                        AppRoutes.PRINTABLE_SETTLEMENT__customerId_settlementId,
                        settlement.customer.id,
                        settlement.id
                      ),
                      '_blank'
                    )
                  }
                />
              }
            />
          </section>
        ) : state === 'PENDING' ? (
          <section className="settlement__approval">
            <InformationLineFc description={`This settlement hasn't been accepted for approval yet`} />
          </section>
        ) : (
          state === 'READY_FOR_APPROVAL' && (
            <section className="settlement__approval">
              <InformationLineFc description={`This settlement hasn't been approved yet`} />
            </section>
          )
        )}

        <SettlementHeaderFc settlement={settlement} />

        {settlement.productTypeSummaries && (
          <SettlementProductTypesSummaryFc productTypeSummaries={settlement.productTypeSummaries} />
        )}

        <SettlementPerProductDetailsFc
          settlement={settlement}
          pdf={pdf}
          onShowPerProductDetailsToggle={onShowPerProductDetailsToggle}
          showPerProductDetails={showPerProductDetails}
        />

        {settlement.adjustments && <SettlementAdjustmentsFc adjustments={settlement.adjustments} />}

        <SettlementTotalSummaryFc settlement={settlement} />

        {pdf && setTimeout(window.print, 800)}
      </>
    </section>
  );
};
