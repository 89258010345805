import classnames from 'classnames';
import React, {FC, PropsWithChildren} from 'react';
import {Link} from 'react-router-dom';

import {getLog} from '../../log';
import {ID} from '../../model';

const log = getLog('ListItemFc', 'INFO');

interface ListItemFcProps extends PropsWithChildren {
  className?: string;
  id?: ID;
  link?: string;
  onClick?: React.MouseEventHandler;
}

export const ListItemFc: FC<ListItemFcProps> = ({id, className, onClick, link, children}) => {
  log.debug('render', {id, className, link, onClick, children});
  return (
    <li
      id={id}
      className={classnames(
        'list__item list__item--block',
        {'list__item--link list__link': !!onClick || !!link},
        className
      )}
      onClick={onClick}
    >
      {link ? <Link to={link}>{children}</Link> : children}
    </li>
  );
};

export default ListItemFc;
