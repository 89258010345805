import {gql} from '@apollo/client';
import {Button} from '@telia/styleguide';
import React, {FunctionComponent} from 'react';
import {useNavigate, useParams} from 'react-router-dom';

import companyFragment from '../../graphql/fragment/company.graphql';
import createCustomerMutation from '../../graphql/mutation/createCustomer.graphql';
import saveCompanyMutation from '../../graphql/mutation/saveCompany.graphql';
import customerOverviewsQuery from '../../graphql/query/customerOverviews.graphql';

import * as AppRoutes from '../../appRoutes';
import {UseApolloCacheEntityProps, useApolloCacheEntity} from '../../hooks/useApolloCacheEntity';
import {asEntity, useFormState} from '../../hooks/useFormState';
import {useMutationWrap} from '../../hooks/useMutationWrap';
import {useUser} from '../../hooks/useUser';
import {getLog} from '../../log';
import {ApolloObject, Company, Customer, CustomerOverview, ID} from '../../model';
import {CUSTOMER_COMPANY_MANAGE} from '../../permissions';
import Loading from '../Loading';
import {CountryAndBrandFormRowFc} from '../common/CountryAndBrandFormRow';
import Form from '../common/Form';
import FormColumn from '../common/FormColumn';
import FormRow from '../common/FormRow';
import PageSubtitle from '../common/PageSubtitle';
import SectionTitle from '../common/SectionTitle';
import {FieldTypes, FieldWithFormState} from '../common/field';
import {PageViewCounter} from '../metrics/PageViewCounter';

const log = getLog('Company', 'INFO');

const customerOverviewsQueryGql = gql(customerOverviewsQuery);

export const CompanyFc: FunctionComponent = () => {
  const {customerId} = useParams<{customerId: ID}>() as {customerId: ID};
  const isCreatingCustomer = customerId === AppRoutes._CREATE;
  const {currentUserBrand, hasPermission} = useUser();
  const useApolloCacheEntityProps: UseApolloCacheEntityProps = {
    fragment: companyFragment,
    entityId: customerId, //  using Customer ID instead of 'company'
    newEntity: {
      countryId: currentUserBrand?.countryId,
      brandId: currentUserBrand?.id,
      name: null,
      organizationNumber: null,
      businessAddress: {
        streetAddress: null,
        postCode: null,
        city: null,
        country: null,
      },
      billingAddress: {
        streetAddress: null,
        postCode: null,
        city: null,
        country: null,
      },
      differentBillingAddress: false,
    },
  };
  const formStateOptions = useApolloCacheEntity(useApolloCacheEntityProps);
  const formState = useFormState(formStateOptions);
  const {initialEntity, entity, entityAs, isEditing, onEdit, onCancel, onSaved} = formState;
  const navigate = useNavigate();
  const {formatWithBrand} = AppRoutes.useBrandFormat();

  const createCustomer = useMutationWrap<{createCustomer: Customer}, {customer: {company: Company}}>(
    gql(createCustomerMutation)
  );
  const saveCompany = useMutationWrap<{saveCompany: Company}, {customerId: ID; company: Company}>(
    gql(saveCompanyMutation)
  );

  const onSave = async () => {
    log.debug('onSave for customer', {customerId});
    if (isCreatingCustomer) {
      log.debug('onSave needs to create the Customer entity before saving Company');
      try {
        const customerId = await createCustomer({
          variables: {customer: {company: entityAs<Company>()}},
          loadingText: 'Creating customer...',
          successText: 'Customer created',
          update(proxy, {data: updateData}) {
            const {id, company, countryId, brandId} = updateData?.createCustomer!;
            const {name} = company || {};
            log.debug('createCustomer update', {updateData});
            const data = proxy.readQuery<{customerOverviews: ApolloObject<CustomerOverview>[]}>({
              query: customerOverviewsQueryGql,
            }); //  TODO: move to useCustomerOverviews ? Agree, should be moved
            log.debug('createCustomer customerOverviews', data);
            const customerOverview: ApolloObject<CustomerOverview> = {
              __typename: 'CustomerOverview',
              id,
              name,
              countryId,
              brandId,
              productOverviews: [],
            };
            log.debug('createCustomer update', {customerOverview});
            const customerOverviews = [...(data?.customerOverviews || []), customerOverview];
            proxy.writeQuery({
              query: customerOverviewsQueryGql,
              data: {customerOverviews},
            });
          },
        }).then(({data}) => {
          log.debug('createCustomerMutate data', data);
          return data?.createCustomer.id;
        });
        // .catch(props.formState.onError)
        log.debug('onSave created Customer entity, redirecting to Customers page with ID: ', customerId);
        customerId && navigate(formatWithBrand(AppRoutes.PROVISIONING_CUSTOMER_OVERVIEW__customerId, customerId));
      } catch (error) {
        log.warn(`onSave couldn't create the Customer entity`);
      }
    } else {
      log.debug('onSave for existing customer', entity);
      saveCompany({
        variables: {customerId, company: entityAs<Company>()},
        loadingText: 'Saving company...',
        successText: 'Company saved',
        update(proxy, {data}) {
          const {saveCompany} = data || {};
          log.debug('saveCompany update', {saveCompany});
          if (saveCompany && saveCompany.name != initialEntity.name) {
            log.debug('saveCompany update change of company name, updating customerOverviews');
            try {
              const data = proxy.readQuery<{customerOverviews: ApolloObject<CustomerOverview>[]}>({
                query: customerOverviewsQueryGql,
              }); //  TODO: move to useCustomerOverviews ?

              if (data) {
                data.customerOverviews = data?.customerOverviews.map((c: ApolloObject<CustomerOverview>) =>
                  c.id != customerId ? c : {...c, name: saveCompany.name}
                );
              }
              proxy.writeQuery({query: customerOverviewsQueryGql, data});
            } catch (e) {
              log.debug('customerOverviews not fetched yet, nothing more to update');
            }
          }
        },
      }).then(({data}) => {
        const {saveCompany} = data || {saveCompany: undefined};
        log.debug('saveCompanyMutation resolved', saveCompany);
        saveCompany && onSaved(asEntity(saveCompany));
        return saveCompany;
      });
      // .catch(props.formState.onError)
    }
  };

  log.debug('render', {customerId, useApolloCacheEntityProps, isEditing, entity, onEdit, onCancel});
  return (
    <>
      <PageSubtitle subtitle="Company" />
      <PageViewCounter page="company" />

      {!entity ? (
        <Loading />
      ) : (
        <>
          <Form>
            <CountryAndBrandFormRowFc formState={formState} canEdit={false} />

            <FormRow>
              <FormColumn>
                <FieldWithFormState formState={formState} entityFieldId={'name'} label="Company name" />
                <FieldWithFormState formState={formState} entityFieldId={'organizationNumber'} label="Org. number" />
              </FormColumn>
            </FormRow>

            <section className={'form-group'}>
              <SectionTitle title={'Business address'} />

              <FormRow>
                <FormColumn>
                  <FieldWithFormState
                    formState={formState}
                    entityFieldId={'businessAddress.streetAddress'}
                    label="Street address"
                    type={FieldTypes.textarea}
                  />
                </FormColumn>
              </FormRow>
              <FormRow>
                <FormColumn>
                  <FieldWithFormState
                    formState={formState}
                    entityFieldId={'businessAddress.postCode'}
                    label="Zip code"
                  />
                  <FieldWithFormState formState={formState} entityFieldId={'businessAddress.city'} label="City" />
                </FormColumn>
              </FormRow>
              <FormRow>
                <FormColumn>
                  <FieldWithFormState formState={formState} entityFieldId={'businessAddress.country'} label="Country" />
                </FormColumn>
              </FormRow>
            </section>

            <section className={'form-group'}>
              <SectionTitle title={'Billing address'} />

              <FormRow>
                <FormColumn>
                  <FieldWithFormState
                    formState={formState}
                    entityFieldId={'differentBillingAddress'}
                    label="Different Billing address"
                    type={FieldTypes.checkbox}
                  />
                </FormColumn>
              </FormRow>

              {entity.differentBillingAddress && (
                <>
                  <FormRow>
                    <FormColumn>
                      <FieldWithFormState
                        formState={formState}
                        entityFieldId={'billingAddress.streetAddress'}
                        label="Street address"
                        type={FieldTypes.textarea}
                      />
                    </FormColumn>
                  </FormRow>
                  <FormRow>
                    <FormColumn>
                      <FieldWithFormState
                        formState={formState}
                        entityFieldId={'billingAddress.postCode'}
                        label="Zip code"
                      />
                      <FieldWithFormState formState={formState} entityFieldId={'billingAddress.city'} label="City" />
                    </FormColumn>
                  </FormRow>
                  <FormRow>
                    <FormColumn>
                      <FieldWithFormState
                        formState={formState}
                        entityFieldId={'billingAddress.country'}
                        label="Country"
                      />
                    </FormColumn>
                  </FormRow>
                </>
              )}
            </section>
          </Form>

          {hasPermission(CUSTOMER_COMPANY_MANAGE) &&
            (!isEditing ? (
              <FormRow>
                <FormColumn>
                  <div>
                    <Button onClick={onEdit} text="edit" />
                  </div>
                </FormColumn>
              </FormRow>
            ) : (
              <div className="">
                <Button onClick={onSave} text={isCreatingCustomer ? 'Create' : 'Save'} kind={Button.kinds.primary} />
                <Button onClick={onCancel} text="Cancel" kind={Button.kinds.cancel} />
              </div>
            ))}
        </>
      )}
    </>
  );
};
