import {gql, useQuery} from '@apollo/client';
import {format, period} from '@telia/cpa-web-common';
import {ActiveStatus} from '@telia/cpa-web-common/dist/period';
import {accessNumbersSortComparator, withAccessNumbersSortComparator} from '@telia/cpa-web-common/dist/sort';
import {FullWidthTable, IconDefinition} from '@telia/styleguide';
import classnames from 'classnames';
import React, {FC, useMemo} from 'react';
import {Link} from 'react-router-dom';

import reservedOasQuery from '../../graphql/query/reservedOas.graphql';

import * as AppRoutes from '../../appRoutes';
import {useProductOverviews} from '../../hooks/useProductOverviews';
import {useUser} from '../../hooks/useUser';
import {getLog} from '../../log';
import {ID, ReservedOa, ReservedOaFormatClassifier, ReservedOaInput} from '../../model';
import {ALL_CUSTOMERS_READ} from '../../permissions';
import {Icon} from '../common/Icon';
import {InformationLineFc} from '../common/InformationLine';

const log = getLog('ReservedOas', 'INFO');

export const classifierData = {
  ALPHANUM: {
    id: 'ALPHANUM',
    name: 'Lowercase alphanumeric text',
    description: 'Text match for lowercase alphanumeric string',
    icon: 'spell-check',
  },
  NUMBER: {
    id: 'NUMBER',
    name: 'Phone with country code',
    description: 'Phone number match including country code prefix',
    match: /^\+{1}[\d]+$/,
    icon: 'roaming',
  },
  SHORTCODE: {
    id: 'SHORTCODE',
    name: 'Short number',
    description: 'Short code match of 3 or 6 digit code',
    match: /^(\d{3,6}){1}$/,
    icon: 'smartphone',
  },
} as {
  [c in ReservedOaFormatClassifier]: {
    id: ReservedOaFormatClassifier;
    name: string;
    description: string;
    match: RegExp;
    icon: IconDefinition;
  };
};

const {activeStatus} = period;

export const COMMA_SEPARATOR = ', ';
export const parseCommaSeparatedIds: (comaSeparatedIds?: ID) => ID[] = (comaSeparatedIds) =>
  comaSeparatedIds
    ?.split(COMMA_SEPARATOR)
    .map((id) => id.trim())
    .filter((id) => id.length > 0) || [];

export const ReportSenderIdTable: FC = () => {
  const {hasPermission, currentUserBrand} = useUser();
  const {getCompanyNameByProductId, getAccessNumberByProductId, getCustomerIdByProductId} = useProductOverviews();
  const {formatWithBrand} = AppRoutes.useBrandFormat();

  const {data: {reservedOas} = {}} = useQuery<{reservedOas: ReservedOa[]}, {countryId?: ID}>(gql(reservedOasQuery), {
    variables: {
      countryId: currentUserBrand?.countryId,
    },
    skip: !currentUserBrand,
  });

  interface EnrichedReservedOa extends ReservedOa {
    companyName?: undefined;
    customerId?: ID;
    allowedProducts: {productId: ID; accessNumber?: string}[] | undefined;
    accessNumber?: ID;
    activeStatus: ActiveStatus;
  }

  const filteredReservedOas: EnrichedReservedOa[] = useMemo(
    () =>
      (reservedOas || []).map(({allowedProductIds, ...reservedOa}) => {
        const sortedAllowedProducts = allowedProductIds
          ?.map((productId) => ({productId, accessNumber: getAccessNumberByProductId(productId)}))
          .sort(withAccessNumbersSortComparator);
        return {
          ...reservedOa,
          allowedProductIds,
          companyName: reservedOa.ownerProductId ? getCompanyNameByProductId(reservedOa.ownerProductId) : undefined,
          customerId: reservedOa.ownerProductId ? getCustomerIdByProductId(reservedOa.ownerProductId) : undefined,
          allowedProducts: sortedAllowedProducts,
          accessNumber: sortedAllowedProducts?.head()?.accessNumber, //  First access number for sorting
          activeStatus: activeStatus(reservedOa),
        } as EnrichedReservedOa;
      }),
    [reservedOas, getCompanyNameByProductId]
  );
  const [sortedReservedOas, getThSortableProps] = FullWidthTable.useThSortable<EnrichedReservedOa>(
    filteredReservedOas,
    3,
    [],
    {
      // @ts-ignore: special comparators not well defined
      accessNumber: accessNumbersSortComparator,
    }
  );

  log.debug('ReservedOasFc', {reservedOas});

  const {THead, Tr, ThSortable, TBody, Td} = FullWidthTable;

  return (
    <>
      {sortedReservedOas && !sortedReservedOas.isEmpty() ? (
        <FullWidthTable clickable={false}>
          <THead>
            <Tr>
              <ThSortable {...getThSortableProps('senderId')} className="leftText">
                SenderID
              </ThSortable>
              <ThSortable {...getThSortableProps('oaFormatClassifier')} className="centerText minWidth">
                Class
              </ThSortable>
              <ThSortable {...getThSortableProps('effect')}>Effect</ThSortable>
              <ThSortable {...getThSortableProps('companyName')}>Customer</ThSortable>
              <ThSortable {...getThSortableProps('ownerProductId')}>Service</ThSortable>
              <ThSortable {...getThSortableProps('accessNumber')}>Allowed short numbers</ThSortable>
              <ThSortable {...getThSortableProps('startDate')}>Start Date</ThSortable>
              <ThSortable {...getThSortableProps('endDate')}>End Date</ThSortable>
              <ThSortable {...getThSortableProps('activeStatus')}>Active Status</ThSortable>
            </Tr>
          </THead>

          <TBody>
            {sortedReservedOas?.map(
              (
                {
                  companyName: reservedOaCompanyName,
                  customerId: reservedOaCustomerId,
                  allowedProducts: reservedOaAllowedProducts,
                  accessNumber,
                  activeStatus,
                  startDate,
                  endDate,
                  ...reservedOa
                },
                i
              ) => (
                <Tr key={i}>
                  <Td className={'code'} style={{textAlign: 'left'}}>
                    {reservedOa.senderId}
                  </Td>
                  <Td className={'code minWidth'} style={{textAlign: 'left'}}>
                    {reservedOa.oaFormatClassifier && (
                      <Icon
                        icon={classifierData[reservedOa.oaFormatClassifier].icon}
                        info={classifierData[reservedOa.oaFormatClassifier].name}
                      />
                    )}
                  </Td>
                  <Td className={classnames(reservedOa.effect !== 'ALLOW' && 'redText')}>
                    {reservedOa.effect === 'ALLOW' ? 'Allow' : 'Block'}
                  </Td>
                  <Td className={classnames({disabled: !reservedOa.ownerProductId})}>
                    {reservedOa.ownerProductId ? (
                      <Link to={formatWithBrand(AppRoutes.PROVISIONING_CUSTOMER__customerId, reservedOaCustomerId!)}>
                        {reservedOaCompanyName || '???'}
                      </Link>
                    ) : (
                      'System'
                    )}
                  </Td>
                  <Td className={classnames({disabled: !reservedOa.ownerProductId})}>
                    {reservedOa.ownerProductId ? (
                      <Link to={formatWithBrand(AppRoutes.PROVISIONING_PRODUCT__productId, reservedOa.ownerProductId)}>
                        {reservedOa.ownerProductId}
                      </Link>
                    ) : (
                      'System'
                    )}
                  </Td>
                  <Td className={classnames({disabled: !reservedOaAllowedProducts?.length})}>
                    {reservedOaAllowedProducts?.map(({productId, accessNumber}, index, all) => (
                      <React.Fragment key={index}>
                        {hasPermission(ALL_CUSTOMERS_READ) ? (
                          <Link to={formatWithBrand(AppRoutes.PROVISIONING_PRODUCT__productId, productId)}>
                            {accessNumber || '???'}
                          </Link>
                        ) : (
                          accessNumber || '???'
                        )}
                        {index < all.length - 1 && <span>, </span>}
                      </React.Fragment>
                    )) || 'na'}
                  </Td>
                  <Td className={classnames({disabled: !startDate})}>
                    {startDate ? format.date(startDate) : 'no start'}
                  </Td>
                  <Td className={classnames({disabled: !endDate})}>{endDate ? format.date(endDate) : 'no end'}</Td>
                  <Td className={classnames(activeStatus.toLowerCase() + 'Period')}>{activeStatus}</Td>
                </Tr>
              )
            )}
          </TBody>
        </FullWidthTable>
      ) : (
        <InformationLineFc>There are no reserved SenderIDs</InformationLineFc>
      )}
    </>
  );
};
