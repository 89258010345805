"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const permissions = [
    {
        id: 'ALL_CUSTOMERS_MANAGE',
        category: 'TELIA',
        name: 'Manage customers',
        description: 'Allows managing customers',
    },
    {
        id: 'ALL_CUSTOMERS_READ',
        category: 'TELIA',
        name: 'See customers',
        description: 'Provides read access to customers list',
    },
    {
        id: 'CUSTOMER_COMPANY_MANAGE',
        category: 'CUSTOMER',
        name: 'Edit organization info',
        description: 'Allows managing the customers company information',
    },
    {
        id: 'CUSTOMER_CONTRACTS_MANAGE',
        category: 'CUSTOMER',
        name: 'Manage contracts',
        description: 'Allows creating customer contracts',
    },
    {
        id: 'CUSTOMER_PRODUCTS_MANAGE',
        category: 'CUSTOMER',
        name: 'Manage products of a contract',
        description: 'Allows managing customers products',
    },
    {
        id: 'CUSTOMER_SETTLEMENTS_READ',
        category: 'CUSTOMER',
        name: 'Read settlements',
        description: 'Provides read access to the own customer settlements',
    },
    {
        id: 'CUSTOMER_TECHNICAL_MANAGE',
        category: 'CUSTOMER',
        name: 'Technical configuration',
        description: 'Allows managing customers technical configuration',
    },
    {
        id: 'CUSTOMER_USERS_MANAGE',
        category: 'CUSTOMER',
        name: 'Manage users',
        description: 'Allows managing the customers users',
    },
    {
        id: 'PERMISSIONS_MANAGE',
        category: 'AUTH',
        name: 'Manage authZ permissions',
        description: 'Allows managing the Permission definitions',
    },
    {
        id: 'ROLES_ASSUME',
        category: 'TELIA',
        name: 'Impersonate user',
        description: 'Allows user to simulate having other roles',
    },
    {
        id: 'ROLES_MANAGE',
        category: 'AUTH',
        name: 'Manage authZ roles',
        description: 'Allows adding permissions to roles',
    },
    {
        id: 'SETTLEMENTS_ADJUST',
        category: 'SETTLEMENT',
        name: 'Adjust settlements',
        description: 'Allows adjusting all customers settlements and pre-approving them',
    },
    {
        id: 'SETTLEMENTS_APPROVE',
        category: 'SETTLEMENT',
        name: 'Approve & invoice settlement',
        description: 'Permission needed for the final approval of settlements',
    },
    {
        id: 'TELIA_USERS_MANAGE',
        category: 'TELIA',
        name: 'Manage Telia users',
        description: 'Allows managing Telia users, who are not limited to any specific customer',
    },
];
exports.default = permissions;
