import React from 'react';

import {Field, FieldTypes} from '../components/common/field';
import {getLog} from '../log';
import {usePreferences} from './usePreferences';

const log = getLog('useShowInactive', 'INFO');

export const SHOW_INACTIVE_PREFERENCE = 'SHOW_INACTIVE';

export const useShowInactive = () => {
  const {getPreference, setPreference} = usePreferences([SHOW_INACTIVE_PREFERENCE]);
  const showInactive = getPreference<boolean>(SHOW_INACTIVE_PREFERENCE) || false;
  const showInactiveComponent = (
    <Field
      label="Show inactive"
      type={FieldTypes.checkbox}
      isEditing={true}
      value={showInactive}
      onChange={(value: boolean) => {
        log.debug('showInactiveComponent onChange', value);
        setPreference<boolean>(SHOW_INACTIVE_PREFERENCE, value);
      }}
      tip={'Show entities in past or future periods'}
    />
  );
  const useShowInactiveHook = {showInactive, showInactiveComponent};
  log.debug('render', useShowInactiveHook);
  return useShowInactiveHook;
};
