import {gql, useQuery} from '@apollo/client';

import accessNumberSeriesQuery from '../graphql/query/accessNumberSeries.graphql';

import {getLog} from '../log';
import {AccessNumberSeries} from '../model';
import {useUser} from './useUser';

const log = getLog('useAccessNumberSeries', 'INFO');

interface AccessNumberSeriesQuery {
  accessNumberSeries: AccessNumberSeries[];
}

export const useAccessNumberSeries = () => {
  const {currentUserBrand} = useUser();
  const {loading, data: {accessNumberSeries} = {}} = useQuery<AccessNumberSeriesQuery>(gql(accessNumberSeriesQuery), {
    variables: {
      countryId: currentUserBrand?.countryId,
    },
    skip: !currentUserBrand,
  });
  const hook = {loading, accessNumberSeriesList: accessNumberSeries};
  log.debug(hook);
  return hook;
};
