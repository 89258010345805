import {format} from '@telia/cpa-web-common';
import React, {FC, useMemo} from 'react';

import './Settlement.scss';

import {useUser} from '../../../hooks/useUser';
import {getLog} from '../../../log';
import {Settlement} from '../../../model';

const log = getLog('SettlementTotalSum', 'INFO');

interface SettlementTotalSumFcProps {
  settlements?: Settlement[];
}

export const calculateTotal = (settlements?: Settlement[]) => {
  log.debug('Calculating total');
  return (
    settlements?.reduce(
      ([total, adjustmentTotal], settlement) => [
        total + settlement.summary.totalExclVat,
        adjustmentTotal + settlement.summary.adjustmentTotal,
      ],
      [0, 0]
    ) || [0, 0]
  );
};

export const SettlementTotalSumFc: FC<SettlementTotalSumFcProps> = ({settlements}) => {
  const {currentUserBrand} = useUser();
  const [total, adjustmentTotal] = useMemo(() => {
    return calculateTotal(settlements);
  }, [settlements]);

  log.debug('render', {total, adjustmentTotal});
  return (
    <span className={'settlement__total__sum'}>
      <span className={'settlement__total__sum--value'}>
        {format.currency(total, currentUserBrand?.currencyCode, true, true)}
      </span>
      <span className={'settlement__total__sum--label'}> in total, including </span>
      <span className={'settlement__total__sum--value darkPurpleText'}>
        {format.currency(adjustmentTotal, currentUserBrand?.currencyCode, true, true)}
      </span>
      <span className={'settlement__total__sum--label'}> in adjustments</span>
    </span>
  );
};
