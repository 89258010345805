import React, {FC} from 'react';
import classnames from 'classnames';
import {PropsWithClassName} from '../../model';

interface ListFcProps extends PropsWithClassName {
  id?: string;
}
export const ListFc: FC<ListFcProps> = (props) => (
  <ul id={props.id} className={classnames('list list--links list--links--block', props.className)}>
    {props.children}
  </ul>
);

export default ListFc;
