"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const settlements = [
    {
        id: '11111',
        date: new Date('2023-02-08T13:17:36.064Z'),
        state: 'PENDING',
        isComplete: true,
        yearMonth: '202301',
        currencyCode: 'NOK',
        customer: {
            id: '22222',
            countryId: 'NO',
            brandId: 'TELIA_NO',
            company: {
                id: '22222',
                name: 'test company 1',
                organizationNumber: '33333',
                countryId: 'NO',
                brandId: 'TELIA_NO',
            },
        },
        contract: {
            id: '44444',
            name: 'test contract 1',
            type: 'EXTERNAL',
            invoiceIncludeVat: true,
            invoiceReference: 'test invoiceReference',
            invoiceAccountId: 'test invoiceAccountId',
            comment: 'test comment 1',
        },
        summary: {
            settlementTotal: 12500,
            adjustmentTotal: 100,
            totalExclVat: 12600,
            vat: 3125,
            vatPercentage: 25,
            totalInclVat: 15725,
        },
        adjustments: [],
        stateAction: {
            action: 'REJECT',
            comment: 'charge some fee',
        },
        productTypeSummaries: [
            {
                productTypeId: 'SMS_BULK',
                fees: [
                    {
                        feeId: 'MONTHLY',
                        type: 'MONTHLY',
                        quantity: 5,
                        fee: 2500,
                        total: 12500,
                    },
                ],
                total: 12500,
            },
        ],
        productDetails: [
            {
                productDetailId: '004',
                productId: '20064',
                productTypeId: 'SMS_BULK',
                total: 2500,
                fees: [
                    {
                        feeId: 'MONTHLY',
                        type: 'MONTHLY',
                        quantity: 1,
                        fee: 2500,
                        total: 2500,
                    },
                ],
            },
            {
                productDetailId: '200',
                productId: '20062',
                productTypeId: 'SMS_BULK',
                total: 2500,
                fees: [
                    {
                        feeId: 'MONTHLY',
                        type: 'MONTHLY',
                        quantity: 1,
                        fee: 2500,
                        total: 2500,
                    },
                ],
            },
            {
                productDetailId: '206',
                productId: '20067',
                productTypeId: 'SMS_BULK',
                total: 2500,
                fees: [
                    {
                        feeId: 'MONTHLY',
                        type: 'MONTHLY',
                        quantity: 1,
                        fee: 2500,
                        total: 2500,
                    },
                ],
            },
            {
                productDetailId: '203',
                productId: '20065',
                productTypeId: 'SMS_BULK',
                total: 2500,
                fees: [
                    {
                        feeId: 'MONTHLY',
                        type: 'MONTHLY',
                        quantity: 1,
                        fee: 2500,
                        total: 2500,
                    },
                ],
            },
            {
                productDetailId: '205',
                productId: '20066',
                productTypeId: 'SMS_BULK',
                total: 2500,
                fees: [
                    {
                        feeId: 'MONTHLY',
                        type: 'MONTHLY',
                        quantity: 1,
                        fee: 2500,
                        total: 2500,
                    },
                ],
            },
        ],
    },
    {
        id: '00000',
        date: new Date('2023-02-08T13:17:38.522Z'),
        state: 'PENDING',
        stateAction: undefined,
        yearMonth: '202301',
        currencyCode: 'NOK',
        isComplete: true,
        customer: {
            id: '99999',
            countryId: 'NO',
            brandId: 'TELIA_NO',
            company: {
                id: '99999',
                name: 'test company 2',
                organizationNumber: '88888',
                countryId: 'NO',
                brandId: 'TELIA_NO',
            },
        },
        contract: {
            id: '77777',
            name: 'test contract 2',
            type: 'EXTERNAL',
            invoiceIncludeVat: false,
            invoiceReference: 'test invoiceReference 2',
            invoiceAccountId: 'test invoiceAccountId 2',
            comment: 'test comment 2',
        },
        summary: {
            settlementTotal: 2500,
            adjustmentTotal: 0,
            totalExclVat: 2500,
            vat: 0,
            vatPercentage: 0,
            totalInclVat: 2500,
        },
        productTypeSummaries: [
            {
                productTypeId: 'SMS_BULK',
                fees: [
                    {
                        feeId: 'MONTHLY',
                        type: 'MONTHLY',
                        quantity: 1,
                        fee: 2500,
                        total: 2500,
                    },
                ],
                total: 2500,
            },
        ],
        adjustments: [],
        productDetails: [
            {
                productDetailId: '26185',
                productId: '2777',
                productTypeId: 'SMS_BULK',
                total: 2500,
                fees: [
                    {
                        feeId: 'MONTHLY',
                        type: 'MONTHLY',
                        quantity: 1,
                        fee: 2500,
                        total: 2500,
                    },
                ],
            },
        ],
    },
];
exports.default = settlements;
