import {Container} from '@telia/styleguide';
import React, {FC, useRef} from 'react';

import {useFormState} from '../../hooks/useFormState';
import {getLog} from '../../log';
import FormColumn from './FormColumn';
import FormRow from './FormRow';
import {FieldTypes, FieldWithFormState} from './field';

const log = getLog('TypeToConfirmField', 'INFO');

interface TypeToConfirmFieldProps {
  confirmText: string;
  onChange: (confirmed: boolean) => void;
}

export const TypeToConfirmField: FC<TypeToConfirmFieldProps> = (props) => {
  const {confirmText, onChange} = props;
  const formState = useFormState({isEditing: true});

  const prevConfirmedState = useRef(false);

  return (
    <>
      <i> To confirm you are sure, type '{confirmText}' in the box below. </i>
      <Container noPadding className="form--compact">
        <FormRow>
          <FormColumn>
            <FieldWithFormState
              entityFieldId="confirmField"
              formState={formState}
              type={FieldTypes.input}
              placeholder={`Type '${confirmText}'`}
              onChangeAlso={(value) => {
                const isConfirmed = value === 'update';

                // Only fire onChange function if confirmed state has actually changed
                if (prevConfirmedState.current !== isConfirmed) {
                  prevConfirmedState.current = isConfirmed;
                  onChange(isConfirmed);
                }
              }}
            />
          </FormColumn>
        </FormRow>
      </Container>
    </>
  );
};
