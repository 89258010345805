fragment userFragment on User {
  id
  cognitoId
  firstName
  lastName
  phone
  email
  description
  permissions
  type
  userAccessStatusType
  customerId
  #    channels
  comment
  #  lastLogin
  roles
  rolesMap {
    resource
    roles
    permissions
  }
  isSSOUser
  mfaOptions {
    sms {
      enabled
      phone
    }
    app {
      enabled
    }
  }
}
