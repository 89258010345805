import {ServerError, ServerParseError} from '@apollo/client';
import {GraphQLError} from 'graphql';
import {ID} from '../model';

export interface Exception {
  name?: string;
  data?: DomainModelError;
}
export interface DomainModelError {
  name: 'DomainModelError';
  entity: ID;
  field: ID;
  message: string;
  status?: number;
  statusText: string;
  code: ID;
  method: 'PUT' | 'POST';
  url: string;
}

export const exception: (error: GraphQLError) => Exception = (error) => error?.extensions?.exception as Exception;
export const errorData = (error: GraphQLError) => exception(error)?.data;
export function isDomainModelError(domainModelError?: DomainModelError): domainModelError is DomainModelError {
  return !!domainModelError && domainModelError.name === 'DomainModelError';
}
export const extractDomainModelError: (error: GraphQLError) => DomainModelError | undefined = (error) =>
  isDomainModelError(errorData(error)) ? errorData(error) : undefined;

export function isServerError(error: Error): error is ServerError {
  const serverError = error as ServerError;
  return !!serverError && !!serverError.response && !!serverError.result && !!serverError.statusCode;
}

export function isServerParseError(error: Error): error is ServerParseError {
  const serverParseError = error as ServerParseError;
  return (
    !!serverParseError && !!serverParseError.response && !!serverParseError.bodyText && !!serverParseError.statusCode
  );
}
