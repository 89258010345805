import React, {FC, PropsWithChildren} from 'react';
import classnames from 'classnames';
import {PropsWithClassName, PropsWithId} from '../../model';

interface TableRowProps extends PropsWithId, PropsWithClassName, PropsWithChildren {
  type?: 'header' | 'normal'; //TODO: Remove this? Not used
}

const TableRowFc: FC<TableRowProps> = (props) => {
  return (
    <tr id={props.id} className={classnames(props.className)}>
      {props.children}
    </tr>
  );
};

export default TableRowFc;
