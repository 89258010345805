import React, {useState} from 'react';

import {cachedLocalStorage} from '../cachedLocalStorage';
import {getLog} from '../log';

const log = getLog('usePreferences', 'INFO');

type Preference = any;

interface Preferences {
  [key: string]: Preference;
}

interface PreferencesHook {
  preferences: Preferences;
  getPreference: <T = Preference>(key: string) => T | undefined;
  setPreference: <T = Preference>(key: string, value: T) => void;
}

export const usePreferences: (preferenceKeys?: string[]) => PreferencesHook = (preferenceKeys = []) => {
  const getPreference = (key: string) => {
    if (preferenceKeys.includes(key)) {
      const serializedValue = localStorage.getItem(key); //  returns null if not found
      const value = serializedValue && JSON.parse(serializedValue);
      log.debug('getPreference', key, value);
      return value;
    } else {
      log.debug('skip getPreference', key);
      return undefined;
    }
  };

  const [preferences, setPreferences] = useState<Preferences>(
    preferenceKeys.reduce((preferences: Preferences, key) => {
      const preference = getPreference(key);
      if (preference !== null) {
        preferences[`${key}`] = preference;
      }
      return preferences;
    }, {})
  );

  const setPreference = (key: string, value: Preference) => {
    if (preferenceKeys.includes(key)) {
      log.debug('setPreference', key, value);
      // Check for any keys that needs to also be stored in cachedLocalStorage
      if (cachedLocalStorage.keys().includes(key)) {
        value ? cachedLocalStorage.set(key, JSON.stringify(value)) : cachedLocalStorage.remove(key);
      } else {
        value ? localStorage.setItem(key, JSON.stringify(value)) : localStorage.removeItem(key);
      }
      setPreferences((prevPreferences) => ({...prevPreferences, [`${key}`]: value}));
    } else {
      log.debug('skip setPreference', key, value);
    }
  };

  log.debug({preferenceKeys, preferences});

  return {preferences, getPreference, setPreference};
};
