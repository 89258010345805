import {period} from '@telia/cpa-web-common';
import classnames from 'classnames';
import React, {FunctionComponent} from 'react';
import {useNavigate} from 'react-router-dom';

import * as AppRoutes from '../../appRoutes';
import {useAccessNumberHistory} from '../../hooks/useAccessNumberHistory';
import {useFormattedText} from '../../hooks/useFormattedText';
import {getLog} from '../../log';
import Loading from '../Loading';
import {InformationLineFc} from '../common/InformationLine';
import List from '../common/List';
import ListItem from '../common/ListItem';
import PageSubtitle from '../common/PageSubtitle';
import {PageViewCounter} from '../metrics/PageViewCounter';

const log = getLog('AccessNumberHistory');
const {activeStatus, isActive, isFuture, isExpired, isValid, overlap} = period;

export const AccessNumberHistory: FunctionComponent = () => {
  const {loading, accessNumberHistoryList} = useAccessNumberHistory();
  const {accessNumberOrRange} = useFormattedText();
  log.debug('render', {loading, accessNumberHistoryList});

  const navigate = useNavigate();

  const {formatWithBrand} = AppRoutes.useBrandFormat();

  return (
    <React.Fragment>
      <PageSubtitle subtitle="Access Numbers History" />
      <PageViewCounter page="access-number-history" />

      {loading ? (
        <Loading />
      ) : (
        <div>
          {accessNumberHistoryList && !accessNumberHistoryList.isEmpty() ? (
            <div id="customer-list__wrapper" className={'customer-list--wrapper'}>
              <List className={'customer-list'}>
                {accessNumberHistoryList
                  .filter(({accessNumber}) => accessNumber)
                  .map((accessNumber) => (
                    <ListItem className={'customer-list__item'} key={accessNumber.accessNumber}>
                      <div>
                        <span className="bold width5rem noWrap">{accessNumberOrRange(accessNumber)}</span>
                        {/*<span className='width5rem rightText'>{series.from}</span> - <span className='width5rem'> {series.to}</span>*/}
                      </div>
                      {accessNumber.productHistory.map((product) => (
                        <div
                          key={accessNumber.accessNumber + '-' + product.id + '-' + product.startDate}
                          className="list__link list__item--hover-outline"
                          onClick={() =>
                            navigate(
                              formatWithBrand(
                                AppRoutes.PROVISIONING_CUSTOMER_SERVICE__customerId_contractId_productId,
                                product.customerId,
                                product.contractId,
                                product.id
                              )
                            )
                          }
                        >
                          <span className="disabled">From </span>
                          {product.startDate} <span className="disabled"> to </span>
                          <span className="width8rem">{product.endDate ? product.endDate : 'no end'}</span>
                          <span className={classnames('width5rem', activeStatus(product).toLowerCase() + 'Period')}>
                            {activeStatus(product)}
                          </span>
                          <span> {product.customerName}</span>
                        </div>
                      ))}
                    </ListItem>
                  ))}
              </List>
            </div>
          ) : (
            <InformationLineFc>
              {
                'No access numbers found' /*No access numbers found with current filter <span className='teliaPurple'>{customersFilter}</span>*/
              }
            </InformationLineFc>
          )}
        </div>
      )}
    </React.Fragment>
  );
};
