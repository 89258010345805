import React, {FunctionComponent} from 'react';

import '../Settlement.scss';

import {useProductTypes} from '../../../../hooks/useProductTypes';
import {getLog} from '../../../../log';
import {SettlementProductTypeSummary} from '../../../../model';
import Error from '../../../common/Error';
import {SettlementFeesTableGroupFc} from './SettlementFeesTableGroup';

const log = getLog('SettlementProductTypesSummary', 'INFO');

interface SettlementProductTypesSummaryFcProps {
  productTypeSummaries: SettlementProductTypeSummary[];
}

export const SettlementProductTypesSummaryFc: FunctionComponent<SettlementProductTypesSummaryFcProps> = ({
  productTypeSummaries,
}) => {
  const {getName} = useProductTypes();
  log.debug('SettlementProductTypesSummaryFc', productTypeSummaries);
  return !productTypeSummaries ? (
    <Error>Missing settlement's service summaries</Error>
  ) : (
    <section className="settlement__aggregate">
      <table id="settlementProductTypesSummaryTable" className="settlement__list">
        <thead>
          <tr>
            <th>Service type summary</th>
            <th className="rightText width0-on-small-only">
              <span>Count</span>
            </th>
            <th className="rightText width0-on-small-only">
              <span>Fee</span>
            </th>
            <th className="rightText">Total</th>
          </tr>
        </thead>
        <tbody>
          {productTypeSummaries.map(({productTypeId, total, fees}, i) => (
            <SettlementFeesTableGroupFc name={getName(productTypeId)} total={total} fees={fees} key={i} />
          ))}
        </tbody>
      </table>
    </section>
  );
};
