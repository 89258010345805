import {useQuery, gql} from '@apollo/client';
import {sort} from '@telia/cpa-web-common';
import brandsQuery from '../graphql/query/brands.graphql';
import {getLog} from '../log';
import {Brand, ID} from '../model';
import {useMemo} from 'react';
import {CurrencyCode} from '@telia/cpa-web-common/dist/model';

const log = getLog('useBrands', 'INFO');

interface BrandsQuery {
  brands: Brand[];
}

export interface BrandsHook {
  loading: boolean;
  brands: Brand[] | undefined;
  getCountryBrands: (countryId: ID) => Brand[] | undefined;
  getBrand: (id: ID) => Brand | undefined;
  getName: (id: ID) => string;
  getCountryId: (id: ID) => ID | undefined;
  getCurrencyCode: (id: ID) => CurrencyCode;
}

export const useBrands = () => {
  const {loading, data: {brands} = {}} = useQuery<BrandsQuery>(gql(brandsQuery));

  const sortedBrands = useMemo(() => brands?.slice().sort(sort.sortByNameComparator), [brands]);
  const getBrand = (brandId: ID) => brands?.find(({id}) => id === brandId);
  const getName: (id: ID) => string = (id: ID) => getBrand(id)?.name || id;
  const getCountryId: (id: ID) => ID | undefined = (id: ID) => getBrand(id)?.countryId;
  const getCurrencyCode: (id: ID) => CurrencyCode = (id: ID) => getBrand(id)?.currencyCode || '€$£';
  const getCountryBrands: (countryId: ID) => Brand[] | undefined = (countryId) =>
    brands?.filter((brand) => brand.countryId === countryId);

  const useBrandsHook: BrandsHook = {
    loading,
    brands: sortedBrands,
    getCountryBrands,
    getBrand,
    getName,
    getCountryId,
    getCurrencyCode,
  };
  log.debug(useBrandsHook);
  return useBrandsHook;
};
