import {PROD} from '@telia/cpa-web-common/dist/environments';
import log from 'loglevel';
import prefix from 'loglevel-plugin-prefix';

import {reportToSentry} from './sentry';

//  Logging format
prefix.reg(log);
prefix.apply(log, {
  nameFormatter(name) {
    return name || '';
  },
  format(level, name, timestamp) {
    return `[${level}]${name && name !== '' ? ` (${name})` : ''}`;
    //return `${chalk.gray(`[${timestamp}]`)} ${colors[level.toUpperCase()](level)} ${chalk.green(`${name}:`)}`;
  },
});

const MIN_LOG_LEVEL: log.LogLevelNumbers = process.env.NODE_ENV !== PROD ? log.levels.DEBUG : log.levels.WARN;
const DEFAULT_LOG_LEVEL: log.LogLevelNumbers = process.env.NODE_ENV !== PROD ? log.levels.INFO : log.levels.WARN;
log.setLevel(MIN_LOG_LEVEL);

type LogLevelString = keyof log.LogLevel;

export const getLog = (name: string, level?: LogLevelString) => {
  const logger = log.getLogger(name);
  const desiredLevel: log.LogLevelNumbers = level
    ? (log.levels[level.toUpperCase() as LogLevelString] as log.LogLevelNumbers)
    : DEFAULT_LOG_LEVEL;
  const maxLevel = desiredLevel > MIN_LOG_LEVEL ? desiredLevel : MIN_LOG_LEVEL;
  logger.setDefaultLevel(maxLevel);
  return logger;
};

//  Send ERROR level logs to Sentry
const originalFactory = log.methodFactory;

type ErrorMessage = Error | Object | string | number | boolean;
interface WrappedErrorMessage {
  message: string;
  error: any;
  data: any;
}

export const wrapErrorMessage: (...message: ErrorMessage[]) => WrappedErrorMessage = (...message) => {
  const composedMessage = message.reduce(
    (composed: string, obj) =>
      composed +
      (composed.length > 0 ? ' ' : '') +
      (obj instanceof Error ? obj.message : obj instanceof Object ? JSON.stringify(obj) : obj),
    ''
  );
  const error = message.find((e) => e instanceof Error);
  const data = message.filter((m) => m instanceof Object);
  return {message: composedMessage, error, data: data.length > 0 ? data : undefined} as WrappedErrorMessage;
};

// for reporting to sentry
log.methodFactory = (methodName, logLevel, loggerName) => {
  const originalMethod = originalFactory(methodName, logLevel, loggerName);
  return (...message) => {
    originalMethod(...message);
    if (logLevel >= log.levels.WARN) {
      const wrappedMessage = wrapErrorMessage(...message);
      reportToSentry({...wrappedMessage, level: methodName});
    }
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
(global as any).log = log;
