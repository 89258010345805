#import '../fragment/detailedMessage.graphql'

query detailedMessages(
  $accessNumber: AccessNumberString
  $contractId: ID
  $contractType: ContractTypeEnum
  $cref: String
  $customerId: ID
  $da: String
  $endDate: Date
  $isCustomer: Boolean
  $messageType: ID
  $oa: String
  $startDate: Date
  $status: ID
  $limit: Int
  $lastEvaluatedKey: String
) {
  detailedMessages(
    accessNumber: $accessNumber
    contractId: $contractId
    contractType: $contractType
    cref: $cref
    customerId: $customerId
    da: $da
    endDate: $endDate
    isCustomer: $isCustomer
    messageType: $messageType
    oa: $oa
    startDate: $startDate
    status: $status
    limit: $limit
    lastEvaluatedKey: $lastEvaluatedKey
  ) {
    items {
      ...detailedMessageFragment
    }
    lastEvaluatedKey
  }
}
