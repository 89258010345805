import React, {FC, FunctionComponent, PropsWithChildren, useState} from 'react';
import {getLog} from '../log';

const log = getLog('Errors', 'INFO');

export interface Errors {
  [key: string]: string | undefined;
}

export type ErrorsState = [Errors, React.Dispatch<React.SetStateAction<Errors>>];

const defaultErrorsState: ErrorsState = [
  {},
  (key) => {
    log.info('setErrorsState default', key);
  },
];
export const ErrorsContext = React.createContext<ErrorsState>(defaultErrorsState);

export const ErrorsProvider: FC<PropsWithChildren> = (props) => {
  const errorsState: ErrorsState = useState<Errors>({});
  const [errors] = errorsState;
  log.debug('render', errors);
  return <ErrorsContext.Provider value={errorsState}>{props.children}</ErrorsContext.Provider>;
};
