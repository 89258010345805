import {Button} from '@telia/styleguide';
import React, {FC} from 'react';

import {Entity, useFormState} from '../../hooks/useFormState';
import {getLog} from '../../log';
import {ReservedOa, SenderId} from '../../model';
import FormColumn from '../common/FormColumn';
import FormRow from '../common/FormRow';
import {SenderIdForm} from './SenderIdForm';

const log = getLog('SenderIdForm', 'INFO');

interface EditSenderIdFormProps {
  reservedOa: ReservedOa;
  senderIds: SenderId[];
  onSave: (reservedOas?: ReservedOa, senderIds?: SenderId[]) => void;
}

export const EditSenderIdForm: FC<EditSenderIdFormProps> = ({reservedOa, senderIds, onSave}) => {
  const formState = useFormState({
    isEditing: true,
    initialEntity: {
      senderIds: senderIds as unknown as Entity[],
    },
  });

  const {subEntityAt} = formState;

  return (
    <div className="form--compact">
      <SenderIdForm formState={formState} />
      <FormRow>
        <FormColumn>
          <div>
            <Button
              kind="primary"
              text="Save"
              onClick={() => {
                onSave && onSave(reservedOa, subEntityAt<SenderId[]>('senderIds'));
              }}
            />
          </div>
        </FormColumn>
      </FormRow>
    </div>
  );
};
