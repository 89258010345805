query accessNumberSeries($countryId: ID!) {
  accessNumberSeries(countryId: $countryId) {
    id
    name
    from
    to
    comment
    productTypes
  }
}
