import React, {FC, useMemo} from 'react';
import {ID, PropsWithClassName} from '../../model';
import {getLog} from '../../log';
import {useCountries} from '../../hooks/useCountries';
import {useBrands} from '../../hooks/useBrands';
import FormRow from './FormRow';
import FormColumn from './FormColumn';
import {FieldTypes, FieldWithFormState} from './field';
import {FormState} from '../../hooks/useFormState';

const log = getLog('CountryAndBrandFormRow', 'INFO');

interface CountryAndBrandFormRowFcProps extends PropsWithClassName {
  formState: FormState;
  canEdit?: boolean;
}

export const CountryAndBrandFormRowFc: FC<CountryAndBrandFormRowFcProps> = ({formState, canEdit = false}) => {
  const {countries} = useCountries();
  const {getCountryBrands, brands} = useBrands();
  const {isEditing, subEntityAt, onChange} = formState;
  const countryId = subEntityAt<ID>('countryId');
  const countryBrands = useMemo(() => getCountryBrands(countryId), [countryId, brands]);

  log.debug('render', {countryId});

  return (
    <FormRow>
      <FormColumn>
        <FieldWithFormState
          formState={formState}
          entityFieldId={'countryId'}
          label="Country"
          options={countries}
          type={FieldTypes.select}
          onChangeAlso={(_) => onChange('brandId')(null)}
          isEditing={canEdit && isEditing}
        />
        <FieldWithFormState
          formState={formState}
          entityFieldId={'brandId'}
          label="Brand"
          options={countryBrands}
          type={FieldTypes.select}
          isEditing={canEdit && isEditing}
        />
      </FormColumn>
    </FormRow>
  );
};
