import classnames from 'classnames';
import React, {FC} from 'react';
import {NavLink} from 'react-router-dom';
import './Submenu.scss';
import {domId} from '../../dom';
import {PropsWithClassName} from '../../model';

interface SubmenuProps extends PropsWithClassName {
  ariaLabel: string;
}

export const SubmenuFc: FC<SubmenuProps> = ({ariaLabel, children, className, ...others}) => (
  <nav className={classnames('submenu', className)} aria-labelledby="submenu__label" {...others}>
    <h3 id="submenu__label" className="visuallyhidden">
      {ariaLabel}
    </h3>
    <ul>{children}</ul>
  </nav>
);

interface SubmenuItemProps extends PropsWithClassName {
  link: string;
  text: string;
  badge?: number | null;
}

export const SubmenuItemFc: FC<SubmenuItemProps> = ({link, text, badge, children, className, ...others}) => {
  return (
    <li>
      <NavLink
        id={domId(text, 'submenuItem')}
        to={link}
        className={({isActive}) =>
          classnames('submenu__item', className, {
            'submenu__item--active': isActive,
          })
        }
      >
        {text}
        {badge !== undefined && badge !== null && <span className="badge">{badge}</span>}
      </NavLink>
    </li>
  );
};
