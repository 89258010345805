import classnames from 'classnames';
import React, {FC} from 'react';

import * as AppRoutes from '../../appRoutes';
import {useUser} from '../../hooks/useUser';
import {getLog} from '../../log';
import {PropsWithClassName} from '../../model';
import {CUSTOMER_STATISTICS_REPORT} from '../../permissions';
import {OverviewBoxFc} from '../common/OverviewBox';
import {OverviewBoxGridFc} from '../common/OverviewBoxGrid';
import PageSubtitle from '../common/PageSubtitle';
import {PageViewCounter} from '../metrics/PageViewCounter';

const log = getLog('ReportOverview');

export const ReportOverviewFc: FC<PropsWithClassName> = (props) => {
  const {hasPermission, isTelia, isCustomer} = useUser();
  const {formatWithBrand} = AppRoutes.useBrandFormat();
  const {currentUserBrand} = useUser();

  log.debug('props', {hasPermission, isTelia, isCustomer});
  return (
    <div className={classnames('Overview', props.className)}>
      <PageSubtitle subtitle="Overview" />
      <PageViewCounter page="overview" />

      {isTelia() && (
        <OverviewBoxGridFc>
          <OverviewBoxFc icon="graph" title="Monthly usage" link={formatWithBrand(AppRoutes.REPORT_MONTHLY)} />
          <OverviewBoxFc
            icon="document-xls"
            title="Detailed statistics"
            link={formatWithBrand(AppRoutes.REPORT_STATISTICS)}
          />
          <OverviewBoxFc icon="chat" title="Detailed messages" link={formatWithBrand(AppRoutes.REPORT_MESSAGES)} />
          {currentUserBrand?.id === 'TELIA_NO' && (
            <OverviewBoxFc icon="money" title="Charging & Billing" link={formatWithBrand(AppRoutes.REPORT_DCB)} />
          )}
          <OverviewBoxFc
            icon="calendar"
            title="Access number history"
            link={formatWithBrand(AppRoutes.REPORT_ACCESS_NUMBER_HISTORY)}
          />
          <OverviewBoxFc
            icon="security"
            title={`Reserved SenderID`}
            link={formatWithBrand(AppRoutes.REPORT_RESERVED_OAS)}
          />
        </OverviewBoxGridFc>
      )}
      {isCustomer() && hasPermission(CUSTOMER_STATISTICS_REPORT) && (
        <OverviewBoxGridFc>
          <OverviewBoxFc
            icon="document-xls"
            title="Detailed statistics"
            link={formatWithBrand(AppRoutes.REPORT_STATISTICS)}
          />
          <OverviewBoxFc icon="chat" title="Detailed messages" link={formatWithBrand(AppRoutes.REPORT_MESSAGES)} />
        </OverviewBoxGridFc>
      )}
      {/*<SettlementTotalsGraph />*/}
    </div>
  );
};
