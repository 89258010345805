import {dates} from '@telia/cpa-web-common';
import {Alert} from '@telia/styleguide';
import React, {FC} from 'react';

import './ReportPendingInfo.scss';

import {SettlementPeriod} from './ReportMonthlyUsageGraph';

interface ReportPendingInfoProps {
  incompleteDate?: SettlementPeriod;
  unapprovedDate?: SettlementPeriod;
}

export const ReportPendingInfoFc: FC<ReportPendingInfoProps> = (props) => {
  const {incompleteDate, unapprovedDate} = props;

  return incompleteDate || unapprovedDate ? (
    <div className="pending__info--container">
      {
        <Alert kind="warning">
          {incompleteDate ? (
            <p>
              The totals report for {dates.monthNames[parseInt(incompleteDate.month) - 1]} {incompleteDate.year} is
              pending and might show incomplete numbers until{' '}
              {dates.monthNames[parseInt(dates.nextMonthMm(incompleteDate.month)) - 1]} 3<sup>rd</sup> when settlements
              are finalized.
            </p>
          ) : (
            unapprovedDate && (
              <p>
                The totals report for {dates.monthNames[parseInt(unapprovedDate.month) - 1]} {unapprovedDate.year} is
                unapproved and may be adjusted before being approved.
              </p>
            )
          )}
        </Alert>
      }
    </div>
  ) : (
    <></>
  );
};
