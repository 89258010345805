import {isV4Format} from 'ip';

export const isV4IpAddress = (address: string): boolean => {
  return isV4Format(address) && address.split('.').every((section) => +section < 256);
};
export const isV4CidrAddress = (address: string): boolean => {
  const [ip, subnet] = address.split(/\/(.*)/s);

  if (ip && subnet) {
    return isV4IpAddress(ip) && /^([0-9]|[1-2][0-9]|3[0-2])?$/.test(subnet);
  } else return false;
};

export const isValidAddress = (address: string): boolean =>
  !!address && (isV4IpAddress(address) || isV4CidrAddress(address));
