import {Dict, ID, ProductFee, Settlement, SettlementProductTypeSummary} from '../../../model';
import {SettlementMonthTotal} from '../../../model';
import {sort} from '@telia/cpa-web-common';

export const feeName = (feeId: ID) => {
  switch (feeId && feeId.toUpperCase()) {
    case 'MONTHLY':
      return 'Monthly fee';
    case 'ESTABLISHMENT':
      return 'Establishment fee';
    case 'HOME':
      return 'On net';
    case 'DOMESTIC':
      return 'Off net domestic';
    case 'FOREIGN':
      return 'Off net foreign';
    case 'BLOCKED':
      return 'Blocked';
    case 'RBM_SINGLE_MESSAGE':
      return 'RBM single message';
    case 'RBM_A2P_CONVERSATION':
      return 'RBM A2P conversation';
    case 'RBM_AGENT':
      return 'RBM agent';
    case 'RBM_BASIC_MESSAGE':
      return 'RBM basic message';
    default:
      return feeId;
  }
};

export const generateMonthTotal: (settlements: Settlement[]) => SettlementMonthTotal = (settlements) => ({
  productTypeSummaries: groupProductTypeSummaries(settlements),
  total: settlements
    .map(({summary}) => summary.totalExclVat)
    .reduce((total, settlementTotal) => total + settlementTotal, 0),
  adjustmentSummary: settlements
    .map(({summary}) => summary.adjustmentTotal)
    .filter((adjustmentTotal) => adjustmentTotal)
    .reduce(
      (adjustmentSummay, adjustment, i, adjustments) => ({
        total: adjustmentSummay.total + adjustment,
        count: adjustments.length,
      }),
      {total: 0, count: 0}
    ),
});

export const groupProductTypeSummaries: (settlements: Settlement[]) => SettlementProductTypeSummary[] = (settlements) =>
  (
    Object.values(
      settlements
        .map((s) => s.productTypeSummaries || [])
        .filter((productTypeSummaries) => productTypeSummaries.length >= 0)
        .reduce((summaryDict, settlementProductTypeSummaries) => {
          settlementProductTypeSummaries
            .filter(({total}) => total !== 0)
            .forEach((summary) => aggregateSummaryToDict(summary, summaryDict));
          return summaryDict;
        }, {} as Dict<SettlementProductTypeSummary>)
    ) as SettlementProductTypeSummary[]
  ).sort(sort.sortByProductTypeIdComparator);

// summaryDict mutates!
const aggregateSummaryToDict = (
  summary: SettlementProductTypeSummary,
  summaryDict: Dict<SettlementProductTypeSummary>
) => {
  summaryDict[`${summary.productTypeId}`] = aggregateSummaries(summary, summaryDict[`${summary.productTypeId}`]);
};

const aggregateSummaries = (a: SettlementProductTypeSummary, b?: SettlementProductTypeSummary) => ({
  productTypeId: a.productTypeId,
  total: a.total + (b?.total || 0),
  fees: aggregateFees(a.fees, b?.fees),
});

export const aggregateFees: (a: ProductFee[], b?: ProductFee[]) => ProductFee[] = (a, b) =>
  a
    .filter(({total}) => total !== 0)
    .reduce((aggregatedFees, aFee) => {
      const foundFee = aggregatedFees?.find(({feeId}) => feeId === aFee.feeId);
      if (foundFee) {
        foundFee.quantity = (foundFee.quantity || 1) + (aFee.quantity || 1);
        foundFee.total = foundFee.total + aFee.total;
        if (foundFee.fee !== aFee.fee) {
          foundFee.fee = foundFee.total / foundFee.quantity;
        }
        return aggregatedFees;
      } else {
        return [{...aFee}, ...aggregatedFees].sort(sort.sortByProductFeeIdComparator);
      }
    }, b?.slice() || []);
